import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Form} from 'react-bootstrap';
import {
  checkUrlForDeepLink,
  checkMenuExists,
  checkRouteExists,
  checkMenu,
  isLinkActive,
  updatedCompanyData,
  capitalizeFirst,
} from '../Utils/commonUtils';

import {
  Q_R,
  WebLogo,
  RoiLogo,
  DropdownIcon,
  dropdownGreen,
  companyCheck,
} from '../Constants/Images';
import {ProfileModal} from '../Components';
import {setItem, getItem, removeItem} from '../Utils/LocalStorage';

import {setMenuState} from '../Store/Actions/CommanAction';
import commonApi from '../Services/CommonService';
import {errorMessageGuest} from '../Utils/ToastMessages';

import BellSvg from '../Svg/BellSvg';

import Scrollbars from 'react-custom-scrollbars';
import {
  getUserMenuPermesionList,
  getActiveCompanyUserMenus,
} from '../Store/Actions/MenuAction';
import {
  companyChangeLoader,
  getUpdateDataOfCompany,
} from '../Store/Actions/CommanAction';
import {db} from '../Firebase/firebase';
import {ref, onValue, off} from 'firebase/database';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {selected_lang} from '../Utils/env';
// import {addUnderscores2} from '../Utils/commonUtils';
import {labels} from '../Constants/Translations';
import {addUnderscores2} from '../Utils/commonUtils';
import {errorMessage} from '../Utils/ToastMessages';
import {useHistory} from 'react-router-dom';
import LoaderSpinner from '../Components/Modals/LoaderSpinner';
class Sidebar extends Component {
  state = {
    menuState: false,
    showProfileModal: false,
    openBottomMenu: getItem('openBottomMenu') ? true : false,
    additionalMenu: getItem('additionalMenu') ? true : false,
    showAlert: false,
    callData: null,
    dot: true,
    items: localStorage.getItem('workspaceItem')
      ? JSON.parse(localStorage.getItem('workspaceItem'))
      : [],
    hardcodeditems: [
      {company_name: 'ROI', id: 'Roi'},
      {company_name: 'The Market', id: 'The'},
      {company_name: 'Davis Ford', id: 'Davis'},
    ],
    workspaceId: localStorage.getItem('activeIds')
      ? localStorage.getItem('activeIds').split(',')
      : [],
    workspaceItem: localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],

    persnalToggle: false,
    menuPersonal: false,
    menuItems: localStorage.getItem('menuListOfUser')
      ? JSON.parse(localStorage.getItem('menuListOfUser'))
      : [],
    tabType: 'shops',
    employmentCount: [],
    showLoader: false,
    companyChange: false,
  };

  closeSubMenu = () => {
    removeItem('openBottomMenu');
    removeItem('additionalMenu');
  };

  firebaseCpmapanyStatusChange = async () => {
    const userId = localStorage.getItem('id');

    const databasePopup = ref(db, 'update_company_status/' + userId);
    await new Promise(resolve => setTimeout(resolve, 100));
    onValue(databasePopup, async snapshot => {
      if (snapshot.val()) {
        const triggerData = snapshot.val();
        // console.log(triggerData,'company snapshot data')
        const triggerTime = triggerData.time + 20;
        const currentTime = Date.now().toString().substring(0, 10);

        const switchCompany = localStorage.getItem('switchCompany');
        //  console.log(Boolean(localStorage.getItem('switchCompany')),'Boolean(localStorage.getItem')
        //  console.log(switchCompany,'switchCompanyswitchCompany')
        //  console.log(Boolean(switchCompany),'Boolean(switchCompany)')
        if (
          triggerTime &&
          triggerTime >= currentTime &&
          switchCompany === 'false'
        ) {
          this._getCompany('trigger');
        }
      }
    });
  };
  firebaseCpmapanyMenuChange = async () => {
    const databasePopup = ref(db, 'update_menus/');
    await new Promise(resolve => setTimeout(resolve, 100));

    onValue(databasePopup, async snapshot => {
      if (snapshot.val()) {
        const triggerData = snapshot.val();
        const triggerTime = triggerData.time + 20;

        const currentTime = Date.now().toString().substring(0, 10);

        // console.log(triggerData,'triggerDatatriggerData')
        // console.log(triggerTime,'triggerTimetriggerTime')
        // console.log(currentTime,'currentTimecurrentTime')
        if (triggerTime && triggerTime >= currentTime) {
          this._getActiveUserMenu();
          this.props.getUserMenuPermesionList();
        }
      }
    });
  };

  componentDidMount() {
    const {scrollbar} = this.refs;
    scrollbar.view.scrollTop = getItem('sidebarScroll');
    document.addEventListener('mousedown', this.handleClickOutside);

    window.addEventListener('beforeunload', this.closeSubMenu);
    window.addEventListener('beforeunload', this.cleanupFirebaseListener);

    this.firebaseCpmapanyStatusChange();
    this.firebaseCpmapanyMenuChange();
    this._getActiveUserMenu();
  }

  cleanupFirebaseListener() {
    // console.log('sadasdasdasdasdasdasdasda')
    const userId = localStorage.getItem('id');

    const database = ref(db, 'update_company_status/' + userId);
    const databasePopup = ref(db, 'update_menus/');

    off(database);
    off(databasePopup);
  }

  componentWillUnmount() {
    this.cleanupFirebaseListener();
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('beforeunload', this.closeSubMenu);
  }

  handleClickOutside = event => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.setState({
        menuPersonal: false,
      });
    }
  };

  UNSAFE_componentWillMount = () => {
    const {menuState} = this.props;

    this.setState({
      menuState,
    });

    const result = checkUrlForDeepLink();
    if (result && localStorage.getItem('id')) {
      this.AddUserInCompany();
      this.setState({
        companyChange: true,
      });
    }

    this._getUserData();
    this._hitCallApi();
    this._hitMessageApi();
    this._hitSnapShotApi();

    this._hitWorkspace();
    if (!result) {
      this._getCompany();
    }
  };

  AddUserInCompany = () => {
    const {pathname} = window.location;
    const urlParts = pathname.split('/').filter(Boolean);
    const payload = {
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      user_id: localStorage.getItem('id'),
    };

    commonApi
      .add_user_in_company(payload)
      .then(res => {
        if (res) {
          if (urlParts[0] === 'The-Market') {
            localStorage.setItem('marketId', '2');
          } else if (getItem('marketId')) {
            removeItem('marketId');
          }
          this._getCompany();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {menuState} = props;

    this.setState({
      menuState,
    });
  };

  _activeCheck = item => {
    const {location} = this.props;
    let isActive = false;
    const currentPage = location.pathname;
    const {pathname} = window.location;
    const urlParts = pathname.split('/').filter(Boolean);
    if (currentPage === item.link) {
      isActive = true;
    }

    if (urlParts.length > 1) {
      isActive = isLinkActive(urlParts, item.link);
    }

    if (item.submenu && Object.entries(item.submenu).length > 0) {
      for (let i = 0; i < Object.entries(item.submenu).length; i++) {
        const submenuItem = item.submenu[i];

        if (currentPage === submenuItem.link) {
          isActive = true;
        }
      }
    }

    return isActive;
  };

  _renderSubMenuItem = (item, index) => {
    const isActive = this._activeCheck(item) ? 'active' : '';
    return (
      <li key={`submenu_key_${index}`} className={isActive}>
        <Link to={item.link} title={item.label}>
          {item.icon && item.faIcon && (
            <FontAwesomeIcon icon={item.icon} className="icon" />
          )}
          {item.icon && !item.faIcon && <i className={`icon ${item.icon}`} />}
          <span className="menu-label">{item.label}</span>
        </Link>
      </li>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    // console.log(
    //   this.props.companyData,
    //   'this.props.companyDatathis.props.companyData',
    // );
    if (
      prevProps.Acive_Menu_Listing?.menus?.menu_details !==
      this.props.Acive_Menu_Listing?.menus?.menu_details
    ) {
      if (this.props.Acive_Menu_Listing.menus?.menu_details) {
        if (this.props.Acive_Menu_Listing.menus?.menu_details.length > 0) {
          localStorage.setItem(
            'menuListOfUser',
            JSON.stringify(this.props.Acive_Menu_Listing.menus?.menu_details),
          );
        } else {
          localStorage.setItem('menuListOfUser', '');
        }
        localStorage.setItem(
          'menuListOfUser',
          JSON.stringify(
            this.props.Acive_Menu_Listing.menus?.menu_details.length > 0
              ? this.props.Acive_Menu_Listing.menus?.menu_details
              : '',
          ),
        );

        this.setState({
          menuItems: this.props.Acive_Menu_Listing.menus?.menu_details,
        });
      }
    }

    if (
      this.props.showComponent !== prevProps.showComponent &&
      prevProps.showComponent === 'WorkSpace'
    ) {
      this._getCompany();
    }

    if (
      this.state.workspaceItem.length > 0 &&
      this.props.companyData &&
      this.props.companyData.company_name &&
      this.state.workspaceItem[0].company_name !==
        this.props.companyData?.company_name
    ) {
      const {updatedCompanies, updatedCompany} = updatedCompanyData(
        this.state.items,
        this.props.companyData,
      );

      // localStorage.setItem('workspaceItem', JSON.stringify(updatedCompanies));

      this.setState({
        workspaceItem: [updatedCompany],
      });
      localStorage.setItem(
        'activeWorkspaceItem',
        JSON.stringify([updatedCompany]),
      );
      this.props.getUpdateDataOfCompany({});
      this.updateItemById(updatedCompany?.id, updatedCompany);
    }

    if (this.props.chatListing !== prevProps.chatListing) {
      this._hitMessageApi();
      this._hitSnapShotApi();
    }
  }

  updateItemById = (idToUpdate, newItem) => {
    console.log(newItem, 'newItemnewItemnewItem');
    this.setState(prevState => ({
      items: prevState.items.map(item =>
        item.id === idToUpdate ? newItem : item,
      ),
    }));
  };

  _hitDataWorkspace = id => {
    const paylaod = {
      company_id: id,
    };
    commonApi
      .update_user_status(paylaod)
      .then(res => {
        if (id === '2') {
          localStorage.setItem('marketId', id);
        } else {
          removeItem('marketId');
        }

        this.props.getUserMenuPermesionList();
        this._getActiveUserMenu(id);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  _getUserData = () => {
    var data = {
      user_id: localStorage.getItem('user') ? localStorage.getItem('user') : 1,
    };
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          const img = localStorage.getItem('profilePic');
          if (res.data.profile_img) {
            if (img !== res.data.profile_img) {
              localStorage.setItem('profilePic', res.data.profile_img);
            }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  _hitCallApi = () => {
    commonApi
      .check_appointment_dolby()
      .then(res => {
        this.setState({
          callData: res,
        });
        this.setState({
          showAlert: res.success,
        });
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  _getCompany = type => {
    var payload = {
      // page: '1',
      company_users: false,
    };
    commonApi
      .get_company(payload)
      .then(res => {
        if (res.data?.length > 0) {
          this.setState({
            items: res.data,
          });

          const activeItems = res.data.filter(
            item => item.user_status === 'active',
          );
          if (activeItems[0]?.id === '2' && !getItem('marketId')) {
            setItem('marketId', '2');
          } else if (activeItems[0]?.id !== '2' && getItem('marketId')) {
            removeItem('marketId');
          }
          if (type === 'trigger') {
            this._getActiveUserMenu();
          }
          const employmentLength = res.data.filter(
            item => item.company_tab_type === 'employment',
          );
          this.setState({
            employmentCount: employmentLength,
          });

          if (activeItems && activeItems[0]?.company_tab_type === 'shops') {
            this.setState({tabType: 'shops'});
          } else {
            this.setState({tabType: 'employment'});
          }
          const activeIds = activeItems.map(item => item.id);
          console.log(activeIds, 'activeIdsactiveIdsactiveIds');

          console.log(
            activeItems,
            'activeItemsactiveItemsactiveItemsactiveItems',
          );
          let updatedIds = [...activeIds];
          let updatedItems = [...activeItems];

          const filterData = updatedIds
            .filter(
              item =>
                !this.state.workspaceId.some(dataItem => dataItem === item),
            )
            .concat(
              this.state.workspaceId.filter(
                item => !updatedIds.some(dataItem => dataItem === item),
              ),
            );

          console.log(
            this.state.workspaceId,
            'this.state.workspaceIdthis.state.workspaceId',
          );
          console.log(filterData, 'filterDatafilterDatafilterData');
          if (
            this.state.workspaceId.length === 0 ||
            filterData.length > 0 ||
            this.state.workspaceItem[0].company_name !==
              activeItems[0].company_name
          ) {
            const dataToStore = res.data;

            localStorage.setItem('workspaceItem', JSON.stringify(dataToStore));

            localStorage.setItem('activeIds', updatedIds.toString(','));

            localStorage.setItem(
              'activeWorkspaceItem',
              JSON.stringify(updatedItems),
            );

            // console.log(updatedItems[0]);

            if (updatedItems[0].created_by === localStorage.getItem('id')) {
              localStorage.setItem('userRole_in_company', 'Owner');
            } else {
              if (
                updatedItems[0].login_user_role_name &&
                updatedItems[0].login_user_role_name !== ''
              ) {
                const role = capitalizeFirst(
                  updatedItems[0].login_user_role_name,
                );
                console.log(role, '=======>rolerole');
                localStorage.setItem('userRole_in_company', role);
              }
              // updatedItems[0].company_users.forEach(user => {
              //   if (user.user_id === localStorage.getItem('id')) {
              //     localStorage.setItem('userRole_in_company', user.role_name);
              //   }
              // });
            }

            this.props.setWorkSpaceItem(updatedItems);
            this.setState({
              persnalToggle: res.personal === 'no' ? false : true,
              workspaceId: updatedIds,
              workspaceItem: updatedItems,
            });

            localStorage.setItem(
              'personal',
              res.personal === 'no' ? false : true,
            );
            localStorage.setItem(
              'workspaceIds',
              updatedIds.length > 0 ? updatedIds.toString(',') : '',
            );
          } else {
          }
        }
        if (
          localStorage.getItem('menuListOfUser') &&
          JSON.parse(localStorage.getItem('menuListOfUser'))
        ) {
        } else {
          setTimeout(() => {
            this._getActiveUserMenu();
          }, 1500);
        }

        localStorage.setItem('switchCompany', false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  _getActiveUserMenu = async id => {
    const {history} = this.props;
    const response = await this.props.getActiveCompanyUserMenus();

    console.log(response, '====> menus resposne');
    //Code For Deeplink
    const {pathname} = window.location;
    const urlParts = pathname.split('/').filter(Boolean);

    const result = checkUrlForDeepLink();

    let companyExist;
    if (result) {
      let payload;
      if (/^\d+$/.test(urlParts[0])) {
        payload = {
          company_name: '',
          user_id: urlParts[0],
        };
      } else {
        payload = {
          company_name: urlParts[0].replace(/-/g, ' '),
          user_id: urlParts[1],
        };
      }

      const companyResponse = await commonApi.company_info(payload);
      companyExist = companyResponse.company_exist;
      localStorage.setItem('companyExist', companyExist);
    }

    if (urlParts.length === 1) {
      try {
        const exists = await checkRouteExists(urlParts[0], response);

        if (!exists && !id) {
          errorMessage(
            'This Menu is not available, Please contact your Administrator',
          );
          history.push(response.menus.menu_details[0].link);
        }
      } catch (error) {
        console.error('Error checking menu existence:', error);
      }
    }

    if (
      urlParts.length > 3 &&
      (!result || !urlParts.includes('invite') || !companyExist)
    ) {
      errorMessage('Invalid Url');
      history.push(response.menus.menu_details[0].link);
    }

    if (result && localStorage.getItem('id') && companyExist) {
      if (urlParts.includes('invite') || urlParts.includes('profile')) {
        try {
          const exists = await checkMenu(urlParts[2], response);

          if (!exists && this.state.companyChange) {
            errorMessage(
              'This Menu is not available to you, Please contact your Administrator',
            );
            history.push(response.menus.menu_details[0].link);
          }
        } catch (error) {
          console.error('Error checking menu existence:', error);
        }
      }
    }

    // setTimeout(() => {
    const res = this.props.Acive_Menu_Listing;

    console.log('pathname', pathname);

    if (res.menus?.menu_details) {
      const newItem = {
        id: '2',
        menu_name: 'Home',
        link: '/customer-mall',
        status: 'active',
        created_at: '2024-01-22 17:48:11',
        updated_at: '2024-01-22 07:48:11',
      };

      if (
        getItem('marketId') === '2' &&
        res.menus?.menu_details[0].id !== '2'
      ) {
        res.menus?.menu_details.unshift(newItem);
      }

      if (res.menus?.menu_details.length > 0) {
        localStorage.setItem(
          'menuListOfUser',
          JSON.stringify(res.menus?.menu_details),
        );
      } else {
        localStorage.setItem('menuListOfUser', '');
      }
      localStorage.setItem(
        'menuListOfUser',
        JSON.stringify(
          res.menus?.menu_details.length > 0 ? res.menus?.menu_details : '',
        ),
      );

      this.setState({
        menuItems: res.menus?.menu_details,
      });
      const {history} = this.props;

      if (res.menus?.menu_details.length > 0 && id) {
        history.push(res.menus?.menu_details[0].link);
      } else {
        if (pathname === '/' && localStorage.getItem('id')) {
          history.push(res.menus?.menu_details[0].link);
        }
      }
    }

    setTimeout(() => {
      this.props.companyChangeLoader(false);
      this.setState({showLoader: false});
    }, 4000);
    // }, 2000);
  };

  _hitSnapShotApi = () => {
    commonApi
      .get_accomplishment_invitation_by_login_id()
      .then(res => {
        localStorage.setItem(
          'snapshot_invitation',
          res.accomplishment_invitations.length === 0
            ? ''
            : res.accomplishment_invitations.length,
        );
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  _hitMessageApi = () => {
    commonApi
      .whole_message_status()
      .then(res => {
        this.setState({
          dot: res.read,
        });
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  _hitWorkspace = () => {};

  _toggleSubmenu = index => {
    const submenuLi = document.getElementById(`parent_menu_${index}`);
    if (submenuLi.classList.contains('open')) {
      submenuLi.classList.remove('open');
    } else {
      submenuLi.classList.add('open');
    }
    this._hitCallApi();
    this._hitMessageApi();
    this._hitSnapShotApi();
  };

  _renderMenuItem = (item, index) => {
    let isActive = this._activeCheck(item) ? 'active removedot' : '';
    let isActiveMessage = this._activeCheck(item) ? 'active removedot' : '';

    isActiveMessage +=
      item.menu_name === 'Messages' && !this.state.dot ? ' message_active' : '';

    isActive += item.link === '' ? ' withPadding' : '';
    // console.log(labels.addUnderscores2(item.menu_name),'you boy')
    let translationName = addUnderscores2(item.menu_name);
    let translationNameShowMenu =
      translationName && labels[translationName][selected_lang];
    // console.log(translationName,'translationName')
    return (
      <li
        id={`parent_menu_${index}`}
        key={`menu_key_${index}`}
        style={{position: 'relative'}}
        className={item.menu_name === 'Messages' ? isActiveMessage : isActive}>
        <span className="menu-text" onClick={() => this._toggleSubmenu(index)}>
          {item.link !== '' &&
            (getItem('userRole') === 'guest' ? (
              <Link
                to={index > 4 ? item.link : ''}
                title={translationNameShowMenu}
                onClick={e => {}}>
                {item.icon && item.faIcon && (
                  <FontAwesomeIcon icon={item.icon} className="icon" />
                )}
                {item.icon && !item.faIcon && (
                  <i className={`icon ${item.icon}`} />
                )}
                <span className="menu-label">{translationNameShowMenu}</span>
              </Link>
            ) : (
              <Link to={item.link} title={translationNameShowMenu}>
                {item.icon && item.faIcon && (
                  <FontAwesomeIcon icon={item.icon} className="icon" />
                )}
                {item.icon && !item.faIcon && (
                  <i className={`icon ${item.icon}`} />
                )}

                {item.img && !item.faIcon && (
                  <img className="icon" src={item.img} alt="" />
                )}

                <span className="menu-label">{translationNameShowMenu}</span>
              </Link>
            ))}
          {item.link === '' && (
            <>
              {item.icon && (
                <FontAwesomeIcon icon={item.icon} className="icon" />
              )}
              <span className="menu-label">{translationNameShowMenu}</span>
            </>
          )}
        </span>
      </li>
    );
  };

  _logLink = () => {
    setItem('haha', true);
  };

  toggleModal = (modal, state) => {
    this.setState({
      [modal]: state,
    });
  };
  toggleItem = itemId => {
    this.setState({
      companyChange: false,
    });
    // this.cleanupFirebaseListener();
    if (!this.state.workspaceId.includes(itemId.id)) {
      this.setState({workspaceId: [itemId.id], workspaceItem: [itemId]}, () => {
        localStorage.setItem('switchCompany', true);
        localStorage.setItem('workspaceId', itemId.id);
        localStorage.setItem(
          'SelectedWorkSpaceArray',
          JSON.stringify([itemId]),
        );

        this._hitDataWorkspace(itemId.id);
        this.props.companyChangeLoader(true);
        this.props.setWorkSpaceItem(itemId);
      });
    }
  };

  handleScroll = values => {
    const {scrollTop} = values.target;

    setItem('sidebarScroll', scrollTop);
  };

  render() {
    const {
      menuState,
      showProfileModal,
      openBottomMenu,
      showAlert,
      callData,
      additionalMenu,
      tabType,
      showLoader,
    } = this.state;

    let menuStatus = '';
    let windowWidth = window.innerWidth;
    if (windowWidth < 1169) {
      menuStatus = menuState ? '' : 'open';
    } else {
      menuStatus = menuState ? 'open' : '';
    }

    return (
      <div className={`sidebar-menu-wrapper ${menuStatus}`}>
        <div className={`padding sidebar_custom_padding ${menuStatus}`}>
          <div className="logo-wrapper">
            {menuStatus && (
              <Link to="/">
                <img src={RoiLogo} alt="" />
              </Link>
            )}
            <span
              className="bars-icon"
              onClick={() => this.props.setMenuState(!menuState)}>
              {menuState && (
                <i
                  className="fas fa-bars-staggered"
                  style={{color: '#949494'}}
                />
              )}
              {!menuState && (
                <i className="fas fa-angle-right" style={{color: '#949494'}} />
              )}
            </span>
          </div>

          <button
            className="userType"
            ref={ref => (this.dropdownRef = ref)}
            onClick={() => {
              this.setState({
                menuPersonal: !this.state.menuPersonal,
              });
            }}>
            <div className="w-100 menu-button">
              <div className="d-flex align-items-center type">
                <p
                  className="single-line-text single-line-text-trunkey text-left text-break"
                  style={{
                    color: this.state.menuPersonal ? '#68b245' : '#fff',
                    margin: '-8px',
                  }}>
                  {this.state.workspaceItem.length > 0
                    ? this.state.workspaceItem[0]?.company_name
                    : 'ROI'}
                </p>
              </div>
              {menuStatus && (
                <img
                  src={this.state.menuPersonal ? dropdownGreen : DropdownIcon}
                  alt=""
                />
              )}
            </div>
            {this.state.menuPersonal && (
              <div className="dropdown-wrapper company_dropdown">
                {showLoader && <LoaderSpinner />}
                <div className="messages-header sidebar_menu-dropdown mb-3">
                  <div className="event-tabs tabs-style-2 mb-0">
                    <ul>
                      {this.state.employmentCount &&
                        this.state.employmentCount.length > 0 && (
                          <li>
                            <Button
                              className={
                                tabType === 'employment' ? 'active' : ''
                              }
                              onClick={e => {
                                e.stopPropagation();
                                this.setState({tabType: 'employment'});
                              }}>
                              Employment
                            </Button>
                          </li>
                        )}
                      <li>
                        <Button
                          className={tabType === 'shops' ? 'active' : ''}
                          onClick={e => {
                            e.stopPropagation();
                            this.setState({tabType: 'shops'});
                          }}>
                          Shops
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="dropdown">
                  {Object.entries(this.state.items).length > 0 ? (
                    this.state.items
                      .filter(item => {
                        if (tabType === 'shops') {
                          return item.company_tab_type === 'shops';
                        } else if (tabType === 'employment') {
                          return item.company_tab_type === 'employment';
                        }

                        return true;
                      })
                      .map((item, index) => {
                        return (
                          <li
                            onClick={e => {
                              e.stopPropagation();

                              this.toggleItem(item);
                              this.setState({
                                menuPersonal: !this.state.menuPersonal,
                              });
                              // this.setState({showLoader: !showLoader})
                            }}
                            key={item.id}
                            className={`active d-flex align-items-center justify-content-between ${
                              item.active ? 'active-item' : ''
                            }`}>
                            <p className="single-line-text single-line-text-trunkey text-break">
                              {item.company_name}
                            </p>
                            <div>
                              {this.state.workspaceId.includes(item.id) && (
                                <div>
                                  <img src={companyCheck} alt="" />
                                </div>
                              )}
                            </div>
                          </li>
                        );
                      })
                  ) : (
                    <li
                      className={`active d-flex align-items-center justify-content-between ${'active-item'}`}>
                      <p className="single-line-text">Davis Ford</p>
                      <div>
                        <Form.Check
                          type="radio"
                          checked={true}
                          className="switchBlock"
                          id={`custom-switch`}
                        />
                      </div>
                    </li>
                  )}
                </ul>

                <div className="create_company_div">
                  <p>Own a company?</p>
                  <p
                    className="publicLink"
                    onClick={() => {
                      this.props.setShowComponent('WorkSpace');
                    }}>
                    Create Profile
                  </p>
                </div>
              </div>
            )}
          </button>

          <div className="sidebar_bar_main">
            <div
              style={{
                color: 'white',
                paddingTop: '15px',
                paddingBottom: ' 10px',
              }}>
              $4,500 (60%)
            </div>
            <div className="score-number-main">
              <div className="score-bar">
                <ProgressBar
                  className="okr-bar"
                  variant="warning"
                  now={60}
                  style={{height: '12px'}}
                />
              </div>
            </div>
          </div>

          <Button className=" shift_button animate__animated">
            Start Shift
          </Button>

          <div className="sidebar-navigation">
            {showAlert && (
              <Link
                className="btn btn-dark text-light"
                to={{
                  pathname: '/appointment-call',
                  state: {
                    callData: callData,
                  },
                }}>
                {callData.message}
              </Link>
            )}

            <Scrollbars
            renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
            // Hide the vertical scrollbar
            renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}
              onScroll={this.handleScroll}
              ref="scrollbar"
              onScrol
              oncrollTop={getItem('sidebarScroll')}>
              {Object.entries(this.state.menuItems).length > 0 && (
                <ul className="navigation-items">
                  {this.state.menuItems
                    .slice(0, 5)
                    .map((item, index) => this._renderMenuItem(item, index))}
                </ul>
              )}
              <div style={{position: 'relative'}}>
                {menuStatus && this.state.menuItems.length >= 6 && (
                  <div
                    className={`mt-3 as-link shift_button additional_menu_icon animate__animated ${
                      additionalMenu ? 'rotate_down' : ''
                    }`}
                    style={{
                      color: '#949494',
                      fontWeight: 'bold',
                    }}
                    onClick={() => {
                      this.setState({additionalMenu: !additionalMenu});
                      if (additionalMenu) {
                        removeItem('additionalMenu');
                      } else {
                        setItem('additionalMenu', 1);
                      }
                    }}>
                    Additional Menus
                  </div>
                )}
              </div>

              <div
                className={`additional-menus ${
                  additionalMenu ? 'menus-visible' : ''
                }`}>
                {additionalMenu &&
                  Object.entries(this.state.menuItems).length > 0 && (
                    <ul className="navigation-items sidebar_ul_items">
                      {this.state.menuItems
                        .slice(5)
                        .map((item, index) =>
                          this._renderMenuItem(item, index),
                        )}
                    </ul>
                  )}
              </div>
            </Scrollbars>
          </div>
          {/* <div class="overLay_menu"></div> */}
          <div className="bottomPart-menu ">
            <div
              className={
                menuState
                  ? 'mb-3 shift_button animate__animated'
                  : 'mb-3 ml-4 shift_button animate__animated'
              }>
              <Link to="/scanqr">
                <img src={Q_R} alt="" />
              </Link>
            </div>
            <div className="user-menu">
              <Link
                to="/profile"
                style={{textDecoration: 'none', display: 'flex'}}>
                <div className="user-img as-link">
                  <img
                    src={
                      this.props.profileImgScrollBar
                        ? this.props.profileImgScrollBar
                        : getItem('profilePic')
                    }
                    alt=""
                  />
                </div>
              </Link>

              <div className="footer-icons">
                <Link className="icon" to="/notification">
                  <span className="as-link NotifyMe">
                    <span
                      className={
                        this.props.snapShotCount &&
                        this.props.snapShotCount.length >= 1
                          ? 'notifyCount'
                          : localStorage.getItem('snapshot_invitation') &&
                            localStorage.getItem('snapshot_invitation') !==
                              '' &&
                            'notifyCount'
                      }>
                      {this.props.snapShotCount &&
                      this.props.snapShotCount.length >= 1
                        ? this.props.snapShotCount.length
                        : localStorage.getItem('snapshot_invitation')}
                    </span>
                    {this.props.location.pathname === '/notification' ? (
                      <BellSvg fill={'#52bd41'} />
                    ) : (
                      <BellSvg fill={'#949494'} />
                    )}
                  </span>
                </Link>

                <div
                  className="as-link"
                  onClick={() => {
                    this.setState({openBottomMenu: !openBottomMenu});
                    if (openBottomMenu) {
                      removeItem('openBottomMenu');
                    } else {
                      setItem('openBottomMenu', 1);
                    }
                  }}>
                  {!openBottomMenu && (
                    <span
                      className="icon fas fa-ellipsis-vertical"
                      style={{color: '#949494'}}
                    />
                  )}

                  {openBottomMenu && (
                    <span
                      className="icon fas fa-times"
                      style={{color: '#949494'}}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {openBottomMenu && (
            <div
              className={`footer-menu-popup animate__animated padding_bottom_menu`}>
              <div>
                <img
                  className=" mb-4"
                  style={{width: '66px'}}
                  src={WebLogo}
                  alt=""
                />
              </div>
              <ul>
                <li
                  className={
                    this.props.location.pathname === '/settings' ? 'active' : ''
                  }>
                  <Link className="icon" to="/settings">
                    <span className="icon-setting_preferences" /> Settings
                  </Link>
                </li>
                <li
                  className={
                    this.props.location.pathname === '/history' ? 'active' : ''
                  }>
                  <Link className="icon" to="/history">
                    <span className="icon-history" /> History
                  </Link>
                </li>

                <li
                  className={
                    this.props.location.pathname === '/widgets' ? 'active' : ''
                  }>
                  <Link className="icon" to="/widgets">
                    <span className="icon-widgets" /> Widgets
                  </Link>
                </li>
              </ul>
            </div>
          )}

          {showProfileModal && (
            <ProfileModal
              show={showProfileModal}
              closeModal={() => this.toggleModal('showProfileModal', false)}
              user_id={1}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  menuState: state.menuState,
  chatListing: state.chatListing,
  Acive_Menu_Listing: state.menuActiveList,
  companyData: state.updateCompanyName,
});

const mapDispatchToProps = {
  getUserMenuPermesionList,
  setMenuState,
  getActiveCompanyUserMenus,
  companyChangeLoader,
  getUpdateDataOfCompany,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Sidebar));
