import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import moment from 'moment';
import DatePicker from 'react-multi-date-picker';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {list} from '../../Utils/commonUtils';
import CustomModal from '../CustomModal';
import PersonalCreateModel from '../Modals/PersonalCreateModel';
import {get_my_all_associate_workspaces} from '../../Store/Actions/SessionAction';
import {useDispatch, useSelector} from 'react-redux';

import TimePicker from 'rc-time-picker';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import CustomDropdown from '../CustomDropdown';
import { RoiLogo } from '../../Constants/Images';
import LoaderSpinner from '../Modals/LoaderSpinner';
import Calender from '../FeedBack/Calender';

const EditAppointment = props => {
  const [subTabType, setSubTabsType] = useState('general');
  const [appointmentType, setAppointmentType] = useState('');
  const [discription, setDiscription] = useState('');
  const [duration, setDuration] = useState('15');
  const [title, setTitle] = useState('');
  const [cost, setCost] = useState('');
  const [appointmentTypeId, setAppointmentTypeId] = useState('1');
  const [validated, setValidated] = useState(false);
  // const [closeTime, setCloseTime] = useState("");
  // const [openTime, setOpenTime] = useState("");

  // const [date, setDate] = useState(new Date());
  const [button, setButton] = useState(true);
  const [days, setDays] = useState('mon');
  const [daysForTime, setDaysForTime] = useState([]);
  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [thursday, setThursday] = useState([]);
  const [wednesday, setWednesday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);
  const [sunday, setSunday] = useState([]);
  const [object, setObjaect] = useState('');
  const [unavailableDButton, setUnavailableDButton] = useState(false);
  const [unavailableValue, setUnavailableValue] = useState('');

  const [timeValue, setTimeValue] = useState('');

  const [showComponent, setShowComponent] = useState('');

  const [deleteSchedule, setDeleteSchedule] = useState({
    name: '',
    scheduleItem: {},
  });
  const [showSpinner, setShowSpinner] = useState(true);
  const [startTimeForSectedDays, setStartTimeForSectedDays] = useState('');
  const [endTimeForSectedDays, setEndTimeForSectedDays] = useState('');

  const [workSpaceIds, setWorkSpaceIds] = useState([]);
  // const today = new Date()
  // const tomorrow = unavailableValue

  // tomorrow.setDate(tomorrow.getDate())
  const [topIcons, setTopIcons] = useState({
    // focus: false,
    // location: false,
    // vaccinate: false,
    // dollar: false,
    // store: false,
    // bullseye: false,
    personal: false,
  });

  const [values, setValues] = useState([]);

  const [startDaysTime, setStartDaysTime] = useState({
    mon: '',
    tus: '',
    wed: '',
    thu: '',
    fri: '',
    sat: '',
    sun: '',
  });
  const [closeDaysTime, setCloseDaysTime] = useState({
    mon: '',
    tus: '',
    wed: '',
    thu: '',
    fri: '',
    sat: '',
    sun: '',
  });
  const [oldSchedule, setOldSchedule] = useState({
    mon: 0,
    tus: 0,
    wed: 0,
    thu: 0,
    fri: 0,
    sat: 0,
    sun: 0,
  });

  const [selectedTime, setSelectedTime] = useState(moment().startOf('day')); // Initialize with 12:00 AM

  const dropdownValues = [
    {id: '15', name: '15 Minutes'},
    {id: '30', name: '30 Minutes'},
    {id: '45', name: '45 Minutes'},
    {id: '60', name: '60 Minutes'},
  ];

  const handleTimeChange = time => {
    setSelectedTime(time);
  };

  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workSpaceIds.length > 0) {
    } else {
      // Filter the workspaces with status "active" from the API response
      const userExist = listWorspacerator.filter(
        workspace => workspace.status === 'active',
      );

      // Extract the 'id' values from 'userExist'
      const updatedIds = userExist.map(workspace => workspace.id);

      if (personal === 'yes') {
        updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      }

      // setActiveWorkspacesId(updatedIds);
      setWorkSpaceIds(updatedIds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  const checkForDays = nameOfDay => {
    if (!daysForTime.includes(nameOfDay)) {
      setDaysForTime(previous => [...previous, nameOfDay]);
    } else {
      setDaysForTime(previous => previous.filter(item => item !== nameOfDay));
    }
  };

  const showModelFordays = (day, Stime, Etime, type) => {
    setDaysForTime([type]);
    setShowComponent('model-for-time');
    setStartTimeForSectedDays(moment(Stime, 'hh:mm A').format('hh:mm A'));
    setEndTimeForSectedDays(moment(Etime, 'hh:mm A').format('hh:mm A'));
    // setCheckingdaysTimeIsAlreadyAvaible(day);
  };

  const addTimeInselectedDays = () => {
    setShowComponent('');
    if (daysForTime.includes('mon')) {
      setMonday(
        monday.filter(
          obj =>
            (obj.start_time >=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')),
        ),
      );

      // setMonday(cur => [...cur, filteredMonday]);
      setMonday(cur => [
        ...cur,
        {
          start_time: moment(startTimeForSectedDays, 'hh:mm A').format(
            'hh:mm A',
          ),
          end_time: moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A'),
        },
      ]);
    }
    if (daysForTime.includes('tus')) {
      setTuesday(
        tuesday.filter(
          obj =>
            (obj.start_time >=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')),
        ),
      );
      setTuesday(pre => [
        ...pre,
        {
          start_time: moment(startTimeForSectedDays, 'hh:mm A').format(
            'hh:mm A',
          ),
          end_time: moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A'),
        },
      ]);
    }
    if (daysForTime.includes('wed')) {
      setWednesday(
        wednesday.filter(
          obj =>
            (obj.start_time >=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')),
        ),
      );
      setWednesday(pre => [
        ...pre,
        {
          start_time: moment(startTimeForSectedDays, 'hh:mm A').format(
            'hh:mm A',
          ),
          end_time: moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A'),
        },
      ]);
    }
    if (daysForTime.includes('thu')) {
      setThursday(
        thursday.filter(
          obj =>
            (obj.start_time >=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')),
        ),
      );
      setThursday(pre => [
        ...pre,
        {
          start_time: moment(startTimeForSectedDays, 'hh:mm A').format(
            'hh:mm A',
          ),
          end_time: moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A'),
        },
      ]);
    }
    if (daysForTime.includes('fri')) {
      setFriday(
        friday.filter(
          obj =>
            (obj.start_time >=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')),
        ),
      );
      setFriday(pre => [
        ...pre,
        {
          start_time: moment(startTimeForSectedDays, 'hh:mm A').format(
            'hh:mm A',
          ),
          end_time: moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A'),
        },
      ]);
    }
    if (daysForTime.includes('sat')) {
      setSaturday(
        friday.filter(
          obj =>
            (obj.start_time >=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')),
        ),
      );
      setSaturday(pre => [
        ...pre,
        {
          start_time: moment(startTimeForSectedDays, 'hh:mm A').format(
            'hh:mm A',
          ),
          end_time: moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A'),
        },
      ]);
    }
    if (daysForTime.includes('sun')) {
      setSunday(
        sunday.filter(
          obj =>
            (obj.start_time >=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <=
              moment(startTimeForSectedDays, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <=
                moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A')),
        ),
      );
      setSunday(pre => [
        ...pre,
        {
          start_time: moment(startTimeForSectedDays, 'hh:mm A').format(
            'hh:mm A',
          ),
          end_time: moment(endTimeForSectedDays, 'hh:mm A').format('hh:mm A'),
        },
      ]);
    }
    successMessage('Schedule added ');
  };

  const unavailableDatesHandle = array => {
    const yoo = moment(new Date()).format('YYYY/MM/DD');
    let ar = array.join(',');
    let sp = ar.split(',');
    for (let i = 0; i < sp.length; i++) {
      if (sp[i] > yoo || sp[i] === yoo) {
        // setValue(sp )
        // setValue(moment(value).format('YYYY-MM-D'))
        // const xyz = fDate(sp);
        //
        //
        // setValue(xyz)
      } else if (sp[i] === ['']) {
      } else {
        errorMessage('Please select a valid date');
        delete sp[i];
      }
    }
    const xyz = fDate(sp);
    setValues(xyz);
  };

  const fDate = arr => {
    let us = [];

    for (let i = 0; i < arr.length; i++) {
      const dddd = new Date(arr[i]);
      us.push(moment(dddd).format('YYYY-MM-D'));
    }
    //
    return us;
  };

  //  const dateHandle = (e) =>{
  //   setDate(...e.target.value)
  //  }
  // const handleChange = event => {
  //   const result = event.target.value.replace(/\D/g, '');

  //   setCost(result);
  // };

  //

  const handleObject = () => {
    const objectData = JSON.parse(object);

    setMonday(objectData.monday);
    setTuesday(objectData.tuesday);
    setWednesday(objectData.wednesday);
    setThursday(objectData.thursday);
    setFriday(objectData.friday);
    setSaturday(objectData.saturday);
    setSunday(objectData.sunday);
    setValues(
      unavailableValue === 'Invalid date' || unavailableValue === ''
        ? ''
        : unavailableValue.split(','),
    );

    setOldSchedule({
      mon: objectData.monday.length,
      tus: objectData.tuesday.length,
      wed: objectData.wednesday.length,
      thu: objectData.thursday.length,
      fri: objectData.friday.length,
      sat: objectData.saturday.length,
      sun: objectData.sunday.length,
    });
  };

  // var monTime = {
  //   start_time: startDaysTime.mon,
  //   end_time: closeDaysTime.mon,
  // };

  const comparee = () => {
    if (monday) {
      const same = monday.find(
        obj =>
          !(
            (obj.start_time >
              moment(startDaysTime.mon, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >
                moment(closeDaysTime.mon, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <
              moment(startDaysTime.mon, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <
                moment(closeDaysTime.mon, 'hh:mm A').format('hh:mm A'))
          ),
      );

      if (same) {
        errorMessage('Time already reserved ');
      } else {
        if (
          startDaysTime.mon === closeDaysTime.mon ||
          startDaysTime.mon > closeDaysTime.mon ||
          startDaysTime.mon === '' ||
          closeDaysTime.mon === ''
        ) {
          errorMessage('Close time must be greater than open time ');
        } else if (
          moment(startDaysTime.mon, 'HH:mm A')
            .add(duration, 'minutes')
            .format('HH:mm A') >
          moment(closeDaysTime.mon, 'HH:mm A').format('HH:mm A')
        ) {
          errorMessage('Duration between time must be ' + duration);
        } else if (startDaysTime.mon < closeDaysTime.mon) {
          successMessage('Schedule added ');
          // var time = new Date("Thu Apr 28 2022"+startDaysTime.mon);
          setMonday(cur => [
            ...cur,
            {
              start_time: moment(startDaysTime.mon, 'hh:mm A').format(
                'hh:mm A',
              ),
              end_time: moment(closeDaysTime.mon, 'hh:mm A').format('hh:mm A'),
            },
          ]);
          setCloseDaysTime({
            ...closeDaysTime,
            mon: '',
          });
          setStartDaysTime({
            ...startDaysTime,
            mon: '',
          });
          showModelFordays(monday, startDaysTime.mon, closeDaysTime.mon, 'mon');
          setButton(true);
        }
      }
    }
  };

  const compareeTuesday = () => {
    if (tuesday) {
      //
      const same = tuesday.find(
        obj =>
          !(
            (obj.start_time >
              moment(startDaysTime.tus, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >
                moment(closeDaysTime.tus, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <
              moment(startDaysTime.tus, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <
                moment(closeDaysTime.tus, 'hh:mm A').format('hh:mm A'))
          ),
      );

      if (same) {
        errorMessage('Time already reserved ');
      } else {
        if (
          startDaysTime.tus === closeDaysTime.tus ||
          startDaysTime.tus > closeDaysTime.tus ||
          startDaysTime.tus === '' ||
          closeDaysTime.tus === ''
        ) {
          errorMessage('Close time must be greater than open time ');
        } else if (
          moment(startDaysTime.tus, 'HH:mm A')
            .add(duration, 'minutes')
            .format('HH:mm A') >
          moment(closeDaysTime.tus, 'HH:mm A').format('HH:mm A')
        ) {
          errorMessage('Duration between time must be ' + duration);
        } else if (startDaysTime.tus < closeDaysTime.tus) {
          successMessage('Schedule added ');
          setTuesday(cur => [
            ...cur,
            {
              start_time: moment(startDaysTime.tus, 'hh:mm A').format(
                'hh:mm A',
              ),
              end_time: moment(closeDaysTime.tus, 'hh:mm A').format('hh:mm A'),
            },
          ]);
          setCloseDaysTime({
            ...closeDaysTime,
            tus: '',
          });
          setStartDaysTime({
            ...startDaysTime,
            tus: '',
          });
          showModelFordays(
            tuesday,
            startDaysTime.tus,
            closeDaysTime.tus,
            'tus',
          );
          setButton(true);
        }
      }
    }
  };
  const compareeWed = () => {
    if (wednesday) {
      const same = wednesday.find(
        obj =>
          !(
            (obj.start_time >
              moment(startDaysTime.wed, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >
                moment(closeDaysTime.wed, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <
              moment(startDaysTime.wed, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <
                moment(closeDaysTime.wed, 'hh:mm A').format('hh:mm A'))
          ),
      );

      if (same) {
        errorMessage('Time already reserved ');
      } else {
        if (
          startDaysTime.wed === closeDaysTime.wed ||
          startDaysTime.wed > closeDaysTime.wed ||
          startDaysTime.wed === '' ||
          closeDaysTime.wed === ''
        ) {
          errorMessage('Close time must be greater than open time ');
        } else if (
          moment(startDaysTime.wed, 'HH:mm A')
            .add(duration, 'minutes')
            .format('HH:mm A') >
          moment(closeDaysTime.wed, 'HH:mm A').format('HH:mm A')
        ) {
          errorMessage('Duration between time must be ' + duration);
        } else if (startDaysTime.wed < closeDaysTime.wed) {
          successMessage('Schedule added ');
          setWednesday(cur => [
            ...cur,
            {
              start_time: moment(startDaysTime.wed, 'hh:mm A').format(
                'hh:mm A',
              ),
              end_time: moment(closeDaysTime.wed, 'hh:mm A').format('hh:mm A'),
            },
          ]);
          setCloseDaysTime({
            ...closeDaysTime,
            wed: '',
          });
          setStartDaysTime({
            ...startDaysTime,
            wed: '',
          });
          showModelFordays(
            wednesday,
            startDaysTime.wed,
            closeDaysTime.wed,
            'wed',
          );
          setButton(true);
        }
      }
    }
  };

  const compareeThursday = () => {
    if (thursday) {
      const same = thursday.find(
        obj =>
          !(
            (obj.start_time >
              moment(startDaysTime.thu, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >
                moment(closeDaysTime.thu, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <
              moment(startDaysTime.thu, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <
                moment(closeDaysTime.thu, 'hh:mm A').format('hh:mm A'))
          ),
      );

      if (same) {
        errorMessage('Time already reserved ');
      } else {
        if (
          startDaysTime.thu === closeDaysTime.thu ||
          startDaysTime.thu > closeDaysTime.thu ||
          startDaysTime.thu === '' ||
          closeDaysTime.thu === ''
        ) {
          errorMessage('Close time must be greater than open time ');
        } else if (
          moment(startDaysTime.thu, 'HH:mm A')
            .add(duration, 'minutes')
            .format('HH:mm A') >
          moment(closeDaysTime.thu, 'HH:mm A').format('HH:mm A')
        ) {
          errorMessage('Duration between time must be ' + duration);
        } else if (startDaysTime.thu < closeDaysTime.thu) {
          successMessage('Schedule added ');
          setThursday(cur => [
            ...cur,
            {
              start_time: moment(startDaysTime.thu, 'hh:mm A').format(
                'hh:mm A',
              ),
              end_time: moment(closeDaysTime.thu, 'hh:mm A').format('hh:mm A'),
            },
          ]);
          setCloseDaysTime({
            ...closeDaysTime,
            thu: '',
          });
          setStartDaysTime({
            ...startDaysTime,
            thu: '',
          });
          showModelFordays(
            thursday,
            startDaysTime.thu,
            closeDaysTime.thu,
            'thu',
          );
          setButton(true);
        }
      }
    }
  };
  const compareeFriday = () => {
    if (friday) {
      const same = friday.find(
        obj =>
          !(
            (obj.start_time >
              moment(startDaysTime.fri, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >
                moment(closeDaysTime.fri, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <
              moment(startDaysTime.fri, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <
                moment(closeDaysTime.fri, 'hh:mm A').format('hh:mm A'))
          ),
      );

      if (same) {
        errorMessage('Time already reserved ');
      } else {
        if (
          startDaysTime.fri === closeDaysTime.fri ||
          startDaysTime.fri > closeDaysTime.fri ||
          startDaysTime.fri === '' ||
          closeDaysTime.fri === ''
        ) {
          errorMessage('Close time must be greater than open time ');
        } else if (
          moment(startDaysTime.fri, 'HH:mm A')
            .add(duration, 'minutes')
            .format('HH:mm A') >
          moment(closeDaysTime.fri, 'HH:mm A').format('HH:mm A')
        ) {
          errorMessage('Duration between time must be ' + duration);
        } else if (startDaysTime.fri < closeDaysTime.fri) {
          successMessage('Schedule added ');

          setFriday(cur => [
            ...cur,
            {
              start_time: moment(startDaysTime.fri, 'hh:mm A').format(
                'hh:mm A',
              ),
              end_time: moment(closeDaysTime.fri, 'hh:mm A').format('hh:mm A'),
            },
          ]);
          setCloseDaysTime({
            ...closeDaysTime,
            fri: '',
          });
          setStartDaysTime({
            ...startDaysTime,
            fri: '',
          });
          showModelFordays(friday, startDaysTime.fri, closeDaysTime.fri, 'fri');
          setButton(true);
        }
      }
    }
  };
  const compareeSat = () => {
    if (saturday) {
      const same = saturday.find(
        obj =>
          !(
            (obj.start_time >
              moment(startDaysTime.sat, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >
                moment(closeDaysTime.sat, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <
              moment(startDaysTime.sat, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <
                moment(closeDaysTime.sat, 'hh:mm A').format('hh:mm A'))
          ),
      );

      if (same) {
        errorMessage('Time already reserved ');
      } else {
        if (
          startDaysTime.sat === closeDaysTime.sat ||
          startDaysTime.sat > closeDaysTime.sat ||
          startDaysTime.sat === '' ||
          closeDaysTime.sat === ''
        ) {
          errorMessage('Close time must be greater than open time ');
        } else if (
          moment(startDaysTime.sat, 'HH:mm ')
            .add(duration, 'minutes')
            .format('HH:mm ') >
          moment(closeDaysTime.sat, 'HH:mm A').format('HH:mm A')
        ) {
          errorMessage('Duration between time must be ' + duration);
        } else if (startDaysTime.sat < closeDaysTime.sat) {
          successMessage('Schedule added ');
          setSaturday(cur => [
            ...cur,
            {
              start_time: moment(startDaysTime.sat, 'hh:mm A').format(
                'hh:mm A',
              ),
              end_time: moment(closeDaysTime.sat, 'hh:mm A').format('hh:mm A'),
            },
          ]);
          setCloseDaysTime({
            ...closeDaysTime,
            sat: '',
          });
          setStartDaysTime({
            ...startDaysTime,
            sat: '',
          });
          showModelFordays(
            saturday,
            startDaysTime.sat,
            closeDaysTime.sat,
            'sat',
          );
          setButton(true);
        }
      }
    }
  };

  const compareeSun = () => {
    if (sunday) {
      const same = sunday.find(
        obj =>
          !(
            (obj.start_time >
              moment(startDaysTime.sun, 'hh:mm A').format('hh:mm A') &&
              obj.start_time >
                moment(closeDaysTime.sun, 'hh:mm A').format('hh:mm A')) ||
            (obj.end_time <
              moment(startDaysTime.sun, 'hh:mm A').format('hh:mm A') &&
              obj.end_time <
                moment(closeDaysTime.sun, 'hh:mm A').format('hh:mm A'))
          ),
      );

      if (same) {
        errorMessage('Time already reserved ');
      } else {
        if (
          startDaysTime.sun === closeDaysTime.sun ||
          startDaysTime.sun > closeDaysTime.sun ||
          startDaysTime.sun === '' ||
          closeDaysTime.sun === ''
        ) {
          errorMessage('Close time must be greater than open time ');
        } else if (
          moment(startDaysTime.sun, 'HH:mm A')
            .add(duration, 'minutes')
            .format('HH:mm A') >
          moment(closeDaysTime.sun, 'HH:mm A').format('HH:mm A')
        ) {
          errorMessage('Duration between time must be ' + duration);
        } else if (startDaysTime.sun < closeDaysTime.sun) {
          successMessage('Schedule added ');

          setSunday(cur => [
            ...cur,
            {
              start_time: moment(startDaysTime.sun, 'hh:mm A').format(
                'hh:mm A',
              ),
              end_time: moment(closeDaysTime.sun, 'hh:mm A').format('hh:mm A'),
            },
          ]);
          setCloseDaysTime({
            ...closeDaysTime,
            sun: '',
          });
          setStartDaysTime({
            ...startDaysTime,
            sun: '',
          });
          showModelFordays(sunday, startDaysTime.sun, closeDaysTime.sun, 'sun');
          setButton(true);
        }
      }
    }
  };
  useEffect(() => {
    if (props.createrId && object !== '') {
      handleObject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object]);

  useEffect(() => {
    if (props.createrId) {
      getAppointmentSlot();
      getAppointmentType();
      checkSchedules();
    } else {
      getAppointmentType();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitData = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      setValidated(true);
    }
    //  else if (workSpaceIds.length == 0) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   errorMessage('Please select workspace');
    // }
    else if (
      monday.length > 0 ||
      tuesday.length > 0 ||
      wednesday.length > 0 ||
      thursday.length > 0 ||
      friday.length > 0 ||
      saturday.length > 0 ||
      sunday.length > 0
    ) {
      event.preventDefault();
      event.stopPropagation();
      props.scheduleId ? editShedules() : createSchedule();
      setValidated(false);
    } else {
      event.preventDefault();
      event.stopPropagation();
      errorMessage(' Please add slot');
      setSubTabsType('avalability');
      setValidated(false);
    }
  };

  const editShedules = () => {
    // setshowLoader(true);
    var payload = {
      appointment_type: appointmentTypeId,
      cost: cost,
      description: discription,
      duration: duration,
      friday: friday,
      id: props.scheduleId,
      monday: monday,
      saturday: saturday,
      session_type: 'other',
      sunday: sunday,
      thursday: thursday,
      title: title,
      tuesday: tuesday,
      unavailable_date: values === '' || !values ? '' : values.join(','),
      wednesday: wednesday,
      workspace_ids: workSpaceIds.toString(','),
    };

    commonApi
      .edit_schedules(payload)
      .then(res => {
        if (res.success === true) {
          successMessage(res.message);
          props.getMySchedules && props.getMySchedules(1);
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const createSchedule = () => {
    var payload = {
      appointment_type: appointmentTypeId,
      cost: cost,
      description: discription,
      duration: duration,
      friday: friday,
      monday: monday,
      saturday: saturday,
      session_type: 'other',
      sunday: sunday,
      thursday: thursday,
      title: title,
      tuesday: tuesday,
      unavailable_date: values === '' || !values ? '' : values.join(','),
      wednesday: wednesday,
      workspace_ids: workSpaceIds.toString(','),
    };

    commonApi
      .create_schedule(payload)
      .then(res => {
        amplitudeEvent('CREATE_SCHEDULE');
        if (res.success === true) {
          successMessage(res.message);
          props.getMySchedules && props.getMySchedules(1);
          props.onClose();

          if (props.refreshConsults) {
            props.refreshConsults();
          }
        }
        // if (friday.length > 0) {
        //   amplitudeEvent('ADD_FRIDAY_SCHEDULE');
        // }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const checkSchedules = () => {
    var payload = {
      schedule_id: props.scheduleId,
    };

    commonApi
      .check_schedules(payload)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAppointmentSlot = () => {
    // setshowLoader(true);
    var payload = {
      creator_id: props.createrId,
      date: '',
      day: '',
      schedule_id: props.scheduleId,
    };

    commonApi
      .get_appointment_slots(payload)
      .then(res => {
        if (res.status === 200) {
          //
          setObjaect(res.schedules.all_days_schedule);
          setDiscription(res.schedules.description);
          setDuration(res.schedules.duration);
          setTitle(res.schedules.title);
          setCost(res.schedules.cost);
          setAppointmentTypeId(res.schedules.appointment_type);

          setUnavailableValue(res.schedules.unavailable_date);
          if (res.schedules?.workspace_ids) {
            setWorkSpaceIds(res.schedules.workspace_ids.split(','));
          }

          if (res.schedules?.personal === 'yes') {
            // alert('@@@@@@@@@@@')
            setTimeout(() => {
              setWorkSpaceIds(previous => [...previous, 'personal']);
              // workSpaceIds.push('personal');
            }, 1500);
          }
          // setDate(res.schedules.unavailable_date);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getAppointmentType = () => {
    // setshowLoader(true);

    commonApi
      .get_appointment_types()
      .then(res => {
        if (res.status === 200) {
          //
          setAppointmentType(res.appointment_type);
          setTimeout(() => {
            setShowSpinner(false)
          }, 300);
          // var objectData= JSON.parse(object)
          // setMonday(objectData.monday);
          // setTuesday(objectData.tuesday);
          // setWednesday(objectData.wednesday);
          // setThursday(objectData.thursday);
          // setFriday(objectData.friday);
          // setSaturday(objectData.saturday);
          // setSunday(objectData.sunday);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const workingDays = [
    {
      index: 0,
      label: 'sun',
      fullName: 'SUN',
      shortName: 'S',
    },
    {
      index: 1,
      label: 'mon',
      fullName: 'MON',
      shortName: 'M',
    },
    {
      index: 2,
      label: 'tus',
      fullName: 'TUE',
      shortName: 'T',
    },
    {
      index: 3,
      label: 'wed',
      fullName: 'WED',
      shortName: 'W',
    },
    {
      index: 4,
      label: 'thu',
      fullName: 'THU',
      shortName: 'R',
    },
    {
      index: 5,
      label: 'fri',
      fullName: 'FRI',
      shortName: 'F',
    },
    {
      index: 6,
      label: 'sat',
      fullName: 'SAT',
      shortName: 'S',
    },
  ];

  const renderDropdown = menu => {
    return <div style={{padding: 10}}>{menu}</div>;
  };

  const handleDeleteSchedule = obj => {
    if (obj.name === 'sunday') {
      setSunday(pre =>
        pre.filter(item => obj.scheduleItem.start_time !== item.start_time),
      );
    } else if (obj.name === 'monday') {
      setMonday(pre =>
        pre.filter(item => obj.scheduleItem.start_time !== item.start_time),
      );
    } else if (obj.name === 'tuesday') {
      setTuesday(pre =>
        pre.filter(item => obj.scheduleItem.start_time !== item.start_time),
      );
    } else if (obj.name === 'wednesday') {
      setWednesday(pre =>
        pre.filter(item => obj.scheduleItem.start_time !== item.start_time),
      );
    } else if (obj.name === 'thursday') {
      setThursday(pre =>
        pre.filter(item => obj.scheduleItem.start_time !== item.start_time),
      );
    } else if (obj.name === 'friday') {
      setFriday(pre =>
        pre.filter(item => obj.scheduleItem.start_time !== item.start_time),
      );
    } else if (obj.name === 'saturday') {
      setSaturday(pre =>
        pre.filter(item => obj.scheduleItem.start_time !== item.start_time),
      );
    }
    setDeleteSchedule({
      name: '',
      scheduleItem: {},
    });
    setShowComponent('');
  };

  const renderHeader = () => {
    
    return (
      <>
      
          <img src={RoiLogo} alt="" />
    
      </>
    );
  };

  return (
    <>
      <SlidingPanel
        hidePanel={() => props.onClose()}
        renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2',
          // marginTop : props.marginTop ? '12px' : ''
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        // lastDivStyle={props.marginTop && {marginTop: '12px'}}
        
        >

{showSpinner && <LoaderSpinner className={'curved-spinner-bg'} />}
        <div className="serach-filter-wrapper ">
          {/* <Scrollbars> */}
          <div className="ticket-icons-options">
            <ul>
              {/* <li
                className={topIcons.bullseye ? 'active' : ''}
                onClick={() => {
                  setShowComponent('SessionFocus');
                }}>
                <i className="icon-bullseye" />
              </li> */}
              {/* <li
                
                className= 'active' 
                onClick={() => {
                  setTopIcons({
                    ...topIcons,
                    ['personal']: !topIcons.personal,
                  });
                  setShowComponent('persnal-model');
                }}>
                
                  <PersnalSvg fill={'white'} />
                
              </li> */}
            </ul>
          </div>

          <div className="event-tabs tabs-style-2 mb-0 fixed-width-inputs black-head-form-pad-left">
            <ul>
              <li>
                <Button
                  className={subTabType === 'general' ? 'active' : ''}
                  onClick={() => setSubTabsType('general')}>
                  {labels.sessions_type_general[selected_lang]}
                </Button>
              </li>
              <li>
                <Button
                  className={subTabType === 'avalability' ? 'active' : ''}
                  onClick={() => {
                    setSubTabsType('avalability');
                    if (props.createrId) {
                      handleObject();
                    }
                  }}>
                  {labels.avalability[selected_lang]}
                </Button>
              </li>
            </ul>

            <div className="search-tabs-icons">
              {subTabType === 'avalability' && (
                <div className="availability-tabs as-link">
                  <div className="text-right">
                    <span
                      className="text-right"
                      onClick={() => {
                        setUnavailableDButton(!unavailableDButton);
                      }}>
                      {labels.unavailable_dates[selected_lang]}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="sidebar-heading-wrapper"></div> */}

          {subTabType === 'general' && (
            <div
              className="send-refferal-form-wrapper mt-4  "
              style={{display: 'flex', flex: 1}}>
              <Form
                noValidate
                validated={validated}
                onSubmit={submitData}
                className="primary_panel_content">
                <div className="refferal-list-wrapper pb-5">
                  <Scrollbars>
                    <div className="fixed-width-inputs black-head-form-pad-left">
                      <Form.Group className="fieldset">
                        <Form.Label>
                          {labels.appointment_type[selected_lang]}
                        </Form.Label>
                        <CustomDropdown
                          // type={'credential'}
                          setDropdownId={setAppointmentTypeId}
                          dropdownId={appointmentTypeId}
                          DropDownValues={appointmentType}
                          itemName={['appointment_type']}
                          className={
                            'recruitment_dropdown recruitement_dropdown_setInvites'
                          }
                          // handleDropdownValue={handleInvitesAssociation}
                          disabled={props.id ? true : false}
                        />
                      </Form.Group>

                      <Form.Group className="fieldset">
                        <Form.Label>{labels.title[selected_lang]}</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Title"
                          value={title}
                          onChange={e => {
                            setTitle(e.target.value);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Title is required.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="fieldset">
                        <Form.Label>
                          {labels.duration[selected_lang]}
                        </Form.Label>
                        {/* <div className="select-wrapper">
                          <select
                            className="form-control"
                            value={duration}
                            onChange={e => {
                              setDuration(e.target.value);
                            }}
                            disabled={props.createrId ? true : false}>
                            <option value="15">15 Minutes</option>
                            <option value="30">30 Minutes</option>
                            <option value="45">45 Minutes</option>
                            <option value="60">60 Minutes</option>
                          </select>
                        </div> */}

                        {Object.entries(dropdownValues).length > 0 && (
                          <CustomDropdown
                            disabled={props.createrId ? true : false}
                            setDropdownId={setDuration}
                            dropdownId={duration}
                            DropDownValues={dropdownValues}
                            itemName={['name']}
                            className={'recruitment_dropdown'}
                          />
                        )}
                      </Form.Group>

                      <Form.Group className="fieldset">
                        <Form.Label>{`${
                          labels.cost[selected_lang]
                        } (${labels.optional[
                          selected_lang
                        ].toLowerCase()})`}</Form.Label>
                        <Form.Control
                          min="0"
                          step="0.01"
                          type="number"
                          placeholder="How much would you like to charge?"
                          value={cost}
                          onChange={e => {
                            // handleChange(e);
                            setCost(e.target.value);
                          }}
                          disabled={props.createrId ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {cost === '' ? '' : 'Invalid price.'}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="fieldset">
                        <Form.Label>
                          {labels.description[selected_lang]}
                        </Form.Label>
                        <Form.Control
                          required
                          as="textarea"
                          rows={8}
                          placeholder="What will the call be about?"
                          value={discription}
                          onChange={e => {
                            setDiscription(e.target.value);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Description is required.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Scrollbars>
                </div>

                <div className="filter-btn-wrapper bg_transparent bottom-0 text-center pb-2 pt-2 ">
                  <Button className="btn-dark m-0" type="submit">
                    {labels.submit[selected_lang]}
                  </Button>
                </div>
                {/* <Button
                  type="submit"
                  style={{display: 'none'}}
                  ref={buttonRef}></Button> */}
              </Form>
            </div>
          )}

          {subTabType === 'avalability' && (
            <Scrollbars>
              <div className="availability-wrapper fixed-width-inputs black-head-form-pad-left mt-4">
                {/* <div className="availability-tabs as-link">
                 
                  <div className="text-right">
                    <span
                      className="text-right"
                      onClick={() => {
                        setUnavailableDButton(!unavailableDButton);
                      }}>
                      {labels.unavailable_dates[selected_lang]}
                    </span>
                  </div>
                </div> */}

                {unavailableDButton === true && (
                  <div className="fieldset custom-datepicker-field mb-4">
                    {/* <DatePicker
                      className="hello"
                      placeholder="Select unavailable date's"
                      multiple
                      value={values}
                      onChange={unavailableDatesHandle}
                    /> */}

                    <Calender 
                    type='multiple' 
                    showDate={true} 
                    showTime={false}
                    selectMultipleDates={values ? values : []}
                    setSelectMultipleDates={(e)=>{
                      setValues(e)
                    }}
                    />

                  </div>
                  // <DatePicker
                  //   multiple
                  //   value={value}
                  //   // format='YYYY-MM-D'
                  //   onChange={(array) => {
                  //     //Array of Dateobjecs

                  //     //
                  //     unavailableDatesHandle(array);
                  //     // fDate(array);
                  //   }}
                  // />
                )}

           
                  {/* <CustomTabs
                  titleLabel="label"
                  tabs={workingDays}
                  active={days}
                  darkmode
                  onSelect={item => setDays(item)}
                /> */}
                  <div className="availability-days aew ">
                    {workingDays.map((item, index) => (
                      <span
                        key={`week-day-${index}`}
                        onClick={() => setDays(item.label)}
                        className={item.label === days ? 'active' : ''}>
                        {item.shortName}
                      </span>
                    ))}
                  </div>

                  
                  {days === 'sun' && (
  <>
    <div className="availability-times mb-3">
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Label>
              {labels.open_time[selected_lang]}
            </Form.Label>
            {/* <Form.Control
              type="time"
              id="appt-time"
              name="appt-time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={startDaysTime.sun}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  sun: e.target.value,
                });
              }}
            /> */}

                <Calender 
                type='multipleTimes' 
                setStartDaysTime={e => {
                setStartDaysTime({
                    ...startDaysTime,
                    sun: e,
                    });
                  }}
                days='sun' 
                startDaysTime={startDaysTime.sun} 
                value={startDaysTime.sun} 
                showDate={false} 
                showTime={true}
                />

            {/* <TimePicker
              showSecond={false}
              value={startDaysTime.sun}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  sun: e,
                });
              }}
              format="h:mm a" // Format for hours, minutes, and AM/PM
              use12Hours // Use 12-hour format with AM/PM
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>
              {labels.close_time[selected_lang]}
            </Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={closeDaysTime.sun}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  sun: e.target.value,
                });
              }}
            /> */}
      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setCloseDaysTime({
          ...closeDaysTime,
          sun: e,
        });
      }} 
      days='sun' 
      startDaysTime={closeDaysTime.sun} 
      value={closeDaysTime.sun} 
      showDate={false} 
      showTime={true}
      />

            {/* <TimePicker
              showSecond={false}
              value={closeDaysTime.sun}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  sun: e,
                });
              }}
              format="h:mm a"
              use12Hours
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={2} className="add-time-holder">
          <span
            className="add-time as-link"
            onClick={() => {
              compareeSun();
            }}>
            <i className="fas fa-plus" />
          </span>
        </Col>
      </Row>
    </div>

    <Form.Label>Added Hours For Sunday</Form.Label>
    {sunday.map((obj, index) => {
      return (
        <div
          className="availability-selected-times"
          key={'sunday' + index}>
          <table>
            <thead>
              <tr>
                <th width="130">Open Time</th>
                <th>Close Time</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{obj.start_time}</td>
                <td>{obj.end_time}</td>
                <td>
                  {oldSchedule.sun <= index && (
                    <span
                      className="remove-btn"
                      onClick={() => {
                        setShowComponent(
                          'deleteConfirmation',
                        );
                        setDeleteSchedule({
                          name: 'sunday',
                          scheduleItem: obj,
                        });
                        // setSunday(pre =>
                        //   pre.filter(
                        //     item =>
                        //       obj.start_time !==
                        //       item.start_time,
                        //   ),
                        // );
                      }}>
                      <i className="fa-solid fa-circle-xmark" />
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    })}
  </>
)}
{days === 'mon' && (
  <>
    <div className="availability-times mb-3">
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Open Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={startDaysTime.mon}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  mon: e.target.value,
                });
              }}
            /> */}

        <Calender 
        type='multipleTimes' 
        setStartDaysTime={e => {
          setStartDaysTime({
            ...startDaysTime,
            mon: e,
          });
        }} 
        days='mon' 
        startDaysTime={startDaysTime.mon} 
        value={startDaysTime.mon} 
        showDate={false} 
        showTime={true}
        />

            {/* <TimePicker
              showSecond={false}
              value={startDaysTime.mon}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  mon: e,
                });
              }}
              format="h:mm a" // Format for hours, minutes, and AM/PM
              use12Hours // Use 12-hour format with AM/PM
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Close Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={closeDaysTime.mon}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  mon: e.target.value,
                });
              }}
            /> */}

      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setCloseDaysTime({
          ...closeDaysTime,
          mon: e,
        });
      }} 
      days='mon' 
      startDaysTime={closeDaysTime.mon} 
      value={closeDaysTime.mon} 
      showDate={false} 
      showTime={true}
      />

            {/* <TimePicker
              showSecond={false}
              value={closeDaysTime.mon}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  mon: e,
                });
              }}
              format="h:mm a"
              use12Hours
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={2} className="add-time-holder">
          <span
            className="add-time as-link"
            onClick={() => {
              comparee();
            }}>
            <i className="fas fa-plus" />
          </span>
        </Col>
      </Row>
    </div>
    <Form.Label>Added Hours For Monday</Form.Label>
    {monday.map((obj, index) => {
      return (
        <div
          className="availability-selected-times"
          key={'monday' + index}>
          <table>
            <thead>
              <tr>
                <th width="130">Open Time</th>
                <th>Close Time</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{obj.start_time}</td>
                <td>{obj.end_time}</td>
                <td>
                  {oldSchedule.mon <= index && (
                    <span
                      className="remove-btn"
                      onClick={() => {
                        setShowComponent(
                          'deleteConfirmation',
                        );
                        setDeleteSchedule({
                          name: 'monday',
                          scheduleItem: obj,
                        });
                        // setMonday(pre =>
                        //   pre.filter(
                        //     item =>
                        //       obj.start_time !==
                        //       item.start_time,
                        //   ),
                        // );
                      }}>
                      <i className="fa-solid fa-circle-xmark" />
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    })}
  </>
)}
{days === 'tus' && (
  <>
    <div className="availability-times mb-3">
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Open Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={startDaysTime.tus}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  tus: e.target.value,
                });
              }}
            /> */}

        <Calender 
        type='multipleTimes' 
        setStartDaysTime={e => {
          setStartDaysTime({
            ...startDaysTime,
            tus: e,
          });
        }} 
        days='tus' 
        startDaysTime={startDaysTime.tus} 
        value={startDaysTime.tus} 
        showDate={false} 
        showTime={true}
        />

            {/* <TimePicker
              showSecond={false}
              value={startDaysTime.tus}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  tus: e,
                });
              }}
              format="h:mm a" // Format for hours, minutes, and AM/PM
              use12Hours // Use 12-hour format with AM/PM
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Close Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={closeDaysTime.tus}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  tus: e.target.value,
                });
              }}
            /> */}
        <Calender 
        type='multipleTimes' 
        setStartDaysTime={e => {
          setCloseDaysTime({
            ...closeDaysTime,
            tus: e,
          });
        }} 
        days='tus' 
        startDaysTime={closeDaysTime.tus} 
        value={closeDaysTime.tus} 
        showDate={false} 
        showTime={true}
        />
{/* 
            <TimePicker
              showSecond={false}
              value={closeDaysTime.tus}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  tus: e,
                });
              }}
              format="h:mm a"
              use12Hours
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={2} className="add-time-holder">
          <span
            className="add-time as-link"
            onClick={() => {
              compareeTuesday();
            }}>
            <i className="fas fa-plus" />
          </span>
        </Col>
      </Row>
    </div>
    <Form.Label>Added Hours For Tuesday</Form.Label>
    {tuesday.map((obj, index) => {
      return (
        <div
          className="availability-selected-times"
          key={'tuesday' + index}>
          <table>
            <thead>
              <tr>
                <th width="130">Open Time</th>
                <th>Close Time</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{obj.start_time}</td>
                <td>{obj.end_time}</td>
                <td>
                  {oldSchedule.tus <= index && (
                    <span
                      className="remove-btn"
                      onClick={() => {
                        setShowComponent(
                          'deleteConfirmation',
                        );
                        setDeleteSchedule({
                          name: 'tuesday',
                          scheduleItem: obj,
                        });
                        // setTuesday(pre =>
                        //   pre.filter(
                        //     item =>
                        //       obj.start_time !==
                        //       item.start_time,
                        //   ),
                        // );
                      }}>
                      <i className="fa-solid fa-circle-xmark" />
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    })}
  </>
)}
{days === 'wed' && (
  <>
    <div className="availability-times mb-3">
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Open Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={startDaysTime.wed}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  wed: e.target.value,
                });
              }}
            /> */}

      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setStartDaysTime({
          ...startDaysTime,
          wed: e,
        });
      }} 
      days='wed' 
      startDaysTime={startDaysTime.wed} 
      value={startDaysTime.wed} 
      showDate={false} 
      showTime={true}
      />

            {/* <TimePicker
              showSecond={false}
              value={startDaysTime.wed}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  wed: e,
                });
              }}
              format="h:mm a" // Format for hours, minutes, and AM/PM
              use12Hours // Use 12-hour format with AM/PM
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Close Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={closeDaysTime.wed}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  wed: e.target.value,
                });
              }}
            /> */}

      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setCloseDaysTime({
          ...closeDaysTime,
          wed: e,
        });
      }} 
      days='wed' 
      startDaysTime={closeDaysTime.wed} 
      value={closeDaysTime.wed} 
      showDate={false} 
      showTime={true}
      />
{/* 
            <TimePicker
              showSecond={false}
              value={closeDaysTime.wed}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  wed: e,
                });
              }}
              format="h:mm a"
              use12Hours
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={2} className="add-time-holder">
          <span
            className="add-time as-link"
            onClick={() => {
              compareeWed();
            }}>
            <i className="fas fa-plus" />
          </span>
        </Col>
      </Row>
    </div>
    <Form.Label>Added Hours For Wednesday</Form.Label>
    {wednesday.map((obj, index) => {
      return (
        <div
          className="availability-selected-times"
          key={'wednesday' + index}>
          <table>
            <thead>
              <tr>
                <th width="130">Open Time</th>
                <th>Close Time</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{obj.start_time}</td>
                <td>{obj.end_time}</td>
                <td>
                  {oldSchedule.wed <= index && (
                    <span
                      className="remove-btn"
                      onClick={() => {
                        setShowComponent(
                          'deleteConfirmation',
                        );
                        setDeleteSchedule({
                          name: 'wednesday',
                          scheduleItem: obj,
                        });
                        // setWednesday(pre =>
                        //   pre.filter(
                        //     item =>
                        //       obj.start_time !==
                        //       item.start_time,
                        //   ),
                        // );
                      }}>
                      <i className="fa-solid fa-circle-xmark" />
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    })}
  </>
)}
{days === 'thu' && (
  <>
    <div className="availability-times mb-3">
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Open Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={startDaysTime.thu}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  thu: e.target.value,
                });
              }}
            /> */}

      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setStartDaysTime({
          ...startDaysTime,
          thu: e,
        });
      }} 
      days='thu' 
      startDaysTime={startDaysTime.thu} 
      value={startDaysTime.thu} 
      showDate={false} 
      showTime={true}
      />

            {/* <TimePicker
              showSecond={false}
              value={startDaysTime.thu}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  thu: e,
                });
              }}
              format="h:mm a" // Format for hours, minutes, and AM/PM
              use12Hours // Use 12-hour format with AM/PM
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Close Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={closeDaysTime.thu}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  thu: e.target.value,
                });
              }}
            /> */}

      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setCloseDaysTime({
          ...closeDaysTime,
          thu: e,
        });
      }} 
      days='thu' 
      startDaysTime={closeDaysTime.thu} 
      value={closeDaysTime.thu} 
      showDate={false} 
      showTime={true}
      />

            {/* <TimePicker
              showSecond={false}
              value={closeDaysTime.thu}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  thu: e,
                });
              }}
              format="h:mm a"
              use12Hours
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={2} className="add-time-holder">
          <span
            className="add-time as-link"
            onClick={() => {
              compareeThursday();
            }}>
            <i className="fas fa-plus" />
          </span>
        </Col>
      </Row>
    </div>
    <Form.Label>Added Hours For Thursday</Form.Label>
    {thursday.map((obj, index) => {
      return (
        <div
          className="availability-selected-times"
          key={'Thursday' + index}>
          <table>
            <thead>
              <tr>
                <th width="130">Open Time</th>
                <th>Close Time</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{obj.start_time}</td>
                <td>{obj.end_time}</td>
                <td>
                  {oldSchedule.thu <= index && (
                    <span
                      className="remove-btn"
                      onClick={() => {
                        setShowComponent(
                          'deleteConfirmation',
                        );
                        setDeleteSchedule({
                          name: 'thursday',
                          scheduleItem: obj,
                        });

                        // setThursday(pre =>
                        //   pre.filter(
                        //     item =>
                        //       obj.start_time !==
                        //       item.start_time,
                        //   ),
                        // );
                      }}>
                      <i className="fa-solid fa-circle-xmark" />
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    })}
  </>
)}
{days === 'fri' && (
  <>
    <div className="availability-times mb-3">
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Open Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={startDaysTime.fri}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  fri: e.target.value,
                });
              }}
            /> */}
      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setStartDaysTime({
          ...startDaysTime,

          fri: e,
        });
      }} 
      days='fri' 
      startDaysTime={startDaysTime.fri} 
      value={startDaysTime.fri} 
      showDate={false} 
      showTime={true}
      />


            {/* <TimePicker
              showSecond={false}
              value={startDaysTime.fri}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,

                  fri: e,
                });
              }}
              format="h:mm a" // Format for hours, minutes, and AM/PM
              use12Hours // Use 12-hour format with AM/PM
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Close Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={closeDaysTime.fri}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  fri: e.target.value,
                });
              }}
            /> */}

      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setCloseDaysTime({
          ...closeDaysTime,
          fri: e,
        });
      }} 
      days='fri' 
      startDaysTime={closeDaysTime.fri} 
      value={closeDaysTime.fri} 
      showDate={false} 
      showTime={true}
      />

            {/* <TimePicker
              showSecond={false}
              value={closeDaysTime.fri}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  fri: e,
                });
              }}
              format="h:mm a"
              use12Hours
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={2} className="add-time-holder">
          <span
            className="add-time as-link"
            onClick={() => {
              compareeFriday();
            }}>
            <i className="fas fa-plus" />
          </span>
        </Col>
      </Row>
    </div>
    <Form.Label>Added Hours For Friday</Form.Label>
    {friday.map((obj, index) => {
      return (
        <div
          className="availability-selected-times"
          key={'friday' + index}>
          <table>
            <thead>
              <tr>
                <th width="130">Open Time</th>
                <th>Close Time</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{obj.start_time}</td>
                <td>{obj.end_time}</td>
                <td>
                  {oldSchedule.fri <= index && (
                    <span
                      className="remove-btn"
                      onClick={() => {
                        setShowComponent(
                          'deleteConfirmation',
                        );
                        setDeleteSchedule({
                          name: 'friday',
                          scheduleItem: obj,
                        });
                        // setFriday(pre =>
                        //   pre.filter(
                        //     item =>
                        //       obj.start_time !==
                        //       item.start_time,
                        //   ),
                        // );
                      }}>
                      <i className="fa-solid fa-circle-xmark" />
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    })}
  </>
)}
{days === 'sat' && (
  <>
    <div className="availability-times mb-3">
      <Row>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Open Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={startDaysTime.sat}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  sat: e.target.value,
                });
              }}
            /> */}

      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setStartDaysTime({
          ...startDaysTime,
          sat: e,
        });
      }} 
      days='sat' 
      startDaysTime={startDaysTime.sat} 
      value={startDaysTime.sat} 
      showDate={false} 
      showTime={true}
      />

            {/* <TimePicker
              showSecond={false}
              value={startDaysTime.sat}
              onChange={e => {
                setStartDaysTime({
                  ...startDaysTime,
                  sat: e,
                });
              }}
              format="h:mm a" // Format for hours, minutes, and AM/PM
              use12Hours // Use 12-hour format with AM/PM
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Close Time</Form.Label>
            {/* <Form.Control
              type="time"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              value={closeDaysTime.sat}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  sat: e.target.value,
                });
              }}
            /> */}

      <Calender 
      type='multipleTimes' 
      setStartDaysTime={e => {
        setCloseDaysTime({
          ...closeDaysTime,
          sat: e,
        });
      }} 
      days='sat' 
      startDaysTime={closeDaysTime.sat} 
      value={closeDaysTime.sat} 
      showDate={false} 
      showTime={true}
      />

            {/* <TimePicker
              showSecond={false}
              value={closeDaysTime.sat}
              onChange={e => {
                setCloseDaysTime({
                  ...closeDaysTime,
                  sat: e,
                });
              }}
              format="h:mm a"
              use12Hours
              placeholder="--:-- --"
              allowEmpty={false}
            /> */}
          </Form.Group>
        </Col>
        <Col md={2} className="add-time-holder">
          <span
            className="add-time as-link"
            onClick={() => {
              compareeSat();
            }}>
            <i className="fas fa-plus" />
          </span>
        </Col>
      </Row>
    </div>
    <Form.Label>Added Hours For Saturday</Form.Label>
    {saturday.map((obj, index) => {
      return (
        <div
          className="availability-selected-times"
          key={'saturday' + index}>
          <table>
            <thead>
              <tr>
                <th width="130">Open Time</th>
                <th>Close Time</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{obj.start_time}</td>
                <td>{obj.end_time}</td>
                <td>
                  {oldSchedule.sat <= index && (
                    <span
                      className="remove-btn"
                      onClick={() => {
                        setShowComponent(
                          'deleteConfirmation',
                        );
                        setDeleteSchedule({
                          name: 'saturday',
                          scheduleItem: obj,
                        });

                        // setSaturday(pre =>
                        //   pre.filter(
                        //     item =>
                        //       obj.start_time !==
                        //       item.start_time,
                        //   ),
                        // );
                      }}>
                      <i className="fa-solid fa-circle-xmark" />
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    })}
  </>
)}
                  {/* <div className='react-time-picker'>
                        <span>
                          Select Time
                        </span>
      <TimePicker
      
      onChange={setTimeValue}
        value={timeValue}
        format="HH:mm" // Optional: Set the time format (24-hour format here)
        clearIcon={null} 
        // disableClock={true}
        />
        
    </div> */}
           
              </div>
            </Scrollbars>
          )}
          {/* </Scrollbars> */}
          {/* <div className="filter-btn-wrapper">
          <Button
            className="btn-dark"
            onClick={() => {
              
              buttonRef.current.click();
            }}>
            {labels.submit[selected_lang]}
          </Button>
        </div> */}
        </div>
      </SlidingPanel>

      {showComponent === 'model-for-time' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent('');
          }}
          className="availability-modal">
          <div className="new-session-modal-wrapper text-center createSessionModal ">
            <div className="new-session-modal">
              <p className="p-reg mb-2">
                Would you like to apply the same time to differents days?
              </p>
              <div className="availability-days my-4">
                {workingDays.map((item, index) => (
                  <span
                    key={`week-day-${index}`}
                    onClick={() => checkForDays(item.label)}
                    className={
                      daysForTime.includes(item.label) ? 'active' : ''
                    }>
                    {item.shortName}
                  </span>
                ))}
              </div>
              {daysForTime.length === 1 ||
                (daysForTime.length > 1 && (
                  <Button
                    disabled={
                      daysForTime.length === 1 || daysForTime.length > 1
                        ? false
                        : true
                    }
                    className="btn-dark add-time"
                    style={{colour: '#59bf49'}}
                    onClick={() => {
                      addTimeInselectedDays();
                    }}>
                    Add Time
                  </Button>
                ))}
              <Button
                className="no-thanks"
                onClick={() => {
                  setShowComponent('');
                }}>
                No Thanks
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {showComponent === 'persnal-model' && (
        <PersonalCreateModel
          list={list}
          setWorkSpaceIds={setWorkSpaceIds}
          workSpaceIds={workSpaceIds}
          onClose={() => {
            setShowComponent('');
            setTopIcons({
              ...topIcons,
              personal: !topIcons.personal,
            });
          }}
          //Temporary state
          button={button}
        />
      )}

      {showComponent === 'deleteConfirmation' && (
        <ConfirmationModel
          closeModel={() => {
            setShowComponent('');
          }}
          discription={'Are you sure you want to remove this Schedule?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            handleDeleteSchedule(deleteSchedule);
            // setShowComponent('')
          }}
        />
      )}
    </>
  );
};
export default EditAppointment;
