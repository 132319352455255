import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import moment from 'moment/moment';
import MineSlidingPannel from '../MineSlidingPannel';
import Scrollbars from 'react-custom-scrollbars';
import {useEffect} from 'react';
import {duplicateAndChangeType, undoDuplicateByTicketId} from '../../Utils/commonUtils';
import {FormattedText} from '../../Utils/Helpers';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import { RoiLogo } from '../../Constants/Images';


const AddTicketOption = props => {
  const [validated, setValidated] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const [ticketTitle, setTicketTitle] = useState('');
  const [ticketCost, setTicketCost] = useState('');
  const [saleEnd, setSaleEnd] = useState('');
  const [maxTickets, setMaxTickets] = useState('');
  const [ticketDescription, setTicketDescription] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [tabType, setTabType] = useState(
    props.tickectsTypeDecesion === 'no' ? 'virtual' : 'in_person',
  );
  const [invalidDate, setInvalidDate] = useState(false);
  const [maximumAllowedperUser, setMaximumAllowedperUser] = useState('');
  const [limitPerUser, setLimitPerUser] = useState(false);
  const [cloneFromInperson, setCloneFromInperson] = useState(false);
  const [showModel, setShowModel] = useState('');

  const handleEdit = index => {
    setEditIndex(index);
    setTicketTitle(props.array[index].title);
    setTicketDescription(props.array[index].description);
    setTicketCost(props.array[index].cost);
    setMaxTickets(props.array[index].maximum_tickets);
    setSaleEnd(props.array[index].sale_end);
    if (props.array[index].maximum_allowed) {
      setLimitPerUser(true);
    }
    setMaximumAllowedperUser(props.array[index].maximum_allowed);
  };

  const handle = e => {
    const form = e.currentTarget;
    if (
      form.checkValidity() === false ||
      (maximumAllowedperUser && maximumAllowedperUser >= maxTickets)
    ) {

      if (saleEnd > props.eventStartDateTime) {
        setInvalidDate(true);
      } else {
        setInvalidDate(false);
      }
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      if (maximumAllowedperUser >= maxTickets) {
        errorMessage('Tickets must be greater than limit per user');
      }
    } else {
      e.preventDefault();
      const generateRandomId = () => {
        const randomPart = Math.random().toString(36).substring(2, 10); 
        const timestampPart = Date.now().toString(36); 
        return `${randomPart}-${timestampPart}`;
      };
      
      if (editIndex === -1) {
        // Adding a new ticket
        const newTicket = {
          ticket_id:generateRandomId(),
          title: ticketTitle,
          cost: ticketCost,
          sale_end: saleEnd,
          maximum_tickets: parseInt(maxTickets),
          description: ticketDescription,
          type: tabType,
          maximum_allowed: maximumAllowedperUser,
        };
        
        let clonedTicket;
        if(cloneFromInperson && tabType ==='in_person'){
           clonedTicket = {
            ...newTicket, 
            type: 'virtual', 
          };
        }

        props.setArray(prevArray => {
          const updatedArray = [...prevArray, newTicket];
          
          if (clonedTicket) {
            updatedArray.push(clonedTicket);
          }
        
          return updatedArray;
        });

        // props.setArray(prevArray => [...prevArray, newTicket]);

        successMessage('Add ticket successfully');
        setTimeout(() => {
          handleResetForm();
        }, 100);
        setValidated(false);
      } else {
        // Editing an existing ticket
        props.setArray(prevArray => {
          const newTickets = [...prevArray];
          newTickets[editIndex] = {
            title: ticketTitle,
            cost: ticketCost,
            sale_end: saleEnd,
            maximum_tickets: parseInt(maxTickets),
            description: ticketDescription,
            type: tabType,
            maximum_allowed: maximumAllowedperUser,
          };

          return newTickets; // Return the updated array directly
        });

        setEditIndex(-1);
        successMessage('Edit ticket successfully');
        setTimeout(() => {
          handleResetForm();
        }, 100);
        setValidated(false);
      }
    }
  };

  useEffect(() => {
    handleResetForm();
  }, [tabType]);

  const handleResetForm = () => {
    setTicketTitle('');
    setSaleEnd('');
    setMaxTickets('');
    setTicketDescription('');
    setTicketCost('');
    setUpdateId(0);
    setMaximumAllowedperUser('');
    setLimitPerUser(false);
  };

  const handleDelete = index => {
   
    const newTickets = [...props.array];
    newTickets.splice(index, 1);
    props.setArray(newTickets);
    setEditIndex(-1);
    setShowModel('');
  };

  const copy = item => {
    setTicketTitle(item.title);
    setSaleEnd(item.sale_end);
    setMaxTickets(item.maximum_tickets);
    setTicketDescription(item.description);
    setTicketCost(item.cost);
    if (item.maximum_allowed) {
      setLimitPerUser(true);
    }
    setMaximumAllowedperUser(item.maximum_allowed);
  };

  const clearDate = () => {
    setSaleEnd('');
    setInvalidDate(false);
  };

  let today = moment(props.serverTimeS).format('YYYY-MM-DD hh:mm');

  const handleOnClose = () => {
    props.onClose();
    // const totalTickets = props.array;
    // let sum = 0;

    // totalTickets.forEach(element => {
    //   sum += parseInt(element.maximum_tickets);
    // });

    // if (sum === parseInt(props.ticket)) {
    //   props.onClose();
    // } else {
    //   let subtract;
    //   subtract = props.ticket - sum;
    //   errorMessage(
    //     'You still have ' +
    //       subtract +
    //       ' tickets available. Update your ticket option or create an additional ticket. ',
    //   );
    // }
  };

  const handleInputChange = event => {
    const userInput = event.target.value;
    const sanitizedValue = userInput.replace(/^0+/, '');
    setTicketCost(sanitizedValue);
  };
  const handleInputChangeMaxtickets = event => {
    const userInput = event.target.value;
    const sanitizedValue = userInput.replace(/^0+/, '');
    setMaxTickets(sanitizedValue);
  };
  const TicketItem = ({index, item, allNotesLines}) => {
    const [showMore, setShowMore] = useState(false);
    const showMoreClass = showMore ? 'show-all' : '';
    console.log('itemitemitemitem', item);

    return (
      <div className="events-item" key={`shedule-${index}`}>
        <div className="session-heading">
          <div className="event-titles">
            <h2>{item.title}</h2>
            <div className="event-date">
              {item.cost === '' || item.cost === 0 ? 'Free' : '$' + item.cost}
            </div>
            {item.description &&
              typeof item.description === 'string' &&
              item.description.length > 0 && (
                <div>
                  <FormattedText
                    className={`profile-description ${showMoreClass}`}
                    content={item.description}
                  />

                  {item.description && item.description.length > 250 && (
                    //  ||
                    //   allNotesLines[index].length > 3
                    <>
                      {' '}
                      <span
                        className="show-more-link"
                        onClick={() => setShowMore(!showMore)}>
                        {!showMore
                          ? labels.read_more[selected_lang]
                          : labels.read_less[selected_lang]}
                      </span>
                    </>
                  )}
                </div>
              )}
          </div>
        </div>
        <div className="events-place-like">
          <span className="address as-link">
            {item.maximum_tickets} Maximum tickets
          </span>
          <div className="search-tabs-icons">
            <span
              className="icon active"
              onClick={() => {
                setShowModel('delete_ticket')
                setEditIndex(index);
                // handleDelete(index);
              }}>
              <i className="fa-solid fa-trash-can"></i>
            </span>
            <span
              className="icon active"
              onClick={() => {
                handleEdit(index);
                setUpdateId(item.id);
              }}>
              <i className="fa-solid fa-pen-to-square"></i>
            </span>
            <span
              className="icon active"
              onClick={() => {
                copy(item);
                setUpdateId(0);
              }}>
              <i className="fa-solid fa-copy"></i>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    
    return (
      <>
        
          <img src={RoiLogo} alt="" />
       
      </>
    );
  };

  // console.log(props.array,'props.array------->')
  console.log(props.eventStartDateTime,'props.eventStartDateTimeprops.eventStartDateTime')
  console.log(invalidDate,'invalid')
  console.log(saleEnd,'saleEndsaleEnd')
  return (

    <>
   
    <MineSlidingPannel hidePanel={() => handleOnClose()} showCloseIcon="yes" renderData={renderHeader}
        showBlackHeader={true}
        // headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
          
      <div className="serach-filter-wrapper ticket_options primary_panel_wraper">
        <div className="headings">
          <h2>Ticket Options</h2>
        </div>
        <div className="event-tabs tabs-style-2 mb-2">
          <ul>
            {props.tickectsTypeDecesion === 'yes' ||
              (props.tickectsTypeDecesion === 'both' && (
                <li>
                  <Button
                    className={tabType === 'in_person' ? 'active' : ''}
                    onClick={() => {
                      setTabType('in_person');
                    }}>
                    In-person
                  </Button>
                </li>
              ))}
            {props.tickectsTypeDecesion === 'no' ||
              (props.tickectsTypeDecesion === 'both' && (
                <li>
                  <Button
                    className={tabType === 'virtual' ? 'active' : ''}
                    onClick={() => {
                      setTabType('virtual');
                    }}>
                    Virtual
                  </Button>
                </li>
              ))}
          </ul>
        </div>
        <Scrollbars>
          <div className="create-ticket-wrapper fix-width-forms">
            {/* <div
          className="text center"
          onClick={() => {
            handleOnClose();
          }}>
          <i class="fa fa-times" aria-hidden="true"></i>
        </div> */}
            <>
              <div className="create-ticket-form">
                <Form noValidate validated={validated} onSubmit={handle}>
                  <div className="fieldset">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label controlid="validationCustom01">
                        Title
                      </Form.Label>
                      {tabType === 'virtual' &&
                        props.tickectsTypeDecesion === 'both' && (
                          <div className="">
                            <Form.Check
                              className=""
                              type="switch"
                              id="custom-switch"
                              label={'Clone from In-Person'}
                              value={cloneFromInperson ? true : false}
                              checked={cloneFromInperson ? true : false}
                              onClick={() => {
                                if (!cloneFromInperson === true) {
                                  // alert('daniyal')
                                  const cloneFromInPersonArray =
                                    duplicateAndChangeType(props.array);
                                  props.setArray(cloneFromInPersonArray);
                                }else{
                                  const undoClone =
                                  undoDuplicateByTicketId(props.array);
                                  console.log(undoClone,'-------->undoClone')
                                props.setArray(undoClone);
                                }
                                setCloneFromInperson(!cloneFromInperson);
                              }}
                            />
                          </div>
                        )}
                    </div>
                    <Form.Control
                      // id="title"
                      // name="title"
                      value={ticketTitle}
                      pattern="^\S.*$"
                      required
                      placeholder="Add title"
                      onChange={e => {
                        setTicketTitle(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {ticketTitle === ''
                        ? 'Title is required.'
                        : 'Invalid title.'}
                    </Form.Control.Feedback>
                  </div>
                  <div className="fieldset">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label>Total Available </Form.Label>
                      <div className="">
                        <Form.Check
                          className=""
                          type="switch"
                          id="custom-switch"
                          label={'Limit Per User'}
                          value={limitPerUser ? true : false}
                          checked={limitPerUser ? true : false}
                          onClick={() => {
                            if (!limitPerUser === false) {
                              setMaximumAllowedperUser('');
                            }
                            setLimitPerUser(!limitPerUser);
                          }}
                        />
                      </div>
                    </div>
                    <Form.Control
                      required
                      value={maxTickets}
                      type="number"
                      min={1}
                      placeholder="Maximum ticket purchases"
                      onChange={handleInputChangeMaxtickets}
                    />
                    <Form.Control.Feedback type="invalid">
                      Invalid ticket
                    </Form.Control.Feedback>
                  </div>
                  {limitPerUser && (
                    <div className="fieldset">
                      <Form.Label>Maximum Allowed per User </Form.Label>

                      <Form.Control
                        required
                        value={maximumAllowedperUser}
                        type="number"
                        min={1}
                        placeholder="Maximum ticket purchases"
                        onChange={e => {
                          setMaximumAllowedperUser(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Invalid maximum allowed per user
                      </Form.Control.Feedback>
                    </div>
                  )}

                  <div className="fieldset">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label>Description </Form.Label>
                    </div>
                    <Form.Control
                      required
                      as="textarea"
                      rows={2}
                      // id="description"
                      // name="description"
                      value={ticketDescription}
                      type="text"
                      placeholder="Write description... "
                      onChange={e => {
                        setTicketDescription(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Discription is required.
                    </Form.Control.Feedback>
                  </div>
                  <div className="fieldset">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label>Cost (Optional)</Form.Label>
                    </div>
                    <Form.Control
                      // id="cost"
                      // name="cost"
                      value={ticketCost}
                      type="number"
                      min={1}
                      placeholder="Add cost "
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Invalid cost.
                    </Form.Control.Feedback>
                  </div>
                  <div className="fieldset">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label>Sale End Date(Optional)</Form.Label>
                      {/* <span
                        className="fas fa-sync-alt"
                        onClick={() => setRepeatOption(!repeateOption)}
                      /> */}
                    </div>
                    <div className="form-control-with-icon">
                      <Form.Control
                        value={saleEnd}
                        type="datetime-local"
                        min={today}
                        max={props.eventStartDateTime}
                        id="datemin"
                        name="datemin"
                        placeholder="Select date "
                        onChange={e => {
                          setSaleEnd(e.target.value);
                          const saleEnd = moment(e.target.value); 
  const eventStartDateTime = moment(props.eventStartDateTime); 
                          if (saleEnd.isAfter(eventStartDateTime) && validated) {
                            setInvalidDate(true);
                          } else {
                            setInvalidDate(false);
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Invalid sale end date.
                      </Form.Control.Feedback>

                      {saleEnd !== '' && (
                        <span
                          className="input-icon"
                          onClick={() => {
                            clearDate();
                          }}
                          style={{top : invalidDate ? '33%' :''}}>
                          <i
                            className="fas fa-close"
                            style={{paddingLeft: '210px'}}
                          />
                        </span>
                      )}
                    </div>
                  </div>

                  {updateId === 0 ? (
                    <div className="fieldset event-btn-wrapper text-center">
                      <Button className="" type="submit">
                        Add Ticket Option
                      </Button>
                    </div>
                  ) : (
                    <div className="fieldset event-btn-wrapper text-center">
                      <Button className="" type="submit">
                        Update Ticket Option
                      </Button>
                    </div>
                  )}
                </Form>

                <div className="session-wrapper mt-3">
                  {tabType === 'in_person' &&
                    props.array.length > 0 &&
                    Object.entries(props.array).length > 0 &&
                    props.array.map(
                      (item, index) =>
                        item.type === 'in_person' && (
                          <TicketItem
                            index={index}
                            item={item}
                            allNotesLines={
                              Object.entries(props.array).length > 1
                                ? Object.entries(props.array).map(
                                    ([key, item]) =>
                                      item.description.split('\n'),
                                  )
                                : []
                            }
                          />
                          // <div className="events-item" key={`shedule-${index}`}>
                          //   <div className="session-heading">
                          //     <div className="event-titles">
                          //       <h2>{item.title}</h2>
                          //       <div className="event-date">
                          //         {item.cost === '' || item.cost === 0
                          //           ? 'Free'
                          //           : '$' + item.cost}
                          //       </div>
                          //       <div className="event-date">
                          //         {item.description}
                          //       </div>
                          //     </div>
                          //   </div>
                          //   <div className="events-place-like">
                          //     <span className="address as-link">
                          //       {item.maximum_tickets} Maximum tickets
                          //     </span>
                          //     <div className="search-tabs-icons">
                          //       <span
                          //         className="icon active"
                          //         onClick={() => {
                          //           handleDelete();
                          //         }}>
                          //         <i className="fa-solid fa-trash-can"></i>
                          //       </span>
                          //       <span
                          //         className="icon active"
                          //         onClick={() => {
                          //           handleEdit(index);
                          //           setUpdateId(item.id);
                          //         }}>
                          //         <i className="fa-solid fa-pen-to-square"></i>
                          //       </span>
                          //       <span
                          //         className="icon active"
                          //         onClick={() => {
                          //           copy(item);
                          //           setUpdateId(0);
                          //         }}>
                          //         <i className="fa-solid fa-copy"></i>
                          //       </span>
                          //     </div>
                          //   </div>
                          // </div>
                        ),
                    )}
                  {tabType === 'virtual' &&
                    props.array.length > 0 &&
                    Object.entries(props.array).length > 0 &&
                    props.array.map(
                      (item, index) =>
                        item.type === 'virtual' && (
                          <TicketItem
                            index={index}
                            item={item}
                            allNotesLines={
                              Object.entries(props.array).length > 1
                                ? Object.entries(props.array).map(
                                    ([key, item]) =>
                                      item.description.split('\n'),
                                  )
                                : []
                            }
                          />
                        ),
                    )}
                </div>
              </div>
            </>
          </div>
        </Scrollbars>
      </div>
    </MineSlidingPannel>


    {showModel === 'delete_ticket' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={'Are you sure you want to remove this ticket type?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            handleDelete(editIndex)
          }}
        />
      )}

    </>
  );
};
export default AddTicketOption;
