import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {Pannels, ProfileGallery} from '../../Components';
import {ShareablesBlackIcon} from '../../Constants/Images';
import {rewardStar} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import BadgeCheck from '../../Svg/BadgeCheck';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import {DEFAULT_IMAGE, ENV_HOST, selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import CustomTabs from '../../Components/CustomTabs';
import CustomSlidingButtons from '../../Components/CustomSlidingButtons';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import EditProfile from '../../Components/ProfilePage/EditProfile';
import {successMessage, errorMessage} from '../../Utils/ToastMessages';
import CustomModal from '../../Components/CustomModal';
import CropImage from '../../Components/Cropper/CropImage';
import {FormattedText} from '../../Utils/Helpers';
import {useSelector} from 'react-redux';
import {getActiveCompanyUserMenus} from '../../Store/Actions/MenuAction';
import EditBusinessProfile from '../../Components/ProfilePage/EditBusinessProfile';
import SendInvitation from './StaticPages/SendInvitation';
import CircleListBuilders from './StaticPages/CircleListBuilders';
import SlidingPanel from '../../Components/SlidingPanel';
import {
  capitalizeWords,
  chatroomInfo,
  checkPermissions,
  collectingUsers,
  fixName,
  replaceSpaceWithDot,
} from '../../Utils/commonUtils';
import CreateTransform from '../../Components/Transformation/CreateTransform';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import TransformDetail from '../../Components/Transformation/TransformDetail';
import {
  DEFAULT_IMAGE_COMPANYBANNER,
  DEFAULT_IMAGE_COMPANYLOGO,
} from '../../Constants/Images';
import AddNewSnapShot from '../../Components/Messages/AddNewSnapShot';
import SnapshotDetail from '../../Components/Snapshot/SnapshotDetail';
import {arrayColumn} from '../../Utils/commonUtils';
import {getUserMenuPermesionList} from '../../Store/Actions/MenuAction';
import {useDispatch} from 'react-redux';
import Branding from '../../Components/BusinnesProfile/Branding';
import SessionBusinessProfile from '../../Components/BusinnesProfile/SessionBusinessProfile';
import RecruitmentBusinessProfile from '../../Components/BusinnesProfile/RecruitmentBusinessProfile';
import AppointmentBusinessProfile from '../../Components/BusinnesProfile/AppointmentBusinessProfile';
import CreateSession from '../../Components/SessionDetails/CreateSession';
import EditOpportunities from '../../Components/OpportunitiesList/EditOpportunities';
import AllBuilders from './StaticPages/AllBuilders';
import SmartChatPage from '../../Components/Common/SmartChatPage';
import InvitePoc from '../../Components/SingleComponent/InvitePoc';
import {faLessThanEqual} from '@fortawesome/free-solid-svg-icons';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import ConfirmationModel from '../../Components/ConfirmationModel/ConfirmationModel';
import {getUpdateDataOfCompany} from '../../Store/Actions/CommanAction';
import {RoiLogo} from '../../Constants/Images';
const BusinessProfile = props => {
  const [currentComponent, setCurrentComponent] = useState('news');
  const [showComponent, setShowComponent] = useState('');
  const [seconadaryComponent, setSecondaryComponent] = useState('');
  const [primaryComponent, setPrimaryComponent] = useState('');
  const [type, setType] = useState('1');
  const [showPocs, setShowPocs] = useState([]);
  const [pocTitle, setPocTitle] = useState('');

  const companyMenuList = useSelector(state => state.menuActiveList);
  const menuPermesions = useSelector(state => state.menuPermesionList);
  const snapshotCategories = useSelector(
    state => state.accomplishmentCategories,
  );

  const BusinnesProfilePermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Business Profile');

  const dispatch = useDispatch();
  const [workSpaceItem, setWorkSpaceItem] = useState(
    localStorage.getItem('activeWorkspaceItem')
      ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
      : [],
  );
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  // const [threeDots, setThreeDots] = useState(false);
  const [marketToggle, setMarketToggle] = useState(false);

  const [ratingToggle, setRatingToggle] = useState(false);
  const [tabType, setTabType] = useState('internal');
  const [announcement, setAnnouncement] = useState('');
  const [editProfile, setEditProfile] = useState('');
  const [companyProfileData, setCompanyProfileData] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [multipleProfileData, setMultipleProfileData] = useState([]);

  const [instagramName, setInstagramName] = useState('');
  const [linkdinName, setLinkdinName] = useState('');
  const [twitterName, setTwitterName] = useState('');
  const [youtubeName, setYoutubeName] = useState('');
  const [website, setWebsite] = useState('');
  const [location, setLocation] = useState('');

  const [showModel, setShowModel] = useState('');
  const [ticketImg, setTicketImg] = useState('');
  const [file, setFile] = useState(null);
  const [bio, setBio] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [marketLoader, setMarketLoader] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showMoreBio, setShowMoreBio] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [showMoreAnnouncement, setShowMoreAnnouncement] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';
  const showMoreClassAnnouncement = showMoreAnnouncement ? 'show-all' : '';
  const showMoreCompanyBio = showMoreBio ? 'show-all' : '';
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const ticketTabs = [
    labels.business_type_internal[selected_lang],
    labels.business_type_market[selected_lang],
  ];
  const [originalPocs, setOrginalPocs] = useState([]);
  const [orginalPocsDetail, setOrginalPocsDetail] = useState([]);
  const [selectedPoc, setSelectedPoc] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [allBuilders, setAllBuilders] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [contact, setContact] = useState([]);
  // const [transformationList, setTransformationList] = useState([]);
  // const [categoryTypes, setCategoryTypes] = useState([]);
  const [dataFroNextComponent, setDataFroNextComponent] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [filterCat, setFilterCat] = useState([]);
  const [snapshotId, setSnapshotId] = useState({});

  const [originalDataOfAud, setOriginalDataOfAud] = useState([]);
  const [selectedAudData, setSelectedAudData] = useState([]);
  const [audience, setAudience] = useState([]);
  const [prevState, setPrevState] = useState('');
  const [bannerFile, setBannerFile] = useState(null);
  // const [showModel, setShowModel] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [banner, setBanner] = useState('');
  const [showMeetups, setShowMeetups] = useState([]);
  const [showAppointment, setShowAppointment] = useState([]);
  const [showOpportuntiy, setShowOpportuntiy] = useState([]);
  const [showDataOfSingleChat, setShowDataOfSingleChat] = useState([]);
  const [showCompanyShareModel, setShowCompanyShareModel] = useState('');
  const [pocDeletedData, setPocDeletedData] = useState('');
  const [refreshPocs, setRefreshPocs] = useState(false);
  const listForDropDown = [
    // {title: 'All', id: '1'},
    {title: 'General', id: 'general'},
    {title: 'Branding', id: 'branding'},
    {title: 'Meetups', id: 'meetup'},
    {title: 'Recruitments', id: 'recruitment'},
    {title: 'Scheduler', id: 'appointment'},
    {title: 'POCs', id: 'pocs'},
    {title: 'Reputation', id: 'reputation'},
  ];
  const [areas_Filter, setAreas_Filter] = useState('general');
  // const [transformation_Filter, setTransformation_Filter] = useState([]);
  const dropDownSelection = e => {
    const {id} = e.target;

    setAreas_Filter(id);

    // console.log(id)

    // if(id==='branding'){
    //   setPrimaryComponent('branding')
    // }
  };

  // const handleClick = e => {
  //   const {id} = e.target;

  //   if (id === 'All') {
  //     if (
  //       transformation_Filter &&
  //       transformation_Filter.length > 0 &&
  //       transformation_Filter.includes(id)
  //     ) {
  //       setTransformation_Filter([]);
  //     } else {
  //       const idsArray = categoryTypes.map(category => category.id);
  //       const newCategory = 'All';

  //       const updatedCategories = [newCategory, ...idsArray];
  //       setTransformation_Filter(updatedCategories);
  //     }
  //   } else {
  //     if (
  //       transformation_Filter &&
  //       transformation_Filter.length > 0 &&
  //       transformation_Filter.includes(id)
  //     ) {
  //       setTransformation_Filter(
  //         transformation_Filter.filter(item => item !== id),
  //       );
  //     } else {
  //       setTransformation_Filter([...transformation_Filter, id]);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   // get_company_transformation();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [transformation_Filter, seconadaryComponent]);

  // const dropdownMenu = [
  //   {id: '1', name: 'Before and After'},
  //   {
  //     id: '2',
  //     name: 'Offerings',
  //   },
  //   {
  //     id: '3',
  //     name: 'Offerings',
  //   },
  // ];

  // const offeringData = [
  //   {
  //     name: 'Business Consulting',
  //     time: '30 Minutes',
  //     category: 'Category',
  //     cost: '$85 / hour',
  //   },
  //   {
  //     name: 'Product Name',
  //     time: 'In-Stock',
  //     category: 'Category',
  //     cost: '$105',
  //   },
  // ];

  const updateBanner = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowModel('updatebanner');

      e.target.value = null;
    }
  };

  const updateBusinessProfile = (type, value) => {
    var payload = {
      available_on_market: type === 'market' ? value : marketToggle,
      public_announcement: announcement,
      banner: type === 'img' ? value : ticketImg,
      show_rating: type === 'rating' ? value : ratingToggle,
      bio: bio,
      linkedin: linkdinName,
      twitter: twitterName,
      youtube: youtubeName,
      instagram: instagramName,
      website: website,
      location: location
        ? location
        : multipleProfileData.longitude
        ? multipleProfileData.longitude
        : '',
      longitude: multipleProfileData.longitude
        ? multipleProfileData.longitude
        : '',
      latitude: multipleProfileData.latitude
        ? multipleProfileData.latitude
        : '',
    };
    commonApi
      .add_multiple_profiles(payload)
      .then(res => {
        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const handleDeletePocsModel = item => {
    setPocDeletedData(item);
    setShowCompanyShareModel('show_delete_poc_model');
  };

  const deletePocs = item => {
    var payload = {
      title: item.title,
      poc_role: item.poc_role,
      role_alias: item.alias,
    };
    commonApi
      .delete_pocs_with_title(payload)
      .then(res => {
        successMessage(res.message);
        setShowPocs(previous =>
          previous.filter(miniitem => miniitem.title !== item.title),
        );
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateProfileBio = (
    bio,
    instagram,
    linkdin,
    twitter,
    youtube,
    global,
    location,
    longitude,
    latitude,
  ) => {
    var payload = {
      available_on_market: marketToggle,
      public_announcement: announcement,
      banner: ticketImg,
      show_rating: ratingToggle,
      bio: bio,
      linkedin: linkdin,
      twitter: twitter,
      youtube: youtube,
      instagram: instagram,
      website: global,
      location: location,
      longitude: longitude,
      latitude: latitude,
    };
    commonApi
      .add_multiple_profiles(payload)
      .then(res => {
        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateCompanyDetails = (image, companyName, bio, banner) => {
    const deletedPocsIds = orginalPocsDetail.filter(item => {
      // Check if the item is present in either selectedAud or selectedMod arrays
      const shouldBeDeleted =
        allBuilders.some(
          audItem =>
            audItem.id === item.id && audItem.user_role === item.user_role,
        ) ||
        selectedAudData.some(
          modItem =>
            modItem.id === item.id && modItem.user_role === item.user_role,
        );
      // If the item is present in either selectedAud or selectedMod, filter it out
      return shouldBeDeleted;
    });

    // const deletedPocsIds = filteredRoles
    // .map(item => item.user_id)
    // console.log('orginalPocsDetail', orginalPocsDetail);
    // console.log('allBuilders', allBuilders);
    // console.log('selectedAudData', selectedAudData);
    const otherPocs = selectedAudData.filter(
      item =>
        !orginalPocsDetail.find(
          poc =>
            poc.id === (item.user_id ? item.user_id : item.id) &&
            poc.user_role ==
              (item.user_role ? item.user_role : item.poc_role_type_name),
        ),
    );
    const customerPocs = allBuilders.filter(
      item =>
        !orginalPocsDetail.find(
          poc =>
            poc.id === (item.user_id ? item.user_id : item.id) &&
            poc.user_role ==
              (item.user_role ? item.user_role : item.poc_role_type_name),
        ),
    );
    const MergeofPocsdata = [...customerPocs, ...otherPocs];

    console.log(deletedPocsIds, 'deletedPocsIds');

    var payload = {
      bio: bio ? bio : companyProfileData.bio,
      image: image ? image : companyProfileData.company_logo,
      company_name: companyName ? companyName : companyProfileData.company_name,
      title: pocTitle,
      multiple_poc_detail: allBuilders,
      delete_poc_ids: deletedPocsIds,
      banner_image: banner ? banner : companyProfileData.image,
    };
    console.log(payload, 'payload');
    commonApi
      .update_company_details_by_any_user(payload)
      .then(res => {
        successMessage(res.message);
        setBuilders([]);
        setAllBuilders([]);
        setOriginalDataOfMod([]);
        getCompanyDetails();
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_meetup_by_user_id = () => {
    commonApi
      .get_meetup_by_user_id()
      .then(res => {
        setShowMeetups(res.meetup);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getUserData = id => {
    var data = {
      user_id: localStorage.getItem('id') ? localStorage.getItem('id') : '',
    };
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserInfo(res);
          setMultipleProfileData(res.data?.multiple_profiles);
          setRatingToggle(
            res.data?.multiple_profiles?.show_rating
              ? res.data?.multiple_profiles?.show_rating
              : false,
          );
          setMarketToggle(
            res.data?.multiple_profiles?.available_on_market
              ? res.data?.multiple_profiles?.available_on_market
              : false,
          );
          setBio(
            res.data?.multiple_profiles?.bio
              ? res.data?.multiple_profiles?.bio
              : '',
          );
          setAnnouncement(
            res.data.multiple_profiles?.public_announcement
              ? res.data.multiple_profiles?.public_announcement
              : '',
          );
          setTicketImg(
            res.data?.multiple_profiles?.banner
              ? res.data?.multiple_profiles?.banner
              : '',
          );
          setInstagramName(
            res.data?.multiple_profiles?.instagram
              ? res.data?.multiple_profiles?.instagram
              : '',
          );
          setLinkdinName(
            res.data?.multiple_profiles?.linkedin
              ? res.data?.multiple_profiles?.linkedin
              : '',
          );
          setTwitterName(
            res.data?.multiple_profiles?.twitter
              ? res.data?.multiple_profiles?.twitter
              : '',
          );
          setYoutubeName(
            res.data?.multiple_profiles?.youtube
              ? res.data?.multiple_profiles?.youtube
              : '',
          );
          setWebsite(
            res.data?.multiple_profiles?.website
              ? res.data?.multiple_profiles?.website
              : '',
          );
          setLocation(
            res.data?.multiple_profiles?.location
              ? res.data?.multiple_profiles?.location
              : '',
          );
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getCompanyDetails = type => {
    commonApi
      .get_for_update_company_details()
      .then(res => {
        if (res) {
          if (companyName !== '' && companyName !== res.company.company_name) {
            dispatch(getUpdateDataOfCompany(res.company));
          }

          setCompanyName(res.company.company_name);
          setCompanyProfileData(res.company);

          // const teamMembers = res.company.web_poc_details.filter(
          //   entry => entry.user_role === 'team',
          // );
          // const customers = res.company.web_poc_details.filter(
          //   entry => entry.user_role === 'customer',
          // );
          // if (res.company.login_user_role === 'team') {
          //   setShowPocs(teamMembers);
          // } else {
          //   setShowPocs(customers);
          // }

          if (res.company.created_by === localStorage.getItem('id')) {
            setShowPocs(res.company.pocs_details);
          } else {
            setShowPocs(
              res.company.pocs_details.filter(
                item =>
                  item.poc_role === res.company.login_user_role &&
                  item.alias === res.company.login_user_role_alias,
              ),
            );
          }

          // const customerTypeId = customers.map(poc => poc.id);
          // const teamMembersTypeId = teamMembers.map(poc => poc.id);
          // setBuilders(customerTypeId);
          // setAudience(teamMembersTypeId);

          // setAllBuilders(customers);
          // setSelectedAudData(teamMembers);
          // setOrginalPocs(res.company.web_poc_details.map(poc => poc.id));
          // setOrginalPocsDetail(res.company.web_poc_details);

          if (type && type === 'open_caht') {
            setCurrentComponent('Poc_chat');
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // console.log('allBuilders',allBuilders,)
  // console.log('orginalPocsDetail',orginalPocsDetail,)
  // console.log('selectedAudData',selectedAudData,)
  // const get_company_transformation = () => {
  //   const filteredArray =
  //     transformation_Filter.length > 0 &&
  //     transformation_Filter.filter(item => item !== 'All');
  //   const payload = {
  //     category_ids: transformation_Filter.length > 0 ? filteredArray : [],
  //   };
  //   commonApi
  //     .get_company_transformation(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setTransformationList(res.transformation);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  function isResponsive() {
    return window.innerWidth < 768; // Adjust this value according to your responsive breakpoint
  }

  useEffect(() => {
    setshowLoader(true);

    setTimeout(() => {
      getUserData();

      getCompanyDetails();
      dispatch(getActiveCompanyUserMenus());
      dispatch(getUserMenuPermesionList());
      setTimeout(() => {
        setshowLoader(false);
      }, 1000);
    }, 1500);
    // get_company_transformation();
    // get_transformation_category();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workSpaceItem]);

  useEffect(() => {
    if (seconadaryComponent !== '' && seconadaryComponent !== 'firstVist') {
      toggleHiddenPanel(true);
      // alert('hehe')
    } else {
      toggleHiddenPanel(false);
      // alert('hehe2')
    }
  }, [seconadaryComponent]);

  useEffect(() => {
    // if(seconadaryComponent ===""){
    //   setMarketLoader(true);
    // }

    setTimeout(() => {
      get_meetup_by_user_id();
      getMySchedules();
      get_opportunity_by_user_id();
    }, 1000);
  }, [workSpaceItem, seconadaryComponent]);

  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  const CircleUserListData = item => {
    if (item.length > 0) {
      item.forEach(miniItem => {
        if (!builders.includes(miniItem.id)) {
          setBuilders(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        }
      });
    }
  };

  const lines = bio ? bio?.split('\n') : '';
  const announcementLines = bio ? bio?.split('\n') : '';
  const companyBioLines = companyProfileData.bio
    ? companyProfileData.bio?.split('\n')
    : '';

  const containerStyle = {
    position: 'relative',
    border: '1px solid #dddddd', // Lightened border color
    borderRadius: '8px', // Rounded corners
    backgroundColor: '#f8f8f8', // Lightened background color
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Shadow effect
    padding: '10px',
  };

  const closeIconStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px', // Aligning to the right
    cursor: 'pointer',
  };

  // const get_transformation_category = () => {
  //   commonApi
  //     .get_transformation_category()
  //     .then(res => {
  //       const categories = res.transformation_categories;

  //       // Create a new category object
  //       const newCategory = {
  //         id: 'All',
  //         category_title: 'All',
  //       };

  //       // Add the new category object to the beginning of the array
  //       const updatedCategories = [newCategory, ...categories];

  //       // Now you can set the state with the updated categories
  //       setCategoryTypes(updatedCategories);
  //       // setCategoryTypes(res.transformation_categories);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const handleShowComponent = (item, type) => {
  //   setDataFroNextComponent(item);
  //   if (type === 'transformationDetail') {
  //     setShowComponent('transformationDetail');
  //   }
  // };

  const handleSnapshotSelection = event => {
    const {value, checked} = event.target;
    const itemObj = JSON.parse(value);

    if (itemObj.id === 'All') {
      //   setCategoriesName(['All']);
      if (!checked) {
        setCategoriesArr([]);
      } else {
        let categories = arrayColumn(snapshotCategories, 'id');
        setCategoriesArr(categories);
      }
    } else {
      if (checked) {
        // setCategoriesName(prev => [...prev, itemObj.title]);
        setCategoriesArr(prev => [...prev, itemObj.id]);
      } else {
        // setCategoriesName(prev =>
        //   prev.filter(title => title !== itemObj.title),
        // );
        setCategoriesArr(prev => prev.filter(id => id !== itemObj.id));
      }
    }
  };

  const manageMarket = (itemId, itemType, value) => {
    var payLoad = {
      id: itemId,
      type: itemType,
      show_in_market: value,
    };
    commonApi
      .manage_market(payLoad)
      .then(response => {
        if (response.status === 200) {
          successMessage(response.message);
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };

  const showSnapshotDetail = ssId => {
    var payLoad = {
      accomplishment_id: ssId,
    };
    commonApi
      .detail_accomplishment(payLoad)
      .then(response => {
        if (response.status === 200) {
          setSnapshotId(response.accomplishment_detail);
          setSecondaryComponent('snapshotDetail');
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };

  const handleComponents = (data, type) => {
    if (type === 'snapshot') {
      showSnapshotDetail(data);
    } else if (type === 'transformationDetail') {
      setDataFroNextComponent(data);
      setShowComponent('transformationDetail');
    } else if (type === 'snapshot_filter') {
      setPrimaryComponent('snapshot_filter');
    } else if (type === 'addNewSnapshot') {
      setSecondaryComponent(type);
    } else if (type === 'createTransform') {
      setSecondaryComponent('createTransformation');
    }
  };

  const updateLogo = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowModel('updatelogo');

      e.target.value = null;
    }
  };

  const updateCompanyBanner = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setBannerFile(file);

      setShowModel('updateCompanybanner');

      e.target.value = null;
    }
  };

  const ToggleDesign = (type, item, toggle, setToggle) => {
    return (
      <div className="mt-2 p20-horizental show-on-public show-on-public-profile-holder">
        <div className="d-flex align-items-center justify-content-center">
          <p className="p-reg m-0">Show on Market</p>
          <div>
            <Form.Check
              type="switch"
              className="switchBlock"
              id="custom-switch"
              value={toggle}
              checked={toggle}
              onClick={() => {
                setToggle(!toggle);
                manageMarket(item.id, type, !toggle);
                updateMarketToggleValue(type, item, !toggle);
                // setIsactiveToggle(!isactiveToggle);
                // change_appearance(type, item);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const updateMarketToggleValue = (type, MiniItem, value) => {
    if (type === 'meetups') {
      const index = showMeetups.findIndex(item => item.id === MiniItem.id);

      // If item found, update its show_in_market_b property
      if (index !== -1) {
        showMeetups[index].show_in_market_b = value;
        return showMeetups[index]; // Return the modified item
      }
    } else if (type === 'opportunity') {
      const index = showOpportuntiy.findIndex(item => item.id === MiniItem.id);

      // If item found, update its show_in_market_b property
      if (index !== -1) {
        showOpportuntiy[index].show_in_market_b = value;
        return showOpportuntiy[index]; // Return the modified item
      }
    } else if (type === 'consult') {
      const index = showAppointment.findIndex(item => item.id === MiniItem.id);

      // If item found, update its show_in_market_b property
      if (index !== -1) {
        showAppointment[index].show_in_market_b = value;
        return showAppointment[index]; // Return the modified item
      }
    } else if (type === 'poc') {
      const index = showPocs.findIndex(item => item.id === MiniItem.id);

      // If item found, update its show_in_market_b property
      if (index !== -1) {
        showPocs[index].show_in_market_b = value;
        return showAppointment[index]; // Return the modified item
      }
    }
  };

  const getMySchedules = () => {
    const paylaod = {
      page: 1,
    };

    commonApi
      .get_my_schedules(paylaod)
      .then(res => {
        if (res.success === true) {
          setShowAppointment(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_opportunity_by_user_id = () => {
    const paylaod = {
      user_id: localStorage.getItem('id'),
    };
    commonApi
      .get_opportunity_by_user_id(paylaod)
      .then(res => {
        setShowOpportuntiy(res.opportunity);
        setMarketLoader(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  console.log(companyProfileData, 'companyProfileData');

  // const create_personal_group_chat_only = (typeOfChat, id) => {
  //   const Pocids =
  //     showPocs && showPocs.length > 0 && showPocs.map(obj => obj.id);

  //   const payLoad = {
  //     type:
  //       companyProfileData.login_user_role === 'team'
  //         ? 'poc_team'
  //         : 'poc_customer',
  //     audience_ids: '',
  //     chat_room_name:
  //       'chatroom_gf' + Math.floor(100000 + Math.random() * 900000),
  //     group_image:
  //       companyProfileData.company_logo !== ''
  //         ? companyProfileData.company_logo
  //         : DEFAULT_IMAGE_COMPANYLOGO,
  //     group_name: companyProfileData.company_name,
  //     moderator_ids:
  //       typeOfChat === 'single'
  //         ? id
  //         : Pocids.length > 0
  //         ? Pocids.toString(',')
  //         : '',
  //     private_group: '',
  //     snap_shot_id: '',
  //     chat_type: typeOfChat === 'single' ? 'single' : 'group',
  //   };

  //   commonApi
  //     .poc_chat(payLoad)
  //     .then(response => {
  //       if (response.status === 200) {
  //         setShowDataOfSingleChat(response);
  //         setCurrentComponent('Poc_chat_Single');
  //         // if (typeOfChat === 'single') {

  //         //   // getCompanyDetails('open_caht');
  //         // } else {
  //         //   getCompanyDetails('open_caht');
  //         // }
  //       }
  //     })
  //     .catch(error => {
  //       console.log('Err', error);
  //       return {type: 'error', message: error.message};
  //     });
  // };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuPermesions]);

  useEffect(() => {
    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);

      if (
        Object.keys(workSpaceItem).length > 0 &&
        urlPart.length === 1 &&
        seconadaryComponent !== ''
      ) {
        setSecondaryComponent('');
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 1000);
  }, [workSpaceItem]);

  const create_personal_group_chat_only = (item, typeOfChat, id) => {
    const Pocids =
      item &&
      item.user_details.length > 0 &&
      item.user_details.map(obj => obj.id);

    const payLoad = {
      poc_alias: item.alias,
      poc_role: item.poc_role,
      type: 'poc_team',
      audience_ids: '',
      chat_room_name:
        'chatroom_gf' + Math.floor(100000 + Math.random() * 900000),
      group_image:
        companyProfileData.company_logo !== ''
          ? companyProfileData.company_logo
          : DEFAULT_IMAGE_COMPANYLOGO,
      group_name: item.title ? item.title : '',
      moderator_ids:
        typeOfChat === 'single'
          ? id
          : Pocids.length > 0
          ? Pocids.toString(',')
          : '',
      private_group: '',
      snap_shot_id: '',
      chat_type: typeOfChat === 'single' ? 'single' : 'group',
    };

    commonApi
      .poc_chat(payLoad)
      .then(response => {
        if (response.status === 200) {
          setShowDataOfSingleChat(response);
          setCurrentComponent('Poc_chat_Single');
          // if (typeOfChat === 'single') {

          //   // getCompanyDetails('open_caht');
          // } else {
          //   getCompanyDetails('open_caht');
          // }
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };
  const update_companypoc_show_in_market = (item, market) => {
    const payload = {
      poc_role: item.poc_role,
      poc_alias: item.alias,
      title: item.title,
      show_in_market: market === 'true' ? 'false' : 'true',
    };

    commonApi
      .update_companypoc_show_in_market(payload)
      .then(response => {
        if (response.status === 200) {
          successMessage(response.message);
        }
      })
      .catch(error => {
        console.log('Err', error);
        return {type: 'error', message: error.message};
      });
  };
  const handleCommonChat = (data, type) => {};

  const shareProfileUrl = replaceSpaceWithDot(
    'www.' + ENV_HOST + 'roiforpros.com/' + companyProfileData.company_name,
  );

  useEffect(() => {
    if (contact.length > 0) {
      setRefreshPocs(!refreshPocs)
    }
  }, [contact]);

  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  // console.log(showPocs, 'showPocs');
  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
      <div className="page-wrapper">
        <Sidebar
          setWorkSpaceItem={setWorkSpaceItem}
          workSpaceItem={workSpaceItem}
          location={props.location}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper">
            <Pannels
              isFloated={false}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="Business_profile_page">
                    <div className="d-flex align-items-center justify-content-center fix-width-forms w-100">
                      <div className="create-ticket-tabs w-100 create-business-ticket-tabs">
                        <CustomTabs
                          darkmode
                          tabs={ticketTabs}
                          active={tabType}
                          onSelect={item => setTabType(item)}
                        />
                      </div>
                    </div>

                    <div className="refferal-list-wrapper mt-2">
                      {showLoader && <LoaderSpinner />}

                      {companyMenuList &&
                        companyMenuList.default_menus === true &&
                        !showMsg && (
                          <div style={containerStyle}>
                            <p style={{margin: '0'}}>
                              Menus have not been setup for you. Please contact
                              your Administrator.
                            </p>
                            <span
                              style={closeIconStyle}
                              onClick={() => {
                                setShowMsg(true);
                              }}>
                              <i className="fas fa-times" />
                            </span>{' '}
                            {/* Placed after the message */}
                          </div>
                        )}
                      <Scrollbars
                        style={{height: 'calc(100vh - 114px)'}}
                        renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                        // Hide the vertical scrollbar
                        renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}
                        autoHide>
                        {tabType ===
                          labels.business_type_internal[selected_lang] && (
                          <div style={{paddingTop: '10px'}}>
                            <div className="business_profile_top top-business-profile">
                              <div className="company_profile_card mb-4">
                                <div className="company_logo">
                                  <img
                                    src={
                                      companyProfileData.company_logo
                                        ? companyProfileData.company_logo
                                        : DEFAULT_IMAGE_COMPANYLOGO
                                    }
                                    alt=""
                                  />

                                  {type === '1' && (
                                    <div className="badge-check">
                                      {/* <img src={<BadgeCheckC fill={'#43C8DA'}/>} alt="" /> */}
                                      <BadgeCheck fill={'#E0A168'} />
                                    </div>
                                  )}
                                </div>

                                <div className="company_content">
                                  <div className="company_name_flex">
                                    <span className="company_title">
                                      {companyProfileData.company_name
                                        ? companyProfileData.company_name
                                        : ''}
                                    </span>
                                    {BusinnesProfilePermessions &&
                                      (BusinnesProfilePermessions === 'allow' ||
                                        BusinnesProfilePermessions?.edit_company ===
                                          true) && (
                                        <span
                                          className=" as-link"
                                          onClick={() => {
                                            setEditProfile('editProf');
                                          }}>
                                          <EditPencilSvg fill={'#52bd41'} />
                                        </span>
                                      )}
                                  </div>

                                  <div className="company_rating_content">
                                    <span className="rating">
                                      4.8 <img src={rewardStar} alt="" />
                                    </span>

                                    <img
                                      src={ShareablesBlackIcon}
                                      alt=""
                                      className="as-link"
                                      onClick={() => {
                                        setShowCompanyShareModel('show_model');
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              {!viewDetails &&
                                companyProfileData.bio &&
                                companyProfileData.bio !== '' && (
                                  <span
                                    className="profile_view_more"
                                    onClick={() => {
                                      setViewDetails(!viewDetails);
                                    }}>
                                    View Details
                                  </span>
                                )}

                              {viewDetails &&
                                companyProfileData.bio &&
                                companyProfileData.bio !== '' && (
                                  <span
                                    className="profile_view_more"
                                    onClick={() => {
                                      setViewDetails(!viewDetails);
                                    }}>
                                    Hide Details
                                  </span>
                                )}
                              {viewDetails && (
                                <div className="bio-title mb-2">
                                  <FormattedText
                                    className={`profile-description ${showMoreCompanyBio}`}
                                    content={
                                      companyProfileData.bio
                                        ? companyProfileData.bio
                                        : ''
                                    }
                                  />

                                  {(companyProfileData.bio?.length > 250 ||
                                    companyBioLines?.length > 3) && (
                                    <>
                                      {' '}
                                      <span
                                        className="view_more_btn"
                                        onClick={() =>
                                          setShowMoreBio(!showMoreBio)
                                        }>
                                        {!showMoreBio
                                          ? labels.view_more[selected_lang]
                                          : labels.view_less[selected_lang]}
                                        {/* {profile.data.bio.length} */}
                                      </span>
                                    </>
                                  )}
                                </div>
                              )}

                              {/* <div className="view_more_btn">View More</div> */}

                              <div className="poc_main mb-3 mt-3">
                                <div className="mb-4 d-flex justify-content-between">
                                  <div className="poc_name">POCs</div>
                                  {BusinnesProfilePermessions &&
                                    (BusinnesProfilePermessions === 'allow' ||
                                      BusinnesProfilePermessions?.add_poc ===
                                        true) && (
                                      <span
                                        className="btn_span"
                                        onClick={() => {
                                          setCurrentComponent('addPocs');
                                        }}>
                                        Add POCs
                                      </span>
                                    )}
                                </div>

                                {showPocs?.length === 0 && (
                                  // <div className="no-results-content">

                                  //                  <div className='text-center'>
                                  //                  {labels.no_record_found[selected_lang]}
                                  //                  </div>

                                  //                   <div className=" filter-btn-wrapper btn-wrapper mt-3">
                                  //                     <Button
                                  //                       className="btn btn-dark"
                                  //                       onClick={() => {

                                  //                       }}>
                                  //                      Add POCs
                                  //                     </Button>
                                  //                   </div>
                                  //                 </div>

                                  <div className="no-results-wrapper">
                                    <div className="no-results-content">
                                      <p>
                                        {labels.no_record_found[selected_lang]}
                                      </p>

                                      {BusinnesProfilePermessions &&
                                        (BusinnesProfilePermessions ===
                                          'allow' ||
                                          BusinnesProfilePermessions?.add_poc ===
                                            true) && (
                                          <div className=" filter-btn-wrapper btn-wrapper mt-3">
                                            <Button
                                              className="btn btn-dark"
                                              onClick={() => {
                                                setCurrentComponent('addPocs');
                                              }}>
                                              Add POCs
                                            </Button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                )}

                                {/* {showPocs?.length === 1 && (
                                  <div className="poc_sent_card">
                                    <div className="poc_card_img">
                                      <img
                                        src={showPocs[0].profile_img}
                                        alt=""
                                      />
                                    </div>

                                    <div className="poc_card_content">
                                      <h1>
                                        {capitalizeWords(showPocs[0].poc_name)}
                                      </h1>
                                      <span>Head Technician </span>
                                    </div>

                                    <div
                                      className="poc_send_icon as-link"
                                      onClick={() => {
                                        create_personal_group_chat_only();
                                      }}>
                                      <i className="fa-solid icon-plane"></i>
                                    </div>
                                  </div>
                                )} */}

                                {/* {BusinnesProfilePermessions &&
                                  (BusinnesProfilePermessions === 'allow' ||
                                    BusinnesProfilePermessions?.edit_company ===
                                      true) && (
                                    <> */}
                                {showPocs?.length >= 1 && (
                                  <div className="customer_care_card">
                                    <div className="customer_care_card_content">
                                      <div
                                        className="d-flex justify-content-between as-link"
                                        onClick={() => {
                                          setSelectedPoc(showPocs[0]);
                                          setCurrentComponent('singlePocs');
                                        }}>
                                        <h1>{showPocs[0].title}</h1>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div
                                          className="customer_imgs as-link"
                                          onClick={() => {
                                            setSelectedPoc(showPocs[0]);
                                            setCurrentComponent('singlePocs');
                                          }}>
                                          <>
                                            {Object.entries(
                                              showPocs[0].user_details,
                                            ).length > 0 &&
                                              showPocs[0].user_details
                                                .slice(0, 3)
                                                .map((item, index) => (
                                                  <img
                                                    src={
                                                      item.profile_img
                                                        ? item.profile_img
                                                        : DEFAULT_IMAGE
                                                    }
                                                    alt=""
                                                  />
                                                ))}

                                            {showPocs[0].user_details.length >
                                              3 && (
                                              <div className="remaining_div">
                                                +
                                                {showPocs[0].user_details
                                                  .length - 3}
                                              </div>
                                            )}
                                          </>
                                        </div>

                                        <div
                                          className="d-flex gap-2 poc-send-icons-new-block"
                                          style={{
                                            marginLeft: 'auto',
                                          }}>
                                          {BusinnesProfilePermessions &&
                                            (BusinnesProfilePermessions ===
                                              'allow' ||
                                              BusinnesProfilePermessions?.add_poc ===
                                                true) && (
                                              <>
                                                <span
                                                  className=" as-link"
                                                  onClick={() => {
                                                    setSelectedPoc(showPocs[0]);
                                                    setCurrentComponent(
                                                      'editPocs',
                                                    );
                                                  }}>
                                                  {/* <img
                                                    height={12}
                                                    src={EditPencilBlack}
                                                    alt="edit"
                                                  /> */}

                                                  <EditPencilSvg
                                                    fill={'#52bd41'}
                                                  />
                                                </span>

                                                <span
                                                  className="poc_delete as-link"
                                                  onClick={() => {
                                                    handleDeletePocsModel(
                                                      showPocs[0],
                                                    );
                                                    // deletePocs(showPocs[0]);
                                                  }}>
                                                  <i className="fa-solid fa-trash-can" />
                                                </span>
                                              </>
                                            )}

                                          {(showPocs[0].user_details.length >
                                            1 ||
                                            (showPocs[0].user_details.length ===
                                              1 &&
                                              showPocs[0].user_details[0].id !==
                                                localStorage.getItem(
                                                  'id',
                                                ))) && (
                                            <div
                                              className="poc_send_icon as-link"
                                              onClick={() => {
                                                create_personal_group_chat_only(
                                                  showPocs[0],
                                                  '',
                                                  '',
                                                );
                                              }}>
                                              <i className="fa-solid icon-plane"></i>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {showPocs?.length > 1 && (
                                  <div
                                    className="view_more_btn mt-3"
                                    onClick={() => {
                                      setCurrentComponent('morePocs');
                                    }}>
                                    View More
                                  </div>
                                )}
                                {/* </>
                                  )} */}
                              </div>
                            </div>

                            <div className="mt-2">
                              <ProfileGallery
                                userId={localStorage.getItem('id')}
                                handleCommand={handleComponents}
                                filterCat={filterCat}
                                permission={BusinnesProfilePermessions}
                                setType={setType}
                                showComponent={seconadaryComponent}
                                fromPage={'business'}
                              />
                            </div>
                            {/* 
                            <div className="search-tabs-wrapper">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="event-tabs tabs-style-2 mb-0">
                                  <ul>
                                    <li>
                                      <Button
                                        className={
                                          currentComponent === 'news'
                                            ? 'active'
                                            : ''
                                        }>
                                        General
                                      </Button>
                                    </li>
                                    <li>
                                      <Button
                                        className={
                                          currentComponent === '1122'
                                            ? 'active'
                                            : ''
                                        }>
                                        Credentials
                                      </Button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="dropdown_with_three_dots">
                              <div className="select-wrapper">
                                <Dropdown
                                  onSelect={(eventKey, event) => {
                                    setType(eventKey);
                                  }}
                                  drop="down">
                                  <Dropdown.Toggle variant="primary">
                                    {type
                                      ? dropdownMenu.find(
                                          item => item.id === type,
                                        ).name
                                      : 'Select Role'}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {dropdownMenu.map((item, index) => (
                                      <Dropdown.Item
                                        key={`st-${index}`}
                                        eventKey={item.id.toString()}
                                        active={item.id === type}>
                                        {item.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>

                              <div className="search-tabs-icons">
                                {BusinnesProfilePermessions &&
                                  (BusinnesProfilePermessions === 'allow' ||
                                    BusinnesProfilePermessions?.edit_company ===
                                      true) && (
                                    <span
                                      className={
                                        seconadaryComponent ===
                                        'createTransformation'
                                          ? 'icon active'
                                          : 'icon'
                                      }
                                      onClick={() => {
                                        if (
                                          seconadaryComponent ===
                                          'createTransformation'
                                        ) {
                                          setSecondaryComponent('');
                                        } else {
                                          setSecondaryComponent(
                                            'createTransformation',
                                          );
                                        }
                                      }}>
                                      <i className="icon-plus"></i>
                                    </span>
                                  )}

                                <span
                                  onClick={() => {
                                    setThreeDots(!threeDots);
                                  }}
                                  className={
                                    threeDots
                                      ? 'icon active threeDots'
                                      : 'icon threeDots'
                                  }>
                                  <i className="fas fa-ellipsis-vertical" />
                                </span>
                              </div>
                            </div> */}

                            {/* {threeDots && type === '1' && (
                              <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                                <CustomSlidingButtons
                                  items={categoryTypes}
                                  activeButtons={transformation_Filter}
                                  onItemClick={e => {
                                    handleClick(e);
                                  }}
                                  showItems={2}
                                />
                              </div>
                            )}

                            {type === '1' && (
                              <>
                                {transformationList &&
                                transformationList.length > 0 ? (
                                  transformationList.map((item, index) => (
                                    <div
                                      className="business_profile_content mt-3 as-link"
                                      key={index}
                                      onClick={() => {
                                        handleShowComponent(
                                          item,
                                          'transformationDetail',
                                        );
                                      }}>
                                      <div
                                        className="before_after_content mr-3"
                                        style={{position: 'relative'}}>
                                        {item.before_images.length > 1 && (
                                          <div
                                            style={{
                                              position: 'absolute',
                                              bottom: '12px',
                                              right: '18px',
                                            }}>
                                            <DoubleCam fill={'#52BD41'} />
                                          </div>
                                        )}
                                        {index === 0 && (
                                          <div className="mb-2">Before</div>
                                        )}
                                        <div className="before_after_img">
                                          <img
                                            src={item.before_images[0]}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="before_after_content"
                                        style={{position: 'relative'}}>
                                        {item.after_images.length > 1 && (
                                          <div
                                            style={{
                                              position: 'absolute',
                                              bottom: '12px',
                                              right: '18px',
                                            }}>
                                            <DoubleCam fill={'#52BD41'} />
                                          </div>
                                        )}
                                        {index === 0 && (
                                          <div className="mb-2">After</div>
                                        )}
                                        <div className="before_after_img">
                                          <img
                                            src={item.after_images[0]}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <>
                                    <div className="no-results-wrapper no-appointments my-5">
                                      
                                      <div className="no-results-content">
                                        <p className={'noRecord-desc'}>
                                          No transformation found
                                        </p>
                                        {BusinnesProfilePermessions &&
                                          (BusinnesProfilePermessions ===
                                            'allow' ||
                                            BusinnesProfilePermessions?.edit_company ===
                                              true) && (
                                            <div className="btn-wrapper mt-3">
                                              <Button
                                                className="btn btn-dark"
                                                onClick={() => {
                                                  setSecondaryComponent(
                                                    'createTransformation',
                                                  );
                                                }}>
                                                Add Transformation
                                              </Button>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )} */}

                            {/* {(type === '2' || type === '3') && (
                              <div className="business_profile_offering_content mt-3">
                                {Object.entries(offeringData).length > 0 &&
                                  offeringData.map((item, index) => (
                                    <div
                                      className="offering_card mb-3"
                                      key={index}>
                                      <div className="offering_card_img"></div>

                                      <div className="offering_card_content">
                                        <div className="offer_title">
                                          {item.name}
                                        </div>

                                        <div className="offer_time">
                                          {item.name}
                                        </div>

                                        <div className="offer_category">
                                          {item.category}
                                        </div>

                                        <div className="offer_cost">
                                          {item.cost}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )} */}
                          </div>
                        )}

                        {tabType ===
                          labels.business_type_market[selected_lang] && (
                          <>
                            <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                              <CustomSlidingButtons
                                items={listForDropDown}
                                activeButtons={areas_Filter}
                                onItemClick={e => {
                                  dropDownSelection(e);
                                }}
                                showItems={2}
                              />
                            </div>

                            <div className="refferal-list-wrapper">
                              {marketLoader && <LoaderSpinner />}

                              {areas_Filter === 'general' && (
                                <div className="business_market_tab">
                                  <div className="market_toogle">
                                    <div>Show on The Market</div>
                                    <Form.Check
                                      disabled={
                                        BusinnesProfilePermessions &&
                                        (BusinnesProfilePermessions ===
                                          'allow' ||
                                          BusinnesProfilePermessions?.edit_company ===
                                            true)
                                          ? false
                                          : true
                                      }
                                      className=""
                                      type="switch"
                                      id="custom-switch"
                                      // label={'Multiple Locations'}
                                      value={marketToggle}
                                      checked={marketToggle}
                                      onClick={() => {
                                        setMarketToggle(!marketToggle);
                                        updateBusinessProfile(
                                          'market',
                                          !marketToggle,
                                        );
                                      }}
                                    />
                                  </div>

                                  <span className="linkedIn_btn">
                                    Import from LinkedIn
                                  </span>

                                  <div className="mb-3">
                                    <div className="announcement_card">
                                      <div>Have an important announcement?</div>

                                      {announcement !== '' && (
                                        <span>
                                          <FormattedText
                                            className={`profile-description ${showMoreClassAnnouncement}`}
                                            content={
                                              announcement ? announcement : ''
                                            }
                                          />

                                          {(announcement?.length > 250 ||
                                            announcementLines?.length > 3) && (
                                            <>
                                              {' '}
                                              <span
                                                className="show-more-link"
                                                onClick={() =>
                                                  setShowMoreAnnouncement(
                                                    !showMoreAnnouncement,
                                                  )
                                                }>
                                                {!showMoreAnnouncement
                                                  ? labels.read_more[
                                                      selected_lang
                                                    ]
                                                  : labels.read_less[
                                                      selected_lang
                                                    ]}
                                                {/* {profile.data.bio.length} */}
                                              </span>
                                            </>
                                          )}
                                        </span>
                                      )}
                                    </div>

                                    <span className="announmentt">
                                      {BusinnesProfilePermessions &&
                                        (BusinnesProfilePermessions ===
                                          'allow' ||
                                          BusinnesProfilePermessions?.edit_company ===
                                            true) && (
                                          <p
                                            onClick={() => {
                                              setCurrentComponent(
                                                'announcement',
                                              );
                                            }}>
                                            Add Public Announcement
                                          </p>
                                        )}
                                    </span>
                                  </div>

                                  <div className="update_banner_card mb-3">
                                    <div className="banner_img_card">
                                      <img
                                        src={
                                          ticketImg
                                            ? ticketImg
                                            : DEFAULT_IMAGE_COMPANYBANNER
                                        }
                                        alt=""
                                      />
                                    </div>

                                    <span className="announmentt">
                                      <div style={{position: 'relative'}}>
                                        {BusinnesProfilePermessions &&
                                          (BusinnesProfilePermessions ===
                                            'allow' ||
                                            BusinnesProfilePermessions?.edit_company ===
                                              true) && (
                                            <>
                                              <p>Update Banner</p>
                                              <div className="banner_input">
                                                <input
                                                  type="file"
                                                  accept="image/*"
                                                  onChange={e =>
                                                    updateBanner(e)
                                                  }
                                                />
                                              </div>{' '}
                                            </>
                                          )}
                                      </div>
                                    </span>
                                  </div>

                                  <div className="company_detail_card">
                                    <div className="company_profile_card mb-4">
                                      <div className="company_logo">
                                        <img
                                          src={
                                            companyProfileData &&
                                            companyProfileData?.company_logo
                                              ? companyProfileData.company_logo
                                              : DEFAULT_IMAGE_COMPANYLOGO
                                          }
                                          alt=""
                                        />
                                      </div>

                                      <div className="company_content">
                                        <span className="company_title">
                                          {multipleProfileData.company_name
                                            ? multipleProfileData.company_name
                                            : ''}
                                        </span>

                                        <div className="company_rating_content">
                                          <span className="rating">
                                            4.8 <img src={rewardStar} alt="" />
                                          </span>

                                          {/* <img
                                            src={ShareablesBlackIcon}
                                            alt=""
                                          /> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="company_detail_card_content">
                                      <div className="bio-title mb-1">
                                        Industry
                                      </div>

                                      {location && (
                                        <div className="company_location">
                                          <i className="fas fa-location-dot" />
                                          <span>
                                            {location ? location : ''}
                                          </span>
                                        </div>
                                      )}

                                      {/* <span>Let’s collaborate and BUILD! </span> */}

                                      <p className="company_description mb-1">
                                        <FormattedText
                                          className={`profile-description ${showMoreClass}`}
                                          content={bio ? bio : ''}
                                        />

                                        {(bio?.length > 250 ||
                                          lines?.length > 3) && (
                                          <>
                                            {' '}
                                            <span
                                              className="show-more-link"
                                              onClick={() =>
                                                setShowMore(!showMore)
                                              }>
                                              {!showMore
                                                ? labels.read_more[
                                                    selected_lang
                                                  ]
                                                : labels.read_less[
                                                    selected_lang
                                                  ]}
                                              {/* {profile.data.bio.length} */}
                                            </span>
                                          </>
                                        )}
                                      </p>

                                      <div className="social_media_icons">
                                        <ul>
                                          {instagramName && (
                                            <li>
                                              <a
                                                // href={profile.data.instagram}
                                                href={`https://www.instagram.com/${instagramName.replace(
                                                  /(http(s?):\/\/)?(www\.)?instagram\.com\//,
                                                  '',
                                                )}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <i className="fab fa-instagram" />
                                              </a>
                                            </li>
                                          )}

                                          {linkdinName && (
                                            <li>
                                              <a
                                                // href={profile.data.instagram}
                                                href={`https://www.linkedin.com/${linkdinName.replace(
                                                  /(http(s?):\/\/)?(www\.)?linkedin\.com\//,
                                                  '',
                                                )}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <i className="fab fa-linkedin" />
                                              </a>
                                            </li>
                                          )}
                                          {twitterName && (
                                            <li>
                                              <a
                                                // href={profile.data.twitter}
                                                href={`https://twitter.com/${twitterName.replace(
                                                  /(http(s?):\/\/)?(www\.)?twitter\.com\//,
                                                  '',
                                                )}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <i className="fab fa-twitter" />
                                              </a>
                                            </li>
                                          )}

                                          {youtubeName && (
                                            <li>
                                              <a
                                                // href={profile.data.youtube}
                                                href={`https://www.youtube.com/${youtubeName.replace(
                                                  /(http(s?):\/\/)?(www\.)?(m\.)?youtube\.com\/(channel\/)?/,
                                                  '',
                                                )}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <i className="fab fa-youtube" />
                                              </a>
                                            </li>
                                          )}

                                          {website && (
                                            <li>
                                              <a
                                                // href={profile.data.web}
                                                href={`https://${website.replace(
                                                  /(http(s)?:\/\/|http(s)?:\/)?/,
                                                  '',
                                                )}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <i className="icon-link" />
                                              </a>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="company_detail_bottm_card">
                                    <div>
                                      <div className="market_toogle">
                                        <div>Show Ratings</div>
                                        <Form.Check
                                          disabled={
                                            BusinnesProfilePermessions &&
                                            (BusinnesProfilePermessions ===
                                              'allow' ||
                                              BusinnesProfilePermessions?.edit_company ===
                                                true)
                                              ? false
                                              : true
                                          }
                                          className=""
                                          type="switch"
                                          id="custom-switch"
                                          // label={'Multiple Locations'}
                                          value={ratingToggle}
                                          checked={ratingToggle}
                                          onClick={() => {
                                            setRatingToggle(!ratingToggle);
                                            updateBusinessProfile(
                                              'rating',
                                              !ratingToggle,
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {BusinnesProfilePermessions &&
                                      (BusinnesProfilePermessions === 'allow' ||
                                        BusinnesProfilePermessions?.edit_company ===
                                          true) && (
                                        <span
                                          className="update_bio_btn"
                                          onClick={() => {
                                            setCurrentComponent('editProfile');
                                          }}>
                                          Update Bio
                                        </span>
                                      )}
                                  </div>
                                </div>
                              )}

                              {areas_Filter === 'branding' && (
                                <Branding
                                  companyProfileData={companyProfileData}
                                  permission={BusinnesProfilePermessions}
                                  updateLogo={updateLogo}
                                  updateBanner={updateCompanyBanner}
                                  // updateCompanyDetails={updateCompanyDetails}
                                  onClose={() => {
                                    setPrimaryComponent('');
                                  }}
                                />
                              )}

                              {areas_Filter === 'meetup' &&
                              Object.entries(showMeetups).length > 0
                                ? showMeetups.map((item, index) => {
                                    // if (typeIs && !item.image) {
                                    return (
                                      <SessionBusinessProfile
                                        key={index} // Make sure to include a unique key when mapping over arrays in React
                                        item={item} // Pass the current item, not the entire showMeetups array
                                        PageDesign={ToggleDesign}
                                      />
                                    );
                                    // } else {
                                    //   return null; // If the condition is not met, return null or render something else
                                    // }
                                  })
                                : areas_Filter === 'meetup' && (
                                    <>
                                      <div className="no-results-wrapper">
                                        <div className="img-bg-wrapper">
                                          <img
                                            src={require('../../Assets/images/norecord/session.png')}
                                            alt="no-session"
                                          />
                                        </div>

                                        <div className="no-results-content">
                                          <h2>Manage Meetups</h2>
                                          <p>
                                            Meetup with your network virtually
                                          </p>

                                          <div className="btn-wrapper mt-3">
                                            <Button
                                              className="btn btn-dark"
                                              onClick={() => {
                                                setSecondaryComponent(
                                                  'createMeetup',
                                                );
                                              }}>
                                              {/* <Link
                                              className="text-light"
                                              style={{textDecoration: 'none'}}
                                              to={{
                                                pathname: '/',
                                                state: {
                                                  typeIs: 'sessionss',
                                                  data: 'createSession',
                                                },
                                              }}>
                                              Create Meetups
                                            </Link> */}
                                              Create Meetups
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                              {areas_Filter === 'recruitment' &&
                              Object.entries(showOpportuntiy).length > 0
                                ? showOpportuntiy.map((item, index) => (
                                    <RecruitmentBusinessProfile
                                      key={index} // Make sure to include a unique key when mapping over arrays in React
                                      PageDesign={ToggleDesign}
                                      item={item} // Pass the current item to the AppointmentBusinessProfile component
                                    />
                                  ))
                                : areas_Filter === 'recruitment' && (
                                    <div className="no-results-wrapper">
                                      <div className="img-bg-wrapper">
                                        <img
                                          src={require('../../Assets/images/norecord/opportunity.png')}
                                          alt=""
                                        />
                                      </div>

                                      <div className="no-results-content">
                                        <h2>
                                          {
                                            labels.Share_Opportunity[
                                              selected_lang
                                            ]
                                          }
                                        </h2>
                                        <p>
                                          {
                                            labels.Someone_Waiting[
                                              selected_lang
                                            ]
                                          }
                                        </p>

                                        <div className="btn-wrapper mt-3">
                                          <Button
                                            className="btn btn-dark"
                                            onClick={() => {
                                              setSecondaryComponent(
                                                'createRecruitment',
                                              );
                                            }}>
                                            {/* <Link
                                            className="text-light"
                                            style={{textDecoration: 'none'}}
                                            to={{
                                              pathname: '/recruitment',
                                            }}>
                                            {
                                              labels.Create_Opportunity[
                                                selected_lang
                                              ]
                                            }
                                          </Link> */}

                                            {
                                              labels.Create_Opportunity[
                                                selected_lang
                                              ]
                                            }
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                              {areas_Filter === 'appointment' &&
                              Object.entries(showAppointment).length > 0
                                ? showAppointment.map((item, index) => (
                                    <AppointmentBusinessProfile
                                      key={index} // Make sure to include a unique key when mapping over arrays in React
                                      PageDesign={ToggleDesign}
                                      item={item} // Pass the current item to the AppointmentBusinessProfile component
                                    />
                                  ))
                                : areas_Filter === 'appointment' && (
                                    <>
                                      <div className="no-results-wrapper no-appointments mt-5">
                                        <div className="img-bg-wrapper">
                                          <img
                                            src={require('../../Assets/images/norecord/no-appointments.png')}
                                            alt=""
                                          />
                                        </div>
                                        <div className="no-results-content">
                                          <p className="noRecord-title">
                                            No Upcoming 1-on-1s
                                          </p>
                                          <p className="noRecord-desc">
                                            You don't have any upcoming
                                            schedules
                                          </p>

                                          <div className="btn-wrapper mt-3">
                                            <Button
                                              className={
                                                'btn btn-dark'
                                                // getItem('userRole') === 'guest'
                                                //   ? 'grey-class-btn'
                                                //   : 'btn btn-dark'
                                              }
                                              onClick={() => {
                                                setSecondaryComponent(
                                                  'allBuilders',
                                                );
                                              }}>
                                              {/* <Link
                                             
                                              className="text-light"
                                              style={{
                                                textDecoration: 'none',
                                              }}
                                              to={{
                                                pathname: '/builders',
                                                state: {
                                                  typeIs: 'search',
                                                },
                                              }}>
                                              Explore Builders
                                            </Link> */}
                                              Explore Builders
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                              {/* {areas_Filter === 'pocs' && (
                                <div className="filter-wrapper custom-modal-filter mb-0">
                                  {showPocs &&
                                    Object.entries(showPocs).length > 0 && (
                                      <div className="filter-headings">
                                        <h2>POCs</h2>
                                      </div>
                                    )}

                                  <div className="filter-category-list">
                                    {showPocs &&
                                    Object.entries(showPocs).length > 0
                                      ? showPocs.map((item, index) => (
                                          <div className="poc_sent_card mb-2">
                                            <div className="poc_card_img">
                                              <img
                                                src={
                                                  item.profile_img
                                                    ? item.profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                            </div>

                                            <div className="poc_card_content">
                                              <h1>
                                                {capitalizeWords(item.poc_name)}
                                              </h1>
                                              <span>Head Technician </span>
                                            </div>
                                            <div className="market_toogle">
                                              <Form.Check
                                                className=""
                                                type="switch"
                                                id="custom-switch"
                                                value={
                                                  item.show_in_market === 'true'
                                                    ? true
                                                    : false
                                                }
                                                checked={
                                                  item.show_in_market === 'true'
                                                    ? true
                                                    : false
                                                }
                                                onClick={() => {
                                                  const updatedShowPocs =
                                                    showPocs.map(poc => {
                                                      if (poc.id === item.id) {
                                                        // Toggle the value of show_in_market
                                                        return {
                                                          ...poc,
                                                          show_in_market:
                                                            item.show_in_market ===
                                                            'true'
                                                              ? 'false'
                                                              : 'true',
                                                        };
                                                      }
                                                      return poc;
                                                    });
                                                  setShowPocs(updatedShowPocs);
                                                  update_companypoc_show_in_market(
                                                    item.poc_id,
                                                    item.show_in_market,
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ))
                                      : areas_Filter === 'pocs' && (
                                          <>
                                            <div className="no-results-wrapper no-appointments my-5">
                                              <div className="no-results-content">
                                                <p className={'noRecord-desc'}>
                                                  No record found
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                  </div>
                                </div>
                              )} */}

                              {areas_Filter === 'pocs' &&
                              showPocs.length > 0 ? (
                                <div className="filter-wrapper custom-modal-filter mb-0 company-pcos-profile-holder">
                                  <div className="filter-headings">
                                    <h2>POCs</h2>
                                  </div>

                                  <div className="filter-category-list customer-care-business-card-holder">
                                    {showPocs &&
                                      Object.entries(showPocs).length > 0 &&
                                      showPocs.map((item, index) => (
                                        <div className="customer_care_card">
                                          <div className="customer_care_card_content">
                                            <div className="d-flex justify-content-between ">
                                              <h1>{item.title}</h1>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-end">
                                              <div className="customer_imgs">
                                                <>
                                                  {Object.entries(
                                                    item.user_details,
                                                  ).length > 0 &&
                                                    item.user_details
                                                      .slice(0, 3)
                                                      .map((item, index) => (
                                                        <img
                                                          src={
                                                            item.profile_img
                                                              ? item.profile_img
                                                              : DEFAULT_IMAGE
                                                          }
                                                          alt=""
                                                        />
                                                      ))}
                                                  {item.user_details.length >
                                                    3 && (
                                                    <div className="remaining_div">
                                                      +
                                                      {item.user_details
                                                        .length - 3}
                                                    </div>
                                                  )}
                                                </>
                                              </div>
                                            </div>
                                          </div>

                                          {/* <div className="mt-2 p20-horizental show-on-public">
        <div className="d-flex align-items-center justify-content-center">
          <p className="p-reg m-0">Show on Market</p>
          <div>
            <Form.Check
              type="switch"
              className="switchBlock"
              id="custom-switch"
              value={item.show_in_market}
              checked={item.show_in_market}
              onClick={() => {
             
                update_companypoc_show_in_market(item.id, item.show_in_market);
                updateMarketToggleValue('poc', item, !item.show_in_market);
                // setIsactiveToggle(!isactiveToggle);
                // change_appearance(type, item);
              }}
            />
          </div>
        </div>
      </div> */}

                                          <div className="market_toogle">
                                            <Form.Check
                                              className=""
                                              type="switch"
                                              id="custom-switch"
                                              value={
                                                item.show_in_market === 'true'
                                                  ? true
                                                  : false
                                              }
                                              checked={
                                                item.show_in_market === 'true'
                                                  ? true
                                                  : false
                                              }
                                              onClick={() => {
                                                const updatedShowPocs =
                                                  showPocs.map(poc => {
                                                    if (
                                                      poc.title ===
                                                        item.title &&
                                                      poc.poc_role ===
                                                        item.poc_role
                                                    ) {
                                                      // Toggle the value of show_in_market
                                                      return {
                                                        ...poc,
                                                        show_in_market:
                                                          item.show_in_market ===
                                                          'true'
                                                            ? 'false'
                                                            : 'true',
                                                      };
                                                    }
                                                    return poc;
                                                  });
                                                setShowPocs(updatedShowPocs);
                                                update_companypoc_show_in_market(
                                                  item,
                                                  item.show_in_market,
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              ) : (
                                areas_Filter === 'pocs' && (
                                  <>
                                    <div className="no-results-wrapper no-appointments mt-5">
                                      {/* <div className="img-bg-wrapper">
                                      <img
                                        src={require('../../Assets/images/norecord/no-appointments.png')}
                                        alt=""
                                      />
                                    </div> */}
                                      <div className="no-results-content">
                                        <p className="noRecord-title">
                                          No POCs
                                        </p>
                                        <p className="noRecord-desc">
                                          Seems Company don't have any POCs
                                        </p>

                                        {BusinnesProfilePermessions &&
                                          (BusinnesProfilePermessions ===
                                            'allow' ||
                                            BusinnesProfilePermessions?.add_poc ===
                                              true) && (
                                            <div className="btn-wrapper mt-3">
                                              <Button
                                                className={
                                                  'btn btn-dark'
                                                  // getItem('userRole') === 'guest'
                                                  //   ? 'grey-class-btn'
                                                  //   : 'btn btn-dark'
                                                }
                                                onClick={() => {
                                                  setCurrentComponent(
                                                    'addPocs',
                                                  );
                                                }}>
                                                Add Pocs
                                              </Button>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </>
                                )
                              )}

                              {areas_Filter === 'reputation' && (
                                <>
                                  <div className="no-results-wrapper no-appointments my-5">
                                    <div className="no-results-content">
                                      <p className={'noRecord-desc'}>
                                        Under Development
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </Scrollbars>
                    </div>
                  </div>

                  {currentComponent === 'Poc_chat' &&
                    showDataOfSingleChat &&
                    showDataOfSingleChat &&
                    showDataOfSingleChat.chat_detail && (
                      <SmartChatPage
                        marginTop={true}
                        allmsg={true}
                        type={'Poc_chat'}
                        item={[]}
                        chatroomInfo={chatroomInfo(
                          showDataOfSingleChat.chat_detail,
                        )}
                        oppoDetails={showDataOfSingleChat.chat_detail}
                        chatDetails={showDataOfSingleChat.chat_detail}
                        allChatUsers={collectingUsers(
                          showDataOfSingleChat.chat_detail,
                        )}
                        chatRoomName={
                          showDataOfSingleChat.chat_detail.chat_room_name
                        }
                        chatRoomId={showDataOfSingleChat.chat_detail.id}
                        onClose={() => {
                          // setChatButton(false);
                          setCurrentComponent('');
                        }}
                        handleCommonChat={(data, type) => {
                          handleCommonChat(data, type);
                        }}
                      />

                      // <SlidingPanel
                      //   hidePanel={() => {
                      //     setChatButton(false);
                      //     setShowComponent('');
                      //   }}>
                      /* <SmartChatPage
            type={'personal-group'}
            chatDetails={props.item.group_chat_detail}
            oppoDetails={props.item.group_chat_detail}
            allChatUsers={props.item.group_chat_detail.group_detail}
            chatRoomName={props.item.group_chat_detail.chat_room_name}
            chatRoomId={props.item.group_chat_detail.id}
            chatRoomStatus={props.item.group_chat_detail.chat_room_status}
            onClose={() => {
              setChatButton(false);
              setShowComponent('');
            }}
          /> */
                      // </SlidingPanel>
                    )}
                  {currentComponent === 'Poc_chat_Single' &&
                    showDataOfSingleChat &&
                    showDataOfSingleChat.chat_detail && (
                      <SmartChatPage
                        allmsg={true}
                        marginTop={true}
                        type={'Poc_chat'}
                        item={[]}
                        chatroomInfo={chatroomInfo(
                          showDataOfSingleChat.chat_detail,
                        )}
                        oppoDetails={showDataOfSingleChat.chat_detail}
                        chatDetails={showDataOfSingleChat.chat_detail}
                        allChatUsers={collectingUsers(
                          showDataOfSingleChat.chat_detail,
                        )}
                        chatRoomName={
                          showDataOfSingleChat.chat_detail.chat_room_name
                        }
                        chatRoomId={showDataOfSingleChat.chat_detail.id}
                        onClose={() => {
                          // setChatButton(false);
                          setCurrentComponent('');
                        }}
                        handleCommonChat={(data, type) => {
                          handleCommonChat(data, type);
                        }}
                      />

                      // <SlidingPanel
                      //   hidePanel={() => {
                      //     setChatButton(false);
                      //     setShowComponent('');
                      //   }}>
                      /* <SmartChatPage
            type={'personal-group'}
            chatDetails={props.item.group_chat_detail}
            oppoDetails={props.item.group_chat_detail}
            allChatUsers={props.item.group_chat_detail.group_detail}
            chatRoomName={props.item.group_chat_detail.chat_room_name}
            chatRoomId={props.item.group_chat_detail.id}
            chatRoomStatus={props.item.group_chat_detail.chat_room_status}
            onClose={() => {
              setChatButton(false);
              setShowComponent('');
            }}
          /> */
                      // </SlidingPanel>
                    )}
                  {currentComponent === 'editProfile' && (
                    <EditProfile
                      profileBio={bio}
                      setProfileBio={setBio}
                      updateBusinessProfile={updateProfileBio}
                      setInstagramValue={setInstagramName}
                      setLinkdinValue={setLinkdinName}
                      setTwitterValue={setTwitterName}
                      setYoutubeValue={setYoutubeName}
                      setWebsite={setWebsite}
                      instagramValue={instagramName}
                      linkdinValue={linkdinName}
                      twitterValue={twitterName}
                      youtubeValue={youtubeName}
                      website={website}
                      setLocationValue={setLocation}
                      locationValue={location}
                      multipleProfileData={multipleProfileData}
                      onClose={() => {
                        setCurrentComponent('');
                      }}
                    />
                  )}
                  {editProfile === 'editProf' && (
                    <EditBusinessProfile
                      updateCompanyDetails={updateCompanyDetails}
                      companyProfileData={companyProfileData}
                      onClose={() => {
                        setEditProfile('');
                      }}
                    />
                  )}
                  {currentComponent === 'announcement' && (
                    <CustomModal
                      size="small"
                      onClose={() => {
                        if (
                          announcement !==
                          multipleProfileData.public_announcement
                        ) {
                          setAnnouncement(
                            multipleProfileData.public_announcement,
                          );
                        }
                        setCurrentComponent('');
                      }}
                      className="availability-modal">
                      <div
                        className="new-session-modal-wrapper text-center  "
                        style={{width: '-webkit-fill-available'}}>
                        <div className="new-session-modal">
                          <div className="fieldset">
                            <div className="d-flex align-items-center justify-content-between">
                              <Form.Label
                                className="w-auto"
                                controlid="validationCustom01">
                                Announcement
                              </Form.Label>
                            </div>

                            <Form.Control
                              required
                              value={announcement}
                              as="textarea"
                              rows={6}
                              type="text"
                              placeholder="Add Announcement... "
                              onChange={e => {
                                setAnnouncement(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Title is required.
                            </Form.Control.Feedback>
                          </div>

                          <Button
                            className="no-thanks"
                            onClick={() => {
                              updateBusinessProfile();
                              setCurrentComponent('');
                            }}>
                            Update
                          </Button>
                        </div>
                      </div>
                    </CustomModal>
                  )}

                  {showModel === 'updatebanner' && file && (
                    <CustomModal
                      size="large"
                      onClose={() => {
                        setShowModel('');
                        setFile(null);
                      }}>
                      <CropImage
                        type="businessProfile"
                        ratio={100 / 50}
                        file={file}
                        setFile={setFile}
                        setCropImg={setShowModel}
                        imgUrl={ticketImg}
                        setImgUrl={setTicketImg}
                        setshowLoader={setshowLoader}
                        updateBusinessProfile={updateBusinessProfile}
                      />
                    </CustomModal>
                  )}

                  {currentComponent === 'addPocs' && (
                    <SlidingPanel
                      hidePanel={() => {
                        setCurrentComponent('');
                        setBuilders([]);
                        setAllBuilders([]);
                        setOriginalDataOfMod([]);
                        setPocTitle('');

                        if (originalPocs.length !== builders.length) {
                          // setBuilders(originalPocs);
                          // setAllBuilders([]);
                          getCompanyDetails();
                        }
                        // setBuilders([])
                        // setAllBuilders([])
                      }}
                      renderData={renderHeader}
                      showBlackHeader={true}
                      headerPadding={'24px 20px'}
                      lastDivStyle={{
                        borderRadius: '35px',
                        background: '#f7f6f2',
                      }}
                      firstDivStyle={{
                        padding: 0,
                        background:
                          'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
                      }}>
                      {/* <div className="invite-page-wrapper"> */}
                      {/* <Scrollbars autoHide> */}
                      <InvitePoc
                        type={'refer'}
                        removeCompnayCreator={'yes'}
                        component={'businessProfile'}
                        moderator={builders}
                        setModerator={setBuilders}
                        selectedModData={allBuilders}
                        setSelectedModData={setAllBuilders}
                        title={pocTitle}
                        setTitle={setPocTitle}
                        contact={contact}
                        setContact={setContact}
                        originalDataOfMod={originalDataOfMod}
                        setOriginalDataOfMod={setOriginalDataOfMod}
                        audience={audience}
                        setAudience={setAudience}
                        selectedAudData={selectedAudData}
                        setSelectedAudData={setSelectedAudData}
                        originalDataOfAud={originalDataOfAud}
                        setOriginalDataOfAud={setOriginalDataOfAud}
                        handleCircle={handleCircle}
                        forApiHit={refreshPocs}
                        onClick={() => setShowComponent('inviteUser')}
                        updateCompanyDetails={updateCompanyDetails}
                        onClose={() => {
                          setCurrentComponent('');
                          setBuilders([]);
                          setAllBuilders([]);
                          setOriginalDataOfMod([]);
                          setPocTitle('');
                        }}
                      />
                      {/* </Scrollbars> */}
                      {/* </div> */}
                    </SlidingPanel>
                  )}

                  {currentComponent === 'editPocs' && selectedPoc && (
                    <SlidingPanel
                      hidePanel={() => {
                        setCurrentComponent('');
                        setBuilders([]);
                        setAllBuilders([]);
                        setOriginalDataOfMod([]);
                        setPocTitle('');
                        setSelectedPoc({});

                        if (originalPocs.length !== builders.length) {
                          // setBuilders(originalPocs);
                          // setAllBuilders([]);
                          getCompanyDetails();
                        }
                        // setBuilders([])
                        // setAllBuilders([])
                      }}
                      renderData={renderHeader}
                      showBlackHeader={true}
                      headerPadding={'24px 20px'}
                      lastDivStyle={{
                        borderRadius: '35px',
                        background: '#f7f6f2',
                      }}
                      firstDivStyle={{
                        padding: 0,
                        background:
                          'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
                      }}>
                      {/* <div className="invite-page-wrapper"> */}
                      {/* <Scrollbars autoHide> */}
                      <InvitePoc
                        type={'refer'}
                        // editPoc={'editPoc'}
                        selectedPoc={selectedPoc}
                        removeCompnayCreator={'yes'}
                        component={'businessProfile'}
                        moderator={builders}
                        setModerator={setBuilders}
                        selectedModData={allBuilders}
                        setSelectedModData={setAllBuilders}
                        title={pocTitle}
                        setTitle={setPocTitle}
                        contact={contact}
                        setContact={setContact}
                        originalDataOfMod={originalDataOfMod}
                        setOriginalDataOfMod={setOriginalDataOfMod}
                        handleCircle={handleCircle}
                        getCompanyDetails={getCompanyDetails}
                        forApiHit={refreshPocs}
                        onClick={() => setShowComponent('inviteUser')}
                        updateCompanyDetails={updateCompanyDetails}
                        onClose={() => {
                          setCurrentComponent('');
                        }}
                      />
                      {/* </Scrollbars> */}
                      {/* </div> */}
                    </SlidingPanel>
                  )}

                  {showComponent === 'CircleList' && (
                    <CircleListBuilders
                      onClose={() => {
                        setShowComponent('');
                      }}
                      // setInviteCircle={setInviteCircle}
                      hide={true}
                      CircleUserListData={CircleUserListData}
                    />
                  )}
                  {showComponent === 'inviteUser' && (
                    <SendInvitation
                      setContact={setContact}
                      onClose={() => setShowComponent('')}
                    />
                  )}

                  {currentComponent === 'singlePocs' && selectedPoc && (
                    <CustomModal
                      size="medium"
                      style={{height: '85%'}}
                      onClose={() => {
                        setCurrentComponent(prevState);
                        setPrevState('');
                      }}>
                      <div className="filter-wrapper custom-modal-filter mb-0">
                        <div className="filter-headings">
                          <h2>{selectedPoc.title}</h2>
                        </div>
                        <Scrollbars>
                          <div className="filter-category-list">
                            {selectedPoc &&
                              Object.entries(selectedPoc.user_details).length >
                                0 &&
                              selectedPoc.user_details.map((item, index) => (
                                <div className="poc_sent_card mb-2">
                                  <div className="poc_card_img">
                                    <img
                                      src={
                                        item.profile_img
                                          ? item.profile_img
                                          : DEFAULT_IMAGE
                                      }
                                      alt=""
                                    />
                                  </div>

                                  <div className="poc_card_content">
                                    {/* <h1>{capitalizeWords(item.poc_name)}</h1> */}
                                    <h1>
                                      {' '}
                                      {fixName(item.first_name, item.last_name)}
                                    </h1>
                                    <span>Head Technician </span>
                                  </div>

                                  {localStorage.getItem('id') !== item.id && (
                                    <div
                                      className="poc_send_icon as-link d-flex align-items-center "
                                      onClick={() => {
                                        create_personal_group_chat_only(
                                          '',
                                          'single',
                                          item.id,
                                        );
                                      }}>
                                      <i className="fa-solid icon-plane"></i>
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        </Scrollbars>
                      </div>
                    </CustomModal>
                  )}

                  {currentComponent === 'morePocs' && (
                    <CustomModal
                      size="medium"
                      style={{height: '85%'}}
                      onClose={() => setCurrentComponent('')}>
                      <div className="filter-wrapper custom-modal-filter mb-0">
                        <div className="filter-headings">
                          <h2>POCs</h2>
                        </div>
                        <Scrollbars>
                          <div className="filter-category-list">
                            {showPocs &&
                              Object.entries(showPocs).length > 0 &&
                              showPocs.map((item, index) => (
                                <div className="customer_care_card ">
                                  <div className="customer_care_card_content as-link">
                                    <div
                                      className="d-flex justify-content-between "
                                      onClick={() => {
                                        setSelectedPoc(item);
                                        setPrevState('morePocs');
                                        setCurrentComponent('singlePocs');
                                      }}>
                                      <h1>{item.title}</h1>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-end">
                                      <div
                                        className="customer_imgs"
                                        onClick={() => {
                                          setSelectedPoc(item);
                                          setPrevState('morePocs');
                                          setCurrentComponent('singlePocs');
                                        }}>
                                        <>
                                          {Object.entries(item.user_details)
                                            .length > 0 &&
                                            item.user_details
                                              .slice(0, isResponsive() ? 2 : 3)
                                              .map((item, index) => (
                                                <img
                                                  src={
                                                    item.profile_img
                                                      ? item.profile_img
                                                      : DEFAULT_IMAGE
                                                  }
                                                  alt=""
                                                />
                                              ))}
                                          {item.user_details.length > 3 && (
                                            <div className="remaining_div">
                                              +
                                              {item.user_details.length -
                                              isResponsive()
                                                ? 2
                                                : 3}
                                            </div>
                                          )}
                                        </>
                                      </div>

                                      <div className="d-flex gap-2 poc-send-icons-new-block">
                                        {BusinnesProfilePermessions &&
                                          (BusinnesProfilePermessions ===
                                            'allow' ||
                                            BusinnesProfilePermessions?.add_poc ===
                                              true) && (
                                            <>
                                              <span
                                                className="as-link"
                                                onClick={() => {
                                                  setSelectedPoc(item);
                                                  setCurrentComponent(
                                                    'editPocs',
                                                  );
                                                }}>
                                                <EditPencilSvg
                                                  fill={'#52bd41'}
                                                />
                                              </span>

                                              <span
                                                className="poc_delete as-link"
                                                onClick={() => {
                                                  handleDeletePocsModel(item);
                                                  // deletePocs(item);
                                                }}>
                                                <i className="fa-solid fa-trash-can" />
                                              </span>
                                            </>
                                          )}

                                        {(item.user_details.length > 1 ||
                                          (item.user_details.length === 1 &&
                                            item.user_details[0].id !==
                                              localStorage.getItem('id'))) && (
                                          <div
                                            className="poc_send_icon as-link"
                                            onClick={() => {
                                              create_personal_group_chat_only(
                                                item,
                                                '',
                                                '',
                                              );
                                            }}>
                                            <i className="fa-solid icon-plane"></i>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </Scrollbars>
                      </div>
                    </CustomModal>
                  )}

                  {primaryComponent === 'snapshot_filter' && (
                    <CustomModal
                      size="medium"
                      className={'filter-modal from-otherProfile-Snapshot'}
                      style={{height: '82%'}}
                      onClose={() => {
                        setPrimaryComponent('');
                      }}>
                      <div className="filter-wrapper custom-modal-filter mb-0">
                        <div className="filter-headings">
                          <h2
                            className="text-center"
                            style={{marginLeft: '-28px'}}>
                            Filter By Category
                          </h2>
                        </div>
                        <Scrollbars className="custom-scroll">
                          <div className="filter-category-list">
                            <ul>
                              <li
                                key={`sessionC-100`}
                                className={
                                  categoriesArr.length === 8 ? 'active' : ''
                                }>
                                <Form.Label>
                                  {'All'}
                                  <input
                                    type="checkbox"
                                    value={'{"id":"All", "title":"All"}'}
                                    onChange={handleSnapshotSelection}
                                    checked={categoriesArr.length === 8}
                                  />
                                  <span />
                                </Form.Label>
                              </li>
                              {Object.entries(snapshotCategories).length > 0 &&
                                snapshotCategories.map((item, index) => (
                                  <li
                                    key={`sessionC-${index}`}
                                    className={
                                      categoriesArr.includes(item.id)
                                        ? 'active'
                                        : ''
                                    }>
                                    <Form.Label>
                                      {item.category_name}
                                      <input
                                        type="checkbox"
                                        value={
                                          '{"id":"' +
                                          item.id +
                                          '", "title":"' +
                                          item.category_name +
                                          '"}'
                                        }
                                        onChange={handleSnapshotSelection}
                                        checked={categoriesArr.includes(
                                          item.id,
                                        )}
                                      />
                                      <span />
                                    </Form.Label>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </Scrollbars>
                        <div
                          className="filter-category-list text-center bg_transparent"
                          style={{paddingRight: '0px'}}>
                          <Button
                            className="btn-dark"
                            onClick={() => {
                              setFilterCat(categoriesArr);
                              setPrimaryComponent('');
                            }}>
                            Filter
                          </Button>
                        </div>
                      </div>
                    </CustomModal>
                  )}

                  {showModel === 'updatelogo' && file && (
                    <CustomModal
                      size="large"
                      onClose={() => {
                        setShowModel('');
                        setFile(null);
                      }}>
                      <CropImage
                        type="businessProfile"
                        component="branding"
                        ratio={50 / 50}
                        file={file}
                        setFile={setFile}
                        setCropImg={setShowModel}
                        imgUrl={companyLogo}
                        setImgUrl={setCompanyLogo}
                        setshowLoader={setshowLoader}
                        updateBusinessProfile={updateCompanyDetails}
                      />
                    </CustomModal>
                  )}

                  {showModel === 'updateCompanybanner' && bannerFile && (
                    <CustomModal
                      size="large"
                      onClose={() => {
                        setShowModel('');
                        setBannerFile(null);
                      }}>
                      <CropImage
                        type="businessProfile"
                        component="branding"
                        banner={true}
                        ratio={100 / 50}
                        file={bannerFile}
                        setFile={setBannerFile}
                        setCropImg={setShowModel}
                        imgUrl={banner}
                        setImgUrl={setBanner}
                        setshowLoader={setshowLoader}
                        updateBusinessProfile={updateCompanyDetails}
                      />
                    </CustomModal>
                  )}

                  {showCompanyShareModel === 'show_model' && (
                    <CustomModal
                      position="bottom"
                      className={'share-links-modal'}
                      onClose={() => setShowCompanyShareModel('')}
                      // setTabType={setTabType}
                    >
                      <div className="camera-options-list w-100 social-links-modal">
                        <ul>
                          <li className="as-link">
                            <FacebookShareButton
                              url={shareProfileUrl}
                              title={false}>
                              <i className="fa-brands fa-facebook"></i>
                            </FacebookShareButton>
                          </li>
                          <li className="as-link">
                            <WhatsappShareButton url={shareProfileUrl}>
                              <i className="fa-brands fa-whatsapp"></i>
                            </WhatsappShareButton>
                          </li>
                          <li className="as-link">
                            <EmailShareButton url={shareProfileUrl}>
                              <i className="fa-solid fa-envelope"></i>
                            </EmailShareButton>
                          </li>
                          <li className="as-link">
                            <TwitterShareButton url={shareProfileUrl}>
                              <i className="fa-brands fa-twitter"></i>
                            </TwitterShareButton>
                          </li>
                          <li className="as-link">
                            <LinkedinShareButton url={shareProfileUrl}>
                              <i className="fa-brands fa-linkedin"></i>
                            </LinkedinShareButton>
                          </li>
                        </ul>
                      </div>
                    </CustomModal>
                  )}

                  {showCompanyShareModel === 'show_delete_poc_model' &&
                    pocDeletedData && (
                      <ConfirmationModel
                        closeModel={() => {
                          setShowCompanyShareModel('');
                        }}
                        discription={
                          'Are you sure you want to delete this poc?'
                        }
                        buttonOne={'Yes'}
                        buttonTwo={'No'}
                        ButtonOneAction={() => {
                          setShowCompanyShareModel('');
                          deletePocs(pocDeletedData);
                        }}
                      />
                    )}
                </>
              }
              secondary={
                <>
                  {seconadaryComponent === 'createMeetup' && (
                    <CreateSession onClose={() => setSecondaryComponent('')} />
                  )}

                  {seconadaryComponent === 'createRecruitment' && (
                    <EditOpportunities
                      item={[]}
                      onClose={() => setSecondaryComponent('')}
                    />
                  )}
                  {seconadaryComponent === 'allBuilders' && (
                    <AllBuilders
                      onClose={() => {
                        setSecondaryComponent('');
                      }}
                    />
                  )}
                  {showComponent === 'transformationDetail' &&
                    dataFroNextComponent && (
                      <TransformDetail
                        item={dataFroNextComponent}
                        onClose={() => {
                          setShowComponent('');
                        }}
                      />
                    )}

                  {seconadaryComponent === 'createTransformation' && (
                    <CreateTransform
                      onClose={() => {
                        setSecondaryComponent('');
                      }}
                    />
                  )}

                  {seconadaryComponent === 'addNewSnapshot' && (
                    <AddNewSnapShot
                      onClose={() => {
                        setSecondaryComponent('');
                      }}
                    />
                  )}

                  {seconadaryComponent === 'snapshotDetail' && (
                    <SnapshotDetail
                      snapshotId={snapshotId}
                      userData={userInfo.data}
                      onClose={() => setSecondaryComponent('')}
                      showRefer={true}
                      //  noshow={false}
                    />
                  )}
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessProfile;
