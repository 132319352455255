import axios from 'axios';
import { BACKEND_URL, BACKEND_URL_Api_Of_ROI } from '../Utils/env';
import { amplitudeEvent } from './AmplitudeServices';

const apiHit = async (url, data, type) => {
  const expiresInMilliseconds = 3600000; // 1 hour

  // Calculate the expiration date
  const expirationDate = new Date(Date.now() + expiresInMilliseconds);
  return axios({
    method: type,
    url: url,
    data: JSON.stringify(data),
    // config: {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Expires': expirationDate.toUTCString(),
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    // },
  })
    .then(res => res.data)
    .catch(err => {
      console.log('Error', err);
      return err.response.data;
    });
};
const apiFormData = async (url, data, type) => {
  const expiresInMilliseconds = 3600000; // 1 hour

  // Calculate the expiration date
  const expirationDate = new Date(Date.now() + expiresInMilliseconds);
  return axios({
    method: type,
    url: url,
    data: data,
    // config: {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Expires': expirationDate.toUTCString(),
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    // },
  }).then(res => res.data);
};
const commonApi = {
  user_info: data => apiHit(`${BACKEND_URL}user_info`, data, 'POST'),
  get_accomplishment: data =>
    apiHit(`${BACKEND_URL}get_accomplishment`, data, 'POST'),
  // get_accomplishment_category: (data) => apiHit(`${BACKEND_URL}get_accomplishment_category`, data, 'POST'),
  get_user_sessions: data =>
    apiHit(`${BACKEND_URL}get_user_sessions_dolby`, data, 'POST'),
  get_user_available_opportunities: data =>
    apiHit(`${BACKEND_URL}get_user_available_opportunities`, data, 'POST'),
  get_preferences: data => apiHit(`${BACKEND_URL}get_preferences`, data, 'GET'),
  check_invited_by: data =>
    apiHit(`${BACKEND_URL}check_invited_by`, data, 'POST'),
  get_conversation: data =>
    apiHit(`${BACKEND_URL}get_conversation`, data, 'POST'),
  get_opportunity: data =>
    apiHit(`${BACKEND_URL}get_opportunity`, data, 'POST'),
  save_last_message: data =>
    apiHit(`${BACKEND_URL}add_last_message`, data, 'POST'),
  // refrl_info: (data) => apiHit(`${BACKEND_URL}get_referrals`, data, "POST"),
  add_app_link: data =>
    apiHit(`${BACKEND_URL}add_website_in_dolby_session`, data, 'POST'),
  get_website_link: data =>
    apiHit(`${BACKEND_URL}get_website_link_in_dolby_session`, data, 'POST'),
  get_frequency: data => apiHit(`${BACKEND_URL}get_frequency`, data, 'GET'),
  get_background: data => apiHit(`${BACKEND_URL}get_background`, data, 'GET'),
  get_opportunity_types: data =>
    apiHit(`${BACKEND_URL}get_opportunity_types`, data, 'GET'),
  get_opportunity_education_level: data =>
    apiHit(`${BACKEND_URL}get_opportunity_education_level`, data, 'GET'),
  get_builders: data =>
    apiHit(`${BACKEND_URL}get_discover_builder`, data, 'POST'),
  get_areas: () => apiHit(`${BACKEND_URL}get_on_boarding_areas`, '', 'GET'),
  get_circles: () => apiHit(`${BACKEND_URL}get_circles`, '', 'GET'),
  get_random_builder: () =>
    apiHit(`${BACKEND_URL}get_random_builder`, '', 'GET'),
  get_circle_users: data =>
    apiHit(`${BACKEND_URL}get_circle_users`, data, 'POST'),
  create_circle: data => {
    amplitudeEvent('CREATE_CIRCLE');
    return apiHit(`${BACKEND_URL}create_circle`, data, 'POST');
  },
  followers_list: data => apiHit(`${BACKEND_URL}followers_list`, data, 'POST'),
  excluded_followers_list: data =>
    apiHit(`${BACKEND_URL}excluded_followers_list`, data, 'POST'),
  create_profile: data => {
    amplitudeEvent('NEW_USER_CREATE');
    return apiHit(`${BACKEND_URL}create_profile`, data, 'POST');
  },
  get_on_boarding_details: data =>
    apiHit(`${BACKEND_URL}get_on_boarding_details`, data, 'GET'),
  upload_profile_picture: data =>
    apiFormData(`${BACKEND_URL}upload_profile_picture`, data, 'POST'),
  get_opportuntiy_location: data =>
    apiHit(`${BACKEND_URL}get_opportuntiy_location`, data, 'POST'),
  create_opportunity: data =>
    apiHit(`${BACKEND_URL}create_opportunity`, data, 'POST'),
  edit_opportunity: data =>
    apiHit(`${BACKEND_URL}edit_opportunity`, data, 'POST'),
  get_appointment_types: data =>
    apiHit(`${BACKEND_URL}get_appointment_types`, data, 'GET'),
  get_areas_categories: data =>
    apiHit(`${BACKEND_URL}get_areas_categories`, data, 'GET'),
  create_session_dolby: data =>
    apiHit(`${BACKEND_URL}create_meetup`, data, 'POST'),
  session_categories: data =>
    apiHit(`${BACKEND_URL}session_categories`, data, 'GET'),
  upload_event_media: data =>
    apiFormData(`${BACKEND_URL}upload_event_media`, data, 'POST'),
  upload_news_picture: data =>
    apiFormData(`${BACKEND_URL}upload_news_picture`, data, 'POST'),
  get_push_notification: data =>
    apiHit(`${BACKEND_URL}get_push_notification`, data, 'GET'),
  manage_push_notification: data =>
    apiHit(`${BACKEND_URL}manage_push_notification`, data, 'POST'),
  create_on_boarding_details: data =>
    apiHit(`${BACKEND_URL}create_on_boarding_details`, data, 'POST'),
  detail_accomplishment: data =>
    apiHit(`${BACKEND_URL}detail_accomplishment`, data, 'POST'),
  following_list: data => apiHit(`${BACKEND_URL}following_list`, data, 'POST'),
  get_appointment_type: data =>
    apiHit(`${BACKEND_URL}get_appointment_type`, data, 'POST'),
  get_appointment_slots: data =>
    apiHit(`${BACKEND_URL}get_appointment_slots`, data, 'POST'),
  get_user_availabilty: data =>
    apiHit(`${BACKEND_URL}check_user_events_against_slot_ids`, data, 'POST'),
  create_appointment: data =>
    apiHit(`${BACKEND_URL}create_appointment`, data, 'POST'),
  get_feedback: data => apiHit(`${BACKEND_URL}get_feedback`, data, 'POST'),
  stripe_payment: data => apiHit(`${BACKEND_URL}stripe_payment`, data, 'POST'),
  get_my_card: data => apiHit(`${BACKEND_URL}get_my_card`, data, 'GET'),
  get_refer_purpose: data =>
    apiHit(`${BACKEND_URL}get_refer_purpose`, data, 'POST'),
  refer_profile: data => apiHit(`${BACKEND_URL}refer_profile`, data, 'POST'),
  all_users: data => apiHit(`${BACKEND_URL}all_users`, data, 'POST'),
  get_consult_session_for_praise: data =>
    apiHit(`${BACKEND_URL}get_consult_session_for_praise`, data, 'POST'),
  add_feedback: data => apiHit(`${BACKEND_URL}add_feedback`, data, 'POST'),
  upload_praise_caution: data =>
    apiFormData(`${BACKEND_URL}upload_praise_caution`, data, 'POST'),
  store_referral: data => apiHit(`${BACKEND_URL}store_referral`, data, 'POST'),
  manage_favourite_opportunity: data =>
    apiHit(`${BACKEND_URL}manage_favourite_opportunity`, data, 'POST'),
  refer_accomplishment: data =>
    apiHit(`${BACKEND_URL}refer_accomplishment`, data, 'POST'),
  refer_opportunity: data =>
    apiHit(`${BACKEND_URL}refer_opportunity`, data, 'POST'),
  refer_outside: data => apiHit(`${BACKEND_URL}refer_outside`, data, 'POST'),
  create_opportunity_chat_room: data =>
    apiHit(`${BACKEND_URL}create_opportunity_chat_room`, data, 'POST'),
  add_opportunity_last_message: data =>
    apiHit(`${BACKEND_URL}add_opportunity_last_message`, data, 'POST'),
  check_opportunity_new_user: data =>
    apiHit(`${BACKEND_URL}check_opportunity_new_user`, data, 'POST'),
  check_favourite: data =>
    apiHit(`${BACKEND_URL}check_favourite`, data, 'POST'),
  manage_favourite: data =>
    apiHit(`${BACKEND_URL}manage_favourite_dolby`, data, 'POST'),
  politely_leave_opportunity: data =>
    apiHit(`${BACKEND_URL}politely_leave_opportunity`, data, 'POST'),
  get_all_languages: data =>
    apiHit(`${BACKEND_URL}get_all_languages`, data, 'GET'),
  get_all_session_opportunity_consult: data =>
    apiHit(`${BACKEND_URL}get_all_session_opportunity_consult`, data, 'GET'),
  get_link_for_consult_session_opportunity: data =>
    apiHit(
      `${BACKEND_URL}get_link_for_consult_session_opportunity`,
      data,
      'POST',
    ),
  add_languages: data => apiHit(`${BACKEND_URL}add_languages`, data, 'POST'),
  edit_user_detail: data =>
    apiHit(`${BACKEND_URL}edit_user_detail`, data, 'POST'),
  get_specific_accomplishment: data =>
    apiHit(`${BACKEND_URL}get_specific_accomplishment`, data, 'POST'),
  get_my_schedules: data =>
    apiHit(`${BACKEND_URL}get_my_schedules`, data, 'POST'),
  create_schedule: data =>
    apiHit(`${BACKEND_URL}create_schedule`, data, 'POST'),
  edit_schedules: data => apiHit(`${BACKEND_URL}edit_schedules`, data, 'POST'),
  check_schedules: data =>
    apiHit(`${BACKEND_URL}check_schedules`, data, 'POST'),
  get_user_schedule: data =>
    apiHit(`${BACKEND_URL}get_user_schedule`, data, 'POST'),
  get_user_focus: data => apiHit(`${BACKEND_URL}get_user_focus`, data, 'GET'),
  get_accomplishment_category: data =>
    apiHit(`${BACKEND_URL}get_accomplishment_category`, data, 'GET'),
  create_focus: data => apiHit(`${BACKEND_URL}create_focus`, data, 'POST'),
  sessions_list: data => apiHit(`${BACKEND_URL}get_meetup`, data, 'POST'),
  sessions_list_dolby: data => apiHit(`${BACKEND_URL}sessions_list_dolby`, data, 'POST'),
  get_circle_push_notification: data =>
    apiHit(`${BACKEND_URL}get_circle_push_notification`, data, 'POST'),
  remove_circle_push_notification: data =>
    apiHit(`${BACKEND_URL}remove_circle_push_notification`, data, 'POST'),
  upload_accomplishment_image: data =>
    apiFormData(`${BACKEND_URL}upload_accomplishment_image`, data, 'POST'),
  add_accomplishment: data => {
    amplitudeEvent('ADD_SNAPSHOT');
    return apiHit(`${BACKEND_URL}add_accomplishment`, data, 'POST');
  },
  create_personal_group_chat_only: data =>
    apiHit(`${BACKEND_URL}create_personal_group_chat_only`, data, 'POST'),
  get_referrals: data =>
    apiHit(`${BACKEND_URL}get_referrals_for_web`, data, 'POST'),
  check_new_users_in_group: data =>
    apiHit(`${BACKEND_URL}check_new_users_in_group`, data, 'POST'),
  upload_group_picture: data =>
    apiFormData(`${BACKEND_URL}upload_group_picture`, data, 'POST'),
  update_personal_group_chat: data =>
    apiHit(`${BACKEND_URL}update_personal_group_chat `, data, 'POST'),
  update_opportunity_group_chat: data =>
    apiHit(`${BACKEND_URL}update_opportunity_group_chat `, data, 'POST'),
  close_chat_room: data =>
    apiHit(`${BACKEND_URL}close_chat_room`, data, 'POST'),
  left_chat_room: data => apiHit(`${BACKEND_URL}left_chat_room`, data, 'POST'),
  feedback_types: data => apiHit(`${BACKEND_URL}feedback_types`, data, 'GET'),
  submit_feedback: data =>
    apiHit(`${BACKEND_URL}submit_feedback`, data, 'POST'),
  get_opportunity_details: data =>
    apiHit(`${BACKEND_URL}get_opportunity_details`, data, 'POST'),
  update_profile: data => apiHit(`${BACKEND_URL}update_profile`, data, 'POST'),
  manage_opportunity_status: data =>
    apiHit(`${BACKEND_URL}manage_opportunity_status`, data, 'POST'),
  change_opportunity_status: data =>
    apiHit(`${BACKEND_URL}change_opportunity_status`, data, 'POST'),
  manage_chat_invitation: data =>
    apiHit(`${BACKEND_URL}manage_chat_invitation`, data, 'POST'),
  add_referral_feedback: data =>
    apiHit(`${BACKEND_URL}add_referral_feedback`, data, 'POST'),
  event_detail: data =>
    apiHit(`${BACKEND_URL}event_detail_dolby`, data, 'POST'),
  session_detail: data =>
    apiHit(`${BACKEND_URL}sessions_detail_dolby`, data, 'POST'),
  check_this_session_show: data =>
    apiHit(`${BACKEND_URL}check_this_session_show`, data, 'POST'),
  get_other_user_setting: data =>
    apiHit(`${BACKEND_URL}get_other_user_setting`, data, 'POST'),
  check_chat_room: data =>
    apiHit(`${BACKEND_URL}check_chat_room`, data, 'POST'),
  user_opportunites_list: data =>
    apiHit(`${BACKEND_URL}user_opportunites_list`, data, 'POST'),
  user_session_list: data =>
    apiHit(`${BACKEND_URL}user_session_list`, data, 'POST'),
  user_events_list: data =>
    apiHit(`${BACKEND_URL}user_events_list`, data, 'POST'),
  join_event: data => apiHit(`${BACKEND_URL}join_event_dolby`, data, 'POST'),
  create_chat_room: data =>
    apiHit(`${BACKEND_URL}create_chat_room`, data, 'POST'),
  cancel_session: data =>
    apiHit(`${BACKEND_URL}cancel_session_dolby`, data, 'POST'),
  get_consult_history: data =>
    apiHit(`${BACKEND_URL}get_consult_history`, data, 'POST'),
  manage_circle_push_notification: data =>
    apiHit(`${BACKEND_URL}manage_circle_push_notification`, data, 'POST'),
  get_payment_types: data =>
    apiHit(`${BACKEND_URL}get_payment_types`, data, 'GET'),
  get_payment_detail: data =>
    apiHit(`${BACKEND_URL}get_payment_detail`, data, 'POST'),
  create_link_token_for_plaid: data =>
    apiHit(`${BACKEND_URL}create_link_token_for_plaid`, data, 'GET'),
  get_user_detail_for_payment: data =>
    apiHit(`${BACKEND_URL}get_user_detail_for_payment`, data, 'GET'),
  update_user_detail_for_payment: data =>
    apiHit(`${BACKEND_URL}update_user_detail_for_payment`, data, 'POST'),
  remove_card: data => apiHit(`${BACKEND_URL}remove_card`, data, 'POST'),
  add_card: data => apiHit(`${BACKEND_URL}add_card`, data, 'POST'),
  add_session_notes_dolby: data =>
    apiHit(`${BACKEND_URL}add_session_notes_dolby`, data, 'POST'),
  delete_accomplishment: data =>
    apiHit(`${BACKEND_URL}delete_accomplishment`, data, 'POST'),
  create_btok_and_connected_stripe_token: data =>
    apiHit(
      `${BACKEND_URL}create_btok_and_connected_stripe_token`,
      data,
      'POST',
    ),
  get_mine_tickets: data =>
    apiHit(`${BACKEND_URL}get_mine_tickets`, data, 'POST'),
  get_event_tickets: data =>
    apiHit(`${BACKEND_URL}get_event_tickets`, data, 'POST'),
  update_session_dolby: data =>
    apiHit(`${BACKEND_URL}update_session_dolby`, data, 'POST'),
  get_my_appointments: data =>
    apiHit(`${BACKEND_URL}get_my_appointments`, data, 'POST'),
  make_call_dolby: data =>
    apiHit(`${BACKEND_URL}make_call_dolby`, data, 'POST'),
  end_call: data => apiHit(`${BACKEND_URL}end_call`, data, 'POST'),
  check_appointment_dolby: data =>
    apiHit(`${BACKEND_URL}check_appointment_dolby`, data, 'GET'),
  get_questions_list: data =>
    apiHit(`${BACKEND_URL}get_questions_list`, data, 'POST'),
  create_poll: data => {
    amplitudeEvent('CREATE_POLL_IN_CHAT');
    return apiHit(`${BACKEND_URL}create_poll`, data, 'POST');
  },
  favourite_question: data =>
    apiHit(`${BACKEND_URL}favourite_question`, data, 'POST'),
  create_question: data =>
    apiHit(`${BACKEND_URL}create_question`, data, 'POST'),
  remove_circle_user: data =>
    apiHit(`${BACKEND_URL}remove_circle_user`, data, 'POST'),
  event_list_location: data =>
    apiHit(`${BACKEND_URL}event_list_location`, data, 'POST'),
  send_invitation_outside: data =>
    apiHit(`${BACKEND_URL}send_invitation_outside`, data, 'POST'),
  create_session_group_chat: data =>
    apiHit(`${BACKEND_URL}create_session_group_chat`, data, 'POST'),
  update_session_group_chat: data =>
    apiHit(`${BACKEND_URL}update_session_group_chat`, data, 'POST'),
  add_last_message_in_question: data =>
    apiHit(`${BACKEND_URL}add_last_message_in_question`, data, 'POST'),
  clear_user_location: data =>
    apiHit(`${BACKEND_URL}clear_user_location`, data, 'POST'),
  update_user_location: data =>
    apiHit(`${BACKEND_URL}update_user_location`, data, 'POST'),
  send_ticket: data => apiHit(`${BACKEND_URL}send_ticket`, data, 'POST'),
  get_favourite_opportunity: data =>
    apiHit(`${BACKEND_URL}get_favourite_opportunity`, data, 'POST'),
  get_favourite_questions: data =>
    apiHit(`${BACKEND_URL}get_favourite_questions`, data, 'POST'),
  get_session_notes: data =>
    apiHit(`${BACKEND_URL}get_session_notes`, data, 'POST'),
  manage_rsvp_dolby: data =>
    apiHit(`${BACKEND_URL}manage_rsvp_dolby`, data, 'POST'),
  create_opportunity_call_dolby: data =>
    apiHit(`${BACKEND_URL}create_opportunity_call_dolby`, data, 'POST'),
  manage_followings: data =>
    apiHit(`${BACKEND_URL}manage_followings`, data, 'POST'),
  user_left_opportunity_call: data =>
    apiHit(`${BACKEND_URL}user_left_opportunity_call`, data, 'POST'),
  mark_as_best: data => apiHit(`${BACKEND_URL}mark_as_best`, data, 'POST'),
  refer_session: data => apiHit(`${BACKEND_URL}refer_session`, data, 'POST'),
  refer_question: data => apiHit(`${BACKEND_URL}refer_question`, data, 'POST'),
  check_server_time: data =>
    apiHit(`${BACKEND_URL}check_server_time`, data, 'GET'),
  get_conference_recursion: data =>
    apiHit(`${BACKEND_URL}get_conference_recursion`, data, 'POST'),
  get_available_tickets: data =>
    apiHit(`${BACKEND_URL}get_available_tickets`, data, 'POST'),
  get_tab_status: data => apiHit(`${BACKEND_URL}get_tab_status`, data, 'GET'),
  add_tab_status: data => apiHit(`${BACKEND_URL}add_tab_status`, data, 'POST'),
  update_card_status: data =>
    apiHit(`${BACKEND_URL}update_card_status`, data, 'POST'),
  whole_message_status: data =>
    apiHit(`${BACKEND_URL}whole_message_status`, data, 'GET'),
  update_firebase_token: data =>
    apiHit(`${BACKEND_URL}update_web_user_firebase_token `, data, 'POST'),
  add_moment_media: data =>
    apiHit(`${BACKEND_URL}add_moment_media`, data, 'POST'),
  user_message_status: data =>
    apiHit(`${BACKEND_URL}user_message_status`, data, 'POST'),
  opportunity_message_status: data =>
    apiHit(`${BACKEND_URL}opportunity_message_status`, data, 'POST'),
  close_poll: data => apiHit(`${BACKEND_URL}close_poll`, data, 'POST'),
  get_user_sess_opp_accom: data =>
    apiHit(`${BACKEND_URL}get_user_sess_opp_accom`, data, 'POST'),
  get_questions_detail: data =>
    apiHit(`${BACKEND_URL}get_questions_detail`, data, 'POST'),
  save_user_detail_for_payment: data =>
    apiHit(`${BACKEND_URL}save_user_detail_for_payment`, data, 'POST'),
  sessions_list_by_id: data =>
    apiHit(`${BACKEND_URL}sessions_list_by_id`, data, 'POST'),
  check_user_events: data =>
    apiHit(`${BACKEND_URL}check_user_events`, data, 'POST'),
  get_opportunity_by_user_id: data =>
    apiHit(`${BACKEND_URL}get_opportunity_by_user_id`, data, 'POST'),
  add_refresh_token: data =>
    apiHit(`${BACKEND_URL}add_refresh_token`, data, 'POST'),
  get_refresh_token: data =>
    apiHit(`${BACKEND_URL}get_refresh_token`, data, 'POST'),

  // get_my_workspaces: data =>
  // apiHit(`${BACKEND_URL}get_refresh_token`, data, 'GET'),
  get_my_workspaces: data =>
    apiHit(`${BACKEND_URL}get_my_workspaces`, data, 'POST'),

  add_google_calender: data =>
    apiHit(`${BACKEND_URL}add_google_calender`, data, 'POST'),
  logout: data => apiHit(`${BACKEND_URL}logout`, data, 'POST'),
  cancel_appointment: data =>
    apiHit(`${BACKEND_URL}cancel_appointment`, data, 'POST'),
  refund_appointment: data =>
    apiHit(`${BACKEND_URL}refund_appointment`, data, 'POST'),
  accept_opportunity_invitation: data =>
    apiHit(`${BACKEND_URL}accept_opportunity_invitation`, data, 'POST'),
  get_suggestions: data =>
    apiHit(`${BACKEND_URL}get_suggestions`, data, 'POST'),
  get_share_tickets: data =>
    apiHit(`${BACKEND_URL}get_share_tickets`, data, 'GET'),
  manage_share_tickets: data =>
    apiHit(`${BACKEND_URL}manage_share_tickets`, data, 'POST'),
  send_emoji_notification: data =>
    apiHit(`${BACKEND_URL}send_emoji_notification`, data, 'POST'),
  add_all_user_in_conference_dolby: data =>
    apiHit(`${BACKEND_URL}add_all_user_in_conference_dolby`, data, 'POST'),
  get_accomplishment_invitation_by_login_id: data =>
    apiHit(
      `${BACKEND_URL}get_accomplishment_invitation_by_login_id`,
      data,
      'GET',
    ),
  manage_accomplishment_invitation: data =>
    apiHit(`${BACKEND_URL}manage_accomplishment_invitation`, data, 'POST'),
  get_actions: data => apiHit(`${BACKEND_URL}get_actions`, data, 'POST'),
  change_action_status: data =>
    apiHit(`${BACKEND_URL}change_action_status`, data, 'POST'),
  add_images_in_action: data =>
    apiHit(`${BACKEND_URL}add_images_in_action`, data, 'POST'),
  upload_action_image: data =>
    apiFormData(`${BACKEND_URL}upload_action_image`, data, 'POST'),

  get_my_appointments_by_type: data =>
    apiHit(`${BACKEND_URL}get_my_appointments_by_type`, data, 'POST'),
  get_consult_history_by_type: data =>
    apiHit(`${BACKEND_URL}get_consult_history_by_type`, data, 'POST'),
  get_sessions_event_against_accomplishment: data =>
    apiHit(
      `${BACKEND_URL}get_sessions_event_against_accomplishment`,
      data,
      'POST',
    ),
  get_opportunity_against_accomplishment: data =>
    apiHit(
      `${BACKEND_URL}get_opportunity_against_accomplishment`,
      data,
      'POST',
    ),
  add_news: data => apiHit(`${BACKEND_URL}add_news`, data, 'POST'),
  get_appointment_payment_detail: data =>
    apiHit(`${BACKEND_URL}get_appointment_payment_detail`, data, 'POST'),
  change_user_mic_status: data =>
    apiHit(`${BACKEND_URL}change_user_mic_status`, data, 'POST'),
  check_this_number_exist: data =>
    apiHit(`${BACKEND_URL}check_this_number_exist`, data, 'POST'),

  create_workspace: data =>
    apiHit(`${BACKEND_URL}create_workspace`, data, 'POST'),
  create_appointment_with_user: data =>
    apiHit(`${BACKEND_URL}create_appointment_with_user`, data, 'POST'),
  is_valid_url: data => apiHit(`${BACKEND_URL}is_valid_url`, data, 'POST'),
  get_consult_by_user_id: data =>
    apiHit(`${BACKEND_URL}get_consult_by_user_id`, data, 'POST'),
  get_user_all_data: data =>
    apiHit(`${BACKEND_URL}get_user_all_data`, data, 'POST'),
  refer_appointment: data =>
    apiHit(`${BACKEND_URL}refer_appointment`, data, 'POST'),
  refer_group_chat: data =>
    apiHit(`${BACKEND_URL}refer_group_chat`, data, 'POST'),
  change_current_workspace: data =>
    apiHit(`${BACKEND_URL}change_current_workspace`, data, 'POST'),
  unsubscribe_workspace: data =>
    apiHit(`${BACKEND_URL}unsubscribe_workspace`, data, 'POST'),
  // get_user_sess_opp_accom: data =>
  //   apiHit(`${BACKEND_URL}get_user_sess_opp_accom`, data, 'POST'),
  add_and_remove_user_in_workspace: data =>
    apiHit(`${BACKEND_URL}add_and_remove_user_in_workspace`, data, 'POST'),
  update_workspace: data =>
    apiHit(`${BACKEND_URL}update_workspace`, data, 'POST'),
  get_my_all_associate_workspaces: data =>
    apiHit(`${BACKEND_URL}get_my_all_associate_workspaces`, data, 'POST'),
  // add_google_calenders_events: data =>
  //   apiHit(`${BACKEND_URL}add_google_calenders_events`, data, 'POST'),
  // add_google_calenders: data =>
  //   apiHit(`${BACKEND_URL}add_google_calenders`, data, 'POST'),
  get_google_calenders: data =>
    apiHit(`${BACKEND_URL}get_google_calenders`, data, 'POST'),
  delete_linked_calendars: data =>
    apiHit(`${BACKEND_URL}delete_linked_calendars`, data, 'POST'),
  add_google_calendars_ids_in_confer_appoint: data =>
    apiHit(
      `${BACKEND_URL}add_google_calendars_ids_in_confer_appoint`,
      data,
      'POST',
    ),
  delete_google_calendar_event: data =>
    apiHit(`${BACKEND_URL}delete_google_calendar_event`, data, 'POST'),
  get_eventbrite_accounts: data =>
    apiHit(`${BACKEND_URL}get_eventbrite_accounts`, data, 'POST'),
  get_events_against_user_accounts: data =>
    apiHit(`${BACKEND_URL}get_events_against_user_accounts`, data, 'POST'),
  add_multiple_events: data =>
    apiHit(`${BACKEND_URL}add_multiple_events`, data, 'POST'),

  delete_eventbrite_accounts: data =>
    apiHit(`${BACKEND_URL}delete_eventbrite_accounts`, data, 'POST'),
  create_action: data => apiHit(`${BACKEND_URL}create_action`, data, 'POST'),
  create_notes: data => apiHit(`${BACKEND_URL}create_notes`, data, 'POST'),
  get_notes: data => apiHit(`${BACKEND_URL}get_notes`, data, 'POST'),
  share_notes_with_others: data =>
    apiHit(`${BACKEND_URL}share_notes_with_others`, data, 'POST'),
  add_compaign_logs: data =>
    apiHit(`${BACKEND_URL}add_compaign_logs`, data, 'POST'),
  get_follow_ups: data => apiHit(`${BACKEND_URL}get_follow_ups`, data, 'POST'),
  change_appearance: data =>
    apiHit(`${BACKEND_URL}change_appearance`, data, 'POST'),
  get_workspace_types: data =>
    apiHit(`${BACKEND_URL}get_workspace_types`, data, 'GET'),
  get_workspace_roles: data =>
    apiHit(`${BACKEND_URL}get_workspace_roles`, data, 'GET'),
  // get_workspace_menu: data =>
  //   apiHit(`${BACKEND_URL}get_workspace_menu`, data, 'GET'),
  get_workspace_menu: data =>
    apiHit(`${BACKEND_URL}get_workspace_menu`, data, 'GET'),

  //ROI APIS

  get_user_roles: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_user_roles`, data, 'GET'),
  get_workspace_menu_item: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_workspace_menu_item`, data, 'GET'),
  // get_workspace_types: data =>
  //   apiHit(`${BACKEND_URL_Api_Of_ROI}get_workspace_types`, data, 'GET'),

  manage_company: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}manage_company`, data, 'POST'),
  update_company_user_data: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_company_user_data`, data, 'POST'),
  get_company: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_companies_by_user_id`, data, 'POST'),
  get_company_users_role: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_users_role`, data, 'POST'),
  get_company_user_menus: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_user_menus`, data, 'GET'),
  update_company_users_role: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_company_users_role`, data, 'POST'),
  update_company_user_menus: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_company_user_menus`, data, 'POST'),
  create_company: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_company`, data, 'POST'),
  update_user_status: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_user_status`, data, 'POST'),
  create_company_catalog: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_company_catalog`, data, 'POST'),
  get_scheduler_types: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_scheduler_types`, data, 'GET'),
  create_scheduler: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_scheduler`, data, 'POST'),
  get_company_catalog: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_catalog`, data, 'POST'),
  delete_company_catalog: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}delete_company_catalog`, data, 'POST'),
  upload_company_image: data =>
    apiFormData(`${BACKEND_URL_Api_Of_ROI}upload_company_image`, data, 'POST'),
  add_company_trigger: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}add_company_trigger`, data, 'POST'),
  get_sub_menus: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_sub_menus`, data, 'POST'),
  get_company_triggers: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_triggers`, data, 'POST'),
  delete_company_trigger: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}delete_company_trigger`, data, 'POST'),
  update_company_detail: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_company_detail`, data, 'POST'),
  update_template_permission: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_template_permission`, data, 'POST'),
  get_template_permission: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_template_permission`, data, 'POST'),
  get_all_global_templates: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_all_global_templates`, data, 'POST'),
  import_template: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}import_template`, data, 'POST'),
  update_company_template: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_company_template`, data, 'POST'),
  get_schedular: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_schedular`, data, 'POST'),
  get_active_company_user_menus: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_active_company_user_menus`, data, 'POST'),
  get_all_catalogs: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_all_catalogs`, data, 'POST'),
  get_catalogs_types: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_catalogs_types`, data, 'GET'),
  get_favorite_catalog: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_favorite_catalog`, data, 'POST'),
  add_to_cart: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}add_to_cart`, data, 'POST'),
  favourite_catalog: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}favourite_catalog`, data, 'POST'),
  get_catalog_cart: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_catalog_cart`, data, 'POST'),
  upload_catalog_images: data =>
    apiFormData(`${BACKEND_URL}upload_catalog_images`, data, 'POST'),
  get_contact_relation_types: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_contact_relation_types`, data, 'GET'),
  get_active_menu_permission: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_active_menu_permission`, data, 'POST'),
  add_multiple_profiles: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}add_multiple_profiles`, data, 'POST'),
  upload_multiple_profile_image: data =>
    apiFormData(`${BACKEND_URL_Api_Of_ROI}upload_multiple_profile_image`, data, 'POST'),
  check_company_name: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}check_company_name`, data, 'POST'),
  get_companyuser_session: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_companyuser_session`, data, 'POST'),
  get_companyuser_opportunity: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_companyuser_opportunity`, data, 'POST'),
  company_info: data => apiHit(`${BACKEND_URL}company_info`, data, 'POST'),

  get_companyuser_consult: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_companyuser_consult`, data, 'POST'),
  get_companyuser_appointment: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_companyuser_appointment`, data, 'POST'),
  update_company_details_by_any_user: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_company_details_by_any_user`, data, 'POST'),
  get_for_update_company_details: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_for_update_company_details`, data, 'POST'),
  create_company_transformation: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_company_transformation`, data, 'POST'),
  get_transformation_category: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_transformation_category`, data, 'POST'),
  create_transformation_category: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_transformation_category`, data, 'POST'),
  upload_transformation_images: data =>
    apiFormData(`${BACKEND_URL_Api_Of_ROI}upload_transformation_images`, data, 'POST'),
  get_company_transformation: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_transformation`, data, 'POST'),
  check_market_user_bio: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}check_market_user_bio`, data, 'POST'),
  send_invitation_in_company: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}send_invitation_in_company`, data, 'POST'),
  add_user_in_company: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}add_user_in_company`, data, 'POST'),
  update_templat_favourite: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_templat_favourite`, data, 'POST'),
  manage_market: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}manage_market`, data, 'POST'),
  get_meetup_by_user_id: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_meetup_by_user_id`, data, 'POST'),
  update_companypoc_show_in_market: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_companypoc_show_in_market`, data, 'POST'),
  poc_chat: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}poc_chat`, data, 'POST'),
  delete_pocs_with_title: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}delete_pocs_with_title`, data, 'POST'),
  edit_company_pocs: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}edit_company_pocs`, data, 'POST'),
  send_invitation_from_landingpage: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}send_invitation_from_landingpage`, data, 'POST'),
  manage_schedules: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}manage_schedules`, data, 'POST'),
  get_first_available_appointment: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_first_available_appointment`, data, 'POST'),
  get_live_chatrooms: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_live_chatrooms`, data, 'POST'),
  list_of_all_pocs_of_company: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}list_of_all_pocs_of_company`, data, 'POST'),
  vendors_list_of_companies: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}catalog_list_of_companies`, data, 'POST'),
  catalog_list_of_companies: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}catalog_list_of_companies_new`, data, 'POST'),
  get_company_bussiness_profile: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_bussiness_profile`, data, 'POST'),
  get_catalogs_for_invoices: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_catalogs_for_invoices`, data, 'POST'),
  get_all_groups: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_all_groups`, data, 'POST'),
  create_group_catalog: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_group_catalog`, data, 'POST'),
  list_of_catalogs_ps: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}list_of_catalogs_ps`, data, 'POST'),
  get_user_for_pocs: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_user_for_pocs`, data, 'POST'),
  send_invoice_estimate: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}send_invoice_estimate`, data, 'POST'),
  check_invoice_draft: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}check_invoice_draft`, data, 'POST'),
  check_invoice_templates: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}check_invoice_templates`, data, 'POST'),
  get_user_invoices: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_user_invoices`, data, 'POST'),
  estimate_to_invoice: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}estimate_to_invoice`, data, 'POST'),
  payment_success_details: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}payment_success_details`, data, 'POST'),
  get_paid_invoices: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_paid_invoices`, data, 'POST'),
  get_company_invoices: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_invoices`, data, 'POST'),

  get_company_catalogs: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_catalogs`, data, 'POST'),
  get_catalog_by_id: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_catalog_by_id`, data, 'POST'),
  get_document_types: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_document_types`, data, 'GET'),
  upload_files_to_s3: data =>
    apiFormData(`${BACKEND_URL_Api_Of_ROI}upload_files_to_s3`, data, 'POST'),
  upload_files: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}upload_files`, data, 'POST'),
  delete_document_files: data =>
    apiFormData(`${BACKEND_URL_Api_Of_ROI}delete_document_files`, data, 'POST'),
  get_document_files: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_document_files`, data, 'POST'),
  refer_document: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}refer_document`, data, 'POST'),
  get_my_appointments_by_type: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_my_appointments_by_type`, data, 'POST'),
  change_button_status: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}change_button_status`, data, 'POST'),
  create_pipeline: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_pipeline`, data, 'POST'),
  get_permission_of_pipeline_tags: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_permission_of_pipeline_tags`, data, 'POST'),
  get_pipeline_details: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_pipeline_details`, data, 'POST'),
  create_deal: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_deal`, data, 'POST'),
  get_deals_list: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_deals_list`, data, 'POST'),
  change_user_relation: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}change_user_relation`, data, 'POST'),
  create_offering: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_offering`, data, 'POST'),
  delete_offering_image: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}delete_offering_image`, data, 'POST'),
  get_customer_list: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_customer_list`, data, 'POST'),
  get_team_list: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_team_list`, data, 'POST'),
  get_company_roles_user: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_roles_user`, data, 'POST'),
  get_survey_time: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_survey_time`, data, 'POST'),
  upload_note_media: data =>
    apiFormData(`${BACKEND_URL_Api_Of_ROI}upload_note_media`, data, 'POST'),
  update_invitaion_status: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_invitaion_status`, data, 'POST'),
  update_user_fields: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}update_user_fields`, data, 'POST'),
  show_user_field: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}show_user_field`, data, 'POST'),
  delete_user_field : data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}delete_user_field`, data, 'POST'),
  manage_check_list : data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}manage_check_list`, data, 'POST'),
  create_company_tags : data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_company_tags`, data, 'POST'),
  get_user_all_companies_users : data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_user_all_companies_users`, data, 'POST'),
  get_company_tag : data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_tag`, data, 'POST'),
  upload_company_tag_icon: data =>
    apiFormData(`${BACKEND_URL}upload_company_tag_icon`, data, 'POST'),
  delete_company_tag: data =>
    apiHit(`${BACKEND_URL}delete_company_tag`, data, 'POST'),
  assign_company_tags: data =>
    apiHit(`${BACKEND_URL}assign_company_tags`, data, 'POST'),
  external_link: data =>
    apiHit(`${BACKEND_URL}external_link`, data, 'POST'),
  get_company_supplier: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_company_supplier`, data, 'POST'),
  get_tags_permission: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_tags_permission`, data, 'POST'),
  update_profile: data => apiHit(`${BACKEND_URL_Api_Of_ROI}update_profile`, data, 'POST'),
  get_all_team_for_project: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_all_team_for_project`, data, 'POST'),
  get_customers_for_project: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_customers_for_project`, data, 'POST'),
  create_update_projects: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_update_projects`, data, 'POST'),
  get_projects: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_projects`, data, 'POST'),
  get_actions_for_project_managemnet: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_actions_for_project_managemnet`, data, 'POST'),
  create_update_expense: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}create_update_expense`, data, 'POST'),
  get_expenses: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_expenses`, data, 'POST'),
  delete_expenses: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}delete_expenses`, data, 'POST'),
  get_outlook_calendars: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_outlook_calendars`, data, 'POST'),
  add_outlook_calendars: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}add_outlook_calendars`, data, 'POST'),
  get_all_calendars: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}get_all_calendars`, data, 'POST'),
  add_outlook_calendars_events: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}add_outlook_calendars_events`, data, 'POST'),
  add_calendar_ids_in_confer_appoint: data =>
    apiHit(`${BACKEND_URL_Api_Of_ROI}add_calendar_ids_in_confer_appoint`, data, 'POST'),
  add_google_calenders_events: data =>
    apiHit(`${BACKEND_URL}add_google_calendars_events`, data, 'POST'),
  add_google_calenders: data =>
    apiHit(`${BACKEND_URL}add_google_calendars`, data, 'POST'),
  add_google_calenders: data =>
    apiHit(`${BACKEND_URL}add_google_calendars`, data, 'POST'),
  add_task_board: data =>
    apiHit(`${BACKEND_URL}add_task_board`, data, 'POST'),
  get_task_boards: data =>
    apiHit(`${BACKEND_URL}get_task_boards`, data, 'POST'),
  get_board_tasks: data =>
    apiHit(`${BACKEND_URL}get_board_tasks`, data, 'POST'),
  change_task_board: data =>
    apiHit(`${BACKEND_URL}change_task_board`, data, 'POST'),
  add_users_in_linked_user_poc: data =>
    apiHit(`${BACKEND_URL}add_users_in_linked_user_poc`, data, 'POST'),
  add_single_user_in_linked_user_poc: data =>
    apiHit(`${BACKEND_URL}add_single_user_in_linked_user_poc`, data, 'POST'),
  
  // followers_list_new: data => apiHit(`${BACKEND_URL_Api_Of_ROI}followers_list_new`, data, 'POST'),
};

export default commonApi;
