import React, {useEffect} from 'react';
import {useState} from 'react';
import {Button,  Form, } from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
// import {DEFAULT_IMAGE} from '../../Utils/env';
import {Link} from 'react-router-dom';
import OppotunityItem from '../OpportunitiesList/OppotunityItem';
import Scrollbars from 'react-custom-scrollbars';
import SesssionItem from '../SesssionItem';
import API from '../../Services/SessionServices';
import EventsItem from '../EventsItem';
import OpportunityDetail from '../OpportunitiesList/OpportunityDetail';
import ReferOpportunity from '../Referral/ReferOpportunity';
import OpportunityMessageList from '../OpportunitiesList/OpportunityMessageList';
import EventDetail from '../Referral/EventDetail';
import PurchaseTicket from '../Referral/PurchaseTicket';
import SessionDetail from '../SessionDetails/SessionDetail';
import CreateSession from '../SessionDetails/CreateSession';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SlidingPanel from '../SlidingPanel';
import SmartChatPage from '../Common/SmartChatPage';
import {
  chatroomInfo,
  chatroomInfoOppo,
  collectingOppoUsers,
} from '../../Utils/commonUtils';
import { RoiLogo } from '../../Constants/Images';

const FavouriteList = props => {
  const [subTabType, setSubTabsType] = useState('session');
  const [showLoader, setshowLoader] = useState(false);
  const [oppData, setOppData] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [oppoDetails, setOppoDetails] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [chatData, setChatData] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');

  const [consult, setConsult] = useState([]);
  const [chatRoomStatus, setChatRoomStatus] = useState('pending');

  const [showComponent, setShowComponent] = useState('');
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);

  const [qaItem, setQaItem] = useState({});
  const [serverTime, setServerTime] = useState('');

  const getFavouriteQuestions = (pageN, qry) => {
    var data = {
      page: pageN,
      search_query: qry,
    };
    commonApi
      .get_favourite_questions(data)
      .then(res => {
        if (res) {
          if (pageN > 1) {
            if (res.favourite_questions.length > 0) {
              setPage(pageN);
              setConsult([...consult, ...res.favourite_questions]);
            }
          } else {
            setPage(1);
            setConsult(res.favourite_questions);
          }

          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const sessions_list = (type, pageN, qry) => {
    var data = {
      category_id: [],
      cityStates: '',
      ethnicities: '',
      events: type === 'favouriteSessions' ? false : true,
      focus: 'no',
      gender: '',
      otherIdentifiers: '',
      page: pageN,
      search_query: qry,
      sessions: type === 'favouriteSessions' ? true : false,
      type: type,
    };
    commonApi.sessions_list_dolby(data)
      .then(res => {
        if (res) {
          if (pageN > 1) {
            if (res.upcoming.length > 0) {
              setPage(pageN);
              setSessionData([...sessionData, ...res.upcoming]);
            }
          } else {
            setPage(1);
            setSessionData(res.upcoming);
          }
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const userOpportunitesList = (pageN, qry) => {
    var data = {
      ally: '',
      amount: '',
      frequency: [],
      latitude: '',
      location: '',
      longitude: '',
      opportunity_types: [],
      page: pageN,
      radius: 100,
      search_query: qry,
      vaccinated: '',
      virtual: '',
    };
    commonApi
      .get_favourite_opportunity(data)
      .then(res => {
        if (res.status === 200) {
          setshowLoader(false);
          if (pageN > 1) {
            if (res.favourite_opportunities.length > 0) {
              setPage(pageN);
              setOppData([...oppData, ...res.favourite_opportunities]);
            }
          } else {
            setPage(1);
            setOppData(res.favourite_opportunities);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const eventDetails = index => {
    // setShowDetails(sessionData.session[index]);
  };
  useEffect(() => {
    if (subTabType === 'session') {
      serverTimeCheck();
    }
  }, [subTabType]);
  useEffect(() => {
    setshowLoader(true);
    if (subTabType === 'session') {
      // sessions_list("favouriteEvents", 1, qry);
      sessions_list('favouriteSessions', page, searchQuery);
    }
    if (subTabType === 'opportunities') {
      userOpportunitesList(page, searchQuery);
    }
    if (subTabType === 'Q&A') {
      getFavouriteQuestions(page, searchQuery);
    }
    if (subTabType === 'event') {
      sessions_list('favouriteEvents', page, searchQuery);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);

  // const arrToStr = arr => {
  //   let txt = [];
  //   for (let str in arr) {
  //     txt.push(arr[str]['title']);
  //   }
  //   return txt.join(', ');
  // };
  // const makeQuestionItemData = obj => {
  //   let returnObj = {
  //     question: obj.question,
  //     background_detail: arrToStr(obj.background_detail),
  //     best_ans: obj.best_ans,
  //     like: obj.like,
  //     location: obj.location,
  //     updated_at: obj.updated_at,
  //     best: false,
  //     name: '',
  //     profile_img: DEFAULT_IMAGE,
  //     answer: '',
  //   };
  //   if (Object.entries(obj.best_ans_user_detail).length > 0) {
  //     returnObj.best = true;
  //     returnObj.name =
  //       obj.best_ans_user_detail.first_name +
  //       ' ' +
  //       obj.best_ans_user_detail.first_name;
  //     returnObj.profile_img = obj.best_ans_user_detail.profile_img;
  //     returnObj.answer = obj.best_ans;
  //   } else if (Object.entries(obj.last_message_user_detail).length > 0) {
  //     returnObj.best = false;
  //     returnObj.name = obj.last_message;
  //     returnObj.profile_img = obj.last_message_user_detail.profile_img;
  //     returnObj.answer = '';
  //   }
  //   return returnObj;
  // };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + scrollTop >= scrollHeight) {
      // Increment the page number and call the API
      if (subTabType === 'session') {
        sessions_list('favouriteSessions', page + 1, searchQuery);
      }
      if (subTabType === 'opportunities') {
        userOpportunitesList(page + 1, searchQuery);
      }
      if (subTabType === 'Q&A') {
        getFavouriteQuestions(page + 1, searchQuery);
      }
      if (subTabType === 'event') {
        sessions_list('favouriteEvents', page + 1, searchQuery);
      }
    }
  };
  const searchHandle = qry => {
    if (subTabType === 'session') {
      sessions_list('favouriteSessions', 1, qry);
    }
    if (subTabType === 'opportunities') {
      userOpportunitesList(1, qry);
    }
    if (subTabType === 'Q&A') {
      getFavouriteQuestions(1, qry);
    }
    if (subTabType === 'event') {
      sessions_list('favouriteEvents', 1, qry);
    }
  };

  const handleItemOpp = (item, type) => {
    setAllData(item);
    props.setAllData(item);
    // 

    if (subTabType === 'opportunities') {
      if (type === 'refer-opportunity') {
        setShowComponent('refer-opportunity');
      } else {
        if (
          parseInt(item.created_by) === parseInt(localStorage.getItem('id'))
        ) {
          setShowComponent('mine-opportunity');
        } else if (item.team_member_details.length > 0) {
          const found = item.team_member_details.find(
            objs => parseInt(objs.id) === parseInt(localStorage.getItem('id')),
          );
          if (typeof found === 'object') {
            setShowComponent('mine-opportunity');
          } else if (item.chat_detail.length === 0) {
            setShowComponent('respond-opportunity');
          }
        } else if (item.chat_detail.length === 0) {
          setShowComponent('respond-opportunity');
        } else if (Object.entries(item.chat_detail).length > 0) {
          setShowComponent('other-opportunity');
        }
      }
    } else {
      if (Object.entries(item.chat_detail).length > 0) {
        setShowComponent('other-opportunity');
      }
    }
  };

  const componentDecesion = (item, type) => {
    setAllData(item);
    setShowComponent(type);
    // 
    // 
  };

  // const handleQuestionItem = (item, type) => {
  //   setAllData(item);
    
  //   if (type === 'refer') {
  //     setShowComponent('refer-opportunity');
  //   } else {
  //     setshowLoader(true);
  //     if (
  //       item.group_chat_detail &&
  //       Object.entries(item.group_chat_detail).length > 0
  //     ) {
  //       // setQaItem(item.group_chat_detail);
  //       // setProperComponent("show-qa-chat");
  //       if (
  //         parseInt(item.created_by) === parseInt(localStorage.getItem('id'))
  //       ) {
  //         setQaItem(item.group_chat_detail);
  //         setShowComponent('show-qa-chat');
  //         setshowLoader(false);
  //       } else if (
  //         item.group_chat_detail.moderator_ids.includes(
  //           localStorage.getItem('id'),
  //         )
  //       ) {
  //         setQaItem(item.group_chat_detail);
  //         setShowComponent('show-qa-chat');
  //         setshowLoader(false);
  //       } else if (
  //         item.group_chat_detail.audience_ids.includes(
  //           localStorage.getItem('id'),
  //         )
  //       ) {
  //         setQaItem(item.group_chat_detail);
  //         setShowComponent('show-qa-chat');
  //         setshowLoader(false);
  //       } else {
  //         let audienceIds = item.group_chat_detail.audience_ids;
  //         audienceIds.push(localStorage.getItem('id'));
  //         audienceIds = audienceIds.toString();

  //         let moderatorIds = item.group_chat_detail.moderator_ids.toString();

  //         updateSessionGroupChat(
  //           audienceIds,
  //           item.group_chat_detail.chat_id,
  //           item.group_chat_detail.creator_id,
  //           item.group_chat_detail.group_image,
  //           item.group_chat_detail.group_name,
  //           item.group_chat_detail.left_removed_users,
  //           item.group_chat_detail.left_removed_users,
  //           moderatorIds,
  //           item.group_chat_detail.private_group,
  //           item.group_chat_detail.snap_shot_id,
  //         );
  //       }
  //     } else if (
  //       parseInt(item.created_by) === parseInt(localStorage.getItem('id')) ||
  //       (item.moderator_ids &&
  //         item.moderator_ids.split(',').includes(localStorage.getItem('id'))) ||
  //       (item.audience_ids &&
  //         item.audience_ids.split(',').includes(localStorage.getItem('id')))
  //     ) {
  //       createSessionGroupChat(
  //         item.audience_ids,
  //         'chatroom_q_' + (Math.random() + 1).toString(36).substring(3),
  //         item.created_by,
  //         item.image_url,
  //         item.question,
  //         item.moderator_ids,
  //         'question',
  //         item.id,
  //       );
  //     } else {
  //       let audienceIds = localStorage.getItem('id');

  //       if (item.audience_ids) {
  //         audienceIds = item.audience_ids.split(',');
  //         audienceIds.push(localStorage.getItem('id'));
  //       }

  //       audienceIds = audienceIds.toString();

  //       createSessionGroupChat(
  //         audienceIds,
  //         'chatroom_q_' + (Math.random() + 1).toString(36).substring(3),
  //         item.created_by,
  //         item.image_url,
  //         item.question,
  //         item.moderator_ids,
  //         'question',
  //         item.id,
  //       );
  //     }
  //   }
  // };

  // const updateSessionGroupChat = (
  //   audience,
  //   chat,
  //   creator,
  //   image,
  //   name,
  //   left_moderator,
  //   left_users,
  //   moderator,
  //   privat,
  //   id,
  // ) => {
  //   let payload = {
  //     audience_ids: audience,
  //     // chat_room_name: '',
  //     chat_id: chat,
  //     creator_id: creator,
  //     group_image: image,
  //     group_name: name,
  //     left_removed_moderator: left_moderator,
  //     left_removed_users: left_users,
  //     moderator_ids: moderator,
  //     // type: '',
  //     // type_id: ''
  //     private_group: privat,
  //     snap_shot_id: id,
  //     workspace_ids:localStorage.getItem('workspaceIds'),
  //   };
  //   commonApi
  //     .update_session_group_chat(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         // setQuestionList(res.get_questions_list);
  //         checkUsersInGroup(res.group_chat_id);
  //       }
  //       // setshowLoader(false);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const createSessionGroupChat = (
  //   audience,
  //   chat,
  //   creator,
  //   image,
  //   name,
  //   moderator,
  //   type,
  //   id,
  // ) => {
  //   let payload = {
  //     audience_ids: audience,
  //     chat_room_name: chat,
  //     creator_id: creator,
  //     group_image: image ? image : DEFAULT_IMAGE,
  //     group_name: name,
  //     moderator_ids: moderator,
  //     type: type,
  //     type_id: id,
  //   };
  //   commonApi
  //     .create_session_group_chat(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         // setQuestionList(res.get_questions_list);
  //         checkUsersInGroup(res.group_chat_id);
  //       }
  //       // setshowLoader(false);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  // const checkUsersInGroup = id => {
  //   let payload = {group_id: id};
  //   commonApi
  //     .check_new_users_in_group(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         // setQuestionList(res.get_questions_list);
  //         setQaItem(res.group_chat_detail);
  //         setShowComponent('show-qa-chat');
  //       }
  //       setshowLoader(false);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  const collectingUsersQa = item => {
    const oppoUsers = [
      {
        id: item.creator_detail.id,
        first_name: item.creator_detail.first_name,
        last_name: item.creator_detail.last_name,
        profile_img: item.creator_detail.profile_img,
        type: 'creator',
      },
    ];
    if (item.audience_detail.length > 0) {
      item.audience_detail.forEach(item => {
        item.type = 'audience';
        oppoUsers.push(item);
      });
    }
    if (item.moderator_detail.length > 0) {
      item.moderator_detail.forEach(item => {
        item.type = 'moderator';
        oppoUsers.push(item);
      });
    }
    return oppoUsers;
  };

  const serverTimeCheck = () => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .check_server_time()
      .then(res => {
        setServerTime(res.server_time);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const filterFavroiuteList = id => {
    if (subTabType === 'opportunities') {
      setOppData(oppData.filter(item => item.id !== id));
    }
    if (subTabType === 'event') {
      setSessionData(sessionData.filter(item => item.id !== id));
    }
  };

  const newDecision = () => {
    if (allData.login_user && allData.login_user.status) {
      return allData.login_user.status;
    } else if (allData.chat_detail.hasOwnProperty('status')) {
      return allData.chat_detail.status;
    } else {
      return 'pending';
    }
  };

  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };

  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()} renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
       
        <div className="serach-filter-wrapper">
          <div className="headings pt-3">
            <div className="event-tabs black-head-form-pad-left tabs-style-2 mb-0">
              <ul>
                <li>
                  <Button
                    className={subTabType === 'session' ? 'active' : ''}
                    onClick={() => {
                      setSubTabsType('session');
                      setSearchQuery('');
                      setshowLoader(true);
                      sessions_list('favouriteSessions', 1, '');
                    }}>
                    Virtual
                  </Button>
                </li>
                <li>
                  <Button
                    className={subTabType === 'event' ? 'active' : ''}
                    onClick={() => {
                      setSubTabsType('event');
                      setSearchQuery('');
                      setshowLoader(true);
                      sessions_list('favouriteEvents', 1, '');
                    }}>
                    In-person
                  </Button>
                </li>
                <li>
                  <Button
                    className={subTabType === 'opportunities' ? 'active' : ''}
                    onClick={() => {
                      setSubTabsType('opportunities');
                      setSearchQuery('');
                      setshowLoader(true);
                      userOpportunitesList(1, searchQuery);
                    }}>
                    Recruitment
                  </Button>
                </li>
                {/* <li>
                      <Button
                        className={subTabType === 'Q&A' ? 'active' : ''}
                        onClick={() => {
                          setSubTabsType('Q&A');
                          setSearchQuery('');
                          setshowLoader(true);
                          getFavouriteQuestions(1, '');
                        }}>
                        Q&A
                      </Button>
                    </li> */}
              </ul>
            </div>
          </div>

          <div className="search-tabs-wrapper black-head-form-pad-left mt-1">
            <div className="search-form d-flex">
              <Form.Group className="search-input">
                <Form.Control
                  onChange={e => {
                    setSearchQuery(e.target.value);
                    searchHandle(e.target.value);
                  }}
                  name="allUsers"
                  type="text"
                  placeholder={ subTabType === 'opportunities' ? 'Search for a ' + 'recruitment' : 'Search for a ' + 'meetup'}
                  value={searchQuery}
                />
              </Form.Group>
            </div>
          </div>

          {subTabType === 'opportunities' && (
            <Scrollbars autoHide onScroll={handleScroll}>
              <div className="event-tabs tabs-style-2 mt-3 mb-0" />
              {showLoader && <LoaderSpinner />}
<div className='fixed-width-inputs black-head-form-pad-left'>


              {Object.entries(oppData).length > 0 ? (
                Object.entries(oppData).length > 0 &&
                oppData.map((item, index) => (
                  <OppotunityItem
                    key={`opportunity-item-${index}`}
                    setShowButton={setShowComponent}
                    showButton={showButton}
                    onShare={() => {
                      setShowComponent('refer-opportunity');
                    }}
                    setChatData={setChatData}
                    chatData={chatData}
                    handleItem={handleItemOpp}
                    item={item}
                    filterFavroiuteList={filterFavroiuteList}
                  />
                ))
              ) : (
                <div className="no-results-wrapper">
                  <div className="img-bg-wrapper">
                    <img
                      src={require('../../Assets/images/norecord/opportunity.png')}
                      alt=""
                    />
                  </div>

                  <div className="no-results-content">
                    <p>
                      There are no opportunities in your favourite list right
                      now.
                    </p>

                    <div className="btn-wrapper mt-3">
                      <Button className="btn btn-dark">
                        <Link
                          className="text-light"
                          style={{
                            textDecoration: 'none',
                          }}
                          to="/opportunities">
                          Explore Opportunities
                        </Link>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              </div>
            </Scrollbars>
          )}
          {subTabType === 'session' && (
            <Scrollbars autoHide onScroll={handleScroll}>
              <div className="event-tabs tabs-style-2 mt-3 mb-0" />
              {showLoader && <LoaderSpinner />}
              <div className='fixed-width-inputs black-head-form-pad-left'>
              {Object.entries(sessionData).length > 0 ? (
                Object.entries(sessionData).length > 0 &&
                sessionData.map((item, index) => (
                  <>
                    {!item.image && (
                      <SesssionItem
                        key={`session-item-${index}`}
                        item={item}
                        componentDecesion={componentDecesion}
                        upcoming={true}
                        serverTime={serverTime}
                      />
                    )}
                  </>
                ))
              ) : (
                <div className="no-results-wrapper">
                  <div className="img-bg-wrapper">
                    <img
                      src={require('../../Assets/images/norecord/session.png')}
                      alt="no-session"
                    />
                  </div>

                  <div className="no-results-content">
                    <p>
                      There are no sessions in your favourite list right now.
                    </p>

                    <div className="btn-wrapper mt-3">
                      <Button className="btn btn-dark">
                        <Link
                          className="text-light"
                          style={{
                            textDecoration: 'none',
                          }}
                          to="/">
                          Explore Sessions
                        </Link>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
               </div>
            </Scrollbars>
          )}
        
          {subTabType === 'event' && (
            <Scrollbars autoHide onScroll={handleScroll}>
              <div className="event-tabs tabs-style-2 mt-3 mb-0" />
              {showLoader && <LoaderSpinner />}
              <div className='fixed-width-inputs black-head-form-pad-left'>
              {Object.entries(sessionData).length > 0 ? (
                Object.entries(sessionData).length > 0 &&
                sessionData.map((item, index) => (
                  <>
                    {item.image && (
                      <EventsItem
                        key={`event-dddd-${index}`}
                        item={item}
                        index={index}
                        eventDetails={eventDetails}
                        componentDecesion={componentDecesion}
                        filterFavroiuteList={filterFavroiuteList}
                      />
                    )}
                  </>
                ))
              ) : (
                <div className="no-results-wrapper">
                  <div className="img-bg-wrapper">
                    <img
                      src={require('../../Assets/images/norecord/event.png')}
                      alt=""
                    />
                  </div>

                  <div className="no-results-content">
                    <p>There are no events in your favourite list right now.</p>

                    <div className="btn-wrapper mt-3">
                      <Button className="btn btn-dark">
                        <Link
                          className="no-results-link text-light"
                          to={{
                            pathname: '/',
                            state: {
                              typeIs: 'events',
                            },
                          }}>
                          Explore Events
                        </Link>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
               </div>
            </Scrollbars>
          )}
        </div>
      </SlidingPanel>

      {showComponent === 'session' &&
        allData.creator_id !== localStorage.getItem('id') && (
          <>
            <SessionDetail
              item={allData}
              onClose={() => {
                // setShowButton(false);
                setShowComponent('');
              }}
            />
          </>
        )}

      {showComponent === 'session' &&
        allData.creator_id === localStorage.getItem('id') && (
          <CreateSession
            editSession={allData}
            showCloseIcon={true}
            onClose={() => {
              // setShowButton(false);
              setShowComponent('');
            }}
          />
        )}

      {showComponent === 'show-qa-chat' && Object.entries(qaItem) && (
        <SmartChatPage
          allmsg={true}
          type={'qa-chats'}
          item={allData}
          oppoDetails={qaItem}
          chatroomInfo={chatroomInfo(qaItem)}
          allChatUsers={collectingUsersQa(qaItem)}
          chatDetails={qaItem}
          chatRoomName={qaItem.chat_room_name}
          // chatRoomId={qaItem.chat_id}
          chatRoomId={qaItem.id}
          chatRoomStatus={qaItem.chat_room_status}
          setQaItem={setQaItem}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'event' &&
        allData.creator_id === localStorage.getItem('id') && (
          <EventDetail
            onClose={() => {
              setShowComponent('');
            }}
            item={allData}
            type={'fvrt'}
          />
        )}

      {showComponent === 'event' &&
        allData.creator_id !== localStorage.getItem('id') && (
          <PurchaseTicket
            onClose={() => {
              setShowComponent('');
            }}
            item={allData}
            withHeadingSpace={true}
            // type={"refer"}
          />
        )}

      {showComponent === 'Refer' && allData && (
        <ReferOpportunity
          onClose={() => {
            setShowComponent('');
          }}
          type={'event'}
          userData={allData}
        />
      )}

      {showComponent === 'refer-opportunity' && (
        <ReferOpportunity
          userData={allData}
          onClose={() => {
            // setReferalButton(false);
            setShowComponent('');
          }}
          type={subTabType === 'Q&A' ? 'question' : 'opportunity'}
        />
      )}

      {showComponent === 'mine-opportunity' && (
        <OpportunityMessageList
          item={allData}
          setOppoDetails={setOppoDetails}
          setAllChatUsers={setAllChatUsers}
          setChatRoomId={setChatRoomId}
          setChatRoomName={setChatRoomName}
          chatRoomName={chatRoomName}
          setChatRoomStatus={setChatRoomStatus}
          chatRoomStatus={chatRoomStatus}
          oppoDetails={oppoDetails}
          allChatUsers={allChatUsers}
          chatRoomId={chatRoomId}
          onClose={() => {
            setShowButton(false);
            setShowComponent('');
          }}
          onEditOppo={() => {
            props.setInviteUser(true);
            props.setCreateSessionEvent(false);
          }}
          onCreateSessionEvent={() => {
            props.setCreateSessionEvent(true);
            props.setInviteUser(false);
          }}
          onShowChat={() => {
            setShowComponent('show-chats');
          }}
        />
      )}

      {/* {chatRoomName !== '' &&
        allChatUsers.length > 0 &&
        showComponent === 'show-chats' && (
          <SmartChatPage
            allmsg={true}
            type={'mine-opportunity'}
            item={allData}
            oppoDetails={oppoDetails}
            chatroomInfo={chatroomInfoOppoMsgList(oppoDetails, 'opportunity')}
            allChatUsers={allChatUsers}
            chatDetails={oppoDetails.chat_detail}
            chatRoomName={chatRoomName}
            chatRoomId={chatRoomId}
            chatRoomStatus={chatRoomStatus}
            onClose={() => {
              setChatRoomName('');
              // setShowButton(true);
              setShowComponent('mine-opportunity');
            }}
          />
        )} */}

      {showComponent === 'other-opportunity' && allData && (
        <SmartChatPage
          allmsg={true}
          type={'other-opportunity'}
          item={[]}
          oppoDetails={allData}
          chatDetails={allData.chat_detail}
          chatroomInfo={chatroomInfoOppo(allData, 'opportunity')}
          allChatUsers={collectingOppoUsers(allData)}
          // allChatUsers={
          //   allData.hasOwnProperty('creator_details')
          //     ? [allData.creator_details]
          //     : [allData.creator_detail]
          // }
          chatRoomName={
            allData.chat_detail.hasOwnProperty('chat_room_name')
              ? allData.chat_detail.chat_room_name
              : ''
          }
          chatRoomId={
            allData.chat_detail.hasOwnProperty('id')
              ? allData.chat_detail.id
              : ''
          }
          onClose={() => {
            // setShowButton(false);
            setShowComponent('');
          }}
          userData={
            allData.hasOwnProperty('creator_details')
              ? allData.creator_details
              : allData.creator_detail
          }
          chatRoomStatus={newDecision()}
          // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
        />
      )}

      {showComponent === 'respond-opportunity' && (
        <OpportunityDetail
          item={allData}
          userData={allData.creator_details}
          withHeadingSpace={true}
          onClose={() => {
            // setShowButton(false);
            setShowComponent('');
          }}
        />
      )}
    </>
  );
};

export default FavouriteList;
