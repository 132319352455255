import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import Scheduler from './DropdownComponents/Scheduler';
import Menus from './DropdownComponents/Menus';
import SystemRoles from './DropdownComponents/SystemRoles';
import Catalog from './DropdownComponents/Catalog';
import SliderComponent6 from '../../Pages/Starter/SliderComponent6';
import SliderComponent7 from '../../Pages/Starter/SliderComponent7';
import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import General from './DropdownComponents/General';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import CustomModal from '../CustomModal';
import {useDispatch, useSelector} from 'react-redux';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import TemplateExchange from './TemplateExchange';
import AddCC from '../SingleComponent/AddCC';
import CompanyInvite from '../SingleComponent/CompanyInvite';
import {getActiveCompanyUserMenus} from '../../Store/Actions/MenuAction';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import InvitePoc from '../SingleComponent/InvitePoc';
import SecondaryPocs from './DropdownComponents/SecondaryPocs';
import SlidingPanel from '../SlidingPanel';
import CreatCircleList from '../Circle/CreatCircleList';
import CropImage from '../Cropper/CropImage';
import SystemRolesInvite from '../SingleComponent/SystemRolesInvite';
import PipelinePermission from '../PipeLine/PipelinePermission';
import {
  ValidatePipelinePermissions,
  ValidateTagsPermissions,
} from '../../Utils/commonUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {getUpdateDataOfCompany} from '../../Store/Actions/CommanAction';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import CreateCatalogGroup from '../Catalog/CreateCatalogGroup';
import ImagePreview from '../Modals/ImagePreview';
// import { updateCompanyName } from '../../Store/Reducers/CommanReducers';
const ROITemplate = props => {
  const [type, setType] = useState('1');
  const [menuItems, setMenuItems] = useState([]);
  const [orginalRolesData, setOrginalRolesData] = useState([]);
  const [systemRoles, setSystemRoles] = useState([]);
  const [companyRoles, setCompanyRoles] = useState([]);
  const [orginalCompanyRolesMenus, setOrginalCompanyRolesMenus] = useState([]);
  const [companyRolesMenus, setCompanyRolesMenus] = useState([]);
  const [allCatalogData, setAllCatalogData] = useState([]);
  const [allCatalogDataGroup, setAllCatalogDataGroup] = useState([]);
  const [catalogItemsList, setCatalogItemsList] = useState([]);
  const [catalogListSaveByUser, setCatalogListSaveByUser] = useState([]);
  const [tabType, setTabType] = useState('myTemplate');
  const [showImportoptions, setShowImportoptions] = useState(false);
  const [industryList, setIndustryList] = useState([]);
  const [hitApi, setHitApi] = useState(false);
  const [hitApiData, setHitApiData] = useState(false);
  const [hiTapiFromExchange, setHiTapiFromExchange] = useState(false);
  const [deleteItemName, setDeleteItemName] = useState('');
  const [isCheck, setIsCheck] = useState([]);
  const [catError, setCatError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isCheckValue, setIsCheckValue] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const [catalogDeleteids, setCatalogDeleteids] = useState([]);
  const [deleteItem, setDeleteItem] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(false);

  const [selectedCatalogGroup, setSelectedCatalogGroup] = useState([]);

  const [catalogName, setCatalogName] = useState('Product');

  const [companyName, setCompanyName] = useState('myTemplate');

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const buttonRef = useRef(null);
  const catalogRef = useRef();

  const dataofCompany = useSelector(state => state.updateCompanyName);
  const [templateOptions, setTemplateOptions] = useState({
    availability: false,
    menus: false,
    roles: false,
    catalog: false,
    email: false,
    task: false,
    projects: false,
    sms: false,
    messages: false,
    goals: false,
    analytics: false,
    leaderboard: false,
  });
  const [triggersTypes, setTriggersTypes] = useState(false);
  const [deleteIDs, setDeleteIDs] = useState([]);
  const [ownProfilePermesion, setOwnProfilePermesion] = useState(false);

  // Scheduler

  const [customerName, setCustomerName] = useState('');
  const [deliverableTitle, setDeliverableTitle] = useState('');
  const [manageDeliverables, setManageDeliverables] = useState('');
  const [reward, setReward] = useState('');
  const [customerTypesArray, setCustomerTypesArray] = useState([]);
  const [selectedSchedulerTypeItems, setSelectedSchedulerTypeItems] = useState(
    [],
  );
  const [selectedSchedulerTypeId, setSelectedSchedulerTypeId] = useState([]);
  const [menuPermesions, setMenuPermesions] = useState([]);

  const [secondaryPOCs, setSecondaryPOCs] = useState([]);

  // General

  const dispatch = useDispatch();

  const [companyData, setCompanyData] = useState({
    name: '',
    industry: '1',
    no_of_employes: '',
    billing_email: '',
    location: [],
    id: '',
    cc_user_ids: [],
    show_cc_user_array: [],
    category_ids: '',
  });
  const [activeCompany, setActiveCompany] = useState({});

  const [ticketImg, setTicketImg] = useState('');
  const [file, setFile] = useState(null);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

  const [locationArray, setLocationArray] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [pocData, setPocData] = useState({
    name: '',
    email: '',
    phone_no: '',
    role: '1',
  });

  const [companyDeatil, setCompanydetail] = useState([]);
  const [secondary, setSecondary] = useState(false);
  const [showLoader, setshowLoader] = useState(true);

  const [companyNameExist, setCompanyNameExist] = useState(false);

  const dropdownMenu = [
    {id: '1', name: 'General'},
    {
      id: '2',
      name: 'Scheduler',
    },
    {
      id: '3',
      name: 'Menus',
    },
    {
      id: '4',
      name: 'Roles',
    },
    {
      id: '5',
      name: 'Offerings',
    },
    {
      id: '6',
      name: 'Triggers',
    },
    {
      id: '7',
      name: 'Analytics',
    },
    {
      id: '8',
      name: 'Leaderboard',
    },
    {
      id: '9',
      name: 'Permissions',
    },
    // {
    //   id: '9',
    //   name: 'Goals',
    // },
  ];
  const [selectedUsers, setSelectedUsers] = useState([]);

  // syste Roles States
  const [selectedInviteTitle, setSelectedInviteTitle] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModel, setShowModel] = useState('');
  const [followers, setFollowers] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [orginalSelectedBuilders, setOrginalSelectedBuilders] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [allBuilders, setAllBuilders] = useState([]);

  //addpocs

  const [pocIds, setPocIds] = useState([]);
  const [selectedPocs, setSelectedPocs] = useState([]);
  const [originalDataOfPocs, setOriginalDataOfPocs] = useState([]);
  const [allPocs, setAllPocs] = useState([]);
  const [pocTitle, setPocTitle] = useState('');
  const [selectedPoc, setSelectedPoc] = useState([]);

  const [showComponent, setShowComponent] = useState('');
  const [pocComponent, setPocComponent] = useState('');
  const [prevShowComponent, setPrevShowComponent] = useState('');
  const [contact, setContact] = useState([]);
  const [dataForApi, setDataForApi] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [dataForpermessions, setDataForpermessions] = useState([]);
  const [companydetailApiData, setCompanydetailApiData] = useState({
    company_id: '',
    company_name: '',
    company_type_id: '1',
    company_email: '',
    no_of_members: '',
    poc_name: '',
    email: '',
    poc_phone: '',
    s_poc_name: '',
    s_email: ' ',
    s_poc_phone: '',
    poc_role: '',
    s_poc_role: '',
    locations: [],
    show_cc_user_array: [],
    cc_user_ids: [],
  });

  const [refreshPocs, setRefreshPocs] = useState(false);

  //pipeline

  const [pipelineRoles, setPipelineRoles] = useState([]);
  const [tagPermissionRoles, setTagPermissionRoles] = useState([]);

  // const data_api = localStorage.getItem('workspaceId');
  useEffect(() => {
    setshowLoader(true);
    setTimeout(() => {
      
      getCompany();
      getMenuItems();
      getSystemRoles();
      getSchedulerData();
      getCompanyRoles();
      getCompanyRolesMenus();
      getCompanyCatalog();
      getPipeLineTagsPermissions('pipeline');

      setTimeout(() => {
        getPipeLineTagsPermissions('tag');
      }, 2000);

      get_template_permission();

      getSchedulerData();
      get_company_triggers();
    }, 1000);

    setHitApi(!hitApi);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workspaceData, hiTapiFromExchange]);


  useEffect(() => {
    if (contact.length > 0) {
      setRefreshPocs(!refreshPocs)
    }
  }, [contact]);

  useEffect(() => {
    if (industryList.length === 0) {
      // alert('')
      get_workspace_types();
    }
  }, []);

  const update_template_permission = () => {
    const payload = {
      availability: templateOptions.availability,
      menus: templateOptions.menus,
      roles: templateOptions.roles,
      catalog: templateOptions.catalog,
      email: templateOptions.email,
      task: templateOptions.task,
      projects: templateOptions.projects,
      sms: templateOptions.sms,
      messages: templateOptions.messages,
      goals: templateOptions.goals,
      analytics: templateOptions.analytics,
      leaderboard: templateOptions.leaderboard,
    };
    commonApi
      .update_template_permission(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          setShowImportoptions(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getSystemRoles = () => {
    commonApi
      .get_user_roles()
      .then(res => {
        if (res.status === 200) {
          if (res.user_roles?.length > 0) {
            // const rolesWithInvites = res.user_roles.map(role => ({
            //   ...role,
            //   invites: '',
            // }));
            setCompanyRoles(res.user_roles);

            // const menusArray = res.user_roles.map(
            //   ({id, role_name, user_ids, alias}) => ({
            //     id: id,
            //     name: role_name,
            //     menus: '',
            //   }),
            // );

            // setCompanyRolesMenus(menusArray);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getSchedulerData = () => {
    commonApi
      .get_schedular()
      .then(res => {
        if (res.status === 200) {
          if (res.data) {
            setCustomerName(res.data.customer_title);
            setDeliverableTitle(res.data.deliverable_title);
            setManageDeliverables(res.data.manage_deliverables);
            setReward(res.data.reward);

            const customerTypes =
              res.data.customer_types && res.data.customer_types !== ''
                ? res.data.customer_types.split(',')
                : [];

            setCustomerTypesArray(customerTypes);
            setSelectedSchedulerTypeItems(res.data.Schedule_types);
            // setCustomerTypes(res.data.customer_types.split(','));

            // setDataForApi(res.data)
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const getSchedulerData = () => {
  //   commonApi
  //     .get_schedular()
  //     .then(res => {
  //       if (res.status === 200) {
  //         if (res.user_roles?.length > 0) {
  //           // setDataForApi()
  //           // setCompanyRoles(res.user_roles);
  //         }
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const getCompany = () => {
    var payload = {
      company_users:true
    };
    commonApi
      .get_company(payload)
      .then(res => {
        setTimeout(() => {
          setshowLoader(false);
        }, 1000);
        // console.log('_getCompany-----------------', res);
        if (res.data?.length > 0) {
          // alert('')
          const activeItems = res.data.filter(
            item => item.user_status === 'active',
          );
          console.log('activeItems', activeItems);
          if (
            activeItems.length > 0 &&
            companyName !== activeItems[0].company_name
          ) {
            setCompanyName(activeItems[0].company_name);
            dispatch(getUpdateDataOfCompany(activeItems[0]));
          }

          setActiveCompany({
            name: activeItems[0].company_name,
            industry:
              activeItems[0].company_type_id === ''
                ? '1'
                : activeItems[0].company_type_id,
            no_of_employes: activeItems[0].no_of_members,
            billing_email: activeItems[0].company_email,
            location: activeItems[0].locations,
            id: activeItems[0].id,
            cc_user_ids:
              activeItems[0].cc_user_ids === ''
                ? []
                : activeItems[0].cc_user_ids.split(','),
            show_cc_user_array: activeItems[0].company_users,
            category_ids: activeItems[0].category_ids,
          });
          setCompanyData({
            name: activeItems[0].company_name,
            industry:
              activeItems[0].company_type_id === ''
                ? '1'
                : activeItems[0].company_type_id,
            no_of_employes: activeItems[0].no_of_members,
            billing_email: activeItems[0].company_email,
            location: activeItems[0].locations,
            id: activeItems[0].id,
            cc_user_ids:
              activeItems[0].cc_user_ids === ''
                ? []
                : activeItems[0].cc_user_ids.split(','),
            show_cc_user_array: activeItems[0].company_users,
            category_ids: activeItems[0].category_ids,
          });

          if (activeItems[0].locations.length > 0) {
            setIsChecked(true);
            setLocationArray(activeItems[0].locations);
          } else {
            setIsChecked(false);
            setLocationArray([]);
          }
          setPocData({
            name: activeItems[0].poc_name,
            email: activeItems[0].email,
            phone_no: activeItems[0].poc_phone,
            role:
              activeItems[0].poc_role === '' ? '1' : activeItems[0].poc_role,
          });

          console.log(activeItems[0], 'activeItems[0]');

          if (activeItems[0].created_by === localStorage.getItem('id')) {
            setSecondaryPOCs(activeItems[0].pocs_details);
          } else {
            setSecondaryPOCs(
              activeItems[0]?.pocs_details.filter(
                item =>
                  item.poc_role === activeItems[0].login_user_role &&
                  item.alias === activeItems[0].login_user_role_alias,
              ),
            );
          }

          // if (
          //   activeItems[0].s_poc_name != '' ||
          //   activeItems[0].s_poc_name != '' ||
          //   activeItems[0].s_poc_phone != '' ||
          //   activeItems[0].s_poc_role != ''
          // ) {
          //   setSecondary(true);
          // } else {
          //   setSecondary(false);
          // }
          setCompanydetail(activeItems);
          // setHitApiData(!hitApiData);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getMenuItems = () => {
    commonApi
      .get_workspace_menu_item()
      .then(res => {
        if (res.status === 200) {
          if (res.menu_items?.length > 0) {
            setMenuItems(res.menu_items);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getCompanyRoles = () => {
    commonApi
      .get_company_users_role()
      .then(res => {
        console.log(res, 'res');
        if (res.status === 200) {
          if (res.data.length > 0) {
            console.log(res.data, 'response');
            setOrginalRolesData(res.data);

            const transformedArray =
              res.data.length > 0 &&
              res.data.map(
                ({role_type_id, role_name, user_ids, role_alias}) => ({
                  id: role_type_id,
                  role: role_name,
                  alias: role_alias,
                  invites: user_ids,
                }),
              );

            console.log(transformedArray, 'transformedArray');

            setSystemRoles(transformedArray);

            // const userIds = transformedArray
            //   .map(item => item.invites.split(',').map(id => `${id.trim()}`))
            //   .flat();

            const userIds = transformedArray
              .map(item => {
                if (item.invites !== '') {
                  return item.invites.split(',').map(id => `${id.trim()}`);
                } else {
                  return [];
                }
              })
              .flat();

            setSelectedUsers(userIds);
            setSelectedBuilders(userIds);
            setOrginalSelectedBuilders(userIds);
          } else {
            setOrginalRolesData([]);
            setSystemRoles([]);
            setSelectedUsers([]);
            setSelectedBuilders([]);
            setOrginalSelectedBuilders([]);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getPipeLineTagsPermissions = type => {
    var payload = {type: type};
    commonApi
      .get_permission_of_pipeline_tags(payload)
      .then(res => {
        console.log(res, 'res');
        if (res.status === 200) {
          if (type === 'pipeline') {
            setPipelineRoles(res.data);
          } else if (type === 'tag') {
            setTagPermissionRoles(res.data);
            setOwnProfilePermesion(res?.own_profile_permission);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_workspace_types = () => {
    commonApi
      .get_workspace_types()
      .then(res => {
        if (res.status === 200) {
          console.log(res, 'response workspace');
          // setIndustryList(res.workspace_types);
          const newArray = [
            {
              id: 'all',
              name: 'All',
              description: '',
              created_at: '',
              updated_at: '',
            },
            ...res.workspace_types,
          ];
          setIndustryList(newArray);
          // setType('all');

          // setTimeout(() => {
          //   if (companyData.industry == '') {
          //     setCompanyData({
          //       ...companyData,
          //       industry: res.workspace_types[0].id,
          //     });
          //   }
          // }, 5000);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getCompanyRolesMenus = async () => {
    try {
      const res = await commonApi.get_company_user_menus();
      console.log(res, 'res');

      if (res.status === 200) {
        if (res.data) {
          // alert('inside res')
          // const convertedArray = convertPermissionsToArrayOfObjects(res.data);

          // console.log(convertedArray, 'convertedArray');
          console.log(res.data, 'company menus response');
          const menusArray = res.data.map(
            ({role_type_id, menu_ids, role_name, permissions, role_alias}) => ({
              role_type_id: role_type_id,
              role_name: role_name,
              menu_ids: menu_ids,
              permissions: permissions,
              role_alias: role_alias,
            }),
          );
          console.log(menusArray, 'menusArray-line-372');

          // const ModifiedPermisionData=updateStateWithModifiedPermissions(res.data)
          // console.log(ModifiedPermisionData,'ModifiedPermisionData')
          setOrginalCompanyRolesMenus(menusArray);
          setCompanyRolesMenus(menusArray);

          const permissionsArray = [];

          res.data.forEach(item => {
            item.permissions.forEach(permission => {
              permissionsArray.push(permission);
            });
          });
          setDataForpermessions(permissionsArray);
          setMenuPermesions(permissionsArray);
          // setMenuItems()
          setHitApiData(!hitApiData);
        }
      }
    } catch (err) {
      console.log('Err', err);
      return {type: 'error', message: err.message};
    }
  };

  // const getCompanyMenus = async () => {
  //   try {
  //     const res = await commonApi.get_company_user_menus();
  //     console.log(res, 'res');

  //     if (res.status === 200) {
  //       if (res.data) {
  //         // alert('inside res')
  //         // const convertedArray = convertPermissionsToArrayOfObjects(res.data);

  //         // console.log(convertedArray, 'convertedArray');
  //         console.log(res.data, 'company menus response');
  //         const menusArray = res.data.map(
  //           ({role_type_id, menu_ids,  role_name, permissions , role_alias}) => ({

  //             role_type_id: role_type_id,
  //             role_name: role_name,
  //             menu_ids: menu_ids,
  //             permissions: permissions,
  //             role_alias : role_alias,
  //           }),
  //         );
  //         console.log(menusArray, 'menusArray-line-372');

  //         setOrginalCompanyRolesMenus(menusArray);
  //         setCompanyRolesMenus(menusArray);
  //       }
  //     }
  //   } catch (err) {
  //     console.log('Err', err);
  //     return {type: 'error', message: err.message};
  //   }
  // };
  useEffect(() => {
    if (
      templateOptions.messages === true ||
      templateOptions.projects === true ||
      templateOptions.task === true ||
      templateOptions.sms === true ||
      templateOptions.email === true
    ) {
      setTriggersTypes(true);
    } else {
      setTriggersTypes(false);
    }
  }, [templateOptions]);

  const get_template_permission = () => {
    commonApi
      .get_template_permission()
      .then(res => {
        if (res.status === 200) {
          if (res.data) {
            const apiRes = res.data;
            setTemplateOptions({
              ...templateOptions,
              availability: apiRes.availability,
              menus: apiRes.menus,
              roles: apiRes.roles,
              catalog: apiRes.catalog,
              email: apiRes.email,
              task: apiRes.task,
              projects: apiRes.projects,
              sms: apiRes.sms,
              messages: apiRes.messages,
              goals: apiRes.goals,
              analytics: apiRes.analytics,
              leaderboard: apiRes.leaderboard,
            });
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getCompanyCatalog = async () => {
    try {
      const res = await commonApi.get_company_catalog();
      console.log(res, 'res');

      if (res.status === 200) {
        if (res.status === 200) {
          if (res.data) {
            setCatalogListSaveByUser([]);
            setCatalogDeleteids([]);
            setAllCatalogData(res.data);
            setAllCatalogDataGroup(res.group_details);

            setCatalogItemsList(res.data);
            setHitApi(!hitApi);
          }
        }
      }
    } catch (err) {
      console.log('Err', err);
      return {type: 'error', message: err.message};
    }
  };

  // const menuPermissionsToString = array => {
  //   return array.map(item => {
  //     const newItem = {...item}; // Make a shallow copy of the item
  //     newItem.permission = JSON.stringify(item.permission);
  //     return newItem;
  //   });
  // };
  // const menuPermissionsToString = array => {
  //   return array.map(item => {
  //     const permissionsString = Object.entries(item.permission)
  //       .map(([key, value]) => `${key}: ${value}`)
  //       .join(', ');
  //     return `'${permissionsString}'`;
  //   });
  // };
  const menuPermissionsToString = array => {
    return array.map(role => {
      if (role.permissions && role.permissions.length > 0) {
        return {
          ...role,
          permissions: role.permissions.map(permission => {
            if (permission.menu_permissions) {
              return {
                ...permission,
                menu_permissions: JSON.stringify(permission.menu_permissions),
              };
            }
            return permission;
          }),
        };
      }
      return role;
    });
  };
  const update_company_template = () => {
    const previousIds = orginalRolesData
      .map(item => item.user_ids.split(',').map(id => `${id.trim()}`))
      .flat();

    const newids = systemRoles
      .map(item => item.invites.split(',').map(id => `${id.trim()}`))
      .flat();

    const filteredArray = previousIds.filter(id => !newids.includes(id));

    console.log(previousIds, 'previousIds');
    console.log(newids, 'newids');

    console.log(filteredArray, 'filteredArray');

    const filteredRoles = orginalRolesData.filter(role1 => {
      return !systemRoles.some(role2 => role2.id === role1.role_type_id);
    });

    console.log(filteredRoles, 'filteredRoles');
    // console.log(deleted_roles, 'deleted_roles');

    const deletedRoletypeIds = orginalRolesData
      .map(role => role.role_type_id)
      .toString();
    console.log(deletedRoletypeIds, 'deletedRoletypeIds');
    // const transformedArray = systemRoles.map(
    //   ({id, role, invites, alias}) => ({
    //     role_type_id: id,
    //     alias: alias,
    //     user_ids: invites,
    //   }),
    // );

    const groupedRoles = orginalRolesData.reduce((acc, role) => {
      const {role_type_id, role_alias} = role;
      if (!acc[role_type_id]) {
        acc[role_type_id] = {role_type_id, role_aliases: []};
      }
      acc[role_type_id].role_aliases.push(role_alias);
      return acc;
    }, {});

    // Convert groupedRoles object to an array
    const result = Object.values(groupedRoles).map(
      ({role_type_id, role_aliases}) => ({
        role_type_id,
        role_alais: role_aliases.join(','),
      }),
    );
    const transformedArray2 = systemRoles.map(({id, role, invites, alias}) => {
      // if (invites !== '') {
      return {
        role_type_id: id,
        alias: alias,
        user_ids: invites,
      };
      // } else {
      //   check = true;
      // }
    });

    // if (!check) {

    // menu
    // const transformedArray = companyRolesMenus
    // .filter(({ menu_ids }) => menu_ids !== '')
    // .map(({ role_type_id, menu_ids }) => ({
    //   role_type_id: role_type_id,
    //   menu_ids: menu_ids,
    // }));

    const transformedArray = companyRolesMenus
      .filter(
        ({role_alias, menu_ids}) =>
          menu_ids !== '' ||
          orginalCompanyRolesMenus.some(item => item.role_alias === role_alias),
      )
      .map(({role_type_id, menu_ids, permissions, role_alias}) => ({
        role_type_id,
        menu_ids,
        permissions,
        role_alias,
      }));

    const pipeLinePermission = pipelineRoles.map(role => ({
      role_id: role.id,
      create_pipeline: role.create_pipeline,
      associate_pipeline: role.associate_pipeline,
      show_pipeline: role.show_pipeline,
      // menu_ids: role.menu_ids,
      // role_name: role.role_name,
      // user_ids: role.user_ids
    }));

    const tagPermissionArray = tagPermissionRoles.map(role => ({
      role_id: role.id,
      create_tag: role.create_tag,
      associate_tag: role.associate_tag,
      show_tag: role.show_tag,
      own_profile_permission: role.own_profile_permission,
    }));

    console.log(transformedArray, 'transformedArray');

    const menusArray = menuPermissionsToString(transformedArray);

    console.log(menusArray, 'menusArray');
    // const menuArrayWithPermissionsToString =
    //   menuPermissionsToString(menuPermesions);

    // const filteredMenus = transformedArray.filter(item => item !== null && item !== undefined);
    const filterDataOfSecondryPocs = secondaryPOCs.filter(poc => !poc.id);
    const payload = {
      general: {
        company_id: companyData.id,
        company_name: companyData.name,
        company_type_id: companyData.industry,
        category_ids: isCheck.toString(''),
        company_email: companyData.billing_email,
        no_of_members: companyData.no_of_employes,
        poc_name: pocData.name,
        email: pocData.email,
        poc_phone: pocData.phone_no,
        poc_role: pocData.role,

        multiple_poc_detail:
          filterDataOfSecondryPocs.length > 0 ? filterDataOfSecondryPocs : [],
        delete_poc_ids: deleteIDs,

        locations: companyData.location,
        cc_user_ids:
          companyData?.cc_user_ids.length > 0
            ? companyData.cc_user_ids.toString(',')
            : '',
        show_cc_user_array: companyData.show_cc_user_array,
      },

      scheduler: {
        customer_title: customerName,
        customer_types: customerTypesArray.toString(','),
        scheduler_type_ids: selectedSchedulerTypeId.toLocaleString(','),
        deliverable_title: deliverableTitle,
        manage_deliverables: manageDeliverables,
        reward: reward,
      },
      menus: {
        menu_details: menusArray,
        // permissions: menuArrayWithPermissionsToString,
      },
      roles: {
        delete_users: filteredArray.toString(),
        roles_deletion:
          // filteredArray.length > 0
          //   ? filteredRoles.length > 0
          //     ? filteredRoles.map(role => role.role_type_id).toString()
          //     : deletedRoletypeIds
          //   : ''
          result.length > 0 ? result : [],
        // pending send deleted roles
        roles: transformedArray2,
      },

      catalog: {
        catalog_details: catalogListSaveByUser,
        catlog_delete:
          catalogDeleteids.length > 0 ? catalogDeleteids.toString(',') : '',
      },

      triggers: triggers,
      pipeline_permission: pipeLinePermission,
      tag_permission: tagPermissionArray,
      analytics: [],
      leaderboard: [],
      goals: [],
      own_profile_permission: ownProfilePermesion,
    };

    console.log(payload, 'allPayloaddataaaaaa');
    commonApi
      .update_company_template(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            successMessage(res.message);

            getCompanyRolesMenus();
            getCompany();
            getCompanyRoles();
            getPipeLineTagsPermissions('pipeline');

      setTimeout(() => {
        getPipeLineTagsPermissions('tag');
      }, 2000);
          } else {
            errorMessage(res.message);
          }

          setTimeout(() => {
            getCompanyCatalog();
            dispatch(getActiveCompanyUserMenus());
          }, 1000);

          setIsButtonDisabled(false)
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleSave = () => {

    if (isButtonDisabled) return;
    setIsButtonDisabled(true);
    // alert('')
    // if (type == '1') {
    //   // alert('111');
    //   update_company_detail();
    // }
    // if (type == '2') {
    //   createScheduler();
    // }
    // if (type === '3') {
    //   const transformedArray = companyRolesMenus.map(
    //     ({role_type_id, menu_ids}) => ({
    //       // if (menu_ids !== '') {
    //       //   return {
    //       role_type_id: role_type_id,
    //       menu_ids: menu_ids,
    //       //   };
    //       // }
    //     }),
    //   );

    //   var Payload = {
    //     menu_details: transformedArray,
    //   };

    //   commonApi
    //     .update_company_user_menus(Payload)
    //     .then(res => {
    //       if (res.status === 200) {
    //         successMessage(res.message);
    //       }
    //     })
    //     .catch(err => {
    //       console.log('Err', err);
    //       return {type: 'error', message: err.message};
    //     });
    // }

    // if (type === '4') {
    //   let check = false;
    //   const previousIds = orginalRolesData
    //     .map(item => item.user_ids.split(',').map(id => `${id.trim()}`))
    //     .flat();

    //   const newids = systemRoles
    //     .map(item => item.invites.split(',').map(id => `${id.trim()}`))
    //     .flat();

    //   const filteredArray = previousIds.filter(id => !newids.includes(id));

    //   console.log(previousIds, 'previousIds');
    //   console.log(newids, 'newids');

    //   console.log(filteredArray, 'filteredArray');

    //   const filteredRoles = orginalRolesData.filter(role1 => {
    //     return !systemRoles.some(role2 => role2.id === role1.role_type_id);
    //   });

    //   console.log(filteredRoles, 'filteredRoles');
    //   // console.log(deleted_roles, 'deleted_roles');

    //   const deletedRoletypeIds = orginalRolesData
    //     .map(role => role.role_type_id)
    //     .toString();
    //   console.log(deletedRoletypeIds, 'deletedRoletypeIds');
    //   // const transformedArray = systemRoles.map(
    //   //   ({id, role, invites, alias}) => ({
    //   //     role_type_id: id,
    //   //     alias: alias,
    //   //     user_ids: invites,
    //   //   }),
    //   // );

    //   const transformedArray = systemRoles.map(({id, role, invites, alias}) => {
    //     // if (invites !== '') {
    //     return {
    //       role_type_id: id,
    //       alias: alias,
    //       user_ids: invites,
    //     };
    //     // } else {
    //     //   check = true;
    //     // }
    //   });
    //   console.log(check, 'checkinvites');

    //   console.log(transformedArray, 'transformedArray');
    //   console.log(filteredArray, 'filteredArray');

    //   // if (!check) {
    //   var Payload = {
    //     delete_users: filteredArray.toString(),
    //     roles_deletion:
    //       filteredArray.length > 0
    //         ? filteredRoles.length > 0
    //           ? filteredRoles.map(role => role.role_type_id).toString()
    //           : deletedRoletypeIds
    //         : '',
    //     // pending send deleted roles
    //     roles: transformedArray,
    //   };

    //   commonApi
    //     .update_company_users_role(Payload)
    //     .then(res => {
    //       if (res.status === 200) {
    //         successMessage(res.message);
    //       }
    //     })
    //     .catch(err => {
    //       console.log('Err', err);
    //       return {type: 'error', message: err.message};
    //     });
    //   // } else {
    //   //   errorMessage(`Please Invite atleast 1 user for each Role`);
    //   // }
    // }

    // if (type === '5') {
    //   var Payload = {
    //     catalog_details: catalogListSaveByUser,
    //   };

    //   console.log(Payload, 'PayloadPayload');
    //   commonApi
    //     .create_company_catalog(Payload)
    //     .then(res => {
    //       if (res.status === true) {
    //         successMessage(res.message);
    //       }
    //     })
    //     .catch(err => {
    //       console.log('Err', err);
    //       return {type: 'error', message: err.message};
    //     });
    // }
    // if (type === '6') {
    //   createCompanyTrigger();
    // }

    const isValid = ValidatePipelinePermissions(pipelineRoles);

    const isTagsValid = ValidateTagsPermissions(tagPermissionRoles);
    if (companyNameExist) {
      errorMessage('Company name already exist ');
    } else if (!isValid && pipelineRoles.length > 0) {
      errorMessage('Please Select Pipleine Permissions');
    } else if (!isTagsValid && tagPermissionRoles.length > 0) {
      errorMessage('Please Select Tags Permissions');
    } else {
      update_company_template();
    }
  };

  // const update_company_detail = () => {
  //   const payload = {
  //     company_id: companydetailApiData.company_id,
  //     company_name: companydetailApiData.company_name,
  //     company_type_id: companydetailApiData.company_type_id,
  //     company_email: companydetailApiData.company_email,
  //     no_of_members: companydetailApiData.no_of_members,
  //     poc_name: companydetailApiData.poc_name,
  //     email: companydetailApiData.email,
  //     poc_phone: companydetailApiData.poc_phone,
  //     s_poc_name: companydetailApiData.s_poc_name,
  //     s_email: companydetailApiData.s_email,
  //     s_poc_phone: companydetailApiData.s_poc_phone,
  //     poc_role: companydetailApiData.poc_role,
  //     s_poc_role: companydetailApiData.s_poc_role,
  //     locations: companydetailApiData.locations,

  //     cc_user_ids: companydetailApiData.cc_user_ids.toString(','),
  //   };
  //   commonApi
  //     .update_company_detail(payload)
  //     .then(res => {
  //       if (res && res.success == false) {
  //         errorMessage(res.message);
  //       } else {
  //         successMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  // const createCompanyTrigger = () => {
  //   const payload = {
  //     role_type: triggers.role_type,
  //     menu_id: triggers.menu_id,
  //     sub_menu_id: triggers.sub_menu_id,
  //     template_id: triggers.template_id,

  //     trigger_type: triggers.trigger_type,
  //   };
  //   commonApi
  //     .add_company_trigger(payload)
  //     .then(res => {
  //       successMessage(res.message);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const handleSelectedInvite = () => {
    const updatedRolesTypes = systemRoles.map(role => {
      if (role.alias === selectedInviteTitle) {
        setSelectedBuilders(previous => {
          const uniqueBuilders = new Set([...previous, ...builders]);
          return Array.from(uniqueBuilders);
        });
        setOrginalSelectedBuilders(previous => {
          const uniqueBuilders = new Set([...previous, ...builders]);
          return Array.from(uniqueBuilders);
        });

        return {...role, invites: builders.join(',')};
      }
      return role;
    });

    setSystemRoles(updatedRolesTypes);
  };

  const get_company_triggers = () => {
    commonApi
      .get_company_triggers()
      .then(res => {
        if (res.status === 200) {
          // setTriggerListing(res.data);
          setTriggers(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleCircle = () => {
    setShowComponent('Builders');
  };

  const CircleUserListData = item => {
    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (
          !builders.includes(miniItem.id) &&
          !selectedBuilders.includes(miniItem.id)
        ) {
          setBuilders(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        }
      });
    }
  };

  const CircleUserPocData = item => {
   
   
    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (
          !pocIds.includes(miniItem.id) 
        ) {
          setPocIds(previous => [...previous, miniItem.id]);
          setSelectedPocs(previous => [...previous, miniItem]);
          setOriginalDataOfPocs(previous => [...previous, miniItem]);
        }
      });
    }
  };

  const updateCompanyPocs = () => {
    var payload = {
      title: pocTitle,
      multiple_poc_detail: selectedPocs,
    };

    commonApi
      .update_company_details_by_any_user(payload)
      .then(res => {
        successMessage(res.message);
        setPocIds([]);
        setSelectedPocs([]);
        setOriginalDataOfPocs([]);
        getCompany();
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const deletePocs = item => {
    var payload = {
      title: item.title,
      poc_role: item.poc_role,
      role_alias: item.alias,
    };
    commonApi
      .delete_pocs_with_title(payload)
      .then(res => {
        successMessage(res.message);
        setSecondaryPOCs(previous =>
          previous.filter(miniitem => miniitem.title !== item.title),
        );
        const filteredSecondaryPocs = secondaryPOCs.filter(
          miniitem => miniitem.title !== item.title,
        );
        if (filteredSecondaryPocs.length === 0) {
          setShowComponent('');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleClick = e => {
    const {id, value, checked} = e.target;
    setIsCheck([...isCheck, id]);
    setIsCheckValue([...isCheckValue, value]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
    if (!checked) {
      setIsCheckValue(isCheckValue.filter(item => item !== value));
    }
  };

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(categories.map(item => item.id));
    setIsCheckValue(categories.map(item => item.category_name));
    if (isCheckAll) {
      setIsCheck([]);
      setIsCheckValue([]);
    }
  };

  useEffect(() => {
    if (categories.length > 0) {
      if (isCheck.length === categories.length) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  const handleCategories = id => {
    const filtercat = industryList.filter(item => item.id === id);
    //  console.log('filtercat',filtercat)
    setCategories(filtercat[0].categories);

    setIsCheckValue([]);
    setIsCheck([]);
  };

  useEffect(() => {
    if (industryList.length > 0 && companyData.name !== '') {
      const filtercat = industryList.filter(
        item => item.id === activeCompany.industry,
      );
      //  console.log('filtercat',filtercat)
      setCategories(filtercat[0].categories);
      const catIds = activeCompany.category_ids.split(',');
      //  console.log(catIds,'catIds')
      //  setIsCheckValue()
      const items = filtercat[0].categories.filter(category =>
        catIds.includes(category.id),
      );

      // console.log(items,'itemscate')

      setIsCheckValue(
        filtercat[0].categories
          .filter(category => catIds.includes(category.id))
          .map(item => item.category_name),
      );
      setIsCheck(activeCompany.category_ids.split(','));
    }
  }, [industryList, activeCompany]);

  const uploadCatalogImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowModel('uploadCatalogImg');

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null;
    }
  };

  const handleEditGroupCatalog = item => {
    setSelectedCatalogGroup(item);
    setShowModel('edit_group_catalog');
  };

  // console.log(isCheck, 'isCheck');
  // console.log(isCheckValue, 'isCheckValue');

  // console.log(showModel, 'props.showModel');
  console.log(dataofCompany, 'dataofCompanydataofCompany');
  useEffect(() => {
    if (!showLoader) {
      setTimeout(() => {
        var roiTemplate = document.getElementById('RoiTemplate');
        console.log(roiTemplate, 'roiTemplateroiTemplate');
        roiTemplate?.classList.remove('animate__animated', 'animate__fadeInUp');
      }, 2000);
    }
  }, [showLoader]);


  console.log(selectedPoc,'selectedPoc------>')
  console.log(pocComponent,'pocComponent------------>')
  return (
    <>
      <SlidingPanel
        lastDivStyle={{display: 'unset'}}
        hidePanel={() => {}}
        firstDivStyle={{opacity: 'unset'}}
        hideCloseicon={true}
        id={'RoiTemplate'}>
        <div className="roi_template_spinner">
          {showLoader && (
            <LoaderSpinner
              firstDivStyle={{
                background: '#f7f6f2',
              }}
            />
          )}
        </div>

        {tabType === 'myTemplate' && !showLoader && (
          <div className="messages-header messages-roi-custom-header">
            <div className="event-tabs tabs-style-2 mb-0">
              {' '}
              <ul className="LabelTabs">
                <li className="ml-20 d-flex align-items-center">
                  <Button
                    className={tabType === 'myTemplate' ? 'active' : ''}
                    onClick={() => {
                      setTabType('myTemplate');
                    }}>
                    {labels.my_template[selected_lang]}
                  </Button>
                </li>

                <li className="ml-20 d-flex align-items-center">
                  <Button
                    className={tabType === 'exchange' ? 'active' : ''}
                    onClick={() => {
                      setTabType('exchange');
                    }}>
                    {labels.exchange[selected_lang]}
                  </Button>
                </li>

                {/* <li className="ml-20 d-flex align-items-center">
              <Button
                className={tabType === 'training' ? 'active' : ''}
                onClick={() => {
                  setTabType('training');
                }}>
                {labels.training[selected_lang]}
              </Button>
            </li> */}
              </ul>
            </div>

            <div className="search-tabs-icons setting_btn">
              {tabType === 'myTemplate' && (
                <>
                  <button
                    style={{
                      color: '#68b245',
                    }}
                    className="as-link ml-5"
                    onClick={() => {
                      setShowImportoptions(true);
                    }}>
                    Save As Template
                  </button>

                  <Button
                    className="new-btn btn-green ml-5 "
                    disabled={isButtonDisabled}
                    onClick={() => {
                      if (type === '1') {
                        buttonRef.current.click();
                      } else {
                        handleSave();
                      }
                    }}>
                    Save
                  </Button>
                </>
              )}

              {tabType === 'exchange' && (
                <>
                  <i
                    style={{fontSize: '18px', color: '#363636'}}
                    className="fa-solid fa-heart"
                  />
                  {/* <Button
                className="new-btn btn-green button-padding ml-5 "
                onClick={() => {}}>
                Add Template
              </Button> */}
                </>
              )}

              {tabType === 'training' && (
                <>
                  <i
                    style={{fontSize: '18px', color: '#363636'}}
                    className="fa-solid fa-heart"
                  />
                  <Button
                    className="new-btn btn-green ml-5 button-padding "
                    onClick={() => {}}>
                    Add Training
                  </Button>
                </>
              )}
            </div>
          </div>
        )}

        {tabType === 'myTemplate' && !showLoader && (
          <div className="roi-template-dropdown mt-3 mb-1 roi_custom_template_dropdown">
            <div className="select-wrapper roi-custom-select-wrapper">
              <Dropdown
                onSelect={(eventKey, event) => {
                  setType(eventKey);
                  setDeleteItem(false);
                }}
                drop="down">
                <Dropdown.Toggle variant="primary">
                  {type
                    ? dropdownMenu.find(item => item.id === type).name
                    : 'Select Role'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dropdownMenu.map((item, index) => (
                    <Dropdown.Item
                      key={`st-${index}`}
                      eventKey={item.id.toString()}
                      active={item.id === type}>
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}

        {/* {tabType === 'exchange' && (
        <div className="exhange-dropdown-header mt-3">
          <div className="exhange-dropdown ">
            <div className="exch_dropown">
              <div className="select-wrapper">
                <Dropdown
                  onSelect={(eventKey, event) => {
                    setType(eventKey);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {type
                      ? templateExchangeMenu.find(item => item.id === type).name
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {templateExchangeMenu.map((item, index) => (
                      <Dropdown.Item
                        key={`st-${index}`}
                        eventKey={item.id.toString()}
                        active={item.id == type}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="search-tabs-icons ">
              <span
                onClick={() => {
                  setThreeDots(!threeDots);
                }}
                className={
                  threeDots ? 'icon active threeDots' : 'icon threeDots'
                }>
                <i className="fas fa-ellipsis-vertical" />
              </span>
            </div>
          </div>
          {threeDots && (
            <div className=" roi-search-form ">
              <div className="input-group">
                <Form.Control
                  type="text"
                  value={templateSearchQry}
                  pattern="^\S.*$"
                  required
                  placeholder="Search"
                  onChange={e => {
                    setTemplateSearchQry(e.target.value);
                  }}
                />
              </div>

              <Button>
                <i
                  className="icon-filter-icon"
                  // onClick={() => setSearchFilterModal(true)}
                />
              </Button>
            </div>
          )}
        </div>
      )} */}

        <Scrollbars autoHide>
          {tabType === 'myTemplate' && (
            <div style={{marginBottom: '120px'}}>
              {type === '1' && (
                <General
                  setCompanyNameExist={setCompanyNameExist}
                  companyNameExist={companyNameExist}
                  companyData={companyData}
                  setCompanyData={setCompanyData}
                  locationArray={locationArray}
                  setLocationArray={setLocationArray}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  pocData={pocData}
                  setPocData={setPocData}
                  secondaryPOCs={secondaryPOCs}
                  setSecondaryPOCs={setSecondaryPOCs}
                  companyDeatil={companyDeatil}
                  setCompanydetail={setCompanydetail}
                  secondary={secondary}
                  setSecondary={setSecondary}
                  showLoader={showLoader}
                  setshowLoader={setshowLoader}
                  setCompanydetailApiData={setCompanydetailApiData}
                  companydetailApiData={companydetailApiData}
                  setShowComponent={setShowComponent}
                  setPocComponent={setPocComponent}
                  hitApi={hitApi}
                  hitApiData={hitApiData}
                  buttonRef={buttonRef}
                  handleSave={handleSave}
                  setDeleteIDs={setDeleteIDs}
                  deleteIDs={deleteIDs}
                  setShowModel={setShowModel}
                  catError={catError}
                  isCheck={isCheck}
                  setIsCheck={setIsCheck}
                  isCheckValue={isCheckValue}
                  handleCategories={handleCategories}
                  categories={categories}
                  deleteItem={deleteItem}
                  setDeleteItem={setDeleteItem}
                  industryListfromRoiTemplate={industryList}
                />
              )}

              {type === '2' && (
                <Scheduler
                  customerName={customerName}
                  setCustomerName={setCustomerName}
                  deliverableTitle={deliverableTitle}
                  setDeliverableTitle={setDeliverableTitle}
                  manageDeliverables={manageDeliverables}
                  setManageDeliverables={setManageDeliverables}
                  reward={reward}
                  setReward={setReward}
                  customerTypesArray={customerTypesArray}
                  setCustomerTypesArray={setCustomerTypesArray}
                  selectedSchedulerTypeItems={selectedSchedulerTypeItems}
                  setSelectedSchedulerTypeItems={setSelectedSchedulerTypeItems}
                  selectedSchedulerTypeId={selectedSchedulerTypeId}
                  setSelectedSchedulerTypeId={setSelectedSchedulerTypeId}
                  setPayloadForApi={setDataForApi}
                  dataForApi={dataForApi}
                  hitApi={hitApi}
                  hitApiData={hitApiData}
                  deleteItem={deleteItem}
                  setDeleteItem={setDeleteItem}
                  setShowModel={setShowModel}
                  showModel={showModel}
                  deleteItemName={deleteItemName}
                  setDeleteItemName={setDeleteItemName}
                />
              )}
              {type === '3' && (
                <Menus
                  menuItems={menuItems}
                  rolesTypes={companyRoles}
                  systemRoles={systemRoles}
                  companyRolesMenus={companyRolesMenus}
                  setCompanyRolesMenus={setCompanyRolesMenus}
                  hitApi={hitApi}
                  hitApiData={hitApiData}
                  menuPermesions={menuPermesions}
                  setMenuPermesions={setMenuPermesions}
                  dataForpermessions={dataForpermessions}
                />
              )}
              {type === '4' && (
                <SystemRoles
                  selectedInviteTitle={selectedInviteTitle}
                  setSelectedInviteTitle={setSelectedInviteTitle}
                  rolesTypes={companyRoles}
                  selectedRoleItems={systemRoles}
                  setSelectedRoleItems={setSystemRoles}
                  selectedUser={selectedUsers}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  followers={followers}
                  setFollowers={setFollowers}
                  builders={builders}
                  setBuilders={setBuilders}
                  selectedBuilders={selectedBuilders}
                  setSelectedBuilders={setSelectedBuilders}
                  orginalSelectedBuilders={orginalSelectedBuilders}
                  setOrginalSelectedBuilders={setOrginalSelectedBuilders}
                  originalDataOfMod={originalDataOfMod}
                  setOriginalDataOfMod={setOriginalDataOfMod}
                  allBuilders={allBuilders}
                  setAllBuilders={setAllBuilders}
                  deleteItem={deleteItem}
                  setDeleteItem={setDeleteItem}
                  setShowModel={setShowModel}
                  showModel={showModel}
                />
              )}
              {type === '5' && (
                <Catalog
                  allCatalogData={allCatalogData}
                  setAllCatalogData={setAllCatalogData}
                  allCatalogDataGroup={allCatalogDataGroup}
                  rolesTypes={companyRoles}
                  catalogItemsList={catalogItemsList}
                  setCatalogItemsList={setCatalogItemsList}
                  setCatalogListSaveByUser={setCatalogListSaveByUser}
                  catalogListSaveByUser={catalogListSaveByUser}
                  hitApi={hitApi}
                  setCatalogDeleteids={setCatalogDeleteids}
                  catalogDeleteids={catalogDeleteids}
                  setShowModel={setShowModel}
                  showModel={showModel}
                  uploadCatalogImg={uploadCatalogImg}
                  ticketImg={ticketImg}
                  showLoader={showLoader}
                  setTicketImg={setTicketImg}
                  getCompanyCatalog={getCompanyCatalog}
                  deleteCatalog={deleteItem}
                  setDeleteCatalog={setDeleteItem}
                  setAllCatalogDataGroup={setAllCatalogDataGroup}
                  handleEditGroupCatalog={handleEditGroupCatalog}
                  aliasRoles={
                    orginalRolesData.length > 0
                      ? orginalRolesData.filter(
                          item => item.role_type_id !== '1',
                        )
                      : []
                  }
                  setCatalogName={setCatalogName}
                  deleteGroup={deleteGroup}
                  setDeleteGroup={setDeleteGroup}
                  deleteItemName={deleteItemName}
                  setDeleteItemName={setDeleteItemName}
                  catalogRef={catalogRef}
                />
              )}
              {type === '6' && (
                <SliderComponent6
                  setTriggers={setTriggers}
                  triggers={triggers}
                  hitApi={hitApiData}
                />
              )}
              {type === '9' && (
                <PipelinePermission
                  aliasRoles={orginalRolesData.filter(
                    item => item.role_type_id !== '1',
                  )}
                  tagAliasRoles={orginalRolesData}
                  systemAliasRoles={pipelineRoles}
                  setSystemAliasRoles={setPipelineRoles}
                  tagPermissionRoles={tagPermissionRoles}
                  setTagPermissionRoles={setTagPermissionRoles}
                  deleteCatalog={deleteItem}
                  setDeleteCatalog={setDeleteItem}
                  setShowModel={setShowModel}
                  showModel={showModel}
                  // setOwnProfilePermesion={setOwnProfilePermesion}
                  // ownProfilePermesion={ownProfilePermesion}
                />
              )}
            </div>
          )}

          {tabType === 'exchange' && (
            <TemplateExchange
              industryList={industryList}
              setHitApi={setHiTapiFromExchange}
              hitApi={hiTapiFromExchange}
              tabType={tabType}
              setTabType={setTabType}
            />
          )}
        </Scrollbars>
      </SlidingPanel>

      {/* {showModal && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModal(false);
            if (orginalSelectedBuilders.length !== selectedBuilders.length) {
              setSelectedBuilders(orginalSelectedBuilders);
            }
          }}
          removeheight={true}>
          <div className="new-session-modal-wrapper text-center createSessionModal eventBrite-Modal">
            <div className="new-session-modal">
              <CompanyInvite
                type={'refer'}
                component={'system-role'}
                followers={followers}
                setFollowers={setFollowers}
                moderator={builders}
                setModerator={setBuilders}
                selectedModData={allBuilders}
                setSelectedModData={setAllBuilders}
                originalDataOfMod={originalDataOfMod}
                setOriginalDataOfMod={setOriginalDataOfMod}
                selectedBuilders={selectedBuilders}
                contact={contact}
                setContact={setContact}
                setShowComponent={setShowComponent}
                forApiHit={showComponent}
                prevShowComponent={prevShowComponent}
                onClick={() => {
                  setShowComponent(previous => {
                    setPrevShowComponent(previous);
                    return 'inviteUser';
                  });
                }}
                handleCircle={handleCircle}
                handleInvite={() => {
                  handleSelectedInvite();
                  setShowModal(false);
                }}
              />
            </div>
          </div>
        </CustomModal>
      )} */}

      {showModal && (
        <SlidingPanel
          hidePanel={() => {
            setShowModal(false);
            if (orginalSelectedBuilders.length !== selectedBuilders.length) {
              setSelectedBuilders(orginalSelectedBuilders);
            }
          }}>
          <SystemRolesInvite
            type={'refer'}
            component={'system-role'}
            followers={followers}
            setFollowers={setFollowers}
            moderator={builders}
            setModerator={setBuilders}
            selectedModData={allBuilders}
            setSelectedModData={setAllBuilders}
            originalDataOfMod={originalDataOfMod}
            setOriginalDataOfMod={setOriginalDataOfMod}
            selectedBuilders={selectedBuilders}
            contact={contact}
            setContact={setContact}
            setShowComponent={setShowComponent}
            forApiHit={showComponent}
            prevShowComponent={prevShowComponent}
            onClick={() => {
              setShowComponent(previous => {
                setPrevShowComponent(previous);
                return 'inviteUser';
              });
            }}
            handleCircle={handleCircle}
            handleInvite={() => {
              handleSelectedInvite();
              setShowModal(false);
            }}
            title={selectedInviteTitle}
          />
        </SlidingPanel>
      )}

      {pocComponent === 'addPocs' && (
        <SlidingPanel
          hidePanel={() => {
            setPocComponent('');
            setPocIds([]);
            setSelectedPocs([]);
            setOriginalDataOfPocs([]);
            setPocTitle('');

            // if (originalPocs.length !== builders.length) {

            //   getCompanyDetails();
            // }
            // setBuilders([])
            // setAllBuilders([])
          }}
          firstDivStyle={{
            padding: 0,
            
          }}
          >
          {/* <div className="invite-page-wrapper"> */}
          {/* <Scrollbars autoHide> */}
          <InvitePoc
            type={'refer'}
            removeCompnayCreator={'yes'}
            component={'businessProfile'}
            moderator={pocIds}
            setModerator={setPocIds}
            selectedModData={selectedPocs}
            setSelectedModData={setSelectedPocs}
            title={pocTitle}
            setTitle={setPocTitle}
            contact={contact}
            setContact={setContact}
            originalDataOfMod={originalDataOfPocs}
            setOriginalDataOfMod={setOriginalDataOfPocs}
            handleCircle={handleCircle}
            forApiHit={refreshPocs}
            onClick={() => setShowComponent('inviteUser')}
            updateCompanyDetails={updateCompanyPocs}
            onClose={() => {
              setPocComponent('');
              setPocIds([]);
              setSelectedPocs([]);
              setOriginalDataOfPocs([]);
              setPocTitle('');
            }}
          />
          {/* </Scrollbars> */}
          {/* </div> */}
        </SlidingPanel>
      )}

     

      {showImportoptions && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowImportoptions(false);
          }}>
          {/* <div> */}
          <Scrollbars className="custom-scroll">
            <div className="new-session-modal-wrapper text-center createSessionModal eventBrite-Modal">
              <div className="new-session-modal">
                <div className="roi-template-general">
                  <div className="general-setting">
                    <h1>Import Options</h1>

                    <div>
                      <Form.Group className="fieldset payment-checkbox">
                        <Form.Check
                          //   onChange={e => setMakeDefault(e.target.checked)}
                          style={{
                            width: '30%',
                          }}
                          type={'checkbox'}
                          id="save-my-card"
                          label="Menus"
                          value={templateOptions.menus}
                          checked={templateOptions.menus}
                          onChange={e => {
                            setTemplateOptions({
                              ...templateOptions,
                              menus: !templateOptions.menus,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group className="fieldset payment-checkbox">
                        <Form.Check
                          //   onChange={e => setMakeDefault(e.target.checked)
                          style={{
                            width: '30%',
                          }}
                          type={'checkbox'}
                          id="save-my-card"
                          label="Roles"
                          value={templateOptions.roles}
                          checked={templateOptions.roles}
                          onChange={e => {
                            setTemplateOptions({
                              ...templateOptions,
                              roles: !templateOptions.roles,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group className="fieldset payment-checkbox">
                        <Form.Check
                          style={{
                            width: '30%',
                          }}
                          //   onChange={e => setMakeDefault(e.target.checked)}
                          type={'checkbox'}
                          id="save-my-card"
                          label="Catalog"
                          value={templateOptions.catalog}
                          checked={templateOptions.catalog}
                          onChange={e => {
                            setTemplateOptions({
                              ...templateOptions,
                              catalog: !templateOptions.catalog,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group className="fieldset payment-checkbox">
                        <Form.Check
                          style={{
                            width: '30%',
                          }}
                          //   onChange={e => setMakeDefault(e.target.checked)}
                          type={'checkbox'}
                          id="save-my-card"
                          label="Trigers"
                          value={triggersTypes}
                          checked={triggersTypes}
                          onChange={e => {
                            setTriggersTypes(!triggersTypes);
                            if (!triggersTypes === true) {
                              setTemplateOptions({
                                ...templateOptions,
                                email: true,
                                task: true,
                                projects: true,
                                sms: true,
                                messages: true,
                              });
                            } else {
                              setTemplateOptions({
                                ...templateOptions,
                                email: false,
                                task: false,
                                projects: false,
                                sms: false,
                                messages: false,
                              });
                            }
                            // setTemplateOptions({
                            //   ...templateOptions,
                            //   triggers: !templateOptions.triggers,
                            // });
                          }}
                        />
                      </Form.Group>

                      <div className="trigers-checkbox">
                        <Form.Group className="fieldset payment-checkbox">
                          <Form.Check
                            style={{
                              width: '30%',
                            }}
                            //   onChange={e => setMakeDefault(e.target.checked)}
                            type={'checkbox'}
                            id="save-my-card"
                            label="Email"
                            value={templateOptions.email}
                            checked={templateOptions.email}
                            onChange={e => {
                              setTemplateOptions({
                                ...templateOptions,
                                email: !templateOptions.email,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="fieldset payment-checkbox">
                          <Form.Check
                            style={{
                              width: '30%',
                            }}
                            //   onChange={e => setMakeDefault(e.target.checked)}
                            type={'checkbox'}
                            id="save-my-card"
                            label="Task"
                            value={templateOptions.task}
                            checked={templateOptions.task}
                            onChange={e => {
                              setTemplateOptions({
                                ...templateOptions,
                                task: !templateOptions.task,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="fieldset payment-checkbox">
                          <Form.Check
                            style={{
                              width: '30%',
                            }}
                            //   onChange={e => setMakeDefault(e.target.checked)}
                            type={'checkbox'}
                            id="save-my-card"
                            label="Projects"
                            value={templateOptions.projects}
                            checked={templateOptions.projects}
                            onChange={e => {
                              setTemplateOptions({
                                ...templateOptions,
                                projects: !templateOptions.projects,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="fieldset payment-checkbox">
                          <Form.Check
                            style={{
                              width: '30%',
                            }}
                            //   onChange={e => setMakeDefault(e.target.checked)}
                            type={'checkbox'}
                            id="save-my-card"
                            label="SMS"
                            value={templateOptions.sms}
                            checked={templateOptions.sms}
                            onChange={e => {
                              setTemplateOptions({
                                ...templateOptions,
                                sms: !templateOptions.sms,
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="fieldset payment-checkbox">
                          <Form.Check
                            style={{
                              width: '30%',
                            }}
                            //   onChange={e => setMakeDefault(e.target.checked)}
                            type={'checkbox'}
                            id="save-my-card"
                            label="Messages"
                            value={templateOptions.messages}
                            checked={templateOptions.messages}
                            onChange={e => {
                              setTemplateOptions({
                                ...templateOptions,
                                messages: !templateOptions.messages,
                              });
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div>
                      <Form.Group className="fieldset payment-checkbox">
                        <Form.Check
                          style={{
                            width: '30%',
                          }}
                          //   onChange={e => setMakeDefault(e.target.checked)}
                          type={'checkbox'}
                          id="save-my-card"
                          label="Goals"
                          value={templateOptions.goals}
                          checked={templateOptions.goals}
                          onChange={e => {
                            setTemplateOptions({
                              ...templateOptions,
                              goals: !templateOptions.goals,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group className="fieldset payment-checkbox">
                        <Form.Check
                          style={{
                            width: '30%',
                          }}
                          //   onChange={e => setMakeDefault(e.target.checked)}
                          type={'checkbox'}
                          id="save-my-card"
                          label="Analytics"
                          value={templateOptions.analytics}
                          checked={templateOptions.analytics}
                          onChange={e => {
                            setTemplateOptions({
                              ...templateOptions,
                              analytics: !templateOptions.analytics,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group className="fieldset payment-checkbox">
                        <Form.Check
                          style={{
                            width: '30%',
                          }}
                          //   onChange={e => setMakeDefault(e.target.checked)}
                          type={'checkbox'}
                          id="save-my-card"
                          label="Leaderboard"
                          value={templateOptions.leaderboard}
                          checked={templateOptions.leaderboard}
                          onChange={e => {
                            setTemplateOptions({
                              ...templateOptions,
                              leaderboard: !templateOptions.leaderboard,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div>
                      <Form.Group className="fieldset payment-checkbox">
                        <Form.Check
                          style={{
                            width: '30%',
                          }}
                          //   onChange={e => setMakeDefault(e.target.checked)}
                          type={'checkbox'}
                          id="save-my-card"
                          label="Available for others"
                          value={templateOptions.availability}
                          checked={templateOptions.availability ? true : false}
                          onChange={e => {
                            setTemplateOptions({
                              ...templateOptions,
                              availability: !templateOptions.availability,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>

          <div
            className="filter-btn-wrapper pt-3"
            style={{position: 'relative'}}>
            <Button
              style={{position: 'absolute', right: '253px', top: '2px'}}
              className="btn-dark"
              type="submit"
              onClick={() => {
                update_template_permission();
              }}>
              Save
            </Button>
          </div>
          {/* </div> */}
        </CustomModal>
      )}

      {showComponent === 'viewPocs' && (
        <SecondaryPocs
          pocDetails={secondaryPOCs}
          setSelectedPoc={setSelectedPoc}
          setShowComponent={setPocComponent}
          deletePocs={deletePocs}
          onClose={() => {
            setShowComponent('');
          }}
          deleteItem={deleteItem}
          setDeleteItem={setDeleteItem}
          setShowModel={setShowModel}
          showModel={showModel}
        />
      )}
       {pocComponent === 'editPocs' && (
        <SlidingPanel
          hidePanel={() => {
            setPocComponent('');
            setPocIds([]);
            setSelectedPocs([]);
            setOriginalDataOfPocs([]);
            setPocTitle('');

            // if (originalPocs.length !== builders.length) {

            //   getCompanyDetails();
            // }
            // setBuilders([])
            // setAllBuilders([])
          }}>
          {/* <div className="invite-page-wrapper"> */}
          {/* <Scrollbars autoHide> */}
          <InvitePoc
            type={'refer'}
            editPoc="edit"
            removeCompnayCreator={'yes'}
            component={'businessProfile'}
            selectedPoc={selectedPoc}
            moderator={pocIds}
            setModerator={setPocIds}
            selectedModData={selectedPocs}
            setSelectedModData={setSelectedPocs}
            title={pocTitle}
            setTitle={setPocTitle}
            contact={contact}
            setContact={setContact}
            originalDataOfMod={originalDataOfPocs}
            setOriginalDataOfMod={setOriginalDataOfPocs}
            getCompanyDetails={getCompany}
            handleCircle={handleCircle}
            forApiHit={refreshPocs}
            onClick={() => setShowComponent('inviteUser')}
            // updateCompanyDetails={updateCompanyDetails}
            onClose={() => {
              setPocComponent('');
              setPocIds([]);
              setSelectedPocs([]);
              setOriginalDataOfPocs([]);
              setPocTitle('');
            }}
          />
          {/* </Scrollbars> */}
          {/* </div> */}
        </SlidingPanel>
      )}

      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          onClose={() => {
            setShowComponent(previous => {
              setPrevShowComponent(previous);
              return '';
            });
          }}
        />
      )}
      {showComponent === 'ccUser' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent('');
          }}
          removeheight={true}>
          <AddCC
            companydetailApiData={companyData}
            setCompanydetailApiData={setCompanyData}
            onClose={() => {
              setShowComponent('');
            }}
          />
        </CustomModal>
      )}

      {showComponent === 'Builders' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          hide={true}
          CircleUserListData={type ==='4' ? CircleUserListData : CircleUserPocData}
        />
      )}

      {showComponent === 'categories' && (
        <CustomModal size="small" onClose={() => setShowComponent('')}>
          <div className="edit-opportunity-modal">
            <div className="filter-headings">
              <h2>Select Categories</h2>
            </div>
            <div className="filter-wrapper">
              <div className="filter-category-list">
                <ul>
                  <li className={isCheckAll ? 'active' : ''}>
                    <Form.Label>
                      All{' '}
                      <input
                        type="checkbox"
                        id="ALL"
                        name="ALL"
                        value="ALL"
                        onChange={handleSelectAll}
                        checked={isCheckAll}
                      />{' '}
                      <span />
                    </Form.Label>
                  </li>
                  {Object.entries(categories).length > 0 &&
                    categories.map((item, index) => (
                      <li
                        key={`sessionC-${index}`}
                        className={isCheck.includes(item.id) ? 'active' : ''}>
                        <Form.Label>
                          {item.category_name}
                          <input
                            type="checkbox"
                            id={item.id}
                            value={item.category_name}
                            onChange={e => {
                              handleClick(e);
                            }}
                            checked={
                              isCheck.includes(item.id) ||
                              isCheckValue.includes(item.category_name)
                            }
                          />
                          <span />
                        </Form.Label>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="bg_transparent text-center">
              <Button
                type="submit"
                className="btn-dark"
                onClick={() => {
                  setShowComponent('');
                }}>
                Select
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

      {showModel === 'add_group' && (
        <CreateCatalogGroup
          type={'catalog'}
          onClose={type => {
            setShowModel('');
            getCompanyCatalog();
          }}
        />
      )}

      {showModel === 'edit_group_catalog' && (
        <CreateCatalogGroup
          type={'catalog'}
          case={'editCatalog'}
          catalogItem={selectedCatalogGroup}
          onClose={type => {
            setShowModel('');
            getCompanyCatalog();
          }}
        />
      )}

      {showModel === 'addSupplier' && (
        <SendInvitation
          type={'Catalog'}
          onClose={() => {
            setShowModel('');
          }}
          onCloseHitApi={() => {
            catalogRef.current.getSupplier('addSupplier');
          }}
        />
      )}

      {showModel === 'uploadCatalogImg' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="templateCatalog"
            ratio={50 / 50}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            imgUrl={ticketImg}
            setImgUrl={setTicketImg}
            setshowLoader={setshowLoader}
          />
        </CustomModal>
      )}

      {showModel === 'deleteItem' && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowModel('');
            setDeleteGroup(false);
          }}
          className={'catalog_modal'}>
          <div className="permission-modal-content text-center">
            {type === '2' ? (
              <p>Are you sure you want to delete this {deleteItemName}?</p>
            ) : type === '4' ? (
              <p>Are you sure you want to delete this Role?</p>
            ) : type === '9' ? (
              <p>Are you sure you want to delete this Permission?</p>
            ) : type === '5' && deleteGroup ? (
              <p>Are you sure you want to delete this Group?</p>
            ) : type === '5' ? (
              <p>Are you sure you want to delete this {catalogName}?</p>
            ) : type === '1' && showComponent === 'viewPocs' ? (
              <p>Are you sure you want to delete this POC?</p>
            ) : (
              <p>Are you sure you want to delete this Location?</p>
            )}

            <div className="event-btn-wrapper">
              <Button
                className="btn-dark btn_padding"
                onClick={() => {
                  
                  setDeleteItem(true);
                  if(type !=='9' && type !=='2'){
                    setShowModel('');
                  }
                  
                }}>
                Yes
              </Button>
              <div className=" text-center mt-2 as-link">
                <span
                  onClick={() => {
                    setShowModel('');
                    setDeleteGroup(false);
                  }}>
                  No
                </span>
              </div>
            </div>
          </div>
        </CustomModal>
      )}

      {showModel === 'deleteMembershipItem' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={`${
            deleteItemName === 'edit_level'
              ? 'Are you sure you want to edit this Level'
              : 'Are you sure you want to delete this ' + deleteItemName
          }?`}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            setShowModel('');
            setDeleteItem(true);
          }}
        />
      )}
       {showModel === 'deleteMilestonePayment' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={`${
          
              'Are you sure you want to delete this ' + deleteItemName
          }?`}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
           
            setDeleteItem(true);
          }}
        />
      )}

{showModel === 'deleteDuarationMilestone' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={'Are you sure you want to remove this milestone?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            setDeleteItem(true);
            // handleDurationMileStone('remove', dleteLevelIndex);
            // setShowModel('');
          }}
        />
      )}

{showModel === 'image_pre' && ticketImg && (
        <ImagePreview
          modelImg={ticketImg}
          onClose={() => {
            setShowModel('');
          }}
        />
      )}
    </>
  );
};

export default ROITemplate;
