import React, {useState, useRef, useEffect} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import TicketDateOption from '../Modals/TicketDateOption';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  App_Secret_key_Dolby,
  App_key_Dolby,
  selected_lang,
} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import commonApi from '../../Services/CommonService';
import API from '../../Services/SessionServices';
import SelectedUsers from '../../Pages/Front/StaticPages/SelectedUsers';
import CreateSnapShot from '../Messages/CreateSnapShot';
import moment from 'moment/moment';
import $ from 'jquery';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import AddTicketOption from './AddTicketOption';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import SessionFocus from '../../Pages/Front/StaticPages/SessionFocus';
import {handleOutlookCalendarEvents} from '../../Utils/OutlookUtils';
import CustomTabs from '../CustomTabs';

import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {
  getCityStateFromAddress,
  convertTimeIntoDateTime,
  goToZoom,
  getEventsByCalendarType,
  getGoogleLatestToken,
  removeTicketId,
  getMaxDateFromToday
} from '../../Utils/commonUtils';
import CustomModal from '../CustomModal';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {useSelector, useDispatch} from 'react-redux';
import {ENV_HOST} from '../../Utils/env';
import {useHistory} from 'react-router-dom';

import {list} from '../../Utils/commonUtils';
import {RoiLogo, UserProfileImg} from '../../Constants/Images';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import Invite from '../SingleComponent/Invite';
import CropImage from '../../Components/Cropper/CropImage';
import PersonalCreateModel from '../Modals/PersonalCreateModel';
import {get_my_all_associate_workspaces} from '../../Store/Actions/SessionAction';
import CalanderModel from '../Modals/CalanderModel';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {ENCRYPTION_KEY} from '../../Utils/env';

import EventBrite from './EventBrite';
import ImagePreview from '../Modals/ImagePreview';
import AlertModel from '../Modals/AlertModel';
import Sponsors from './Sponsors';
import CustomDropdown from '../CustomDropdown';
import TimePicker from 'rc-time-picker';
import Calender from '../FeedBack/Calender';
import {parseISO} from 'date-fns';

// import { loadStripe } from '@stripe/stripe-js';
// import {
//   PaymentElement,
//   Elements,
//   useStripe,
//   useElements,
// } from '@stripe/react-stripe-js';
// import CheckoutForm from './CheckoutForm';

// const stripePromise = loadStripe('pk_test_51PEB5a2K8W7Cs25henyw4Ahf3eGMQgtDgRztlGV4POXFghfMEH5DDg5PtNpT916JChWA9ELeby2Znh5mu2IjXyuY00TWUVZN1V');

const CreateSession = props => {
  //const stripe = useStripe();
  // const elements = useElements();
  const [showLoader, setshowLoader] = useState(false);

  const [disableBtn, setDisableBtn] = useState(false);

  const [topIcons, setTopIcons] = useState({
    focus: false,
    location: true,
    vaccinate: true,
    dollar: false,
    store: false,
    bullseye: false,
    personal: false,
  });
  const buttonRef = useRef(null);
  const [sessionDolby, setSessionDolby] = useState({});
  const [serverTimeS, setServerTime] = useState('');
  const [subTabs, setSubTabs] = useState('moderator');
  const [category, setCategory] = useState('16');
  const [location, setLocation] = useState('');
  const [title, setTitle] = useState('');
  const [ticket, setTicket] = useState('');
  const [date, setDate] = useState('');
  const [modrator, setModrator] = useState([]);
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  const [audience, setAudience] = useState([]);
  const [price, setPrice] = useState('');
  const [validated, setValidated] = useState(false);
  const [privateButton, setPrivateButton] = useState('public');
  const [sliderImage, setSliderImage] = useState([]);
  const [createdSessionId, setcreatedSessionId] = useState('');
  const [selectedModData, setSelectedModData] = useState([]);
  const [selectedAudData, setSelectedAudData] = useState([]);

  const [tabType, setTabType] = useState('general');
  const [repeateOption, setRepeatOption] = useState(false);
  const [showError, setShowError] = useState(false);
  const [startTimeError, setStarTimeError] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [showModalButtons, setShowModalButtons] = useState(false);
  const [showModelDecesion, setShowModelDecesion] = useState('');
  // const [showModelDecesion, setShowModelDecesion] = useState('');

  const [sessionCategories, setSessionCategories] = useState('');
  const [ticketImg, setTicketImg] = useState('');
  const [discription, setDiscription] = useState('');
  const [snapShotButton, setSnapShotButton] = useState(false);
  const [hostId, setHostId] = useState('');
  const [images, setImages] = useState([]);
  // const [change, setChange] = useState(false);
  const [imageCreate, setImageCreate] = useState('');
  const [newButton, setNewButton] = useState(false);
  const [isRepeat, setRepeat] = useState(false);
  const [selectedDays, selectWeekDay] = useState([]);
  const [exculdedDates, setExculdedDates] = useState([]);
  const [occurrences, setOccurrences] = useState('1');
  const [recursionDateTime, setRecursionDateTime] = useState('');
  const [weekMonth, setWeekMonth] = useState('week');
  const [startAgainAfter, setStartAgainAfter] = useState('1');
  const [startAgainWeek, setStartAgainWeek] = useState('1');
  const [sessionEndTime, setSessionEndTime] = useState(15);
  const [modalEndEvent, setModalEndEvent] = useState(false);
  // const [clockButton, setClockButton] = useState(false);
  const [sessionLink, setSessionLink] = useState(false);
  const [showModel, setShowModel] = useState('');

  const [array, setArray] = useState([]);
  const [imgModal, setImgModal] = useState(false);
  const [toggleEventBrite, setToggleEventBrite] = useState(false);
  const [eventBriteEvents, setEventBriteEvents] = useState(false);
  const [checkEventBritelogin, setCheckEventBritelogin] = useState(false);
  const [inPersonOnly, setInPersonOnly] = useState(false);
  const [allowSponsers, setAllowSponsers] = useState(false);

  // const eventBrite = useRef(false);

  const [identiferId, setIdentiferId] = useState([]);
  const [ethnicityId, setEthnicityId] = useState([]);
  const [gender, setGender] = useState([]);
  const [focusLocations, setFocusLocations] = useState([]);
  // const [inviteCircle, setInviteCircle] = useState([]);
  const [showComponent, setShowComponent] = useState('');

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [opportunityId, setOpportunityId] = useState('');
  const [questionId, setQuestionId] = useState('');

  const [alreadyAddedAud, setAlreadyAddedAud] = useState([]);
  const [alreadyAddedMod, setAlreadyAddedMod] = useState([]);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

  const [appointmentId, setAppointmentId] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [persnalTypesId, setPersnalTypesId] = useState([]);
  const [createrId, setCreaterId] = useState('');
  const [eventId, setEventId] = useState('');
  const [showModalSesion, setShowModalSesion] = useState(false);
  const [showModalEventbrite, setShowModalEventbrite] = useState(false);
  const [cropImg, setCropImg] = useState(false);
  const [file, setFile] = useState(null);

  const [conferenceId, setConferenceId] = useState();
  const [calendarData, setCalendarData] = useState([]);
  const [events, setevents] = useState('');

  // const audDataArray = props.sessionData.audience_ids === "" ? [] :props.sessionData.audience_ids.split(',');
  // const moddataArray=  props.sessionData.moderator_ids === "" ? [] :   props.sessionData.moderator_ids.split(',');

  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [originalDataOfAud, setOriginalDataOfAud] = useState([]);
  const [contact, setContact] = useState([]);
  const [workSpaceIds, setWorkSpaceIds] = useState([]);
  const [workSpaceIdsforBrite, setWorkSpaceIdsforBrite] = useState([]);
  const [eventBriteList, setEventBriteList] = useState([]);
  const [eventBriteEventsData, setEventBriteEventsData] = useState([]);
  const [eventBriteCount, setEventBriteCount] = useState(0);
  const [modelId, setModelId] = useState([]);
  const [modelIdItem, setModelItem] = useState([]);
  const [selectedEventbriteId, setSelectedEventbriteId] = useState();
  const [closeScreenEventBrite, setCloseScreenEventBrite] = useState(false);

  const [locationData, setLocationData] = useState(null);
  const [modelImg, setModelImg] = useState('');
  const RadioButtonName = ['Invite Only', 'Share on The Market'];
  const [invitesOptionvalue, setInvitesOptionvalue] = useState(
    'Share on The Market',
  );

  const [startPickerOpen, setStartPickerOpen] = useState(false);
  const [endPickerOpen, setEndPickerOpen] = useState(false);
  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');
  // const [showModal, setShowModal] = useState(false);
  const [sponserArray, setSponserArray] = useState([]);
  const [accountType, setAccountType] = useState('');
  //  const [ opptype, setopptype] = useState("");
  //  const [ consulttype  ,  setConsulttype  ] = useState("");
  const opportunityDetail = useSelector(state => state.opportunityChatDetail);
  const history = useHistory();
  // const locationWeb = useLocation();
  let eventsDataForCalender = {};
  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );
    // get_calenders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workSpaceIds.length > 0) {
    } else {
      // Filter the workspaces with status "active" from the API response
      const userExist = listWorspacerator.filter(
        workspace => workspace.status === 'active',
      );

      // Extract the 'id' values from 'userExist'
      const updatedIds = userExist.map(workspace => workspace.id);

      if (personal === 'yes') {
        updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      }

      // setActiveWorkspacesId(updatedIds);
      setWorkSpaceIds(updatedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // let fileExtension = file.name.split('.').pop().toLowerCase();

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);
      setCropImg(true);
      setShowModel('modal');

      e.target.value = null;

      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {

      //   uploadMedia(e.target.result);
      // };

      // setTicketImg(objectUrl);
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
    // const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  };

  // const changeTicketImgSnapSHot = e => {
  //   if (e.target.files.length === 0) return false;
  //   const file = e.target.files[0];
  //   let fileExtension = file.name.split('.').pop().toLowerCase();

  //   // If no image selected, return
  //   // || fileExtension === "gif"
  //   if (!/^image\//.test(file.type)) {
  //     errorMessage(`File is not an image.`);
  //     return false;
  //   } else {
  //     const filePath = e.target.files[0];
  //     const objectUrl = URL.createObjectURL(filePath);
  //     let reader = new FileReader();
  //     reader.readAsDataURL(filePath);
  //     reader.onload = e => {
  //       uploadMediaSnapshot(e.target.result);
  //     };

  //     // setTicketImg(objectUrl);
  //   }
  //   // const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  // };
  // let jugar = location !== '' ? location.label : 'Type city and state';
  let jugar =
    typeof location === 'object'
      ? location.label
      : typeof location === 'string' && location !== ''
      ? location
      : 'Type city and state';

  useEffect(() => {
    if (images) {
      images.forEach(item => {
        if (hostId === item.id) {
          setImageCreate(item.images[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newButton]);

  // let clockArray = [
  //   {
  //     time: '15 mins',
  //     value: 15,
  //   },
  //   {
  //     time: '30 mins',
  //     value: 30,
  //   },
  //   {
  //     time: '45 mins',
  //     value: 45,
  //   },
  //   {
  //     time: '1.00 hour',
  //     value: 60,
  //   },
  //   {
  //     time: '1.15 hour',
  //     value: 75,
  //   },
  //   {
  //     time: '1.30 hour',
  //     value: 90,
  //   },
  //   {
  //     time: '1.45 hour',
  //     value: 105,
  //   },
  //   {
  //     time: '2.00 hours',
  //     value: 120,
  //   },
  //   {
  //     time: '2.15 hours',
  //     value: 135,
  //   },
  //   {
  //     time: '2.30 hours',
  //     value: 150,
  //   },
  //   {
  //     time: '2.45 hours',
  //     value: 165,
  //   },
  //   {
  //     time: '3.00 hours',
  //     value: 180,
  //   },
  // ];

  // const openModal = () => {
  //   setShowModal(true);
  // };

  useEffect(() => {
    getAccomplishment();
  }, [newButton]);

  useEffect(() => {
    getSessionCatData();
    getAccomplishment();
    // get_outLook_calenders()
    serverTime();
  }, []);

  const privatee = () => {
    privateButton ? setPrivateButton('private') : setPrivateButton('public');
  };

  // const uploadGroupPicture = img => {
  //   setshowLoader(true);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'image',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   commonApi
  //     .upload_group_picture(bodyFormData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setImageCreate(res.image);

  //         // setImagePath(res.image);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const cancelSession = () => {
    var payLoad = {
      id: props.referType
        ? props.editSession.refer_type_id
        : !props.referType && props.editSession
        ? props.editSession.id
        : props.typeOfReferEvent === true
        ? props.editEvent.refer_type_id
        : props.editEvent.id,
    };
    commonApi
      .cancel_session(payLoad)
      .then(res => {
        setshowLoader(false);
        if (res.status === 200) {
          if (props.getApiSessionList) {
            props.getApiSessionList(props.sessionType, '', !props.typeIs);
          }

          successMessage(res.message);

          var updateEvent = props.editEvent
            ? props.editEvent
            : props.editSession;
          if (
            updateEvent &&
            updateEvent.all_calendars_events &&
            updateEvent.all_calendars_events.length > 0
          ) {
            delete_google_calendar_event(
              props.referType
                ? props.editSession.refer_type_id
                : !props.referType && props.editSession
                ? props.editSession.id
                : props.typeOfReferEvent === true
                ? props.editEvent.refer_type_id
                : props.editEvent.id,
            );

            const {outlookEvents, googleEvents} = getEventsByCalendarType(
              updateEvent.all_calendars_events,
            );

            if (googleEvents.length > 0) {
              if (
                updateEvent &&
                updateEvent.all_calendars_events &&
                updateEvent.all_calendars_events.length > 0
              ) {
                getRefreshToken(googleEvents, 'delete');
              }
              // if (
              //   props.editSession &&
              //   props.editSession.all_calendars_events &&
              //   props.editSession.all_calendars_events.length > 0
              // ) {
              //   getRefreshToken(
              //     props.editSession.all_calendars_events,
              //     'delete',
              //   );
              // }
            }

            if (outlookEvents.length > 0) {
              handleOutlookCalendarEvents(outlookEvents, '', 'delete');
            }
          }
          props.onClose();

          props.Close();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const tickectsTypeDecesion =
    !inPersonOnly && location === ''
      ? 'no'
      : inPersonOnly
      ? 'both'
      : location && 'yes';

  const getAccomplishment = () => {
    var payLoad = {
      categories: [],
      user_id: localStorage.getItem('user'),
    };
    commonApi
      .get_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          if (res.accomplishment.length > 0) {
            setImages(res.accomplishment);
            setHostId(res.accomplishment[0].id);
            setImageCreate(res.accomplishment[0].images[0]);
          } else {
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const uploadMediaSnapshot = img => {
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'slider_images',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   setshowLoader(true);
  //   commonApi
  //     .upload_event_media(bodyFormData)
  //     .then(res => {
  //       setshowLoader(false);

  //       if (res.status === 200) {
  //         setImageCreate(res.slider_image_path);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const checkDateTime = () => {
  //   const dateTime = {
  //     date_time: topIcons.focus
  //       ? moment(eventStartDateTime).format('YYYY-MM-DD hh:mm:ss')
  //       : sessionStartTime === 'Invalid date'
  //       ? ''
  //       : moment(sessionStartTime).format('YYYY-MM-DD hh:mm:ss'),
  //   };

  //   commonApi
  //     .check_user_events(dateTime)
  //     .then(res => {
  //       if (res.success) {
  //         successMessage(res.message);
  //       } else {
  //         errorMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const getCityState = (lat, lng) => {
  //   const apiKey = 'AIzaSyAUwMSFtdT541UlTa2ucZ1EORtWqwz0R7s';

  //   // Construct the URL for the Geocoding API request
  //   const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  //   // Fetch data from the API
  //   fetch(apiUrl)
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.status === 'OK') {
  //         const result = data.results[0];
  //         const addressComponents = result.address_components;
  //         const city =
  //           addressComponents.find(comp => comp.types.includes('locality'))
  //             ?.long_name || '';
  //         const state =
  //           addressComponents.find(comp =>
  //             comp.types.includes('administrative_area_level_1'),
  //           )?.long_name || '';
  //         const county =
  //           addressComponents.find(comp =>
  //             comp.types.includes('administrative_area_level_2'),
  //           )?.long_name || '';
  //         const completeAddress = result.formatted_address;

  //         setLocationData({city, state, county, completeAddress});
  //       } else {
  //         setLocationData(null);
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error fetching location data:', error);
  //       setLocationData(null);
  //     });
  // };

  const handle = event => {
   
   
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    // if (!props.editSession &&  !props.editEvent) {
    //   EventData();
    // }
    if (
      (date && timeStart && timeEnd) ||
      props.editEvent ||
      props.editSession
    ) {
      EventData();
    }

    // if (props.eventData || props.referSession) {
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     // setLocation("")
    //     // if (timeEnd > timeStart || timeEnd === timeStart) {
    //     //   errorMessage("Invalid Date");
    //     // }

    //     setValidated(true);
    //
    //   } else {
    //     event.preventDefault();

    //
    //     updateSession();
    //     // createSession("", "");

    //     handleResetForm();
    //     setValidated(false);
    //   }
    // }
    // console.log(location,'--------->location')
    // console.log(timeEnd,'--------->timeEnd')
    // console.log(timeStart,'--------->timeStart')
    if (
      form.checkValidity() === false ||
      (location !== '' && timeEnd !== '' && ticketImg === '' && !props.editEvent) ||
      // ((ticketImg !== '' || sliderImage.length === 0) && location !== '') || // Require image if location is selected
      (location === '' && inPersonOnly) ||
      ((inPersonOnly || location !== '') &&
        (timeEnd === '' || timeStart === '') && !props.editEvent) // Require location if inPersonOnly is true
    ) {
      event.preventDefault();
      event.stopPropagation();

     
      // if (sliderImage.length === 0 && location !== '') {
      //   errorMessage('Please select an image.');
      // }

      if (location !== '' && timeEnd !== '' && ticketImg === '') {
        errorMessage('Please select an image.');
      }
      if (location === '' && inPersonOnly) {
        setShowError(true);
      }

      if (timeStart === '') {
        setStarTimeError(true);
      }

      if (timeEnd === '') {
        setEndTimeError(true);
      }

      setValidated(true);
    } else {
      event.preventDefault();
      if (date && timeEnd < timeStart) {
        errorMessage('select valid time');
      }
      // else if (workSpaceIds.length == 0) {
      //   errorMessage('Please select workspace');
      // }
      // else if (
      //   array.length === 0 &&
      //   topIcons.dollar &&
      //   topIcons.focus &&
      //   !props.editEvent
      // ) {
      //   errorMessage('Please add tickets option');
      // }
      else {
        event.preventDefault();
        setShowError(false);

        let place_id =
          typeof location === 'object'
            ? location.value.place_id
            : typeof location === 'string'
            ? ''
            : '';

        if (place_id) {
          geocodeByPlaceId(place_id)
            .then(results => {
              const geocodingResult = results[0];

              // Assuming getLatLng and getCityStateFromAddress are asynchronous functions
              return Promise.all([
                getLatLng(geocodingResult),
                getCityStateFromAddress(geocodingResult),
              ]);
            })
            .then(([latLngResult, cityStateResult]) => {
              const {lat, lng} = latLngResult;
              const data = cityStateResult;

              if (props.editSession) {
               
                updateSession(lat, lng, data.address);
              } else {
                createSession(lat, lng, data.address);
              }

              // handleResetForm(); // You might want to call this here if needed
            })
            .catch(error => {
              console.error('An error occurred:', error);
            });
        } else {
          if (props.editSession || props.editEvent) {
            updateSession('', '');
          } else {
            createSession('', '');
          }
        }

        // props.onClose();

        setValidated(false);
      }
    }
  };

  const handleResetForm = () => {
    setLocation('');

    setTopIcons({
      focus: false,
      store: false,
    });
    setAudience([]);
    setTitle('');
    setTicket('');
    setSliderImage([]);
    setPrice('');
    setModrator([]);
    setTimeEnd('');
    setTicketImg('');
    setTimeStart('');
    setDate('');
  };

  const enterSession = async () => {
    // alert('................')
    // initializeVoxeet();
    var CheckLastSession = '';
    // localStorage.getItem('session_started');
    if (CheckLastSession && CheckLastSession === createdSessionId) {
      let payload = {conference_id: createdSessionId};
      const response = await API.is_session_started(payload);

      if (response.session_id) {
        // localStorage.setItem('conferenceAlias', response.conferenceAlias);
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', createdSessionId);
        // history.push('/session-details');
        setShowModalSesion(false);
        props.onClose();
        goToZoom(response.session_id);
      }
    }
    if (!CheckLastSession) {
      let payload = {conference_id: createdSessionId};
      const response = await API.is_session_started(payload);

      if (response.session_id) {
        // localStorage.setItem('conferenceAlias', response.conferenceAlias);
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', createdSessionId);
        setShowModalSesion(false);
        props.onClose();
        goToZoom(response.session_id);
      }
    }
    if (CheckLastSession && CheckLastSession !== createdSessionId) {
      errorMessage(
        'Your are already in a session ' +
          localStorage.getItem('session_started_name') +
          '.',
      );
    }
  };

  const updateSession = (lat, lng, completeAddress) => {
    // setshowLoader(true);
    // let endDateTime = moment(sessionStartTime)
    //   .add(sessionEndTime, 'm')
    //   .toISOString();
    setDisableBtn(false);

    let address =
      typeof location === 'object'
        ? location.label
        : typeof location === 'string'
        ? location
        : '';
    // session_streamings_id
    var scnd = {
      complete_address: completeAddress,
      address: address,
      ally: topIcons.bullseye ? 'yes' : 'no',
      audience_ids: audience,
      category_id: category,
      city_states: focusLocations,
      // ethnicities:
      //   typeof ethnicityId === 'string' ? ethnicityId : ethnicityId.toString(),
      ethnicities: '',
      // gender: typeof gender === 'string' ? gender : gender.toString(),
      gender: '',
      // otherIdentifires:
      //   typeof identiferId === 'string' ? identiferId : identiferId.toString(),
      otherIdentifires: '',
      on_boarding_areas: persnalTypesId,
      appointment_types: appointmentId,
      on_boarding_categories: categoriesId,
      conference_id: props.referType
        ? props.editSession.refer_type_id
        : !props.referType && props.editSession
        ? props.editSession.id
        : props.typeOfReferEvent === true
        ? props.editEvent.refer_type_id
        : props.editEvent.id,
      end_time: topIcons.focus
        ? props.editEvent.end_time
        : props.editSession.end_time,

      latitude:
        typeof location === 'object'
          ? lat
          : typeof location === 'string'
          ? latitude
          : '',
      location: location !== '' ? 'yes' : 'no',
      longitude:
        typeof location === 'object'
          ? lng
          : typeof location === 'string'
          ? longitude
          : '',
      moderator_ids: modrator,
      paid_status: topIcons.dollar ? 'yes' : 'no',
      price: price,
      session_description: discription,
      session_start_time: topIcons.focus
        ? props.editEvent.session_start_time
        : props.editSession.session_start_time,
      slider_image: sliderImage,
      start_time: props.editEvent
        ? props.editEvent.start_time
        : props.editSession.start_time,
      snapshot_id: hostId,
      snapshot_image: imageCreate,
      status: 'pending',
      tickets: ticket,
      title: title,
      workspace_ids: workSpaceIds.toString(','),
    };

    commonApi
      .update_session_dolby(scnd)
      .then(async res => {
        if (res.status === 200) {
          // setSessionDolby(res);
          successMessage(res.message);
          setDisableBtn(false);

          var updateEvent = props.editEvent
            ? props.editEvent
            : props.editSession;
          console.log(updateEvent, '=--------updateEvent');
          if (
            updateEvent.all_calendars_events &&
            updateEvent.all_calendars_events.length > 0
          ) {
            const {outlookEvents, googleEvents} = getEventsByCalendarType(
              updateEvent.all_calendars_events,
            );

            console.log(outlookEvents, '=======>outlookEventsoutlookEvents');

            console.log(googleEvents, '=======>googleEvents');

            if (googleEvents.length > 0) {
              if (
                updateEvent &&
                updateEvent.all_calendars_events &&
                updateEvent.all_calendars_events.length > 0
              ) {
                getRefreshToken(googleEvents);
              }
            }

            if (outlookEvents.length > 0) {
              handleOutlookCalendarEvents(
                outlookEvents,
                eventsDataForCalender,
                'edit',
              );
            }
          }

          props.onClose();
          props.Close() && props.Close();
          handleResetForm();
          props.getApiSessionList(props.sessionType, '', !props.typeIs);
        }
        setshowLoader(false);
        setDisableBtn(true);
      })
      .catch(err => {
        console.log('Err', err);
        setDisableBtn(true);
        return {type: 'error', message: err.message};
      });
  };

  const getRefreshToken = async (all_calenders_event, type) => {
    var payload = {
      type: 'google',
    };
    commonApi.get_refresh_token(payload).then(async response => {
      // getAccessTokenFromRefreshToken(
      //   response.refresh_token[0].refresh_token,
      //   all_calenders_event,
      //   type,
      // );

      // if (type && type === 'delete') {
      //   delete_google_calendar_event(
      //     props.referType
      //       ? props.editSession.refer_type_id
      //       : !props.referType && props.editSession
      //       ? props.editSession.id
      //       : props.typeOfReferEvent === true
      //       ? props.editEvent.refer_type_id
      //       : props.editEvent.id,
      //   );
      // }

      for (const item of all_calenders_event) {
        const {google_calender_email} = item;

        const email = google_calender_email

        // if (google_calender_email) {
        // Find the matching token for the current calendar email
        // const matchingToken = response.refresh_token.find(
        //   token => token.email === google_calender_email,
        // );

        // if (matchingToken && matchingToken.refresh_token) {
        //   getAccessTokenFromRefreshToken(
        //     matchingToken.refresh_token,
        //     item.calendar_id,
        //     item.calendar_event_id,
        //     type,
        //   );

        //   // You can use the accessToken as needed
        // } else {
        // }

        try {
          // Find the matching token for the current calendar email
          const matchingToken = response.refresh_token.find(
            token => token.email === email,
          );

          let filterToken;
          let token;
          if (!matchingToken) {
            // Get the latest token if no matching token found
            filterToken = getGoogleLatestToken(response.refresh_token);
          }

          // Decide which token to use
          token = matchingToken ? matchingToken : filterToken;

          console.log(token, 'final tokenh----------->')
          if (token && token.refresh_token) {
            try {
              // Get the access token from the refresh token
              

              const response = await getAccessTokenFromRefreshToken(
                token.refresh_token,
                item.calendar_id,
                item.calendar_event_id,
                type,
              );

              // console.log(accessToken, '------->accessToken');
              // You can use the accessToken as needed
            } catch (accessTokenError) {
              console.error(
                'Error getting access token:',
                accessTokenError.message,
              );
              // Handle errors related to getting the access token
            }
          } else {
            console.warn('No valid token found for email:', email);
          }
        } catch (tokenProcessingError) {
          console.error(
            'Error processing token for email:',
            email,
            tokenProcessingError.message,
          );
        }
        // }
      }
    });
  };

  const getAccessTokenFromRefreshToken = async (
    refreshToken,
    calendar_id,
    calendar_event_id,
    type,
  ) => {
    // alert('inside getAccessTokenFromRefreshToken');

    const decrypted = CryptoJS.AES.decrypt(
      refreshToken,
      ENCRYPTION_KEY,
    ).toString(CryptoJS.enc.Utf8);

    const requestBody = {
      grant_type: 'refresh_token',
      client_id:
        '556170493308-2o3iobkftj6d5ppk7uh1vtc8c3fn84g4.apps.googleusercontent.com',
      client_secret: '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',
      refresh_token: decrypted,
    };

    try {
      const response = await axios.post(
        'https://oauth2.googleapis.com/token',
        requestBody,
      );

      if (type && type === 'delete') {
        // all_calenders_event.map((miniItem, index) => {
        deleteEventFromCalendar(
          response.data.access_token,
          calendar_id,
          calendar_event_id,
        );
        // });
      } else {
        // all_calenders_event.map((miniItem, index) => {
        editEventInCalendar(
          response.data.access_token,
          calendar_id,
          calendar_event_id,
          eventsDataForCalender,
        );
        // });
      }

      // hitAddEvent(response.data.access_token);

      //   return response.data;
    } catch (error) {
      return error;
    }
  };

  const delete_google_calendar_event = id => {
    let payLoad = {
      type_id: id,
      type: 'conference',
    };
    commonApi
      .delete_google_calendar_event(payLoad)
      .then(res => {
        if (res.success === true) {
          // successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const deleteEventFromCalendar = async (token, calendarId, eventId) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      authorization: `Bearer ${token}`,
    };

    try {
      const config = {
        headers,
        method: 'DELETE',
        url: `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  const editEventInCalendar = async (token, calendarId, eventid, event) => {
    // alert('editEventInCalendar');
    const headers = {
      Authorization: `Bearer ${token}`,
      authorization: `Bearer ${token}`,
    };

    try {
      const config = {
        headers,
        data: event,
        method: 'PUT',
        url: `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventid}`,
      };
      await axios(config);

      // return response.data;
      props.onClose();
    } catch (error) {
      return error;
    }
  };

  const ticketTabs =
    allowSponsers === true
      ? [
          labels.sessions_type_general[selected_lang],
          labels.sessions_type_invites[selected_lang],
          'Sponsors',
        ]
      : [
          labels.sessions_type_general[selected_lang],
          labels.sessions_type_invites[selected_lang],
        ];
  const createSessionTESt = (laat, lnng, completeAddress) => {
    const consumerKey = App_key_Dolby;
    const consumerSecret = App_Secret_key_Dolby;

    VoxeetSDK.initialize(consumerKey, consumerSecret);
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded',
        authorization:
          'Basic T1dnUWZuZjE1MVFQTDcwX1NVWVF2Zz09OlVGY2JWQUd2Vmh3LU5RbU5xaEhqM00yT1diazdCdTVPcVhQMkFKYnFVQXM9',
      },
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        expires_in: '86400',
        scope: 'comms:*',
      }),
    };

    fetch('https://api.dolby.io/v1/auth/token', options)
      .then(response => response.json())
      .then(response => {
        const options3 = {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            authorization: 'Bearer ' + response.access_token,
          },
          body: JSON.stringify({
            expiresIn: 86400,
            externalId: 'asad1',
            sessionScope: '*',
          }),
        };

        fetch('https://comms.api.dolby.io/v2/client-access-token', options3)
          .then(response2 => response2.json())
          .then(async response2 => {
            console.log('response2', response2);

            const options1 = {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + response.access_token,
              },
              body: JSON.stringify({
                parameters: {dolbyVoice: true, audioOnly: true},
                alias: 'aaaaaaaaaa132',
                ownerExternalId: 'asad1',
              }),
            };

            fetch('https://comms.api.dolby.io/v2/conferences/create', options1)
              .then(response1 => response1.json())
              .then(response1 => {
                console.log('response1', response1);
              })
              .catch(err => console.error(err));

            console.log('response', response);
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  };

  // const stripeImplementation = () => {

  // }

  let companyData = localStorage.getItem('activeWorkspaceItem')
    ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
    : [];
  const createSession = (laat, lnng, completeAddress) => {
    // stripeImplementation()
    // return;
    setDisableBtn(false);

    setshowLoader(true);
    let recursionTime = topIcons.focus
      ? moment.utc(eventStartDateTime, 'YYYY-MM-DDTHH:mm:ss.SSSN')
      : sessionStartTime === 'Invalid date'
      ? ''
      : moment.utc(date, 'YYYY-MM-DDTHH:mm:ss.SSSN');

    let endDateTime = moment(sessionStartTime)
      .add(sessionEndTime, 'minutes')
      .toString();

    var address = !location ? '' : location.label;

    const dayNameMapping = {
      SU: 'Sunday',
      MO: 'Monday',
      TU: 'Tuesday',
      WE: 'Wednesday',
      TH: 'Thursday',
      FR: 'Friday',
      SA: 'Saturday',
    };
    const fullDayNames = selectedDays.map(
      abbreviatedDay => dayNameMapping[abbreviatedDay],
    );
   

    const ticketArray = removeTicketId(array)
    
    // console.log(ticketArray,'--------->ticketArray')
    var scnd = {
      address: address,
      complete_address: completeAddress,
      ally: topIcons.bullseye ? 'yes' : 'no',
      audience_ids: audience,
      category_id: category,
      conference_id: '',

      event:
        !inPersonOnly && location === ''
          ? 'no'
          : inPersonOnly
          ? 'both'
          : location && 'yes',
      event_id: '',
      latitude: !location ? '' : laat,
      location: location ? 'yes' : 'no',
      longitude: !location ? '' : lnng,
      moderator_ids: modrator,
      // opportunity_id: '',
      paid_status: topIcons.dollar ? 'yes' : 'no',
      price: price,
      city_states: focusLocations,
      // ethnicities: ethnicityId.toString(),
      ethnicities: '',
      // gender: gender.toString(),
      gender: '',
      // otherIdentifires: identiferId.toString(),
      otherIdentifires: '',
      on_boarding_areas: persnalTypesId,
      appointment_types: appointmentId,
      on_boarding_categories: categoriesId,
      recursion: {
        by_day: isRepeat,
        days: fullDayNames,
        exculded_dates: exculdedDates,
        occurrences: occurrences,
        recursion_date_time: recursionTime._i,
        start_again: isRepeat ? 'month' : weekMonth,
        start_again_after: startAgainAfter,
        start_again_week: '',
      },
      session_description: discription,

      end_time: eventEndDateTime
        ? moment(eventEndDateTime).format('YYYY-MM-DD HH:mm:ss')
        : endDateTime === null
        ? ''
        : '',
      // moment(endDateTime).format('YYYY-MM-DD HH:mm:ss'),

      session_start_time: eventStartDateTime
        ? moment(eventStartDateTime).format('YYYY-MM-DD HH:mm:ss')
        : // : sessionStartTime === 'Invalid date'
          // ? ''
          '',
      // sessionStartTime,

      start_time: eventStartDateTime
        ? moment(eventStartDateTime).format('YYYY-MM-DD HH:mm:ss')
        : // : sessionStartTime === 'Invalid date'
          // ? ''
          '',
      // sessionStartTime,

      // session_type: topIcons.vaccinate ? 'private' : 'public',
      session_type: 'public',
      slider_image: sliderImage,
      snapshot_id: hostId,
      snapshot_image: imageCreate,

      status: 'pending',
      ticket_options: ticketArray,
      tickets: ticket,
      title: title,
      video: 'yes',
      opportunity_id: opportunityId,
      question_id: questionId,
      // complete_address:  completeAddress,
      eventbrite_account_id: toggleEventBrite
        ? selectedEventbriteId.toString()
        : '',
      workspace_ids: workSpaceIds.toString(','),

      show_permission:
        invitesOptionvalue === 'Share on The Market'
          ? 'on_the_market'
          : 'invite_only',
      in_person: inPersonOnly,
    };

    commonApi
      .create_session_dolby(scnd)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            setSessionDolby(res.session);
            setDisableBtn(false);

            handleResetForm();

            const link =
              'www.' +
              ENV_HOST +
              'roiforpros.com/' +
              (companyData.length > 0 ? companyData[0].company_name : '') +
              '/' +
              localStorage.getItem('id') +
              '/virtual/invite?id=' +
              res.session.id;
            setSessionLink(link);

            successMessage(res.message);
            setcreatedSessionId(res.session.id);

            if (!date && res.conference_status === 'no') {
              setTimeout(() => {
                setshowLoader(false);
              }, 2000);
              setShowModalSesion(true);
            } else {
              // props.onClose();
              get_calenders();
              // get_google_calenders();
            }
          } else {
            errorMessage(res.message);
            setDisableBtn(true);
          }
        } else {
          // props.onClose();
          errorMessage(res.message);
          setDisableBtn(true);
        }
        setConferenceId(res.conference_id && res.conference_id);
        // setTimeout(() => {
        //   setshowLoader(false);
        // }, 2000);

        props.getApiSessionList(props.sessionType, '', !props.typeIs);
      })
      .catch(err => {
        console.log('Err', err);
        setDisableBtn(true);
        return {type: 'error', message: err.message};
      });
  };

  const getSessionCatData = () => {
    setshowLoader(true);
    commonApi
      .session_categories()
      .then(res => {
        if (res.status === 200) {
          setSessionCategories(res.data);

          setCategory(res.data[0].id);

          setTimeout(() => {
            setshowLoader(false);
          }, 1500);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // let priceButton = props.referSession ? props.referSession.price : '';
  const serverTime = qry => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .check_server_time()
      .then(res => {
        setServerTime(res.server_time);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const getAllUsers = qry => {
  //   var payLoad = {
  //     search_query: qry,
  //   };
  //   API.all_users(payLoad)
  //     .then(res => {
  //       setshowLoader(false);
  //       if (res.status === 200) {
  //         // setFolowers(res.data);
  //         setFolowers(res.data);
  //         setNewFolowers(
  //           res.data.filter(
  //             item =>
  //               !audience.includes(item.id) && !modrator.includes(item.id),
  //           ),
  //         );
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // useEffect(() => {
  //   if (builderSearchQry === "") {
  //     getfolowers();
  //     getSessionCatData();
  //     getAccomplishment();
  //   } else {
  //     // getfolowers(builderSearchQry);
  //     getAllUsers(builderSearchQry);
  //   }
  // }, [builderSearchQry]);

  // useEffect(() => {
  //   // setFolowers(newFolowers.filter((item) => !audience.includes(item.id) && !modrator.includes(item.id)));
  //   setSelectedAudData(
  //     folowers.filter((item) => audience.includes(item.id))
  //   );
  // }, [audience]);

  // useEffect(() => {
  //   // setFolowers(newFolowers.filter((item) => !audience.includes(item.id) && !modrator.includes(item.id)));
  //   setSelectedModData(
  //     folowers.filter((item) => modrator.includes(item.id))
  //   );
  // }, [modrator]);
  // let today = moment(serverTimeS).format('YYYY-MM-DDThh:mm');
  // let maxSessionDate = moment().format('2099-12-30Thh:mm');
  let sessionStartTime = moment(date).format('YYYY-MM-DD HH:mm:ss');

  const dateString = date ? date.toString() : '';
  const timeString = timeStart ? timeStart.toString() : '';
  let eventStartDateTime =
    date && timeStart ? dateString + ' ' + timeString : '';

  const endDateString = date ? date.toString() : '';
  const endTimeString = timeEnd ? timeEnd.toString() : '';
  let eventEndDateTime =
    date && timeEnd ? endDateString + ' ' + endTimeString : '';

  // let minDate = moment(serverTimeS).format('YYYY-MM-DD');
  const clearAll = () => {
    setLocation('');
    setLatitude('');
    setLongitude('');

    $('.css-1uccc91-singleValue').html('Type city and state');
  };
  const clearDate = () => {
    setDate('');
    setTimeStart('');
    setTimeEnd('');
  };
  const clearStartTime = () => {
    setTimeStart('');
  };
  const clearEndTime = () => {
    setTimeEnd('');
  };
  // useEffect(() => {
  //   if (props.showEvent === true) {
  //     setTopIcons({
  //       ...topIcons,
  //       ['focus']: !topIcons.focus,
  //       ['location']: topIcons.focus ? false : true,
  //       ['store']: topIcons.focus ? true : false,
  //     });
  //   }
  // }, [props.showEvent]);

  useEffect(() => {
    if (props.editSession) {
      if (
        props.editSession.paid_status === 'yes' &&
        props.editSession.price !== ''
      ) {
        setPrice(props.editSession.price);
        setTopIcons(prev => ({
          ...prev,
          dollar: true,
        }));
      } else {
        setTopIcons(prev => ({
          ...prev,
          dollar: false,
        }));
      }
      if (props.editSession.session_type === 'private') {
        setTopIcons(prev => ({
          ...prev,
          vaccinate: true,
        }));
      } else {
        setTopIcons(prev => ({
          ...prev,
          vaccinate: false,
        }));
      }
      // const audDataArray =
      //   props.editSession.audience_ids === ''
      //     ? []
      //     : props.editSession.audience_ids.split(',');
      // const moddataArray =
      //   props.editSession.moderator_ids === ''
      //     ? []
      //     : props.editSession.moderator_ids.split(',');
      // setModrator(moddataArray);
      // setAudience(audDataArray);
      setTitle(props.editSession.session_title);
      setCategory(props.editSession.category_id);
      setDiscription(props.editSession.session_description);
      if (props.editSession?.workspace_ids) {
        setWorkSpaceIds(props.editSession.workspace_ids.split(','));
      }

      if (props.editSession?.personal === 'yes') {
        // alert('@@@@@@@@@@@')
        setTimeout(() => {
          setWorkSpaceIds(previous => [...previous, 'personal']);
          // workSpaceIds.push('personal');
        }, 1500);
      }
      // setWorkSpaceIds(props.editSession.workspace_ids.split(','))
      // setLocation(props.editSession.address);
      // setPrice(props.editSession.price);
      setDate(
        props.editSession.session_start_time === ''
          ? ''
          : moment(props.editSession.session_start_time).format(
              'YYYY-MM-DDTHH:mm',
            ),
      );
      if (props.editSession.moderator_ids !== '') {
        if (props.editSession.moderators_list.length > 0) {
          let item = props.editSession.moderators_list;
          item.forEach((miniItem, index) => {
            setModrator(previous => [...previous, miniItem.id]);
            setAlreadyAddedMod(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          });
        }
      }
      if (props.editSession.audience_ids !== '') {
        if (props.editSession.audience_list.length > 0) {
          let item = props.editSession.audience_list;
          item.forEach((miniItem, index) => {
            setAudience(previous => [...previous, miniItem.id]);
            setAlreadyAddedAud(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          });
        }
      }

      if (props.editSession.location === 'yes') {
        setLocation(props.editSession.address);
        setLatitude(props.editSession.latitude);
        setLongitude(props.editSession.longitude);

        setTopIcons(prev => ({
          ...prev,
          location: true,
        }));
      } else {
        setTopIcons(prev => ({
          ...prev,
          location: false,
        }));
      }

      if (props.editSession.allow_ally === 'yes') {
        setEthnicityId(props.editSession.focus_ethnicity);
        setIdentiferId(props.editSession.focus_identifier);
        setGender(props.editSession.focus_gender);
        setFocusLocations(props.editSession.focus_locations);
        setPersnalTypesId(
          props.editSession.on_boarding_areas
            ? props.editSession.on_boarding_areas.split(',')
            : [],
        );
        setAppointmentId(
          props.editSession.appointment_types
            ? props.editSession.appointment_types.split()
            : [],
        );
        setCategoriesId(
          props.editSession.on_boarding_categories
            ? props.editSession.on_boarding_categories.split()
            : [],
        );

        setTopIcons(prev => ({
          ...prev,
          bullseye: true,
        }));
      } else {
        setTopIcons(prev => ({
          ...prev,
          bullseye: false,
        }));
      }
      if (
        props.editSession.snap_shot_image &&
        props.editSession.snap_shot_id &&
        props.editSession.snap_shot_image !== '' &&
        props.editSession.snap_shot_id !== ''
      ) {
        setHostId(props.editSession.snap_shot_id);
        // if (images) {
        //   images.map(item => {
        //
        //     if (hostId === item.id) {
        //       setImageCreate(item.images[0]);
        //     }
        //   });
        // }
        setImageCreate(props.editSession.snap_shot_image);
        setSliderImage([]);
        setSnapShotButton(true);
        setNewButton(true);
      } else {
        setSnapShotButton(false);
      }
      // setTicket(props.referSession.tickets);

      let New = moment(date)
        .subtract(props.editSession.end_time, 'm')
        .toISOString();

      setTimeout(() => {
        setSessionEndTime(New);
      }, 1200);
    }
    //end of sesion edit
    //
    //Just for create session from event
    if (props.createSesion) {
      setTitle(props.createSesion.session_title);
      setCategory(props.createSesion.category_id);
      setDiscription(props.createSesion.session_description);

      if (
        props.createSesion.moderator_ids !== ''
        //  &&
        // props.createSesion.moderator_ids != []
      ) {
        if (
          props.createSesion.moderators_list &&
          props.createSesion.moderators_list.length > 0
        ) {
          let item = props.createSesion.moderators_list;
          item.forEach((miniItem, index) => {
            setModrator(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          });
        } else if (
          props.createSesion.moderator_list &&
          props.createSesion.moderator_list.length > 0
        ) {
          let item = props.createSesion.moderator_list;
          item.forEach((miniItem, index) => {
            if (props.createSesion.creator_id !== miniItem.id) {
              setModrator(previous => [...previous, miniItem.id]);
              setSelectedModData(previous => [...previous, miniItem]);
              setOriginalDataOfMod(previous => [...previous, miniItem]);
            }
          });
        }
      }

      if (
        props.createSesion.audience_ids !== ''
        //  &&
        // props.createSesion.audience_ids != []
      ) {
        if (
          props.createSesion.audience_list &&
          props.createSesion.audience_list.length > 0
        ) {
          let item = props.createSesion.audience_list;
          item.forEach((miniItem, index) => {
            setAudience(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          });
        }
      }
    }
    //End of create session from event

    //Create session from opportunity
    if (props.opportunitySession) {
      setTitle(props.opportunitySession.title);
      // setCategory(props.opportunitySession.opportunity_type_id);

      if (props.opportunitySession.team_member_details.length > 0) {
        let item = props.opportunitySession.team_member_details;
        item.forEach((miniItem, index) => {
          setModrator(previous => [...previous, miniItem.id]);
          setSelectedModData(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        });
      }
      // if (props.opportunitySession.personal_invite_details.length > 0) {
      //   let item = props.opportunitySession.personal_invite_details;
      //   item.map((miniItem, index) => {
      //     setAudience(previous => [...previous, miniItem.id]);
      //     setSelectedAudData(previous => [...previous, miniItem]);
      //   });
      // }
      if (
        props.opportunitySession.other_persons_opportunity_chat_room.length > 0
      ) {
        let item = props.opportunitySession.other_persons_opportunity_chat_room;
        item.forEach((miniItem, index) => {
          setAudience(previous => [...previous, miniItem.id]);
          setSelectedAudData(previous => [...previous, miniItem]);
          setOriginalDataOfAud(previous => [...previous, miniItem]);
        });
      }
    }
    //Create session from QA
    if (props.qaSession) {
      if (props.qaSession.opportunity_id) {
        setOpportunityId(props.qaSession.opportunity_id);
        if (props.qaSession.hasOwnProperty('team_member_details')) {
          if (props.qaSession.team_member_details.length > 0) {
            let item = props.qaSession.team_member_details;
            item.forEach((miniItem, index) => {
              setModrator(previous => [...previous, miniItem.id]);
              setSelectedModData(previous => [...previous, miniItem]);
            });
          }
          if (props.qaSession.other_persons_opportunity_chat_room.length > 0) {
            let item = props.qaSession.other_persons_opportunity_chat_room;
            item.forEach((miniItem, index) => {
              setAudience(previous => [...previous, miniItem.id]);
              setSelectedAudData(previous => [...previous, miniItem]);
            });
          }
        } else {
          if (opportunityDetail.team_member_details.length > 0) {
            let item = opportunityDetail.team_member_details;
            item.forEach((miniItem, index) => {
              setModrator(previous => [...previous, miniItem.id]);
              setSelectedModData(previous => [...previous, miniItem]);
              // setOriginalDataOfMod(previous => [...previous, miniItem]);
            });
          }
          if (
            opportunityDetail.other_persons_opportunity_chat_room.length > 0
          ) {
            let item = opportunityDetail.other_persons_opportunity_chat_room;
            item.forEach((miniItem, index) => {
              setAudience(previous => [...previous, miniItem.id]);
              setSelectedAudData(previous => [...previous, miniItem]);
              // setOriginalDataOfAud(previous => [...previous, miniItem]);
            });
          }
        }
      } else {
        setQuestionId(props.qaSession.id);
        if (
          props.qaSession.moderator_detail.length > 0 &&
          props.qaSession.moderator_ids !== ''
        ) {
          let item = props.qaSession.moderator_detail;
          item.forEach((miniItem, index) => {
            setModrator(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          });
        }
        if (
          props.qaSession.audience_detail.length > 0 &&
          props.qaSession.audience_ids !== ''
        ) {
          let item = props.qaSession.audience_detail;
          item.forEach((miniItem, index) => {
            setAudience(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          });
        }
      }
    }

    //event edit

    if (props.editEvent) {
      setTicket(props.editEvent.tickets);

      if (props.editEvent?.workspace_ids) {
        setWorkSpaceIds(props.editEvent.workspace_ids.split(','));
      }

      if (props.editEvent?.personal === 'yes') {
        setTimeout(() => {
          setWorkSpaceIds(previous => [...previous, 'personal']);
        }, 1500);
      }
      // if (
      //   props.editEvent.paid_status === 'yes' &&
      //   props.editEvent.price !== ''
      // ) {
      //   setPrice(props.editEvent.price);
      //   setTopIcons(prev => ({
      //     ...prev,
      //     ['dollar']: true,
      //   }));
      // }
      if (props.editEvent.session_type === 'private') {
        setTopIcons(prev => ({
          ...prev,
          vaccinate: true,
        }));
      }
      setTopIcons({
        ...topIcons,
        focus: !topIcons.focus,
        location: topIcons.focus ? false : true,
        store: topIcons.focus ? true : false,
      });

      setTitle(props.editEvent.session_title);
      setCategory(props.editEvent.category_id);
      setDiscription(props.editEvent.session_description);

      setDate(
        props.editEvent.session_start_time === ''
          ? ''
          : moment(props.editEvent.session_start_time).format(
              'YYYY-MM-DDTHH:mm',
            ),
      );
      if (props.editEvent.moderator_ids !== '') {
        if (props.editEvent.moderators_list.length > 0) {
          let item = props.editEvent.moderators_list;
          item.forEach((miniItem, index) => {
            setModrator(previous => [...previous, miniItem.id]);
            setAlreadyAddedMod(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          });
        }
      }
      if (props.editEvent.audience_ids !== '') {
        if (props.editEvent.audience_list.length > 0) {
          let item = props.editEvent.audience_list;
          item.forEach((miniItem, index) => {
            setAudience(previous => [...previous, miniItem.id]);
            setAlreadyAddedAud(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          });
        }
      }

      setLocation(props.editEvent.address);
      setLatitude(props.editEvent.latitude);
      setLongitude(props.editEvent.longitude);

      if (props.editEvent.allow_ally === 'yes') {
        setEthnicityId(props.editEvent.focus_ethnicity);
        setIdentiferId(props.editEvent.focus_identifier);
        setGender(props.editEvent.focus_gender);
        setFocusLocations(props.editEvent.focus_locations);
        setPersnalTypesId(
          props.editEvent.on_boarding_areas
            ? props.editEvent.on_boarding_areas.split(',')
            : [],
        );
        setAppointmentId(
          props.editEvent.appointment_types
            ? props.editEvent.appointment_types.split(',')
            : [],
        );
        setCategoriesId(
          props.editEvent.on_boarding_categories
            ? props.editEvent.on_boarding_categories.split(',')
            : [],
        );
        setTopIcons(prev => ({
          ...prev,
          bullseye: true,
        }));
      }
      if (props.editEvent.paid_status === 'yes') {
        setTopIcons(prev => ({
          ...prev,
          dollar: true,
        }));
      }
      if (props.editEvent.snap_shot_image && props.editEvent.snap_shot_id) {
        setImageCreate(props.editEvent.snap_shot_image);

        setSnapShotButton(true);
        setNewButton(true);
        setHostId(props.editEvent.snap_shot_id);
      }
      // setTicket(props.referSession.tickets);

      setSliderImage(
        props.editEvent?.image?.slider_images
          ? [props.editEvent?.image?.slider_images]
          : [],
      );
      setTicketImg(
        props.editEvent?.image?.slider_images
          ? props.editEvent?.image?.slider_images
          : '',
      );
      let New = moment(date)
        .subtract(props.editEvent.end_time, 'm')
        .toISOString();

      setTimeout(() => {
        setSessionEndTime(New);
      }, 1200);
    }

    if (props.editSession || props.editEvent) {
      setEventId(
        props.referType
          ? props.editSession.refer_type_id
          : !props.referType && props.editSession
          ? props.editSession.id
          : props.typeOfReferEvent === true
          ? props.editEvent.refer_type_id
          : props.editEvent.id,
      );

      setCreaterId(
        props.referType
          ? props.editSession.creator_id
          : !props.referType && props.editSession
          ? props.editSession.creator_id
          : props.typeOfReferEvent === true
          ? props.editEvent.creator_id
          : props.editEvent.creator_id,
      );
    }

    if (props.appointment && props.appointmentDetail) {
      setTitle(props.appointmentDetail.title);
      setDiscription(props.appointmentDetail.description);
      if (props.appointmentDetail.cost !== '' && props.appointmentDetail) {
        setTopIcons({
          ...topIcons,
          dollar: !topIcons.dollar,
        });
        setTimeout(() => {
          $('.css-1wa3eu0-placeholder').css('color', 'black');
        }, 1000);
        setPrice(props.appointmentDetail.cost);
      }
      if (props.builders && props.builders.length > 0) {
        // alert('QQQQQQ');
        let item = props.builders;
        item.forEach((miniItem, index) => {
          setModrator(previous => [...previous, miniItem.id]);
          setSelectedModData(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        });
      }
      const formattedEndTime = moment(
        props.appointment.end_time,
        'HH:mm',
      ).format('hh:mm');
      if (props.location) {
        setTopIcons({
          ...topIcons,
          location: !topIcons.location,
          focus: !topIcons.focus,
        });
        setLocation(props.location);

        setDate(moment(props.appointment.new_start_time).format('YYYY-MM-DD'));
        setTimeEnd(formattedEndTime);

        setTimeStart(moment(props.appointment.new_start_time).format('hh:mm'));
      } else {
        setDate(moment(props.appointment.new_start_time).format('YYYY-MM-DD'));
        setTimeEnd(formattedEndTime);

        setTimeStart(moment(props.appointment.new_start_time).format('hh:mm'));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editSession, props.editEvent]);

  // useEffect(() => {
  //   setNewFolowers(
  //     folowers.filter(
  //       item => !audience.includes(item.id) && !modrator.includes(item.id),
  //     ),
  //   );
  // }, [audience, modrator]);

  const CircleUserListData = item => {
    if (item.length > 0) {
      if (subTabs === 'moderator') {
        item.forEach((miniItem, index) => {
          if (
            !modrator.includes(miniItem.id) &&
            !audience.includes(miniItem.id)
          ) {
            setModrator(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          }
        });
      }
      if (subTabs === 'audience') {
        item.forEach((miniItem, index) => {
          if (
            !audience.includes(miniItem.id) &&
            !modrator.includes(miniItem.id)
          ) {
            setAudience(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          }
        });
      }
    }
  };

  const handleCircle = () => {
    setShowComponent('Builders');
  };

  const implementRecurrenceRule = startAgainWeek => {
    let rule = [];
    selectedDays.forEach(day => {
      const data = startAgainWeek + day;
      rule.push(data);
    });

    return rule.toString();
  };
  const EventData = () => {
    if (props.editSession || props.editEvent) {
      var StartTime = moment(
        topIcons.focus
          ? props.editEvent.session_start_time
          : props.editSession.session_start_time,
      ).format('HH:mm');
      var dateOfEvent = moment(
        topIcons.focus
          ? props.editEvent.session_start_time
          : props.editSession.session_start_time,
      ).format('YYYY-MM-DD');

      var EndTime = moment(
        topIcons.focus ? props.editEvent.end_time : props.editSession.end_time,
      ).format('HH:mm');

      // var Title = topIcons.focus
      // ? props.editEvent.session_title
      // : props.editSession.session_title

      eventsDataForCalender = {
        summary: title,
        location: '',
        start: {
          dateTime: convertTimeIntoDateTime(
            new Date(dateOfEvent),
            StartTime,
          ).toISOString(),
          timeZone: 'America/New_York',
        },
        end: {
          dateTime: convertTimeIntoDateTime(
            new Date(dateOfEvent),
            EndTime,
          ).toISOString(),
          timeZone: 'America/New_York',
        },
        visibility: 'public',
        recurrence:
          selectedDays.length > 0
            ? [
                `RRULE:FREQ=${
                  !isRepeat ? 'WEEKLY' : 'MONTHLY'
                };COUNT=${occurrences};${
                  !isRepeat
                    ? 'BYDAY=' + selectedDays.toString()
                    : 'BYDAY=' + implementRecurrenceRule(startAgainAfter)
                }`,
              ]
            : [],
        attendees: [],
        reminders: {
          useDefault: false,
          overrides: [
            {method: 'email', minutes: 24 * 60},
            {method: 'popup', minutes: 10},
          ],
        },
      };
    } else {
      setevents({
        summary: title,
        location: '',
        description: discription,
        start: {
          dateTime: convertTimeIntoDateTime(
            new Date(date),
            timeStart,
          ).toISOString(),
          timeZone: 'America/New_York',
        },
        end: {
          dateTime: convertTimeIntoDateTime(
            new Date(date),
            timeEnd,
          ).toISOString(),
          timeZone: 'America/New_York',
        },
        visibility: 'public',
        recurrence:
          selectedDays.length > 0
            ? [
                `RRULE:FREQ=${
                  !isRepeat ? 'WEEKLY' : 'MONTHLY'
                };COUNT=${occurrences};${
                  !isRepeat
                    ? 'BYDAY=' + selectedDays.toString()
                    : 'BYDAY=' + implementRecurrenceRule(startAgainAfter)
                }`,
              ]
            : [],
        attendees: [],
        reminders: {
          useDefault: false,
          overrides: [
            {method: 'email', minutes: 24 * 60},
            {method: 'popup', minutes: 10},
          ],
        },
      });
    }
  };

  const get_calenders = () => {
    var payload = {
      // type: 'outlook',
    };
    commonApi
      .get_all_calendars(payload)
      .then(response => {
        if (response.all_calendars.length > 0) {
          
          setCalendarData(response.all_calendars);
          setShowModel('calneder');
          setAccountType('outlook');
          setTimeout(() => {
            setshowLoader(false);
          }, 400);
        } else {
          if(props.appointment && props.onCloseHitApi){
            props.onCloseHitApi()
          }
          props.onClose();
          
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_google_calenders = () => {
    var payload = {
      type: 'google',
    };
    commonApi
      .get_all_calendars(payload)
      .then(response => {
        if (response.all_calendars.length > 0) {
          setCalendarData(response.all_calendars);
          setShowModel('calneder');
        } else {
          props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  let maxDate = moment().format('2099-12-30');
  // event time check
  const momentObj = moment(serverTimeS, 'YYYY-MM-DD HH:mm:ss');
  const outputString = momentObj.format('HH:mm').replace(/^12/, '00');

  // session  time check
  // const momentObjSession = serverTimeS;
  // const formattedDateTimeSession = momentObjSession.format("YYYY-MM-DDTHH:mm");
  // const formattedDateTimeSession = moment(momentObjSession).format(
  //   'YYYY-MM-DDT' +
  //     moment(momentObjSession).format('HH:mm').replace(/^12/, '00'),
  // );

  let startTimeOfeventServer =
    moment(date).format('YYYY-MM-DD') ===
    moment(serverTimeS).format('YYYY-MM-DD')
      ? outputString
      : '';

  // const formattedTime = getTimeFormatForChat(topIcons.focus
  //   ? props.editEvent.end_time
  //   : props.editSession.end_time)

  // const formattedTime = moment(topIcons.focus
  //   ? props.editEvent.end_time
  //   : props.editSession.end_time).format("HH:mm");

  // const Recussion = {
  //   recurrence:
  //     selectedDays.length > 0
  //       ? [
  //           `RRULE:FREQ=${
  //             !isRepeat ? 'WEEKLY' : 'MONTHLY'
  //           };COUNT=${occurrences};${
  //             !isRepeat
  //               ? 'BYDAY=' + selectedDays.toString()
  //               : 'BYDAY=' + implementRecurrenceRule(startAgainAfter)
  //           }`,
  //         ]
  //       : [],
  // };

  const addEventsFromEventbrite = check => {
    setToggleEventBrite(!toggleEventBrite);
    // eventBrite.current = !eventBrite.current

    if (check) {
      get_eventbrite_accounts();
    }
  };

  const eventBriteLogin = () => {
    const userId = localStorage.getItem('id');
    const apiUrl = `https://www.eventbrite.com/oauth/authorize?response_type=code&client_id=OTQSAZRMDWDVRSPIVD&state=${userId},
    )}`;

    const popupWidth = 700; // Set the desired width
    const popupHeight = 600; // Set the desired height
    const left = (window.innerWidth - popupWidth) / 2;
    const top = (window.innerHeight - popupHeight) / 2;
    // Open the popup window with the specified dimensions and URL
    const popup = window.open(
      apiUrl,
      '',
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
    );

    // Create a setInterval() loop to check if the popup window is still open
    const interval = setInterval(() => {
      if (!popup || popup.closed) {
        check_eventbrite_accounts();
        get_eventbrite_accounts();

        // Clear the setInterval() loop
        clearInterval(interval);

        // Perform any necessary cleanup tasks or update state in your React app
      }
    }, 1000);
  };

  const get_eventbrite_accounts = () => {
    // setshowLoader(true);
    var Payload = {
      token: localStorage.getItem('token'),
    };
    commonApi
      .get_eventbrite_accounts(Payload)
      .then(res => {
        if (res.status === 200) {
          if (res.account_details.length === 1) {
            setEventBriteEvents(true);

            setSelectedEventbriteId(res.account_details[0].id);
            getEventsBriteEventsData(res.account_details[0].id);
          } else if (res.account_details.length > 1) {
            setShowModel('eventbrite-model');
            setEventBriteList(res.account_details);
          } else {
            if (!checkEventBritelogin) {
              // errorMessage('You do not have linked your eventBrite Account');
              setShowModalEventbrite(true);
            }
          }
          // setEventBriteList(res.account_details);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getEventsBriteEventsData = id => {
    var Payload = {
      user_id: localStorage.getItem('id').toString(),
      account_id: id.toString(),
    };

    commonApi
      .get_events_against_user_accounts(Payload)
      .then(res => {
        if (res.status === 200) {
          setEventBriteEventsData(res.data.events);
          setEventBriteCount(res.count);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const check_eventbrite_accounts = () => {
    // setshowLoader(true);
    var Payload = {
      token: localStorage.getItem('token'),
    };
    commonApi
      .get_eventbrite_accounts(Payload)
      .then(res => {
        if (res.status === 200) {
          if (res.account_details.length > 0) {
          } else {
            setCheckEventBritelogin(false);
            setToggleEventBrite(!toggleEventBrite);
          }
          // setEventBriteList(res.account_details);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleEventbriteItem = item => {
    // if (!modelId.includes(item.id)) {
    setModelId([item.id]);
    setModelItem([item]);
    // } else {
    //   setModelId(previous => previous.filter(id => id !== item.id));
    //   setModelItem(previous =>
    //     previous.filter(miniItem => miniItem.id !== item.id),
    //   );
    // }
  };

  const AddEventBriteAccountId = item => {
    setEventBriteEvents(true);
    setSelectedEventbriteId(item[0]);
    getEventsBriteEventsData(item[0]);
    setShowModel('');
  };

  const handleInviteOption = e => {
    // const {id, checked} = e.target;
    // setOpportunity([...opportunity, id]);
    setInvitesOptionvalue(e.target.value);
  };

  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  console.log(
    moment(timeStart, 'HH:mm:ss'),
    'moment(timeStart)moment(timeStart)',
  );
  console.log(timeStart, 'timeStart');
  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
       
          <img src={RoiLogo} alt="" />
      
      </>
    );
  };

  return (
    <>
     

      <SlidingPanel
       renderData={renderProfileTagsButtons}
       showBlackHeader={true}
       lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
       firstDivStyle={{
         padding: 0,
         background:
           'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
       }}
        hidePanel={() => {
          props.onClose();
        }}>
        {/* <Elements stripe={stripePromise} options={{
          mode: 'payment',
          amount: 10 * 100,
          currency: 'usd',
          paymentMethodTypes: ['card'],
          payment_method_types: ['card']
        }}>
          <CheckoutForm />
        </Elements> */}
        {/* <Button onClick={()=>{handleshowData();}}>Daniyal</Button> */}
        <div className="serach-filter-wrapper create-sessionEvents">
          {/* <div className="ticket-icons-options">
            <ul>
              
              {!props.hideEvent && !props.editSession && (
                <li
                

                  className={
                    topIcons.location &&
                    topIcons.focus &&
                    !props.editEvent &&
                    !props.editSession
                      ? 'active'
                      : topIcons.location &&
                        topIcons.focus &&
                        (props.editEvent || props.editSession)
                      ? 'disabled-Class_3'
                      : (props.editEvent || props.editSession) &&
                        'disabled-Class_2'
                  }
                  onClick={() => {
                    {
                    

                      if (
                        props.editEvent &&
                        Object.entries(props.editEvent).length > 0
                      ) {
                      } else {
                        setShowModelDecesion('focus');
                        setShowModalButtons(true);
                      }
                    }
                  }}>
                  <i className="icon-tickets" />
                </li>
              )}
              <li
               
                className={
                  topIcons.location && !props.editEvent && !props.editSession
                    ? 'active'
                    : topIcons.location &&
                      (props.editEvent || props.editSession)
                    ? 'disabled-Class_3'
                    : (props.editEvent || props.editSession) &&
                      'disabled-Class_2'
                }
                onClick={() => {
                  {
                    if (
                      (props.editEvent &&
                        Object.entries(props.editEvent).length > 0) ||
                      (props.editSession &&
                        Object.entries(props.editSession).length > 0)
                    ) {
                    } else {
                      setShowModelDecesion('location');
                      setShowModalButtons(true);
                    }
                  }
                
                }}
                disabled={props.eventData ? true : false}>
                <i className="fas fa-map-marker-alt" />
              </li>
              <li
               
                className={
                  topIcons.dollar && !props.editEvent && !props.editSession
                    ? 'active'
                    : topIcons.dollar && (props.editEvent || props.editSession)
                    ? 'disabled-Class_3'
                    : (props.editEvent || props.editSession) &&
                      'disabled-Class_2'
                }
                onClick={() => {
                  {
                    if (
                      (props.editEvent &&
                        Object.entries(props.editEvent).length > 0) ||
                      (props.editSession &&
                        Object.entries(props.editSession).length > 0)
                    ) {
                    } else {
                      setShowModelDecesion('dollar');
                      setShowModalButtons(true);
                      setPrice('');
                    }
                  }
                }}>
                <i className="icon-dollar-sign" />
              </li>
              <li
                className={
                  topIcons.vaccinate && !props.editEvent && !props.editSession
                    ? 'active'
                    : topIcons.vaccinate &&
                      (props.editEvent || props.editSession)
                    ? 'disabled-Class_3'
                    : (props.editEvent || props.editSession) &&
                      'disabled-Class_2'
                }
                onClick={() => {
                  {
                    if (
                      (props.editEvent &&
                        Object.entries(props.editEvent).length > 0) ||
                      (props.editSession &&
                        Object.entries(props.editSession).length > 0)
                    ) {
                    } else {
                      setShowModelDecesion('visibility');
                      setShowModalButtons(true);
                    }
                  }
                }}>
                <i className="fas fa-lock" />
              </li>

              <li
                className={snapShotButton ? 'disabled-Class_3' : ''}
                onClick={() => {
                  if (hostId) {
                  } else {
                   
                    setShowModelDecesion('snapshot');
                    setShowModalButtons(true);
                  }
                }}>
                <i className="icon-store  "></i>
              </li>
            
            </ul>
          </div> */}

          <div className="m-0 w-100 d-flex align-items-center justify-content-between fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right">
            <div className="d-flex">
              <p className="p-bold m-0 f_600_h">
                {' '}
                {props.showEvent && !props.editSession && !props.editEvent
                  ? 'Create Meetups'
                  : !props.showEvent && !props.editSession && !props.editEvent
                  ? 'Create Meetups'
                  : !props.showEvent && !props.editSession && props.editEvent
                  ? 'Update Meetups'
                  : !props.showEvent &&
                    props.editSession &&
                    !props.editEvent &&
                    'Update Meetups'}
              </p>
            </div>
            <div className="d-flex">
              {props.editSession && (
                <span
                  className="cancel-btn as-link text-yellow"
                  onClick={() => {
                    setModalEndEvent(true);
                  }}>
                  Cancel meetups
                </span>
              )}
              {props.editEvent && (
                <span
                  className="cancel-btn as-link"
                  onClick={() => {
                    setModalEndEvent(true);
                  }}>
                  Cancel meetups
                </span>
              )}
            </div>
          </div>

          <div className="custom_tabs d-flex align-items-center justify-content-center w-100">
            <div className="create-ticket-tabs ">
              <CustomTabs
                darkmode
                tabs={ticketTabs}
                active={tabType}
                onSelect={item => {
                  setTabType(item);
                  if (
                    item === labels.sessions_type_general[selected_lang] &&
                    inviteQry !== ''
                  ) {
                    setInviteFollowers(followersList);
                    setInviteQry('');
                  }
                }}
              />
            </div>
          </div>
          {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
          <Scrollbars
            className="custom-scroll"
            onScroll={() => {
              setStartPickerOpen(false);
              setEndPickerOpen(false);
            }}>
            <div className="create-ticket-form  fixed-width-inputs black-head-form-pad-left black-head-form-pad-right create-ticket-form-padd-bottom">
      
              <Form noValidate validated={validated} onSubmit={handle}>
                {tabType === labels.sessions_type_general[selected_lang] && (
                  <>
                    <div className="fieldset ">
                      <div className="d-flex align-items-center justify-content-between">
                        <Form.Label className="form_label">Category</Form.Label>
                        {/* {topIcons.focus && ( */}
                        <div className="toogle_btn">
                          <Form.Check
                            // disabled={props.id ? true : false}
                            className=""
                            type="switch"
                            id="custom-switch"
                            label={'Eventbrite'}
                            value={toggleEventBrite ? true : false}
                            checked={toggleEventBrite ? true : false}
                            onClick={() => {
                              setSelectedEventbriteId();
                              addEventsFromEventbrite(!toggleEventBrite);
                              setEventBriteEvents(false);
                            }}
                          />
                        </div>
                        {/* <div className="">
                          <Form.Check
                            className=""
                            type="switch"
                            id="custom-switch"
                            label={'Collaborate'}
                          value={toggleEventBrite ? true : false}
                          checked={toggleEventBrite ? true : false}
                          onClick={() => {
                            setSelectedEventbriteId();
                            addEventsFromEventbrite(!toggleEventBrite);
                            setEventBriteEvents(false);
                          }}
                          />
                        </div> */}

                        {/* )} */}
                      </div>
                      {/* <div className="select-wrapper ">
                        <select
                          value={category}
                          onChange={e => {
                            setCategory(e.target.value);
                          }}>
                          {Object.entries(sessionCategories).length > 0 &&
                            sessionCategories.map((item, index) => (
                              <option
                                id={item.id}
                                value={item.id}
                                key={`sessionCategories-${index}`}>
                                {item.ses_category_name}
                              </option>
                            ))}
                        </select>
                      </div> */}

                      <CustomDropdown
                        setDropdownId={setCategory}
                        dropdownId={category}
                        DropDownValues={sessionCategories}
                        itemName={['ses_category_name']}
                        className={'mb-3 recruitment_dropdown'}
                      />
                    </div>

                    <div className="fieldset">
                      <div className="d-flex align-items-center justify-content-between">
                        <Form.Label
                          className="w-auto form_label"
                          controlid="validationCustom01">
                          Title
                        </Form.Label>
                        <div className="toogle_btn">
                          <Form.Check
                            label={'Allow Sponsors'}
                            className=""
                            type="switch"
                            id="custom-switcht"
                            value={allowSponsers ? true : false}
                            checked={allowSponsers ? true : false}
                            onClick={() => {
                              setAllowSponsers(!allowSponsers);
                            }}
                          />
                        </div>
                        {/* {topIcons.focus && eventBriteEvents && ( */}
                        {/* <div className="text-right">
                          <Form.Label
                            className="text-yellow as-link"
                            controlid="validationCustom01"
                            onClick={() => {
                              setShowComponent('eventbrite');
                            }}>
                            Import From Eventbrite{' '}
                            {eventBriteCount > 0 && (
                              <span> ({eventBriteCount}) </span>
                            )}
                          </Form.Label>
                        </div> */}
                        {/* )} */}
                      </div>
                      <Form.Control
                        type="text"
                        value={title}
                        //  pattern="[^' ']+"
                        // pattern="[a-zA-Z0-9+]"
                        // pattern="[^\s ]+"
                        //  pattern="^[^-\s][\w\s-]+$"
                        pattern="^\S.*$"
                        required
                        placeholder="Choose something catchy"
                        onChange={e => {
                          setTitle(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Title is required.
                      </Form.Control.Feedback>
                    </div>
                    {/* {snapShotButton && ( */}
                    {/* <div className="invite-persons-wrapper d-flex align-items-center justify-content-between">
                      <div className="fieldset">
                        {Object.entries(images).length > 0 &&
                          images.map((item, index) => (
                            <div key={`idimage-${index}`}>
                              {item.id === hostId ? (
                                <div className="profile-img upload-user-img">
                                  <img
                                    src={
                                      item.id === hostId
                                        ? imageCreate
                                        : imageCreate
                                    }
                                    alt=""
                                    onChange={() => {
                                      setImageCreate(
                                        item.id === hostId
                                          ? imageCreate
                                          : imageCreate,
                                      );
                                    }}
                                  />
                                  <span className="icon icon-camera"></span>
                                  <input
                                    type="file"
                                    name="my-image"
                                    id="image"
                                    accept="image/*"
                                    onChange={e => {
                                      changeTicketImgSnapSHot(e);
                                      e.target.value = null;
                                    }}
                                  />
                                </div>
                              ) : (
                              
                                <></>
                              )}
                            </div>
                          ))}
                      </div>
                      <div className="fieldset w-75">
                        <div className="select-wrapper">
                          <select
                            value={hostId}
                            onChange={e => {
                              setHostId(e.target.value);
                              setChange(!change);
                            }}>
                            {Object.entries(images).length > 0 ? (
                              Object.entries(images).length > 0 &&
                              images.map((item, index) => (
                                <option value={item.id} key={`cat-${index}`}>
                                  {item.title}
                                </option>
                              ))
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                      </div>
                    </div> */}
                    {/* )} */}
                    <div className="fieldset">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label>
                          {/* && !props.eventData */}
                          {!topIcons.focus && !props.editEvent
                            ? ' Description (Optional)'
                            : ' Description'}
                        </Form.Label>
                      </div>
                      {/* || props.eventData */}
                      <Form.Control
                        required={topIcons.focus}
                        value={discription}
                        as="textarea"
                        rows={6}
                        type="text"
                        placeholder="Write description... "
                        onChange={e => {
                          setDiscription(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Description is required.
                      </Form.Control.Feedback>
                    </div>

                    {topIcons.location &&
                      !props.editEvent &&
                      !props.editSession && (
                        <div className="fieldset">
                          <div className="d-flex align-items-center justify-content-between mt-2">
                            <Form.Label className="form_label">
                              Location
                            </Form.Label>

                            <div className="toogle_btn">
                              <Form.Check
                                label={'In-Person & Virtual'}
                                className=""
                                type="switch"
                                id="custom-switchy"
                                value={inPersonOnly ? true : false}
                                checked={inPersonOnly ? true : false}
                                onClick={() => {
                                  setInPersonOnly(!inPersonOnly);
                                }}
                              />
                            </div>
                          </div>

                          {/* <div className="form-control-with-icon"></div> */}
                          <div className="location-field form-control form-control-with-icon create_session">
                            <GooglePlacesAutocomplete
                              location
                              selectProps={{
                                location,
                                onChange: setLocation,
                                name: 'city',
                                placeholder: jugar,
                                noOptionsMessage: () => null,
                              }}
                            />

                            {location !== '' && (
                              <span
                                className="location_clear_icon"
                                onClick={() => {
                                  clearAll();
                                }}>
                                <i
                                  className="fas fa-close"
                                  // style={{paddingLeft: '210px'}}
                                />
                              </span>
                            )}
                          </div>

                          {showError === true &&
                          location === '' &&
                          inPersonOnly ? (
                            <div className="text-danger textforerror">
                              Location is required.
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    {/* !props.eventData && */}
                    {/* {!topIcons.focus && !props.editEvent && (
                      <>
                        <div className="fieldset">
                          <div className="d-flex justify-content-between align-items-center location-icons">
                            <Form.Label>Date/Time (Optional)</Form.Label>
                            <div className="d-flex">
                              {sessionStartTime &&
                                sessionStartTime !== 'Invalid date' &&
                                !props.editSession && (
                                  <span
                                    className="icon clear-btn check-time"
                                    style={{
                                      inlineSize: '-webkit-fill-available',
                                      textAlignLast: 'end',
                                    }}
                                    onClick={() => {
                                      checkDateTime();
                                    }}>
                                    Check time
                                  </span>
                                )}
                              {date !== '' && !props.editSession && (
                                <>
                                  <span
                                    className="mr-2 icon clear-btn"
                                    onClick={() => {
                                      if (!props.editSession) {
                                        setDate('');
                                        setClockButton(false);
                                      }
                                    }}>
                                    Clear
                                  </span>
                                  <span
                                    className="icon fa-solid fa-clock"
                                    disabled={props.editSession ? true : false}
                                    onClick={() => {
                                      if (!props.editSession) {
                                        setClockButton(!clockButton);
                                      }
                                    }}></span>
                                </>
                              )}
                              {!props.editSession && (
                                <span
                                  className="icon fas fa-sync-alt"
                                  disabled={props.editSession ? true : false}
                                  onClick={() => {
                                    if (!props.editSession) {
                                      setRepeatOption(!repeateOption);
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <Form.Control
                            required={date !== '' ? true : false}
                            value={date}
                            type="datetime-local"
                            id="datemin"
                            name="datemin"
                            min={formattedDateTimeSession}
                            max={maxSessionDate}
                            placeholder="Select date "
                            disabled={props.editSession ? true : false}
                            onChange={e => {
                              setDate(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {date === ''
                              ? 'Date is required.'
                              : 'Invalid date.'}
                          </Form.Control.Feedback>
                          
                        </div>
                        <div className="fieldset">
                          {clockButton === true && (
                            <div className="select-wrapper">
                              <select
                                onChange={e => {
                                  setSessionEndTime(e.target.value);
                                }}>
                                {Object.entries(clockArray).length > 0 ? (
                                  Object.entries(clockArray).length > 0 &&
                                  clockArray.map((item, index) => (
                                   
                                    <option
                                      value={item.value}
                                      key={`seds-${index}`}>
                                      {item.time}
                                    </option>
                                  ))
                                ) : !showLoader ? (
                                  <>No Data Found</>
                                ) : (
                                  <></>
                                )}
                              </select>
                            </div>
                          )}
                        </div>
                      </>
                    )} */}
                    {/* !props.eventData && */}
                    {!props.editEvent && !props.editSession && (
                      <div className="fieldset">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <Form.Label className="mb-0">
                            Date {inPersonOnly || location ? '' : '(Optional)'}
                          </Form.Label>
                          <div className="d-flex align-items-center">
                            {/* {eventStartDateTime && timeStart && (
                              <span
                                className="check-time"
                                style={{
                                  inlineSize: '-webkit-fill-available',
                                  textAlignLast: 'end',
                                }}
                                // className={'as-link clear-btn'}
                                onClick={() => {
                                  checkDateTime();
                                }}>
                                Check time
                              </span>
                            )} */}
                            {/* {date !== '' && (
                              <span
                                className="clear-btn"
                                onClick={() => {
                                  clearDate();
                                }}>
                                Clear
                              </span>
                            )} */}
                            <span
                              className="clear-btn margin"
                              onClick={() => setRepeatOption(!repeateOption)}>
                              Repeat
                            </span>

                            <span
                              className="fas fa-sync-alt margin"
                              onClick={() => setRepeatOption(!repeateOption)}
                            />
                          </div>
                        </div>

                        <div className="form-control-with-icon">
                          {/* <Form.Control
                            required={inPersonOnly || location ? true : false}
                            value={date}
                            min={moment(serverTimeS).format('YYYY-MM-DD')}
                            max={maxDate}
                            name="startDate"
                            type="date"
                            placeholder="Select date "
                            // disabled={!topIcons.focus && date == '' ? true : false}
                            onChange={e => {
                              setDate(e.target.value);
                            }}
                          />

                          <Form.Control.Feedback type="invalid">
                            {date === ''
                              ? 'Date is required.'
                              : 'Invalid date.'}
                          </Form.Control.Feedback> */}

                          
                   <Calender 
                        minDate={serverTimeS}
                        maxDate={getMaxDateFromToday}
                        setSelectedDate={(selectedDate) => {
                          if(selectedDate){
                          const formattedDate = selectedDate.toISOString().split('T')[0];
                          setDate(formattedDate); // Set the formatted date
                          }else{
                            setDate('')
                          }
                      }}
                      selectedDate={date ? parseISO(date) : null} // Use parseISO to convert to Date
                      showDate={true} 
                        showTime={false}
                        type='meetups'
                        />

                          {date !== '' && (
                            <span
                              className="input-icon"
                              onClick={() => {
                                clearDate();
                              }}>
                              <i
                                className="fas fa-close"
                                style={{paddingLeft: '210px'}}
                              />
                            </span>
                          )}
                        </div>

                        {/* <Form.Control
                          required={topIcons.focus ? true : false}
                          value={date}
                          min={moment(serverTimeS).format('YYYY-MM-DD')}
                          max={maxDate}
                          name="startDate"
                          type="date"
                          placeholder="Select date "
                          onChange={e => {
                            setDate(e.target.value);
                          }}
                          
                        /> */}
                      </div>
                    )}
                    {!props.editEvent && !props.editSession && (
                      <>
                        {props.eventData}
                        <div className="fieldset">
                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label>
                              Start Time{' '}
                              {inPersonOnly || location ? '' : '(Optional)'}
                            </Form.Label>
                            {/* <div className="d-flex align-items-center">
                              {timeStart !== '' && (
                                <span
                                  className="clear-btn"
                                  onClick={() => {
                                    clearStartTime();
                                  }}>
                                  Clear
                                </span>
                              )}
                            </div> */}
                          </div>

                          <div className="form-control-with-icon create_session_timepicker">
                            {/* <Form.Control
                              required={inPersonOnly || location ? true : false}
                              disabled={date === '' ? true : false}
                              value={timeStart}
                              min={startTimeOfeventServer}
                              name="startTime"
                              type="time"
                              placeholder="Start Time"
                              onChange={e => {
                                setTimeStart(e.target.value);
                              }}
                            /> */}

                            {/* <TimePicker
                              showSecond={false}
                              value={
                                timeStart === ''
                                  ? ''
                                  : moment(timeStart, 'HH:mm:ss')
                              }
                              onChange={e => {
                                setTimeStart(
                                  moment(e, 'HH:mm').format('HH:mm'),
                                );
                              }}
                              disabled={date === '' ? true : false}
                              min={startTimeOfeventServer}
                              format="h:mm a"
                              use12Hours
                              placeholder="--:-- --"
                              allowEmpty={false}
                              placement="bottomLeft"
                              open={startPickerOpen}
                              onOpen={() => {
                                setStartPickerOpen(true);
                              }}
                              onClose={() => {
                                setStartPickerOpen(false);
                              }}
                            /> */}


                    <Calender 
                        type='singleTime' 
                        setStartDaysTime={e => {
                          if(e){
                          setTimeStart(moment(e, 'HH:mm').format('HH:mm'));
                          }else{
                            setTimeStart('')
                          }
                        }} 
                        startDaysTime={ timeStart === ''
                          ? ''
                          : moment(timeStart, 'HH:mm:ss')} // Ensure the moment object is valid before passing
                        showDate={false} 
                        showTime={date !== "" ? true : false} 
                        setSelectedDate={setDate} 
                        selectedDate={date} 
                        serverTimeS={serverTimeS}
                        closeDaysTime={timeEnd}
                      />


                            {timeStart !== '' && (
                              <span
                                className="input-icon"
                                onClick={() => {
                                  clearStartTime();
                                }}>
                                <i
                                  className="fas fa-close"
                                  style={{paddingLeft: '210px'}}
                                />
                              </span>
                            )}
                            {startTimeError &&
                              timeStart === '' &&
                              (inPersonOnly || location) && (
                                <div className="text-danger textforerror">
                                  Time is required.
                                </div>
                              )}
                            <Form.Control.Feedback type="invalid">
                              {timeStart === ''
                                ? 'Time is required.'
                                : 'Invalid time.'}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="fieldset">
                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label>
                              End Time{' '}
                              {inPersonOnly || location ? '' : '(Optional)'}
                            </Form.Label>
                            {/* <div className="d-flex align-items-center">
                              {timeEnd !== '' && (
                                <span
                                  className="clear-btn"
                                  onClick={() => {
                                    clearEndTime();
                                  }}>
                                  Clear
                                </span>
                              )}
                            </div> */}
                          </div>

                          <div className="form-control-with-icon create_session_timepicker">
                            {/* <Form.Control
                              required={inPersonOnly || location ? true : false}
                              disabled={date === '' ? true : false}
                              min={moment(timeStart).format('hh:mm')}
                              value={timeEnd}
                              name="endTime"
                              type="time"
                              placeholder="End Time"
                              onChange={e => {
                                setTimeEnd(e.target.value);
                                // EventData();
                              }}
                            /> */}

                            {/* <TimePicker
                              showSecond={false}
                              value={
                                timeEnd === ''
                                  ? ''
                                  : moment(timeEnd, 'HH:mm:ss')
                              }
                              onChange={e => {
                                setTimeEnd(moment(e, 'HH:mm').format('HH:mm'));
                              }}
                              disabled={date === '' ? true : false}
                              min={startTimeOfeventServer}
                              format="h:mm a"
                              use12Hours
                              placeholder="--:-- --"
                              allowEmpty={false}
                              placement="bottomLeft"
                              open={endPickerOpen}
                              onOpen={() => {
                                setEndPickerOpen(true);
                              }}
                              onClose={() => {
                                setEndPickerOpen(false);
                              }}
                            /> */}

                <Calender
                    type='singleTime' 
                    setCloseDaysTime={e => {
                      if(e){
                      setTimeEnd(moment(e, 'HH:mm').format('HH:mm'));
                      }else{
                        setTimeEnd('')
                      }
                    }} 
                    minTime={startTimeOfeventServer} 
                    closeDaysTime={timeEnd === ''
                      ? ''
                      : moment(timeEnd, 'HH:mm:ss')} // Ensure the moment object is valid before passing
                    showDate={false} 
                    showTime={date !== "" ? true : false} 
                    checkTime='checkTime'
                    startDaysTime={timeStart ? timeStart : ''}
                  />

                            {timeEnd !== '' && (
                              <span
                                className="input-icon"
                                onClick={() => {
                                  clearEndTime();
                                }}>
                                <i
                                  className="fas fa-close"
                                  style={{paddingLeft: '210px'}}
                                />
                              </span>
                            )}

                            {endTimeError &&
                              timeEnd === '' &&
                              (inPersonOnly || location) && (
                                <div className="text-danger textforerror">
                                  Time is required.
                                </div>
                              )}

                            {/* <Form.Control.Feedback type="invalid">
                              {timeEnd === ''
                                ? 'Time is required.'
                                : 'Invalid Time.'}
                            </Form.Control.Feedback> */}
                          </div>

                          {/* <Form.Control
                            // required={topIcons.focus ? true : false}
                            min={moment(timeStart).format('hh:mm')}
                            value={timeEnd}
                            name="endTime"
                            type="time"
                            placeholder="End Time"
                            onChange={e => {
                              setTimeEnd(e.target.value);
                              // EventData();
                            }}
                          /> */}
                        </div>
                      </>
                    )}

                    {/* {topIcons.focus && (
                      <>
                        <div className="fieldset">
                          <Form.Label>Available tickets</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            min="1"
                            disabled={props.editEvent ? true : false}
                            value={ticket}
                            name="maxTickets"
                            placeholder="Maximum ticket purchases"
                            onChange={e => {
                              const value = parseInt(e.target.value);
                              if (!isNaN(value) && value >= 0) {
                                setTicket(e.target.value);
                              } else {
                                setTicket('');
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {ticket === ''
                              ? 'Maximum tickets required.'
                              : 'Invalid maximum ticket.'}
                          </Form.Control.Feedback>
                        </div>
                      </>
                    )} */}
                    {/* {props.eventData && (
                    <div className="fieldset">
                      <Form.Label>Available tickets</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        value={ticket}
                        name="maxTickets"
                        placeholder="Maximum ticket purchases"
                        onChange={e => {
                          setTicket(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please write tickets
                      </Form.Control.Feedback>
                    </div>
                  )} */}
                    {/* && !props.eventData  */}
                    <div className="d-flex align-items-center flex-wrap mb-3 ticket-session-img-btn-holder">
                      <div className="" style={{marginRight: 30}}>
                        <div className={`ticket-img-wrapper mb-0 m-0 `}>
                          {ticketImg && (
                            <p
                              onClick={() => {
                                // setImgModal(true);
                                if(sliderImage.length > 0){
                                  setModelImg(sliderImage[0]);
                                  setShowModel('image_pre');
                                }
                               
                              }}>
                              Onclick
                            </p>
                          )}

                          <input
                            type="file"
                            accept="image/*"
                            onChange={e => changeTicketImg(e)}
                          />
                          {ticketImg && <img src={ticketImg} alt="" />}
                          <i className="icon-camera as-link" />
                        </div>

                        {/* {sliderImage.length > 0 && (
                          <span
                            className="as-link info-text mb-3 mt-0"
                            onClick={() => {
                              setImgModal(true);
                            }}>
                            Preview
                          </span>
                        )} */}
                      </div>

                      {!props.editEvent && (
                        <div className=" flex-1 text-right">
                          <Button
                            className="btn-dark dark_btn"
                            onClick={() => {
                              if (
                                timeEnd === '' ||
                                timeStart === '' ||
                                date === '' ||
                                timeEnd < timeStart ||
                                timeEnd === timeStart
                              ) {
                                errorMessage('Select a valid time ');
                              } else {
                                setShowComponent('AddTicketOption');
                              }
                            }}>
                            Ticket Options
                          </Button>
                        </div>
                      )}
                    </div>
                    {/*  */}
                    <Button
                      type="submit"
                      style={{display: 'none'}}
                      ref={buttonRef}></Button>
                  </>
                )}
              </Form>

              {tabType === labels.sessions_type_invites[selected_lang] && (
                <>
                  <div className="refferal-list-wrapper">
                    {inviteLoader && (
                      <div className="invite_loader_hieght">
                        <LoaderSpinner />
                      </div>
                    )}
                    {!inviteLoader && (
                      <>
                        <div
                          className="stage-list-item invitation_outside as-link text-left d-flex align-items-center"
                          onClick={() => {
                            setShowComponent('inviteUser');
                          }}>
                          <div className="stage-person-img addcontact-image">
                            <img alt="" src={UserProfileImg} />
                          </div>
                          <p className="p-reg text-gey">New Contact</p>
                        </div>
                        <div className="CreateSession-invite-radio-btns ">
                          {Object.entries(RadioButtonName).length > 0 &&
                            RadioButtonName.map((item, index) => (
                              <Form.Check
                                type="radio"
                                // name="radioGroup"
                                label={item}
                                id={item}
                                value={item}
                                checked={invitesOptionvalue === item}
                                onChange={handleInviteOption}
                              />
                            ))}
                        </div>
                      </>
                    )}

                    <Invite
                      // type={'session'}
                      moderator={modrator}
                      setModerator={setModrator}
                      selectedModData={selectedModData}
                      setSelectedModData={setSelectedModData}
                      audience={audience}
                      setAudience={setAudience}
                      selectedAudData={selectedAudData}
                      setSelectedAudData={setSelectedAudData}
                      contact={contact}
                      setContact={setContact}
                      originalDataOfMod={originalDataOfMod}
                      setOriginalDataOfMod={setOriginalDataOfMod}
                      originalDataOfAud={originalDataOfAud}
                      setOriginalDataOfAud={setOriginalDataOfAud}
                      subTabs={subTabs}
                      setSubTabs={setSubTabs}
                      alreadyAddedMod={alreadyAddedMod}
                      handleCircle={handleCircle}
                      editEvent={
                        props.editSession || props.editEvent ? true : false
                      }
                      onClick={() => {
                        setShowComponent('inviteUser');
                        setTimeout(() => {
                          if (contact.length === 0 && inviteQry !== '') {
                            setInviteFollowers(followersList);
                            setInviteQry('');
                          }
                        }, 1000);
                      }}
                      forApiHit={showComponent}
                      showLoader={inviteLoader}
                      setshowLoader={setInviteLoader}
                      followers={inviteFollowers}
                      setFollowers={setInviteFollowers}
                      setFollowersList={setFollowersList}
                      followersList={followersList}
                      setSearchQry={setInviteQry}
                      searchQry={inviteQry}
                    />
                  </div>
                </>
              )}
              {tabType === 'Sponsors' && (
                <Sponsors setArray={setSponserArray} array={sponserArray} />
              )}
            </div>
          </Scrollbars>

          {tabType === labels.sessions_type_general[selected_lang] && (
            <div className="filter-btn-wrapper pt-1 pb-1 create-session-btn mx-0 bg_transparent bottom-0">
              <div
                className="invite-btn w-100 text-center"
                style={
                  {
                    // marginBottom: '30px',
                  }
                }>
                {/* &&
                        !props.referSession &&
                        !props.eventData  */}

                {topIcons.focus && !props.editSession && !props.editEvent && (
                  <Button
                    disabled={showLoader || disableBtn ? true : false}
                    className={
                      disableBtn ? 'disable btn-dark m-0' : 'btn-dark m-0'
                    }
                    type="submit"
                    onClick={() => {
                      buttonRef.current.click();
                    }}>
                    Create Meetups
                  </Button>
                )}

                {/* // !props.referSession &&
                        // !props.eventData && */}

                {!topIcons.focus && !props.editSession && !props.editEvent && (
                  <Button
                    disabled={showLoader || disableBtn ? true : false}
                    className={
                      disableBtn
                        ? 'disable btn-dark mb-1 mt-1'
                        : 'btn-dark mb-1 mt-1'
                    }
                    type="submit"
                    onClick={() => {
                      buttonRef.current.click();
                    }}>
                    Create Meetups
                  </Button>
                )}

                {/* && props.referSession */}
                {topIcons.focus && props.editEvent && !props.editSession ? (
                  <Button
                    disabled={showLoader || disableBtn ? true : false}
                    className={disableBtn ? 'disable btn-dark' : 'btn-dark'}
                    type="submit"
                    onClick={() => {
                      buttonRef.current.click();
                    }}>
                    Update Meetups
                  </Button>
                ) : (
                  props.editSession &&
                  !topIcons.focus &&
                  !props.editEvent && (
                    <Button
                      disabled={showLoader || disableBtn ? true : false}
                      className={disableBtn ? 'disable btn-dark' : 'btn-dark'}
                      type="submit"
                      onClick={() => {
                        buttonRef.current.click();
                      }}>
                      Update Meetups
                    </Button>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {showComponent === 'Builders' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          //  onCloseHitApi={()=>{
          //   setShowComponent('');
          //     setInviteLoader(true)
          // }}
          eventId={eventId}
          creatorId={createrId}
          // setInviteCircle={setInviteCircle}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          onClose={() => {
            setShowComponent('');
          }}

          // onCloseHitApi={()=>{
          //   setShowComponent('');
          //   if(contact.length > 0){
          //     setInviteLoader(true)
          //   }
          // }}
        />
      )}
      {showComponent === 'SessionFocus' && (
        <SessionFocus
          setTopIcons={setTopIcons}
          topIcons={topIcons}
          setIdentiferId={setIdentiferId}
          setEthnicityId={setEthnicityId}
          setGender={setGender}
          gender={gender}
          ethnicityId={ethnicityId}
          identiferId={identiferId}
          setFocusLocations={setFocusLocations}
          setPersnalTypesId={setPersnalTypesId}
          setCategoriesId={setCategoriesId}
          setAppointmentId={setAppointmentId}
          persnalTypesId={persnalTypesId}
          appointmentId={appointmentId}
          categoriesId={categoriesId}
          setShowModelDecesion={setShowModelDecesion}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {/* ticket && // */}
      {showComponent === 'AddTicketOption' && (
        <AddTicketOption
          ticket={ticket}
          array={array}
          setArray={setArray}
          eventStartDateTime={eventEndDateTime}
          serverTimeS={serverTimeS}
          tickectsTypeDecesion={tickectsTypeDecesion}
          // costT={costT}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {newButton === true && showComponent === 'CreateSnapShot' && (
        <CreateSnapShot
          images={images}
          setHostId={setHostId}
          setImages={setImages}
          hostId={hostId}
          onClose={() => {
            setNewButton(false);
            setShowComponent('');
          }}
          snapShotButton={snapShotButton}
        />
      )}

      {audience.length > 0 && showComponent === 'AudSelectedUsers' && (
        <SelectedUsers
          onClose={() => {
            setShowComponent('');
          }}
          selectedAllUser={selectedAudData}
          setSelectedAllUser={setSelectedAudData}
          setUsers={setAudience}
          users={audience}
          alreadyAddedUser={alreadyAddedAud}
          locationData={locationData}
          //   enterSession={enterSession}
        />
      )}

      {modrator.length > 0 && showComponent === 'ModSelectedUsers' && (
        <SelectedUsers
          onClose={() => setShowComponent('')}
          selectedAllUser={selectedModData}
          setSelectedAllUser={setSelectedModData}
          setUsers={setModrator}
          users={modrator}
          alreadyAddedUser={alreadyAddedMod}
        />
      )}

      {repeateOption && (
        <TicketDateOption
          serverTimeS={serverTimeS}
          date={date}
          setDate={setDate}
          timeStart={timeStart}
          setTimeStart={setTimeStart}
          timeEnd={timeEnd}
          setTimeEnd={setTimeEnd}
          startTimeOfeventServer={startTimeOfeventServer}
          topIcons={topIcons}
          show={repeateOption}
          onClose={() => setRepeatOption(!repeateOption)}
          // timeStart={timeStart}
          // date={date}
          selectedDays={selectedDays}
          exculdedDates={exculdedDates}
          occurrences={occurrences}
          recursionDateTime={recursionDateTime}
          weekMonth={weekMonth}
          startAgainAfter={startAgainAfter}
          startAgainWeek={startAgainWeek}
          setRepeat={setRepeat}
          isRepeat={isRepeat}
          selectWeekDay={selectWeekDay}
          setExculdedDates={setExculdedDates}
          setOccurrences={setOccurrences}
          setRecursionDateTime={setRecursionDateTime}
          setWeekMonth={setWeekMonth}
          setStartAgainAfter={setStartAgainAfter}
          setStartAgainWeek={setStartAgainWeek}

          // startDateTime={
          //   topIcons.focus
          //     ? moment(eventStartDateTime).format('MM-DD-YY hh:mm A')
          //     : sessionStartTime === 'Invalid date'
          //     ? ''
          //     : moment(date).format('MM-DD-YY hh:mm A')
          // }
        />
      )}

      {imgModal && (
        <SlidingPanel hidePanel={() => setImgModal(false)}>
          <div className="serach-filter-wrapper">
            <div className="permission-modal-content text-left">
              <h3>Images</h3>
            </div>

            <Scrollbars className="custom-scroll">
              <div className="snapshot-preview-wrapper fix-width-forms">
                <Row>
                  {Object.entries(sliderImage).length > 0 ? (
                    Object.entries(sliderImage).length > 0 &&
                    sliderImage.map((item, index) => {
                      return (
                        <Col md={4} sm={4} key={`image-${index}`}>
                          <div className="snapshot-item">
                            <span
                              className="remove-icon"
                              onClick={() => {
                                setShowModel('image_remove_alert');
                              }}>
                              <i className="fas fa-times" />
                            </span>

                            <img
                              className="as-link"
                              src={item}
                              alt=""
                              onClick={() => {
                                setModelImg(item);
                                setShowModel('image_pre');
                              }}
                            />
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <p className="text-center">No Images Found</p>
                  )}
                </Row>
              </div>
            </Scrollbars>
          </div>
        </SlidingPanel>
      )}
      {showModel === 'image_pre' && modelImg && (
        <ImagePreview
          modelImg={modelImg}
          onClose={() => {
            setShowModel('');
          }}
        />
      )}
      {showModel === 'image_remove_alert' && (
        <AlertModel
          // modelImg={modelImg}
          onClose={() => {
            setShowModel('');
          }}
          removeImage={() => {
            setSliderImage(prevImgs => prevImgs.slice(1));
            // setImgUrl('');
            setTicketImg('');
          }}
        />
      )}

      {/* <Modal
        show={imgModal}
        onHide={() => {
          setImgModal(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Images</Modal.Title>
          <div className="modal-header-btns">
            <span className="close as-link" onClick={() => setImgModal(false)}>
              <i className="fas fa-times" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="gallery-imgs">
              <Row>
                {Object.entries(sliderImage).length > 0 ? (
                  Object.entries(sliderImage).length > 0 &&
                  sliderImage.map((item, index) => {
                    return (
                      <Col md={4} sm={4} key={`image-${index}`}>
                        <span
                          className="text-center ml-5 pl-5"
                          onClick={() => {
                            
                            setSliderImage(prevImgs => prevImgs.slice(1));
                            setTicketImg('');
                          }}>
                          <i className="fas fa-times" />
                        </span>
                        <div className="gallery-item">
                          <div className="mt-5">
                            <img src={item} alt="" />
                          </div>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <>No Images Found</>
                )}
              </Row>
            </div>
          </>
        </Modal.Body>
      </Modal> */}
      {showComponent === 'persnal-model' && (
        <PersonalCreateModel
          list={list}
          setWorkSpaceIds={setWorkSpaceIds}
          workSpaceIds={workSpaceIds}
          onClose={() => {
            setShowComponent('');
            setTopIcons({
              ...topIcons,
              personal: !topIcons.personal,
            });
          }}
        />
      )}

      {showModalButtons && (
        <CustomModal
          size="small"
          className="session-events-creation-modals"
          onClose={() => setShowModalButtons(false)}>
          <div className="permission-modal-content text-center">
            {showModelDecesion === 'snapshot' && (
              <h3>
                <i className="icon-store  "></i>
                Host
              </h3>
            )}
            {showModelDecesion === 'dollar' && (
              <h3 className="d-flex align-items-center justify-content-center">
                <i className="icon-dollar-sign" />
                Paid
              </h3>
            )}
            {showModelDecesion === 'focus' && (
              <h3>
                <i className="icon-tickets" />
                Event
              </h3>
            )}
            {showModelDecesion === 'visibility' && (
              <h3>
                <i className="fas fa-lock" />
                Private
              </h3>
            )}
            {showModelDecesion === 'location' && (
              <h3>
                <i className="fas fa-map-marker-alt" />
                Location
              </h3>
            )}

            {showModelDecesion === 'dollar' && (
              <p className="mb-0">This feature is required payments.</p>
            )}
            {showModelDecesion === 'snapshot' && (
              <p className="mb-0">
                Are you sure you want to enable this feature?
              </p>
            )}
            {showModelDecesion === 'focus' && (
              <p className="mb-0">
                This will convert your session to an event.
              </p>
            )}
            {showModelDecesion === 'visibility' && (
              <p className="mb-0">This feature is for invites only.</p>
            )}
            {showModelDecesion === 'location' && (
              <p className="mb-0">This feature is enable location.</p>
            )}

            <div className="event-btn-wrapper">
              <Button
                className="btn-dark mt-0"
                onClick={() => {
                  if (showModelDecesion === 'snapshot') {
                    setSnapShotButton(true);
                    setNewButton(true);
                    setShowComponent('CreateSnapShot');
                  }
                  if (showModelDecesion === 'focus') {
                    setTopIcons({
                      ...topIcons,
                      focus: !topIcons.focus,
                      location: topIcons.focus ? false : true,
                      store: topIcons.focus ? true : false,
                    });
                  }
                  if (showModelDecesion === 'dollar') {
                    setTopIcons({
                      ...topIcons,
                      dollar: !topIcons.dollar,
                    });
                    setArray([]);
                  }
                  if (showModelDecesion === 'visibility') {
                    setTopIcons({
                      ...topIcons,
                      vaccinate: !topIcons.vaccinate,
                    });
                    privatee();
                  }
                  if (showModelDecesion === 'location') {
                    setTopIcons({
                      ...topIcons,
                      location: !topIcons.location,
                      focus: !topIcons.location ? topIcons.focus : false,
                    });
                    clearAll();
                  }

                  setShowModalButtons(false);
                }}>
                {hostId === '' && showModelDecesion === 'snapshot' && ' Enable'}
                {topIcons.focus && showModelDecesion === 'focus'
                  ? 'Disable'
                  : !topIcons.focus &&
                    showModelDecesion === 'focus' &&
                    ' Enable'}
                {topIcons.dollar && showModelDecesion === 'dollar'
                  ? 'Disable'
                  : !topIcons.dollar &&
                    showModelDecesion === 'dollar' &&
                    ' Enable'}
                {topIcons.vaccinate && showModelDecesion === 'visibility'
                  ? 'Disable'
                  : !topIcons.vaccinate &&
                    showModelDecesion === 'visibility' &&
                    ' Enable'}
                {topIcons.location && showModelDecesion === 'location'
                  ? 'Disable'
                  : !topIcons.location &&
                    showModelDecesion === 'location' &&
                    ' Enable'}
              </Button>
              <div className="text-center mt-2 as-link">
                <span
                  onClick={() => {
                    setShowModalButtons(false);
                  }}>
                  Nevermind
                </span>
              </div>
              {/* {showModelDecesion === 'dollar' && 
              <div className=" text-center mt-2 as-link">
                <span
                  onClick={() => {
                    setShowModalButtons(false);
                  }}>
                  Update Banking Details
                </span>
              </div>} */}
            </div>
          </div>
        </CustomModal>
      )}

      {showModalSesion && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModalSesion(false);
            props.onClose();
          }}>
          <div className="new-session-modal-wrapper text-center createSessionModal session-success-modal">
            <div className="new-session-modal">
              <h3 className="p-reg mb-2">
                You have successfully created a Meetups {title}
              </h3>
              <p className="p-bold mb-2">
                {sessionDolby && sessionDolby.session_title}
              </p>
              <Button className="btn-dark mb-2" onClick={() => enterSession()}>
                Start Virtual
              </Button>
              <p className="p-reg">
                {/* invite more people after starting your session with the "+" icon
                or copy the URL below. */}
                Invite more people by copying the URL below.
              </p>
              <div
                className="copy-link-text d-flex align-items-center justify-content-center m-0"
                onClick={() => {
                  navigator.clipboard.writeText(sessionLink);
                  successMessage(labels.copy_link[selected_lang]);
                }}>
                <i className="icon-link" />
                Copy Invite URL
              </div>
            </div>
          </div>
        </CustomModal>
      )}
      {showComponent === 'eventbrite' && (
        <EventBrite
          sessionCategories={sessionCategories}
          selectedEventbriteId={selectedEventbriteId}
          list={list}
          setWorkSpaceIds={setWorkSpaceIds}
          workSpaceIds={workSpaceIds}
          eventBriteEventsData={eventBriteEventsData}
          eventBriteEvents={eventBriteEvents}
          closeScreenEventBrite={closeScreenEventBrite}
          eventBriteCount={eventBriteCount}
          setCloseScreenEventBrite={setCloseScreenEventBrite}
          onClose={() => {
            setShowComponent('');
          }}
          onClose2={() => {
            props.onClose();
            setShowComponent('');
          }}
        />
      )}

      {showModalEventbrite && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowModalEventbrite(false);
            setToggleEventBrite(!toggleEventBrite);
          }}>
          <div className="new-session-modal-wrapper text-center createSessionModal eventBrite-Modal">
            <div className="new-session-modal">
              <h3 className="p-reg mb-2">Eventbrite Account</h3>
              <p className="p-bold mb-2">
                You have not any account. Linked your Account First.
              </p>

              <Button
                className="btn-dark mb-2"
                onClick={() => {
                  setShowModalEventbrite(false);
                  eventBriteLogin();
                  // setShowComponent('persnalModelForEventBrite');
                  setCheckEventBritelogin(true);
                }}>
                Yes
              </Button>

              <span
                //  variant="primary"
                className="d-block text-yellow as-link mt-3-bold"
                onClick={() => {
                  setShowModalEventbrite(false);
                  setToggleEventBrite(!toggleEventBrite);
                }}>
                No Thanks!
              </span>

              {/* <div
                className="copy-link-text d-flex align-items-center justify-content-center"
                onClick={() => {
                  navigator.clipboard.writeText(sessionLink);
                  successMessage(labels.copy_link[selected_lang]);
                }}>
                <i className="icon-link" />
                Copy Invite URL
              </div> */}
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'persnalModelForEventBrite' && (
        <PersonalCreateModel
          type="eventbrite"
          list={list}
          setWorkSpaceIds={setWorkSpaceIdsforBrite}
          workSpaceIds={workSpaceIdsforBrite}
          onClose={() => {
            setShowComponent('');
          }}
          setToggleEventBrite={setToggleEventBrite}
          toggleEventBrite={toggleEventBrite}
          setCheckEventBritelogin={setCheckEventBritelogin}
          eventBriteLogin={eventBriteLogin}
          setLocationData={setLocationData}
        />
      )}

      {/* {cropImg && (
        <CustomModal
          size="large"
          onClose={() => {
            setCropImg(false);
          }}>
          <div className="new-session-modal-wrapper text-center createSessionModal">
            <div className="new-session-modal">
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={c => setCompletedCrop(c)}
                aspect={aspect}
                // minWidth={400}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                />
              </ReactCrop>

              {completedCrop && (
                <>
                  <div>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        display: 'none',
                      }}
                    />
                  </div>
                </>
              )}
              <Button
                className="btn-dark mb-2"
                onClick={() => {
                  handleImg();
                  setCropImg(false);
                }}>
                Crop Image
              </Button>
            </div>
          </div>
        </CustomModal>
      )} */}

      {showModel === 'modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="event"
            ratio={429 / 187}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            imgUrl={ticketImg}
            cropImg={cropImg}
            setImgUrl={setTicketImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setshowLoader}
          />
        </CustomModal>
      )}

      {modalEndEvent && (
        <CustomModal
          className="cancle-event-modal"
          onClose={() => setModalEndEvent(false)}>
          <div className="remove-user-text">
            <p>
              {' '}
              Do you want to cancel this{' '}
              {props.editSession ? 'session' : 'event'}?
            </p>
            <Button className="btn-dark" onClick={cancelSession}>
              Yes
            </Button>
            <Button
              className="btn-dark"
              onClick={() => {
                setModalEndEvent(false);
              }}>
              No
            </Button>
          </div>
        </CustomModal>
      )}

      {showModel === 'calneder' && calendarData.length > 0 && (
        <CalanderModel
          calendarData={calendarData}
          typeId={conferenceId}
          type={'conference'}
          event={events}
          accountType={accountType}
          setShowModel={setShowModel}
          onClose={() => {
            props.onClose();
            if(props.appointment && props.onCloseHitApi){
              props.onCloseHitApi()
            }
          }}
        />
      )}

      {showModel === 'eventbrite-model' && eventBriteList.length > 0 && (
        <CustomModal
          style={{height: '100%'}}
          onClose={() => {
            setShowModel('');
            setToggleEventBrite(!toggleEventBrite);
          }}>
          {/* {showLoader && <LoaderModal show={showLoader} />} */}

          <div className="serach-filter-wrapper">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="headings m-0">
                <h2 className="m-0">Select your Desired Account</h2>
              </div>

              {/* <div className="sidebar-heading-wrapper mt-0 text-right">
                            <div className="ticket-icons-options">
                              <ul>
                                <li
                                  className={'active'}
                                  onClick={() => {
                                    setshowComponent('persnal-model');
                                  }}>
                                  <PersnalSvg fill={'white'} />
                                </li>
                              </ul>
                            </div>
                          </div> */}
            </div>

            <div className="filter-wrapper">
              <div className="filter-category-list">
                <ul>
                  {Object.entries(eventBriteList).length > 0 &&
                    eventBriteList.map((item, index) => (
                      <li
                        key={`sessionC-${index}`}
                        //  className={
                        //   //  props.isCheck.includes(item.id) ? 'active' : ''
                        //  }
                      >
                        <Form.Label>
                          {item.email}
                          <input
                            type="checkbox"
                            id={item.id}
                            value={item.id}
                            onChange={() => {
                              handleEventbriteItem(item);
                            }}
                            checked={modelId.includes(item.id)}
                          />
                          <span />
                        </Form.Label>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            {/* </Scrollbars> */}
            <div className="filter-btn-wrapper pt-3">
              <Button
                className="btn-dark"
                type="submit"
                onClick={() => {
                  if (modelIdItem.length > 0) {
                    // AddWorksapceIds();
                    AddEventBriteAccountId(modelId);
                  } else {
                    errorMessage('One workspace should be selected.');
                  }
                }}>
                Select
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};
export default CreateSession;
